import { History } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { init, RematchRootState } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
// import createHistory from 'history/createBrowserHistory';
import auth from '../models/auth';
import errors from '../models/errors';
import exam from '../models/exam';
import applicant from '../models/applicant';
import examApplications from '../models/examApplications';
import notifications from '../models/notifications';
import eventsState from '../models/events';
import regions from '../models/regions';
import countries from '../models/countries';
import qualifications from '../models/qualifications';

const createHistory = require('history').createBrowserHistory;

const models = {
    auth,
    errors,
    examApplications,
    exam,
    regions,
    countries,
    qualifications,
    applicant,
    notifications,
    eventsState,
};

export const browserHistory: History = createHistory();

export const store = init({
    models,
    plugins: [createLoadingPlugin({})],
    redux: {
        initialState: {},
        reducers: {
            router: connectRouter(browserHistory),
        },
        middlewares: [routerMiddleware(browserHistory)],
    },
});

export type Store = typeof store;
export type Dispatch = typeof store.dispatch;
export type IRootState = RematchRootState<typeof models> & LoadingPlugin;

interface LoadingPlugin {
    loading: {
        global?: boolean;
        models: RematchRootState<typeof models>;
        effects: Dispatch;
    };
}
