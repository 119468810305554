import React, { useState } from 'react';
import RegistrationStep1 from './RegistrationStep1';
import RegistrationStep2 from './RegistrationStep2';
import RegistrationStep3 from './RegistrationStep3';
import RegistrationStep4 from './RegistrationStep4';

const RegistrationContainer: React.FC = () => {
    const [step, setStep] = useState(1);

    return (
        <React.Fragment>
            {step === 1 && <RegistrationStep1 nextStep={() => setStep(2)} />}
            {step === 2 && (
                <RegistrationStep2
                  nextStep={() => setStep(3)}
                  prevStep={() => setStep(1)}
                />
            )}
            {step === 3 && <RegistrationStep3 nextStep={() => setStep(4)} />}
            {step === 4 && <RegistrationStep4 />}
        </React.Fragment>
    );
};

export default RegistrationContainer;
