const MediaConstraints = {
    default: {
        audio: true,
        video: {
            width: 960,
            height: 720,
            frameRate: 8,
            aspectRatio: 1.33,
        },
    },

    screen: {
        audio: false,
        video: {
            width: 1280,
            height: 720,
            frameRate: 5,
        },
    },

    timeout: 10000,
};

// eslint-disable-next-line no-underscore-dangle
(window as any).__mediaConstraints = MediaConstraints;

export default MediaConstraints;
