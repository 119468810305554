import * as React from 'react';

const StatusIcon: React.FC = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.09711 0.055542H12.2221C12.7974 0.055542 13.2638 0.521912 13.2638 1.09721V12.9028C13.2638 13.4781 12.7974 13.9444 12.2221 13.9444H1.111C0.535706 13.9444 0.0693359 13.4781 0.0693359 12.9028V8.04165C0.0693359 7.46636 0.535706 6.99999 1.111 6.99999H4.236V4.56943C4.236 3.99413 4.70237 3.52776 5.27767 3.52776H8.05545V1.09721C8.05545 0.521912 8.52182 0.055542 9.09711 0.055542ZM1.111 8.38888V12.5555C1.111 12.7473 1.26646 12.9028 1.45822 12.9028H4.236V8.04165H1.45822C1.26646 8.04165 1.111 8.19711 1.111 8.38888ZM5.27767 12.9028V8.04165V4.91665C5.27767 4.72489 5.43313 4.56943 5.62489 4.56943H8.05545V12.9028H5.27767ZM9.09711 1.44443V12.9028H11.8749C12.0667 12.9028 12.2221 12.7473 12.2221 12.5555V1.44443C12.2221 1.25267 12.0667 1.09721 11.8749 1.09721H9.44434C9.25257 1.09721 9.09711 1.25267 9.09711 1.44443Z"
          />
      </g>
  </svg>
);

export default StatusIcon;
