import {
    ApplicantQualification,
    ApplicantQualificationDetails,
    ApplicantIdentityDoc,
    SetApplicantPassport,
    ISetApplicantPassport,
    SetApplicantOtherIdentity,
    ISetApplicantOtherIdentity,
    QualificationDocumentTemplateGroup,
    ISendCodeToSignApplication,
    SendCodeToSignApplication,
    ApplicantDiploma,
    IUpdateApplicantDiploma,
    UpdateApplicantDiploma,
} from '../../api/ApiClient';
import { INotification } from 'components';
import { createModel } from '@rematch/core';
import apiConnector from '../../api/ApiConnector';

const initialState: IState = {
    qualificationsList: null,
    qualificationDetails: null,
    applicantIdentityDoc: null,
    documentTemplateGroups: null,
    applicantDiploma: null,
};

export default createModel({
    state: initialState,
    reducers: {
        setQualificationsList(state, qualificationsList: ApplicantQualification[] | null) {
            return { ...state, qualificationsList };
        },
        setQualificationDetails(state, qualificationDetails: ApplicantQualificationDetails | null) {
            return { ...state, qualificationDetails };
        },
        setDocumentTemplateGroups(state, documentTemplateGroups: QualificationDocumentTemplateGroup[] | null) {
            return { ...state, documentTemplateGroups };
        },
        setApplicantIdentityDoc(state, applicantIdentityDoc: ApplicantIdentityDoc | null) {
            return { ...state, applicantIdentityDoc };
        },
        setApplicantDiploma(state, applicantDiploma: ApplicantDiploma | null) {
            return { ...state, applicantDiploma };
        },
        clearState() {
            return {
                ...initialState,
            };
        },
        clearQualificationDetails(state): IState {
            return {
                ...state,
                qualificationDetails: null,
            };
        },
    },
    effects: (dispatch: any) => ({
        async getQualificationsListAsync() {
            const qualificationsList = await apiConnector.execApi(dispatch, apiClient => apiClient.getApplicantQualifications());

            this.setQualificationsList(qualificationsList);
        },
        async getQualificationDetailsAsync(examApplicationId: string) {
            const qualificationDetails = await apiConnector.execApi(dispatch, apiClient => apiClient.getApplicantQualificationDetails(examApplicationId));

            this.setQualificationDetails(qualificationDetails);

            return qualificationDetails;
        },
        async getIdentityDocAsync() {
            const applicantIdentityDoc = await apiConnector.execApi(dispatch, apiClient => apiClient.getIdentityDoc());

            this.setApplicantIdentityDoc(applicantIdentityDoc);
            return applicantIdentityDoc;
        },
        async getApplicantDiplomaAsync() {
            const diploma = await apiConnector.execApi(dispatch, apiClient => apiClient.getApplicantDiploma());
            this.setApplicantDiploma(diploma);
            return diploma;
        },
        async updateApplicantDiplomaAsync(data: IUpdateApplicantDiploma) {
            const diploma = await apiConnector.execApi(dispatch, apiClient => apiClient.updateApplicantDiploma(new UpdateApplicantDiploma(data)));

            this.setApplicantDiploma(diploma);
            return diploma;
        },
        async getDocumentTemplateGroupsAsync(qualificationId: string) {
            const documentTemplateGroups = await apiConnector.execApi(dispatch, apiClient => apiClient.getDocumentTemplateGroups(qualificationId));

            this.setDocumentTemplateGroups(documentTemplateGroups);

            return documentTemplateGroups;
        },
        async setApplicantPassportAsync(data: ISetApplicantPassport) {
            const applicantIdentityDoc = await apiConnector.execApi(dispatch, apiClient => apiClient.setApplicantPassport(new SetApplicantPassport(data)));

            this.setApplicantIdentityDoc(applicantIdentityDoc);
            // if (applicantIdentityDoc) {
            //     dispatch.notifications.addNotification([{ message: 'Паспортные данные успешно обновлены!', appearance: 'success' }] as INotification[]);
            // }
            return applicantIdentityDoc;
        },
        async setApplicantOtherIdentityAsync(data: ISetApplicantOtherIdentity) {
            const applicantIdentityDoc = await apiConnector.execApi(dispatch, apiClient => apiClient.setApplicantOtherIdentity(new SetApplicantOtherIdentity(data)));

            this.setApplicantIdentityDoc(applicantIdentityDoc);

            // if (applicantIdentityDoc) {
            //     dispatch.notifications.addNotification([{ message: 'Данные успешно обновлены!', appearance: 'success' }] as INotification[]);
            // }

            return applicantIdentityDoc;
        },
        async sendCodeToSignApplicationAsync(data: ISendCodeToSignApplicationProps) {
            const verificationCodeSendingStatus = await apiConnector.execApi(dispatch, apiClient => apiClient.sendCodeToSignApplication(
                data.examApplicationId,
                new SendCodeToSignApplication(data.body),
            ));

            return verificationCodeSendingStatus;
        },
        /* Clear state */
        clearExpertState() {
            this.clearState();
        },
    }),
});

interface IState {
    qualificationsList: ApplicantQualification[] | null;
    qualificationDetails: ApplicantQualificationDetails | null;
    applicantIdentityDoc: ApplicantIdentityDoc | null;
    documentTemplateGroups: QualificationDocumentTemplateGroup[] | null;
    applicantDiploma: ApplicantDiploma | null;
}

interface ISendCodeToSignApplicationProps {
    examApplicationId: string;
    body: ISendCodeToSignApplication;
}
