import React from 'react';
import {
    AnimatedTabs,
} from 'components';
import { Tab } from 'react-bootstrap';
import { ExamInfoTab } from '../ExamDetailsTabs/ExamInfoTab';
import { RulesTab } from '../ExamDetailsTabs/RulesTab';
import { IApplicantExamDetails } from 'applicant/src/api/ApiClient';
import moment from 'moment';

const ApplicantExamDetailsTabs = ({
    examDetails: exam,
    inModal,
}: IProps) => (
  <AnimatedTabs
    id="applicantExamDetailsTabsTabs"
    inModal={inModal}
  >
      <Tab eventKey={TabKey.Info} title="Информация" className="tab p-0">
          <ExamInfoTab
            examDetails={{
                ...exam,
                id: exam.examId,
                status: exam.examStatus,
                statusChangeTime: exam.applicationSubmitDate || moment(),
                examLogs: [],
                qualificationId: '',
                attestationSubCommissionId: '',
                attestationCommissionId: '',
                applicationsNumber: 0,
                examStages: [],
                examCreationProgress: [],
                minimumRequiredExpertsNumber: 0,
                secretaryPhone:'',
                secretaryFullName: '',
            }}
            withoutPadding={inModal}
          />
      </Tab>

      <Tab eventKey={TabKey.Rules} title="Правила проведения" className="tab p-0">
          <RulesTab />
      </Tab>
  </AnimatedTabs>
);

export default ApplicantExamDetailsTabs;

export interface IProps {
    examDetails: IApplicantExamDetails;
    inModal?: boolean;
}

enum TabKey {
    Info = 'info',
    Rules = 'rules',
}
