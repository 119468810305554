import moment from 'moment-timezone';
// import moment from 'moment';
// import moment from 'moment-timezone/moment-timezone-utils';

 class TimingSynchronizer {
    protected static instance = new TimingSynchronizer();

    private constructor() {
        moment.locale('ru');
    }

    private offset = 0;

    public setDelta(serverTime: string) {
        this.offset = moment(serverTime).diff(new Date());
        this.applyOffset();
    }

    public setTimeZone(zoneOffset: number) {
        this.offset = 0;
        const zoneName = `zone_${zoneOffset}`;
        // zone_3||-30|0| -> moscow
        moment.tz.add(`${zoneName}||${zoneOffset * -10}|0|`);
        moment.tz.setDefault(zoneName);
    }

    private applyOffset() {
        moment.now = this.calcOffset.bind(this);
    }

    private calcOffset() {
        return +new Date() + this.offset;
    }

    public static getInstance(): TimingSynchronizer {
        return TimingSynchronizer.instance;
    }
}

export const timingSynchronizer = TimingSynchronizer.getInstance();
