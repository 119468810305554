import { IBaseInfo, IPagination } from './models';
import { IPaginationData } from './Pagination/Pagination';

export const EmptyInfo: IBaseInfo<never, never> = { clear: () => {}, fetch: async () => {}, info: null };

export const EmptyPagination = { page: 0, pageSize: 0, total: 0 };

export class PaginationImpl implements IPagination {
    page: number;
    pageSize: number;
    total: number;

    constructor(data: IPagination | null = EmptyPagination) {
        const newPaging = data || EmptyPagination;
        this.page = newPaging.page;
        this.pageSize = newPaging.pageSize;
        this.total = newPaging.total;
    }

    getPages = () => Math.ceil(this.total / this.pageSize) || 1;

    getPaginationData = (): IPaginationData => ({
        pages: this.getPages(),
        page: this.page,
        perPage: this.pageSize,
    })
}
