import * as React from 'react';
import {
    ExamAssigningApplicationsColumnHeader,
    ExamAssigningApplicationsTable,
} from '../../../../../ExamAssigningApplicationsTable';
import {
    IApiError,
    IAssignApplicationsForDay,
    IAssignForDayInput,
    IBaseExamApplicationsForDay,
    IExamApplication,
    IExamDay,
    IExamDetails,
} from 'components/models';
import ConfirmDateModal from '../ConfirmDateModal';

const ExamApplicationsTab: React.FC<IProps> = (props) => {
    const applications = props.examApplicationsForDay.info ? props.examApplicationsForDay.info.items : [];
    const availableDays = props.days.filter(d => d.dayNumber !== props.day.dayNumber && d.canAssignApplications);

    const [showConfirmDate, setShowConfirmDate] = React.useState(false);
    const [assigningApplicantsForReplace, setAssigningApplicantsForReplace] = React.useState<IExamApplication[]>([]);

    const [selectedDayForReplace, setSelectedDayForReplace] = React.useState(availableDays[0] ? availableDays[0].dayNumber : 0);

    React.useEffect(() => {
        if (availableDays[0] && !selectedDayForReplace) {
            setSelectedDayForReplace(availableDays[0].dayNumber);
        }
    }, [props.day]);

    const handleAssignedApplicants = React.useCallback((applicants: IExamApplication[]) => {
        setShowConfirmDate(true);
        setAssigningApplicantsForReplace(applicants);
    }, []);

    const handleConfirmAssigning = async () => {
        const selectedDay = availableDays[selectedDayForReplace];
        if (selectedDay) {
            if (await props.assignApplicationsForDay({
                examId: props.examDetails.id,
                stage: props.stageNumber,
                examDayId: selectedDay.id,
                body: {
                    examApplicationIds: assigningApplicantsForReplace.map(a => a.examApplicationId),
                },
            })) {
                await props.examApplicationsForDay.fetch({
                    examDayId: props.day.id,
                    examId: props.day.examId,
                    stage: props.day.stageNumber,
                });
                setShowConfirmDate(false);
                setAssigningApplicantsForReplace([]);
            }
        }
    };

    const applicantHeaders: ExamAssigningApplicationsColumnHeader[] = [
        { value: 'Имя, фамилия', key: 'applicantFullName' },
        { value: 'Телефон', key: 'applicantPhone' },
    ];

    return (
      <>
          <ExamAssigningApplicationsTable
            readonly={props.readonly}
            withCheckbox
            withConfirmButton
            onAssignedApplicants={handleAssignedApplicants}
            confirmButtonText="Назначить выбранных на другой день"
            headers={applicantHeaders}
            examApplications={applications}
          />

          <ConfirmDateModal
            stage={props.examDetails.examStages[props.stageNumber - 1]}
            disableDateEditor
            show={showConfirmDate}
            onHide={() => setShowConfirmDate(false)}
            onAccess={handleConfirmAssigning}
            days={availableDays}
            selectedDayNumber={selectedDayForReplace}
            chooseDay={setSelectedDayForReplace}
            apiErrors={props.apiError}
          />
      </>
    );
};

interface IProps {
    readonly?: boolean;
    day: IExamDay;
    days: IExamDay[];
    examDetails: IExamDetails;
    stageNumber: number;

    examApplicationsForDay: IBaseExamApplicationsForDay;
    assignApplicationsForDay: (data: IAssignForDayInput<IAssignApplicationsForDay>) => Promise<unknown>;
    apiError?: IApiError | null;
}

export default ExamApplicationsTab;
