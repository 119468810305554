import { IExamDay } from '../../../models';


export interface IFakeExamDay extends Partial<IExamDay>{
    id: string;
    dayNumber: number;
}

const getRandomId = () => Math.ceil(Math.random() * 100000000000000);
export default (daysCount: number, start: number = 1): IFakeExamDay[] => [...Array(daysCount)].map((_, i) => ({
    id: String(getRandomId()),
    dayNumber: i + start,
    attemptNumber: undefined,
    time: undefined,
    totalApplications: undefined,
    totalExperts: undefined,
}));
