import { useEffect } from 'react';

type ErrorHandlerType = 'local' | 'global';

const useLocalErrorHandler = (handlerType: (handlerType: ErrorHandlerType) => void, disable?: boolean) => {
    useEffect(() => {
        handlerType('local');
        return () => {
            handlerType('global');
        };
    }, []);
};

export default useLocalErrorHandler;
