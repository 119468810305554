import React from 'react';
import cn from 'classnames';
import styles from './Loader.module.scss';
import LoaderSvg from '../../assets/svg/Loader_svg.svg';

const Loader = ({ className, fullHeight, showBg }: IProps) => (
    <div className={cn(fullHeight && styles.container_full, showBg && styles.container_bg)}>
        <img className={cn(styles.svg, fullHeight && styles.svg_full, className)} src={LoaderSvg} alt="загрузка..." />
    </div>
);

export default Loader;

interface IProps {
    className?: string;
    fullHeight?: boolean;
    showBg?: boolean;
}
