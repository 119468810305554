import React from 'react';
import { OverlayTrigger } from 'components';
import { IExamExpert } from 'components/models';
import penIcon from 'components/assets/svg/pen_icon.svg';
import waitTimeIcon from 'components/assets/svg/wait_time_icon.svg';
import styles from './SignedExpert.module.scss';
import cn from 'classnames';

const SignedExpert = ({ expert, commissionTitle, acceptInvite }: ISignedExpertProps) => {
    const renderChairman = () => {
        if (expert.chairman !== undefined) {
            return expert.chairman ? `Председатель ${commissionTitle}:` : `Член ${commissionTitle}:`;
        }

        return null;
    };

    return (
        <span className={cn(styles.expertSignStatus, expert.signed && styles.expertSignStatus_signed)}>
            {renderChairman()} {expert.chairman === undefined ? `${commissionTitle}:` : ''} <strong>{expert.fullName}</strong>
            {expert.signed || (expert.acceptedInvite && acceptInvite)
                ? (
                    <OverlayTrigger triggerText={`Код подписания: ${expert.signedCode || '-'}`}>
                        <div className={styles.signedButton}>
                            <img className={styles.icon} src={penIcon} alt="penIcon" />
                        </div>
                    </OverlayTrigger>
                )
                : <img className={styles.icon} src={waitTimeIcon} alt="waitTimeIcon" />
            }
        </span>
    );
};

export const renderSignedExpertsRow = (experts: IExamExpert[], commissionTitle: string, acceptInvite: boolean = false) => experts.map(expert => (
    <SignedExpert
      key={`expert_${expert.expertId} `}
      expert={expert}
      commissionTitle={commissionTitle}
      acceptInvite={acceptInvite}
    />
));

export default SignedExpert;

interface ISignedExpertProps {
    expert: IExpert;
    commissionTitle: string;
    acceptInvite?: boolean;
}

interface IExpert {
    fullName: string;
    signed: boolean;
    signedCode?: string | null;
    chairman?: boolean;
    acceptedInvite?: boolean;
}
