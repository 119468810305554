import * as React from 'react';
import cn from 'classnames';
import styles from './DayEditor.module.scss';
import moment, { Moment } from 'moment';
import { Form } from 'react-bootstrap';
import { DatePicker } from '../DatePicker';
import { TimePicker } from '../Common/TimePicker';

const DayEditor: React.FC<IProps> = (props) => {
    const preparedTime = props.date.clone().utc();
    const handleSelectDate = (_: string, changedDate: Moment) => {
        const newDate = changedDate.clone().utc();
        newDate.hour(preparedTime.hour());
        newDate.minute(preparedTime.minute());

        props.onChangeDate(newDate.utc());
    };

    return (
      <div className={cn(styles.dateForm, props.className)}>
          {!props.hideMainPart && (
            <>
                <span>Дата</span>
                <DatePicker
                  datePickerSizeSm
                  from={-1}
                  to={3}
                  initialDate={moment.utc(props.date.clone().format('YYYY-MM-DD'))}
                  onChange={handleSelectDate}
                  dayClass={styles.daySelect}
                  monthClass={styles.monthSelect}
                  yearClass={styles.yearSelect}
                  disabled={props.disabled}
                />
                <span>Время</span>
                <TimePicker time={preparedTime} onChange={props.onChangeDate} disabled={props.disabled} />
                {!!props.apiError && (
                  <Form.Control.Feedback className="d-block" type="invalid" style={{ gridColumn: '1/3', width: 400 }}>
                      <p>
                          {props.apiError}
                      </p>
                  </Form.Control.Feedback>
                )}
            </>
          )}
          {props.children}
      </div>
    );
};

interface IProps {
    disabled?: boolean;
    date: Moment;
    onChangeDate: (date: Moment) => void;
    className?: string;
    hideMainPart?: boolean;
    apiError?: string | null;
}

export default DayEditor;
