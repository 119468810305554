import React from 'react';
import {
    Card,
    ApplicantExamDetailsTabs,
    AlertText,
    ExamApplicationStatus,
    ExamStatus,
} from 'components';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { RegistrationForExamContainer } from '../RegistrationForExamContainer';
import { useLocalErrorHandler } from 'hooks';

const ExamDetailsContainer = ({
                                  setErrorHandler,
                                  fetchExamDetails,
                                  examId,
                                  exam,
                                  examApplication,
                                  onPush,
                                  apiError,
                                  createExamApplication,
                                  onlineExamRedirectionUrl,
                              }: Props) => {
    useLocalErrorHandler(setErrorHandler);

    const [processing, setProcessing] = React.useState(false);
    const [showRegisterInfo, setShowRegisterInfo] = React.useState(false);

    const redirectionLink = React.useRef<HTMLAnchorElement | null>(null);
    const examStatus = React.useMemo(() => exam && exam.examStatus, [exam]);
    const examApplicationStatus = React.useMemo(() => examApplication && examApplication.status, [examApplication]);
    const title = React.useMemo(() => (exam ? exam.qualificationTitle : ''), [exam]);

    const urlParams = new URLSearchParams(window.location.search);
    const from = urlParams.get('from');


    React.useEffect(() => {
        const fetch = async () => {
            setProcessing(true);
            await fetchExamDetails(examId);
            setProcessing(false);
        };

        fetch();
    }, []);

    React.useEffect(() => {
        if (redirectionLink.current && onlineExamRedirectionUrl) {
            redirectionLink.current.click();
        }
    }, [redirectionLink, onlineExamRedirectionUrl]);

    const goBack = () => {
        onPush(`/${from}`);
    };

    const onRegisterButtonClick = async () => {
        if (examApplicationStatus !== ExamApplicationStatus.Draft && examStatus === ExamStatus.IsApplying) {
            await createExamApplication(examId);
        }
        if (examApplication && (examApplicationStatus === ExamApplicationStatus.InReview || examApplicationStatus === ExamApplicationStatus.Approved)) {
            onPush(`/exam-application/${examApplication.examApplicationId}?from=exam-timetables`);
        }
        else {
            setShowRegisterInfo(true);
        }
        
    };

    const cardHeaderBtn = () => (
        <Button onClick={onRegisterButtonClick}>
            {!examApplicationStatus ? 'Подать заявку' : examApplicationStatus === ExamApplicationStatus.CorrectionsRequired
                ? 'Перейти к исправлению'
                : `${examApplicationStatus === ExamApplicationStatus.Draft ? 'Редактировать' : 'Посмотреть'} заявку`
            }
        </Button>
    );


    if (showRegisterInfo) {
        return (
          <RegistrationForExamContainer
            examId={examId}
            onGoBack={() => setShowRegisterInfo(false)}
          />
        );
    }

    return (
      <Card
        onClickBack={goBack}
        title={title}
        apiError={apiError}
        showLoader={processing}
        headerChild={cardHeaderBtn()}
        show404Error={!exam}
        withBackButton
        withoutPadding
        smallTitle
      >
          {exam && exam.reviewComment && exam.examApplicationStatus === ExamApplicationStatus.CorrectionsRequired
            ? (
              <AlertText
                className="mb-4"
                title="В текущую заявку требуется внести следующие исправления:"
                text={exam.reviewComment}
              />
            ) : null
          }
          {exam ? (
            <ApplicantExamDetailsTabs
              examDetails={exam}
            />
          ) : null}
      </Card>
    );
};

const mapState = (state: IRootState) => ({
    exam: state.exam.exam,
    examApplication: state.exam.examApplication,
    apiError: state.errors.error,
    onlineExamRedirectionUrl: state.exam.onlineExamRedirectionUrl,
});

const mapDispatch = (dispatch: Dispatch) => ({
    fetchExamDetails: dispatch.exam.fetchExamDetails,
    createExamApplication: dispatch.exam.createExamApplication,
    setErrorHandler: dispatch.errors.setErrorHandler,
    onPush: (key: string) => dispatch(push(key)),
});

export default connect(mapState, mapDispatch)(ExamDetailsContainer);

interface ComponentOwnProps {
    examId: string;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
type Props = connectedProps & ComponentOwnProps;
