import * as React from 'react';
import { ColorStatus } from '_helpers';

const StatusColumn: React.FC<IProps> = ({ status }) => (status ? (
  <span style={{ color: `var(${status.color})` }}>
      {status.text}
  </span>
) : null);

interface IProps {
    status: ColorStatus;
}

export default StatusColumn;
