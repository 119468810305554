import React from 'react';
import { Location } from 'history';
import { connect } from 'react-redux';
import { Header, Loader } from 'components';
import { SidebarContainer } from '../../containers';
import { IRootState } from '../../redux/store/configureStore';
import { useReloader } from 'hooks';
import cx from 'classnames';
import styles from './AuthorizedLayout.module.scss';
import sidebarTabs from '../../routes/SidebarTabs';

const AuthorizedLayout = ({
    children, location, adaptive, authProcessing, auth, examApplicationsProcessing,
}: Props) => {
    const reload = useReloader(location);

    return (
        <div className={cx('wrapper', styles.rootWrapper, { [styles.adaptive]: adaptive })}>
            <SidebarContainer location={location} adaptive={adaptive} />
            <div className={cx('main-panel', styles.mainPanel, { [styles.adaptive]: adaptive })}>
                <Header
                  location={location}
                  tabs={sidebarTabs}
                  adaptive={adaptive}
                />
                {reload ? null : (
                    <div data-test-authorized-content className={styles.content}>
                        {(authProcessing || examApplicationsProcessing) && <Loader />}
                        {children}
                    </div>
                )}
            </div>
            {authProcessing && (!auth || (auth && !auth.profile)) ? <Loader showBg /> : null}
        </div>
    );
};

const mapState = (state: IRootState) => ({
    auth: state.auth,
    authProcessing: Boolean(state.loading.models.auth),
    examApplicationsProcessing: Boolean(state.loading.models.examApplications),
});

export default connect(mapState)(AuthorizedLayout);

interface AuthorizedLayoutOwnProps {
    children: JSX.Element[] | JSX.Element;
    location: Location;
    adaptive?: boolean;
}

type connectedProps = ReturnType<typeof mapState>;
type Props = connectedProps & AuthorizedLayoutOwnProps;
