import {
    CreateExamApplication,
    IApplicantExamDetails,
    IApplicantExamList,
    IExamApplicationDetails,
    QualificationDocumentTemplateGroup,
    SubmitExamApplication,
} from '../../api/ApiClient';
import { INotification } from 'components';
import { createModel } from '@rematch/core';
import apiConnector from '../../api/ApiConnector';
import { IExamsFilters, IFilterPart, IFromToDatePart } from 'components/models';
import { saveToLocalStorage, loadFromLocalStorage } from '_helpers';

const initialState: ExamState = {
    examsList: null,
    exam: null,
    examApplication: null,
    paymentUrl: null,
    documentTemplateGroups: null,
    onlineExamRedirectionUrl: null,
    filterState: null,
};

export default createModel({
    state: initialState,
    reducers: {
        setExamState<S extends ExamState, K extends keyof ExamState>(state: S, data: (Pick<S, K> | S | null)) {
            return { ...state, ...data };
        },
        setOnlineExamRedirectionUrl(state, onlineExamRedirectionUrl: string | null) {
            return { ...state, onlineExamRedirectionUrl };
        },
        setFilterState(state, filterState: IExamFilterState) {
            return { ...state, filterState };
        },
    },
    effects: (dispatch: any) => ({
        async fetchExams(payload: IExamsFilters & IFromToDatePart & IFilterPart) {
            this.setExamState({
                examsList: await apiConnector.execApi(dispatch, apiClient => apiClient.getExams(
                    payload.regionIds,
                    payload.qualificationIds,
                    payload.from,
                    payload.to,
                    payload.page,
                    payload.pageSize,
                    payload.sortOrder,
                    payload.sortField,
                )),
            });
        },
        async initFilterState(filterState: IExamFilterState, root: IRootState) {
            if(root.exam.filterState) return root.exam.filterState;
            const data = loadFromLocalStorage('exams-filter-state');
            if (data) {
                const state = JSON.parse(data) as IExamFilterState;
                this.setFilterState(state);
                return state;
            }
            this.setFilterState(filterState);
            return filterState;
        },
        async fetchExamsWithStateSafe(payload: IExamsFilters & IFromToDatePart & IFilterPart & {residency?: boolean;}, root: IRootState) {

            if(root.exam.filterState != null) {
                const filterState = {
                    regionIds: payload.regionIds,
                    qualificationIds: payload.qualificationIds,
                    residency: payload.residency
                };
                this.setFilterState(filterState);
                saveToLocalStorage('exams-filter-state', JSON.stringify(filterState));
            }
            
            this.setExamState({
                examsList: await apiConnector.execApi(dispatch, apiClient => apiClient.getExams(
                    payload.regionIds,
                    payload.qualificationIds,
                    payload.from,
                    payload.to,
                    payload.page,
                    payload.pageSize,
                    payload.sortOrder,
                    payload.sortField,
                )),
            });
        },
        async uploadExamApplicationDocument(payload: UploadDocumentPayload) {
            this.setExamState({
                examApplication: await apiConnector.execApi(dispatch, api => api.uploadExamApplicationDocumentFix(
                    payload.examApplicationId,
                    payload.templateId,
                    payload.file,
                )),
            });
        },
        async deleteExamApplicationDocument(payload: DeleteDocumentPayload) {
            this.setExamState({
                examApplication: await apiConnector.execApi(dispatch, api => api.deleteExamApplicationDocument(
                    payload.examApplicationId,
                    payload.documentId,
                )),
            });
        },
        async deleteExamApplicationDocumentSourceAsync(payload: DeleteDocumentSource) {
            this.setExamState({
                examApplication: await apiConnector.execApi(dispatch, api => api.deleteExamApplicationDocumentSource(
                    payload.examApplicationId,
                    payload.documentId,
                    payload.sourceFileId,
                )),
            });
        },
        async submitExamApplication(data: ISubmitExamApplication) {
            const examApplication = await apiConnector.execApi(dispatch, api => api.submitExamApplication(data.examApplicationId, data.body));
            
            if (examApplication) {
                this.setExamState({ examApplication });
                dispatch.notifications.addNotification([{ message: 'Ваша заявка была успешно зарегистрирована', appearance: 'success' }] as INotification[]);
            }

            return examApplication;
        },
        async createExamApplication(examId: string) {
            const examApplication = await apiConnector.execApi(dispatch, api => api.createExamApplication(new CreateExamApplication({ examId })));
            this.setExamState({ examApplication });

            return examApplication;
        },
        async fetchExamApplication(id: string) {
            const examApplication = await apiConnector.execApi(dispatch, api => api.getExamApplication(id));
            this.setExamState({ examApplication });

            return examApplication;
        },
        async fetchExamDetails(id: string) {
            const exam = await apiConnector.execApi(dispatch, api => api.getExamDetails(id));
            let app = null;
            if (exam) {
                if (exam.examApplicationId) {
                    const examApplication = await apiConnector.execApi(dispatch, api => api.getExamApplication(String(exam.examApplicationId)));
                    app = examApplication;
                    this.setExamState({
                        exam,
                        examApplication,
                    });
                } else {
                    this.setExamState({
                        exam,
                        examApplication: null,
                    });
                }
            }
            this.setExamState({ exam });

            return app;
        },
    }),
});

interface ExamState {
    examsList: IApplicantExamList | null;
    exam: IApplicantExamDetails | null;
    examApplication: IExamApplicationDetails | null;
    paymentUrl: string | null;
    documentTemplateGroups: QualificationDocumentTemplateGroup[] | null;
    onlineExamRedirectionUrl: string | null;
    filterState: IExamFilterState | null;
}

interface DeleteDocumentPayload {
    examApplicationId: string;
    documentId: string;
}

interface UploadDocumentPayload {
    examApplicationId: string;
    templateId: string;
    file: File;
}

interface DeleteDocumentSource {
    examApplicationId: string;
    documentId: string;
    sourceFileId: string;
}

interface ISubmitExamApplication {
    examApplicationId: string;
    body?: SubmitExamApplication;
}

interface IExamFilterState {
    regionIds?: number[];
    qualificationIds?: string[];
    residency?: boolean;
}

interface IRootState {
    //auth: AuthState;
    exam: ExamState;
}
