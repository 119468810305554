import _ from 'lodash';

const prepareTableHeaders = (headers: IHeaderRows): IHeaderRows => headers.map(h => h.reduce((acc, item) => {
    const lastItem = _.last(acc);
    if (!lastItem) return [item];
    const newAcc = acc.slice();
    if (item.text === lastItem.text && lastItem.text.length !== 0) {
        newAcc[newAcc.length - 1] = { ...lastItem, colSpan: lastItem.colSpan ? lastItem.colSpan + 1 : 1 };
        return newAcc;
    }
    return [...acc, item];
}, [] as IRowItem[]));

const getHeaderRowCount = (headers: string[], separator: string) => headers.reduce((acc, h) => (
    h.split('#').length > 1 ? h.split(separator).length : acc
), 1);

export const getTableHeader = (headers: string[], separator: string): IHeaderRows => {
    const rowCount = getHeaderRowCount(headers, separator);
    const tableHeaders: IHeaderRows = new Array(rowCount).fill(0).map(() => new Array(headers.length).fill({ text: '', colSpan: 1 }));

    for (let row = 0; row < rowCount; row += 1) {
        for (let col = 0; col < headers.length; col += 1) {
            const headerArr = headers[col].split(separator);
            tableHeaders[row][col] = { text: headerArr[row] || '', colSpan: 1 };
        }
    }
    return prepareTableHeaders(tableHeaders);
};

export function createArray <T>(arrayLength: number, createItemCb: () => T) {
    return new Array(arrayLength).fill(0).map(createItemCb);
}

export interface IRowItem {
    text: string;
    colSpan?: number;
}

export type IHeaderRows = IRowItem[][];
