export interface IQualificationPracticalQuestionDetails {
    openAnswer?: string;
    taskTables?: ITable[];
    id?: string;
    number: number;
    active: boolean;
    text: string;
    type: QuestionType;
    criteriaForRate?: string;
}

export interface ITable {
    caption?: string;
    columns: IColumn[];
    exactEquality: boolean;
    forbidMoving: boolean;
    initialRows: string[][];
    answerRows?: string[][];
}

interface IColumn {
    header: string;
    width: number;
    readonly: boolean;
    numeric: boolean;
    key: boolean;
    multiline: boolean;
}

export enum QuestionType {
    MultipleChoiceTheoretical = 'multipleChoiceTheoretical',
    OpenAnswerPractical = 'openAnswerPractical',
    MatchingTheoretical = 'matchingTheoretical',
    EssayPractical = 'essayPractical',
    OpenAnswerTheoretical = 'openAnswerTheoretical',
    OrderingTheoretical = 'orderingTheoretical',
    ManualCheckTabularPracticalQuestion = 'manualCheckTabularPracticalQuestion',
    AutoCheckTabularPracticalQuestion = 'autoCheckTabularPracticalQuestion',
    OfflinePractical = 'offlinePractical',
}

export interface IFieldsError {
    [key: string]: string;
}
