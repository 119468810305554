import React from 'react';
import {
    InputText,
    CustomSwitch,
    CKEditor,
    CustomRadio,
    LoaderBtn,
    EditableTable,
} from 'components';
import {
    IQualificationPracticalQuestionDetails,
    IFieldsError,
    QuestionType,
    ITable,
} from './types';
import { Form, Button } from 'react-bootstrap';
import { mapAPIError, RegExpConst } from '_helpers';
import { IApiError } from '_helpers/ApiConnector/types';
import _ from 'lodash';
import styles from './PracticalQuestionRedactor.module.scss';
import cn from 'classnames';

const PracticalQuestionRedactor = ({
    practicalQuestion, errors, onSubmit, onExit, loading,
}: IProps) => {
    const errorRef = React.useRef(errors);

    const initialState: IState = {
        questionType: QuestionType.OpenAnswerPractical,
        question: practicalQuestion || emptyQuestion,
        formFieldErrors: {},
        process: false,
        preview: false,
    };
    const [question, setQuestion] = React.useState(initialState.question);
    const [questionType, setQuestionType] = React.useState(initialState.questionType);
    const [formFieldErrors, setFormFieldErrors] = React.useState(initialState.formFieldErrors);
    const [process, setProcess] = React.useState(initialState.process);
    const [preview, setPreview] = React.useState(initialState.preview);

    React.useEffect(() => {
        errorRef.current = errors;
        const errorsMap = mapAPIError(errors);
        if (errorsMap) {
            setFormFieldErrors({ ...formFieldErrors, ...errorsMap });
        }
    }, [errors]);

    React.useEffect(() => {
        if (practicalQuestion && !loading) {
            setQuestion(practicalQuestion);
            setQuestionType(practicalQuestion.type);
        }
    }, [practicalQuestion]);

    const setQuestionNumber = (name: string, number: string) => {
        const newNumber = number === '' ? '0' : number;
        if (RegExpConst.NUMBER.test(newNumber)) {
            setQuestion({ ...question, number: parseInt(newNumber, 10) });
            setFormFieldErrors({ ...formFieldErrors, number: '' });
        }
    };

    const handleChangeTextInput = (name: string, value: string) => {
        setQuestion({ ...question, openAnswer: value });
        setFormFieldErrors({ ...formFieldErrors, openAnswer: '' });
    };

    const handleClickSwitch = () => {
        setQuestion({ ...question, active: !question.active });
    };

    const handlePreviewClickSwitch = () => {
        setPreview(!preview);
    };

    const onChangeRadio = (type: QuestionType) => {
        if (!practicalQuestion) {
            setQuestionType(type);
            setFormFieldErrors({});
            setQuestion({
                ...emptyQuestion,
                number: question.number,
                text: question.text,
                criteriaForRate: question.criteriaForRate,
                type,
            });
        }
    };

    const onChangeCKEditorText = (text: string) => {
        setQuestion({ ...question, text });
        setFormFieldErrors({ ...formFieldErrors, text: '' });
    };

    const onChangeCKEditorCriterial = (criteriaForRate: string) => {
        setQuestion({ ...question, criteriaForRate });
        setFormFieldErrors({ ...formFieldErrors, criteriaForRate: '' });
    };

    const isValidFormData = (fieldsError: IFieldsError): boolean => _.values(fieldsError).every(e => e.length === 0);

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const validationErrors = getValidationErrors(question);

        if (isValidFormData(validationErrors)) {
            const res = await onSubmit(question);

            if (!errorRef.current && res) {
                onExit();
            }
        } else {
            setFormFieldErrors(validationErrors);
        }
    };

    const checkValidTable = (table: ITable) => {
        if (table.columns && !table.columns.find((col) => {
            if (col.header === null || col.header === undefined
                || col.key === null || col.header === undefined
                || col.multiline === null || col.multiline === undefined
                || col.numeric === null || col.numeric === undefined
                || col.readonly === null || col.readonly === undefined
                || col.width === null || col.width === undefined
            ) {
                return table;
            }
            return undefined;
        }) && table.initialRows
            && table.exactEquality !== null && table.exactEquality !== undefined
            && table.forbidMoving !== null && table.forbidMoving !== undefined
        ) {
            return table;
        }
        return null;
    };

    const onUploadTable = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProcess(true);
        if (e && e.currentTarget && e.currentTarget.files) {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.result) {
                    try {
                        const addedTaskTables: ITable[] = JSON.parse(reader.result.toString());
                        if (addedTaskTables) {
                            const notValidTables: number[] = [];
                            addedTaskTables.forEach((el, i) => !checkValidTable(el) && notValidTables.push(i));
                            const taskTables = addedTaskTables.filter(checkValidTable);

                            setQuestion({ ...question, taskTables });
                            setFormFieldErrors({
                                ...formFieldErrors,
                                taskTables: !notValidTables.length ? '' : `Неверные данные в таблицах: ${notValidTables.join(',')}`,
                            });
                        }
                    } catch (err) {
                        setFormFieldErrors({ ...formFieldErrors, taskTables: `Ошибка при разборе таблицы: ${err}` });
                    }
                }
            };

            reader.readAsText(e.currentTarget.files[0]);
        }
        setProcess(false);

        e.target.value = '';
    };

    return (
        <Form onSubmit={submit} autoComplete="off">
            <div className={styles.radio_section}>
                <Form.Group className="mr-4 mb-0">
                    <CustomRadio
                      className={styles.radio_block}
                      label="С открытым ответом"
                      name="questionType"
                      onClick={() => onChangeRadio(QuestionType.OpenAnswerPractical)}
                      option={QuestionType.OpenAnswerPractical}
                      checked={questionType === QuestionType.OpenAnswerPractical}
                      disabled={!!practicalQuestion}
                    />
                    <CustomRadio
                      className={styles.radio_block}
                      label="Эссе"
                      name="questionType"
                      onClick={() => onChangeRadio(QuestionType.EssayPractical)}
                      option={QuestionType.EssayPractical}
                      checked={questionType === QuestionType.EssayPractical}
                      disabled={!!practicalQuestion}
                    />
                    <CustomRadio
                      className={styles.radio_block}
                      label="Оффлайн"
                      name="questionType"
                      onClick={() => onChangeRadio(QuestionType.OfflinePractical)}
                      option={QuestionType.OfflinePractical}
                      checked={questionType === QuestionType.OfflinePractical}
                      disabled={!!practicalQuestion}
                    />
                </Form.Group>
                <Form.Group>
                    <CustomRadio
                      className={styles.radio_block}
                      label="С таблицами и ручной проверкой"
                      name="questionType"
                      onClick={() => onChangeRadio(QuestionType.ManualCheckTabularPracticalQuestion)}
                      option={QuestionType.ManualCheckTabularPracticalQuestion}
                      checked={questionType === QuestionType.ManualCheckTabularPracticalQuestion}
                      disabled={!!practicalQuestion}
                    />
                    <CustomRadio
                      className={styles.radio_block}
                      label="С таблицами и авто проверкой"
                      name="questionType"
                      onClick={() => onChangeRadio(QuestionType.AutoCheckTabularPracticalQuestion)}
                      option={QuestionType.AutoCheckTabularPracticalQuestion}
                      checked={questionType === QuestionType.AutoCheckTabularPracticalQuestion}
                      disabled={!!practicalQuestion}
                    />
                </Form.Group>
            </div>
            <InputText
              inputGroupClassNames={cn('mb-4 mt-4', styles.id_input)}
              inputClassNames="d-flex align-items-center"
              name="number"
              value={`${question.number}`}
              labelTitle="№"
              onChange={setQuestionNumber}
              fieldFailures={formFieldErrors}
            >
                <CustomSwitch containerClassName="ml-4" name="setActive" value={question.active} onClick={handleClickSwitch} />
            </InputText>
            <CKEditor
              className="mb-5"
              onChange={onChangeCKEditorText}
              data={question.text}
            >
                {_.has(formFieldErrors, 'text') && (
                    <Form.Control.Feedback type="invalid" className="mt-0 d-block">
                        {formFieldErrors.text}
                    </Form.Control.Feedback>
                )}
            </CKEditor>
            <h3 className="mt-5 mb-4 font-weight-bold">Ключ/критерии оценки</h3>
            <CKEditor
              className="mb-5"
              onChange={onChangeCKEditorCriterial}
              data={question.criteriaForRate}
            >
                {_.has(formFieldErrors, 'criteriaForRate') && (
                    <Form.Control.Feedback type="invalid" className="mt-0 d-block">
                        {formFieldErrors.criteriaForRate}
                    </Form.Control.Feedback>
                )}
            </CKEditor>

            {questionType === QuestionType.OpenAnswerPractical && (
                <InputText
                  inputGroupClassNames={cn('mb-4', styles.id_input)}
                  inputClassNames="d-flex align-items-center"
                  name="answer"
                  value={question.openAnswer || ''}
                  labelTitle="Ответ"
                  onChange={handleChangeTextInput}
                  fieldFailures={formFieldErrors}
                />
            )}

            {(questionType === QuestionType.ManualCheckTabularPracticalQuestion
                || questionType === QuestionType.AutoCheckTabularPracticalQuestion) && (
                    <>
                        <div>
                            <div className={styles.review}>
                                <span>Предпросмотр</span>
                                <CustomSwitch containerClassName="ml-4" name="setActive" value={preview} onClick={handlePreviewClickSwitch} />
                            </div>
                            {question.taskTables && question.taskTables.map((table, i) => (
                                <div key={`initialTable_${i}`}>
                                    <EditableTable
                                      key={`editableInitialTable_${i}`}
                                      tableId={`editableInitialTable_${i}`}
                                      headersSeparator="#"
                                      table={table}
                                      onlyWatch={!preview}
                                    />
                                </div>
                            ))}

                            {question.taskTables && <h3 className="mt-5 mb-4 font-weight-bold">Ключ к заданию:</h3>}

                            {question.taskTables && question.taskTables.map((table, i) => (
                                <div key={`answerTable_${i}`}>
                                    <EditableTable
                                      key={`editableTable_${i}`}
                                      tableId={`editableTable_${i}`}
                                      table={table}
                                      headersSeparator="#"
                                      fromAnswerRows
                                      onlyWatch
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="d-flex mt-4">
                            {question.taskTables && (
                                <a
                                  href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(question.taskTables))}`}
                                  download="table.json"
                                  className={cn(styles['font-extra-small'], 'd-block')}
                                >
                                    Скачать таблицу
                                </a>
                            )}
                            <input type="file" id="loadTable" className="d-none" accept="application/JSON" onChange={onUploadTable} />
                            <label
                              htmlFor="loadTable"
                              className={cn(styles['font-extra-small'], styles.loadButton, 'd-block', question.taskTables && 'ml-4')}
                            >
                                {process ? 'Загрузка...' : 'Загрузить таблицу'}
                            </label>
                        </div>
                        {_.has(formFieldErrors, 'taskTables') && (
                            <Form.Control.Feedback type="invalid" className="mt-0 d-block">
                                {formFieldErrors.taskTables}
                            </Form.Control.Feedback>
                        )}
                    </>
                )}


            {_.has(formFieldErrors, 'general') ? (
                <div className="row">
                    <Form.Text className="col p-0 m-0">
                        <p className="nav-link m-0 p-0 text-danger">
                            {formFieldErrors.general}
                        </p>
                    </Form.Text>
                </div>
            ) : null}
            <div className="mt-4">
                <LoaderBtn
                  type="submit"
                  variant="primary"
                  loading={loading}
                >
                    Сохранить
                </LoaderBtn>
                <Button onClick={onExit} variant="outline-secondary" className="ml-4">Отмена</Button>
            </div>
            {errors && (!errors.failures || !(errors.failures && (errors.failures.taskTables || errors.failures.answer || errors.failures.text || errors.failures.number))) && (
                <Form.Control.Feedback type="invalid" className="mt-2 mb-2 d-block">
                    {errors.message}
                </Form.Control.Feedback>
            )}
        </Form>
    );
};

export default PracticalQuestionRedactor;

interface IProps {
    errors: IApiError | null;
    practicalQuestion: IQualificationPracticalQuestionDetails | null;
    loading?: boolean;
    onSubmit: (data: IQualificationPracticalQuestionDetails) => Promise<boolean>;
    onExit: () => void;
}

interface IState {
    questionType: QuestionType;
    question: IQualificationPracticalQuestionDetails;
    formFieldErrors: IFieldsError;
    process: boolean;
    preview: boolean;
}

const emptyQuestion = {
    number: 0,
    text: '',
    criteriaForRate: '',
    active: true,
    type: QuestionType.OpenAnswerPractical,
};

const getValidationErrors = (data: IQualificationPracticalQuestionDetails): IFieldsError => {
    const requiredErrorText = 'Обязательное поле для заполнения';
    return Object.keys(data).reduce((acc, key) => {
        switch (key) {
            case 'number':
                return { ...acc, number: data.number === 0 ? 'Введите номер отличный от 0' : '' };
            case 'text':
                return { ...acc, [key]: data[key] === '' ? requiredErrorText : '' };
            default:
                return acc;
        }
    }, {});
};
