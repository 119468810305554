import React, { CSSProperties } from 'react';
import { EmptySection } from 'components';
import { Table } from 'react-bootstrap';
import styles from './SimpleTable.module.scss';
import Pagination, { IPaginationData } from '../../Pagination/Pagination';
import EmptyListIcon from 'components/assets/svg/news_icon.svg';
import EmptyUsersListIcon from 'components/assets/svg/group.svg';

const SimpleTable = ({
    tableRows,
    handleRowClick,
    tableCellClassName,
    tableHeaders,
    handleOrder,
    placeholderIcon,
    placeholderTitle,
    placeholderText,
  tableClassName,
  ...props
}: TableProps) => {
    const renderTableItem = () => tableRows.map((item, num) => (
        <tr key={`tr_${item.id || num}`} onClick={() => handleRowClick(item.id)} data-test-exam-id={item.id}>
            {item.columns.map((x, i) => <td key={i} className={tableCellClassName || 'py-3'} style={tableHeaders[i].columnStyles}>{x}</td>)}
        </tr>
    ));

    const renderTableHeaders = () => tableHeaders.map((field, i) => (
        <th key={i} className="border-top-0" style={field.columnStyles}>
            {field.title}
            {field.fieldName !== '' && handleOrder
                ? (<div className={`${styles.sortBtn} ml-2`} onClick={() => handleOrder(`${field.fieldName}`)} />)
                : null
            }
        </th>
    ));

    if (tableRows.length === 0) {
        return (
            <EmptySection
              placeholderTitle={placeholderTitle || 'Нет ничего в списке'}
              placeholderText={placeholderText || 'Здесь будут отображаться данные'}
              placeholderIcon={placeholderIcon || (props.users ? EmptyUsersListIcon : EmptyListIcon)}
            />
        );
    }
    return (
        <>
            <Table hover className={`mb-0 w-100 applicationsTable ${tableClassName}`}>
                <thead>
                <tr>
                    {renderTableHeaders()}
                </tr>
                </thead>
                <tbody>
                {renderTableItem()}
                </tbody>
            </Table>
            {!!(props.pagination && props.onPagination) && (
              <Pagination
                className={props.paginationClassName}
                onChange={props.onPagination}
                pagination={props.pagination}
              />
            )}
        </>
    );
};

export default SimpleTable;

export interface SimpleTableHeader {
    title: string;
    columnStyles?: CSSProperties;
    fieldName?: string;
}

export interface SimpleTableRow {
    id: string | number;
    columns: (string | number | JSX.Element)[];
}

interface TableProps {
    users?: boolean;
    tableClassName?: string;
    tableRows: SimpleTableRow[];
    tableHeaders: SimpleTableHeader[];
    handleRowClick: (itemId: string | number) => void;
    handleOrder?: (fieldName: string) => void;
    placeholderIcon?: string;
    placeholderTitle?: string;
    placeholderText?: string;
    tableCellClassName?: string;
    onPagination?: (data: IPaginationData) => void;
    pagination?: IPaginationData;
    paginationClassName?: string;
}
