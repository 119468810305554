import React from 'react';
import { FormControl } from 'react-bootstrap';
import cx from 'classnames';
import styles from './InputPassword.module.scss';

const InputPassword = (props: ComponentProps) => {
    const [open, setOpen] = React.useState(false);

    return (
        <div className={styles.InputPassword__container}>
            <FormControl
                {...props}
                type={open ? 'text' : 'password'}
                className={cx(styles.InputPassword, props.className)}
                autoComplete="off"
            />
            <span
                className={open ? styles.eye_open : styles.eye_closed}
                onClick={() => setOpen(!open)}
            />
        </div>
    );
};

interface ComponentProps {
    size?: 'sm' | 'lg';
    plaintext?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    value?: string;
    name?: string;
    id?: string;
    isValid?: boolean;
    isInvalid?: boolean;
    className?: string;
    autoFocus?: boolean;
    required?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default InputPassword;
