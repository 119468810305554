/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Location } from 'history';
import { Collapse } from 'react-bootstrap';
import { NotificationBundle } from 'components';
import cx from 'classnames';
import styles from './Sidebar.module.scss';
import _ from 'lodash';
import { ExpertStatus } from 'expert/src/api/ApiClient';

const NavList = ({ links, location, onClickMenuLink }: NavListProps) => {
    const [isOpen, setIsOpen] = React.useState<{[key: string]: boolean}>({});

    React.useEffect(() => {
        links.forEach((link: Tab) => {
            if (link.list && isActiveBlock(link.list)) {
                const escapedName = link.state || escapeString(link.name);
                changeOpenState(escapedName);
            }
        });
    }, []);

    const isActiveRoute = (routeName: string | string[] | undefined): boolean => {
        if (!routeName) return false;
        return isSamePath(routeName);
    };

    const isActiveBlock = (list: SubItem[] | undefined) => {
        if (!list) return false;
        return list.some((link: SubItem) => isActiveRoute(mergePathAndRoutes(link.path, link.routes)));
    };

    const isSamePath = (routeName: string | string[]) => {
        if (_.isArray(routeName)) {
            return routeName.some(route => isSameLinks(route));
        }
        return isSameLinks(routeName);
    };

    const isSameLinks = (routeName: string) => {
        const { pathname, search } = location;

        if (routeName.indexOf('/*') !== -1) {
            const sp = routeName.split('/*').filter(el => el.length !== 0);

            if (sp.length === 1) {
                return pathname.startsWith(sp[0]);
            }

            return pathname.startsWith(sp[0]) && search.endsWith(sp[1]);
        }

        return routeName === pathname;
    };

    const escapeString = (string: string) => string.toLocaleLowerCase().replace(/' '/g, '_');

    const changeOpenState = (nameOfBlock: string) => setIsOpen({
        ...isOpen,
        [nameOfBlock]: !isOpen[nameOfBlock],
    });

    const handleCollapseBlock = (nameOfBlock: string) => () => {
        changeOpenState(nameOfBlock);
    };

    const renderLinkIcon = (icon: string | undefined, name: string) => {
        const nameWords = name.split(' ');
        return (
            <div className={styles.nav__link_icon}>
                {icon
                    ? <img src={icon} alt={name} />
                    : (
                        <div>
                            {nameWords[0].charAt(0)}
                            {Boolean(nameWords[1]) && nameWords[nameWords.length - 1].charAt(0)}
                        </div>
                    )}
            </div>
        );
    };

    const mergePathAndRoutes = (path: string | undefined, routes: string | string[] | undefined): string | string[] | undefined => {
        if (!path) return routes;
        if (!routes) return path;
        if (_.isArray(routes)) {
            return [...routes, path];
        }
        return [routes, path];
    };

    const renderCollapseBlock = (prop: Tab, key: number) => {
        const escapedName = prop.state || escapeString(prop.name);
        return (
            <React.Fragment key={key}>
                <li
                  className={cx(styles.navButtons, isActiveBlock(prop.list) && 'active')}
                  key={key}
                >
                    <a
                      className={cx(styles.nav__link, 'nav-link')}
                      onClick={handleCollapseBlock(escapedName)}
                    >
                        {renderLinkIcon(prop.icon, prop.name)}
                        <p>
                            {prop.name}
                        </p>
                        {prop.events ? <NotificationBundle className={styles.notification} value={prop.events} /> : null}
                        {prop.list && (
                            <b className={cx('caret', isOpen[escapedName] && styles['rotate-180'])} />
                        )}
                    </a>
                </li>
                <Collapse in={isOpen[escapedName]}>
                    <ul className={cx('nav', styles.nav__list)} key={key}>
                        {prop.list && prop.list.map(renderSimpleNavLink)}
                    </ul>
                </Collapse>
            </React.Fragment>
        );
    };

    const renderSimpleNavLink = (prop: Tab | SubItem, key: number) => (
        <li
          className={cx(styles.navButtons, isActiveRoute(mergePathAndRoutes(prop.path, prop.routes)) && 'active')}
          key={key}
          onClick={onClickMenuLink}
        >
            <NavLink
              exact
              to={prop.path || '#'}
              className={cx(styles.nav__link, 'nav-link')}
              activeClassName="active"
            >
                {renderLinkIcon(prop.icon, prop.name)}
                <p>{prop.name}</p>
                {('list' in prop) && (
                    <b className="caret" />
                )}
                {prop.events ? <NotificationBundle value={prop.events} /> : null}
            </NavLink>
        </li>
    );

    return (
        <ul className="nav">
            {links.map((prop: Tab, key: number) => {
                if (prop.list) {
                    return renderCollapseBlock(prop, key);
                }
                return renderSimpleNavLink(prop, key);
            })}
        </ul>
    );
};

interface NavListProps {
    links: Tab[];
    location: Location;
    onClickMenuLink?: () => void;
}

export interface Tab {
    name: string;
    icon: string;
    state: string;
    path?: string;
    routes?: string | string[];
    list?: SubItem[];
    role?: ExpertStatus[];
    events?: number;
}

export interface SubItem {
    name: string;
    path: string;
    routes?: string[];
    showParam?: string;
    role?: ExpertStatus[];
    icon?: string;
    events?: number;
}

export default NavList;
