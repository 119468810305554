import React from 'react';
import { CustomRadio } from 'components';
import { Sex } from 'components/enums';
import { Form, Col } from 'react-bootstrap';
import styles from './SexRadios.module.scss';

const SexRadios = ({
    sex, onClick, disabled, validErrors, children, className, groupClassName,
}: IProps) => (
        <Form.Group className={`row ${styles.row} ${groupClassName}`}>
            <Col sm="2" className="p-0">
                <Form.Label column>
                    ПОЛ
                </Form.Label>
            </Col>
            <Col sm="5" className={className}>
                <div className={styles.container}>
                    <CustomRadio
                      option="male"
                      name="sex"
                      label="Мужской"
                      checked={sex === Sex.Male}
                      disabled={disabled}
                      onClick={() => !disabled && onClick(Sex.Male)}
                    />
                    <CustomRadio
                      option="female"
                      name="sex"
                      label="Женский"
                      checked={sex === Sex.Female}
                      disabled={disabled}
                      onClick={() => !disabled && onClick(Sex.Female)}
                    />
                </div>
                {children}
                <Form.Control.Feedback className="d-block" type="invalid">{validErrors.sex}</Form.Control.Feedback>
            </Col>
        </Form.Group>
    );

export default SexRadios;

interface IProps {
    sex?: Sex;
    validErrors: IValidErrors;
    disabled?: boolean;
    children?: React.ReactNode;
    className?: string;
    groupClassName?: string;
    onClick: (sex: Sex) => void;
}

interface IValidErrors {
    [key: string]: string;
}
