import React from 'react';
import { connect } from 'react-redux';
import {
    Sidebar,
    WarningModal,
    NotificationSystem,
    SidebarMenuMapType,
    updateTabEvents,
    EmailConfirmationNotification,
} from 'components';
import { Location } from 'history';
import { push } from 'connected-react-router';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { useShowGlobalErrorModal } from 'hooks';
import sidebarTabs from '../../routes/SidebarTabs';

const PROFILE_TABS = [
    {
        path: '/my-profile',
        name: 'Настройки профиля',
        mini: 'НП',
    },
];

const SidebarContainer = ({
    profile,
    location,
    adaptive,
    sendConfirmationEmail,
    fetchProfile,
    startEventStatePoll,
    stopEventStatePoll,
    cleanNotifications,
    notifications,
    errors,
    events,
    onPush,
    onLogout,
}: Props) => {
    const TABS = sidebarTabs;
    const [tabs, setTabs] = React.useState(TABS);
    const { showModal, toggleModal } = useShowGlobalErrorModal(errors);

    React.useEffect(() => {
        fetchProfile();
        if (process.env.REACT_APP_LOCAL_RUN_TESTS !== 'true') {
            startEventStatePoll();
        }
        return () => {
            stopEventStatePoll();
        };
    }, []);

    React.useEffect(() => {
        setTabs(TABS.map(t => updateTabEvents(t, events, menuEventsMap)));
    }, [events]);

    return (
        <>
            <Sidebar
              profile={profile || {}}
              location={location}
              profileTabs={PROFILE_TABS}
              tabs={tabs}
              adaptive={adaptive}
              onLogout={onLogout}
            />
            {errors.error && errors.handler === 'global' && !errors.error.failures
                ? (
                    <WarningModal
                      title={errors.error.message}
                      show={showModal}
                      onHide={() => toggleModal(false)}
                      onAccess={() => toggleModal(false)}
                      accessBtnTittle="Вернуться"
                      titleClassNames="text-danger"
                    >
                        <p>Код ошибки:{errors.error.code}</p>
                        <p>Тип ошибки:{errors.error.type}</p>
                    </WarningModal>
                )
                : null}
            <NotificationSystem
              notifications={notifications}
              cleanNotifications={cleanNotifications}
            />
            <EmailConfirmationNotification
              profile={profile}
              onPush={onPush}
              sendConfirmationEmail={captcha => sendConfirmationEmail({ captcha })}
              cleanNotifications={cleanNotifications}
              apiError={errors.error}
            />
        </>
    );
};


const mapState = (state: IRootState) => ({
    ...state.auth,
    errors: state.errors,
    notifications: state.notifications,
    events: state.eventsState,
});

const mapDispatch = (dispatch: Dispatch) => ({
    fetchProfile: dispatch.auth.fetchProfile,
    cleanNotifications: dispatch.notifications.clean,
    startEventStatePoll: dispatch.eventsState.startEventStatePoll,
    stopEventStatePoll: dispatch.eventsState.stopEventStatePoll,
    sendConfirmationEmail: dispatch.auth.sendConfirmationEmailAsync,
    onLogout: dispatch.auth.logout,
    onPush: (key: string) => dispatch(push(key)),
});

interface SidebarOwnProps {
    location: Location;
    adaptive?: boolean;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
type Props = connectedProps & SidebarOwnProps;

export default connect(mapState, mapDispatch)(SidebarContainer);

const menuEventsMap: SidebarMenuMapType = {
    'Мои заявки': 'ApplicantExamApplications',
    'Мои аккредитации': 'ApplicantMyExamApplications',
};
