export interface IApiError {
    code: number;
    type: AppExceptionType;
    message: string;
    failures?: { [key: string]: string[] };
}

export enum AppExceptionType {
    Unspecified = 'unspecified',
    Validation = 'validation',
    NotFound = 'notFound',
    AlreadyExists = 'alreadyExists',
    WrongVerificationCode = 'wrongVerificationCode',
    TooManyRequests = 'tooManyRequests',
    WrongCredentials = 'wrongCredentials',
    AuthUserNotFound = 'authUserNotFound',
    Forbidden = 'forbidden',
    Captcha = 'captcha',
    WrongIp = 'wrongIp',
}

export interface ExecAPiOptions {
    withoutErrorsHandle?: boolean;
    eventStateRequest?: boolean;
    suppressErrorAndReturnNull?: boolean;
}
