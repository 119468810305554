import * as React from 'react';
import styles from '../StageContainer.module.scss';
import { IFakeExamDay } from '../getInitialDays';
import { WarningModal } from 'components/Common/Modals';
import ExtendedSelect from 'components/Common/ExtendedSelect/ExtendedSelect';
import {
    IApiError, IExamApplication,
    IExamDay, IExamStage,
} from 'components/models';
import { Form } from 'react-bootstrap';
import { Color, formatDayOption } from '_helpers';

const attemptsTxt = ['Первая', 'Вторая', 'Третья', 'Четвертая', 'Пятая'];

const ConfirmDateModal: React.FC<IProps> = props => (
  <WarningModal
    withoutOverflow
    withoutTitlePadding
    centered={false}
    show={props.show}
    title="Выберите день"
    onHide={props.onHide}
    accessBtnTittle="Подтвердить распределение"
    onAccess={() => props.onAccess()}
    bodyClassNames={styles.modalBody}
    closeBtnTittle="Отмена"
  >
      <p>
          Пожалуйста, выберите день, на который необходимо распределить выбранных аккредитуемых.
          В дальнейшем вы сможете изменить это распределение, если возникнет необходимость.
      </p>

      {props.examApplication && (
        <div className="mb-50">
            {props.examApplication.map(a => (
              <div className="d-flex justify-content-between mt-20">
                  <span>
                      {a.applicantFullName}
                  </span>
                  <span style={{ color: `var(${Color.Orange})` }}>
                      {a.lastFinalizedStageAttemptNumber ? `${attemptsTxt[a.lastFinalizedStageAttemptNumber - 1]} пересдача` : ''}
                  </span>
              </div>
            ))}
        </div>
      )}

      <ExtendedSelect
        createOptionPosition="first"
        creatable={!props.disableDateEditor}
        createLabel="Создать новый день"
        onCreateOption={() => props.onCreateNewDay && props.onCreateNewDay()}
        name="selectDate"
        options={props.days
          .filter((_, i) => (props.days[i] ? !!props.days[i].examId : true))
          .map((d, i) => formatDayOption({ ...d, value: String(i) }, props.stage))}
        value={String(props.selectedDayNumber)}
        onChange={event => props.chooseDay && event.value !== String(props.selectedDayNumber) && props.chooseDay(Number(event.value))}
        noOptionsMessage="Нет доступных дней"
      />

      {!!props.apiErrors && (
        <Form.Control.Feedback className="d-block" type="invalid" style={{ gridColumn: '1/3', width: 400 }}>
            <p>
                {props.apiErrors && props.apiErrors.failures && props.apiErrors.failures.time && props.apiErrors.failures.time[0]}
            </p>
        </Form.Control.Feedback>
      )}
  </WarningModal>
);

interface IProps {
    examApplication?: IExamApplication[];
    disableDateEditor?: boolean;
    show: boolean;
    onHide: () => void;
    onCreateNewDay?: () => void;
    onAccess: () => void;
    days: (IExamDay | IFakeExamDay)[];
    stage: IExamStage;
    selectedDayNumber: number;
    apiErrors?: IApiError | null;
    chooseDay?: (d: number) => void;
}

export default ConfirmDateModal;
