import React, { useEffect, useState, ChangeEvent } from 'react';
import { Form, Col, InputGroup } from 'react-bootstrap';
import { formDetector } from '_helpers';
import cn from 'classnames';
import styles from './InputText.module.scss';
import _ from 'lodash';

const InputTotal = ({
    fieldFailures,
    name,
    setRef,
    onChange,
    inputGroupClassNames,
    labelTitle,
    labelColSize,
    labelClassNames,
    withoutLabel,
    inputClassNames,
    inputColSize,
    prependContent,
    disabled,
    value,
    minLength,
    maxLength,
    type,
    placeholder,
    children,
    withoutDetectChanges,
    pattern,
    autoFocus,
    withDarkLabel,
    autoComplete,
}: Props) => {
    let ref: any;

    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (value && !withoutDetectChanges) {
            formDetector.setChangedInput(name, value);
        }

        return () => {
            setHasError(false);
        };
    }, [value]);

    useEffect(() => {
        if (fieldFailures && _.has(fieldFailures, name) && fieldFailures[name].length > 0 && !hasError && setRef) {
            setHasError(true);
            setRef(ref);
        } else if (hasError && (!fieldFailures || !_.has(fieldFailures, name) || fieldFailures[name].length === 0)) {
            setHasError(false);
        }
    }, [fieldFailures]);

    const handleChangeInput = (event: any) => {
        const { target } = event as ChangeEvent<HTMLInputElement>;

        if (!withoutDetectChanges) {
            formDetector.setChangedInput(target.name, target.value);
        }

        onChange(target.name, target.value);
    };

    const onSetRef = (node: any) => {
        ref = node;
    };

    return (
        <Form.Group className={cn('row', 'align-middle', styles.group, inputGroupClassNames)}>
            {withoutLabel
                ? null
                : (
                    <Form.Label column className={cn(`col-${labelColSize !== undefined ? labelColSize : 1}`, labelClassNames, withDarkLabel && styles.darkLabel)}>
                        {labelTitle}
                    </Form.Label>
                )}
            <Col sm={inputColSize || 11} className={inputClassNames || ''}>
                <InputGroup>
                    {prependContent
                        ? (
                            <InputGroup.Prepend>
                                <InputGroup.Text className={styles.prependContent}>{prependContent}</InputGroup.Text>
                            </InputGroup.Prepend>
                        )
                        : null
                    }
                    <Form.Control
                      ref={onSetRef}
                      disabled={disabled}
                      name={name}
                      autoComplete={autoComplete || 'off'}
                      value={value !== null ? value : ''}
                      onChange={handleChangeInput}
                      minLength={minLength}
                      maxLength={maxLength}
                      type={type || 'text'}
                      placeholder={placeholder}
                      isInvalid={!!(fieldFailures && _.has(fieldFailures, name) && fieldFailures[name].length > 0)}
                      pattern={pattern}
                      autoFocus={autoFocus}
                    />
                    <Form.Control.Feedback type="invalid" className="mt-0">
                        {fieldFailures && _.has(fieldFailures, name) && fieldFailures[name].length > 0
                            ? fieldFailures[name]
                            : null}
                    </Form.Control.Feedback>
                </InputGroup>
                {children}
            </Col>
        </Form.Group>
    );
};

export default InputTotal;

export interface Props {
    value: string;
    name: string;
    onChange: (name: string, value: string) => void;

    labelTitle?: string;
    withoutLabel?: boolean;
    labelColSize?: number;
    inputColSize?: number;

    labelClassNames?: string;
    inputClassNames?: string;
    inputGroupClassNames?: string;
    withDarkLabel?: boolean;

    prependContent?: string | number | JSX.Element;

    fieldFailures?: IFieldFailures | null;
    setRef?: (node: HTMLInputElement | null) => void;

    minLength?: number;
    maxLength?: number;

    type?: string;
    disabled?: boolean;
    placeholder?: string;
    children?: React.ReactNode;
    withoutDetectChanges?: boolean;
    pattern?: string;
    autoComplete?: string;
    autoFocus?: boolean;
}

interface IFieldFailures {
    [key: string]: string;
}
