let subDomain = 'dev.';
const spHost = document.location.host.split('.');
if (spHost.length === 3 && spHost[1] === 'main') { // prod: as-souznmp.ru
    subDomain = '';
} else if (spHost.length === 4 && spHost[1] !== 'dev') { // other test hosts
    subDomain = `${spHost[1]}.`;
}
const IceServers = {
    StunServers: {
        urls: [],
    },
    TurnServers: {
        urls: [`turn:kurento.${subDomain}as-souznmp.ru:3478`],
        credential: 'kms',
        username: 'kms',
    },
};

// eslint-disable-next-line no-underscore-dangle
(window as any).__iceServers = IceServers;

export default IceServers;
