import React from 'react';
import dotsIcon from './dots_icon.svg';
import styles from './DotsSettings.module.scss';
import cn from 'classnames';

const DotsSettings = ({ buttons }: IProps) => {
    const [showButton, setShowButton] = React.useState(false);
    const iconRef = React.useRef<HTMLImageElement | null>(null);

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (iconRef.current && !iconRef.current.contains(event.target as Node)) {
                setShowButton(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [iconRef]);

    const renderButtonIcon = (iconType?: IconType) => {
        if (!iconType) return null;

        if (iconType === 'delete') {
            return (
                <svg className={styles.svg} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.44215 7.64001C5.08632 7.64001 4.81421 7.91212 4.81421 8.26796V12.0984C4.81421 12.4542 5.08632 12.7264 5.44215 12.7264C5.79799 12.7264 6.0701 12.4542 6.0701 12.0984V8.26796C6.0701 7.91212 5.79799 7.64001 5.44215 7.64001Z" />
                    <path d="M8.37257 7.64001C8.01674 7.64001 7.74463 7.91212 7.74463 8.26796V12.0984C7.74463 12.4542 8.01674 12.7264 8.37257 12.7264C8.72841 12.7264 9.00052 12.4542 9.00052 12.0984V8.26796C9.00052 7.91212 8.72841 7.64001 8.37257 7.64001Z" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.61173 14.1705V6.23752C0.732612 6.1538 0.0628052 5.44213 0.0628052 4.56301V3.72575C0.0628052 2.80476 0.816337 2.05123 1.73732 2.05123H4.12351V1.73726C4.12351 0.816276 4.87704 0.0627441 5.79802 0.0627441H8.01676C8.93774 0.0627441 9.69127 0.816276 9.69127 1.73726V2.05123H12.0775C12.9984 2.05123 13.752 2.80476 13.752 3.72575V4.56301C13.752 5.44213 13.0822 6.1538 12.203 6.23752V14.1705C12.203 15.0915 11.4495 15.8451 10.5285 15.8451H3.28625C2.36526 15.8451 1.61173 15.0915 1.61173 14.1705ZM3.28625 14.5892H10.5285C10.7588 14.5892 10.9472 14.4008 10.9472 14.1705V6.23752H2.86762V14.1705C2.86762 14.4008 3.056 14.5892 3.28625 14.5892ZM5.37939 1.73726C5.37939 1.50701 5.56778 1.31863 5.79802 1.31863H8.01676C8.247 1.31863 8.43538 1.50701 8.43538 1.73726V2.05123H5.37939V1.73726ZM1.73732 3.30712C1.50708 3.30712 1.31869 3.4955 1.31869 3.72575V4.56301C1.31869 4.79325 1.50708 4.98164 1.73732 4.98164H12.0775C12.3077 4.98164 12.4961 4.79325 12.4961 4.56301V3.72575C12.4961 3.4955 12.3077 3.30712 12.0775 3.30712H1.73732Z" />
                </svg>
            );
        }

        if (iconType === 'logout') {
            return (
                <svg className={styles.svg} width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.0348 10.6667C12.0348 13.8899 9.15942 14.5623 6.72464 14.5623C4.28986 14.5623 1.43768 13.8899 1.43768 10.6667V5.33333C1.43768 4.15072 1.94783 1.43768 6.72464 1.43768C9.13623 1.43768 12.0348 2.11015 12.0348 5.33333C12.8232 5.33333 12.7072 5.33333 13.4725 5.33333C13.4725 1.94783 11.0145 0 6.74783 0C2.57391 0 0 2.04058 0 5.33333V10.6667C0 14.0522 2.45797 16 6.72464 16C10.9913 16 13.4493 14.0522 13.4493 10.6667" />
                    <path d="M17.1826 7.1884H6.93332V8.81159H17.1826L15.6985 9.83188L16.5101 11.0609L19.9884 8.6029C20.2898 8.32463 20.2898 7.72174 19.9884 7.37391L16.5101 4.91594L15.6985 6.14492L17.1826 7.1884Z" />
                </svg>
            );
        }

        if (iconType === 'plus') {
            return (
              <svg className={styles.svg} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58928 0 0 3.58931 0 8.00001C0 12.4107 3.58928 16 8 16C12.4107 16 16 12.4107 16 8.00001C16 3.58931 12.4107 0 8 0ZM8 1.28C11.7189 1.28 14.72 4.28109 14.72 8.00001C14.72 11.7189 11.7189 14.72 8 14.72C4.28106 14.72 1.28 11.7189 1.28 8.00001C1.28 4.28109 4.28106 1.28 8 1.28ZM7.36 3.52C7.36 3.16653 7.64653 2.88 8 2.88C8.35347 2.88 8.64 3.16653 8.64 3.52V7.36001H12.48C12.8335 7.36001 13.12 7.64653 13.12 8.00001C13.12 8.35348 12.8335 8.64001 12.48 8.64001H8.64V12.48C8.64 12.8335 8.35347 13.12 8 13.12C7.64653 13.12 7.36 12.8335 7.36 12.48V8.64001H3.52C3.16653 8.64001 2.88 8.35348 2.88 8.00001C2.88 7.64653 3.16653 7.36001 3.52 7.36001H7.36V3.52Z" />
              </svg>
            );
        }

        if (iconType === 'change') {
            return (
              <svg className={styles.svg} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M14.7999 2.72727H12.0363V2C12.0363 0.927269 11.1817 0.0727234 10.109 0.0727234H7.34537C6.29082 0.0727234 5.41809 0.927269 5.41809 2V2.72727H2.65446C1.23627 2.72727 0.0908203 3.87272 0.0908203 5.2909V13.3636C0.0908203 14.7818 1.23627 15.9273 2.65446 15.9273H14.7817C16.1999 15.9273 17.3454 14.7818 17.3454 13.3636V5.2909C17.3635 3.87272 16.2181 2.72727 14.7999 2.72727ZM6.79991 1.98181C6.79991 1.69091 7.03627 1.43636 7.34537 1.43636H10.1272C10.4181 1.43636 10.6726 1.67272 10.6726 1.98181V2.70909H6.79991V1.98181ZM14.7817 4.10909H2.65446C2.01809 4.10909 1.47264 4.63636 1.47264 5.30909V6.92727C2.99991 7.36363 4.74537 7.65454 6.58173 7.78181V7.65454C6.58173 7 7.109 6.47272 7.76355 6.47272H9.67264C10.3272 6.47272 10.8545 7 10.8545 7.65454V7.78181C12.6908 7.65454 14.4545 7.36363 15.9635 6.92727V5.2909C15.9635 4.63636 15.4363 4.10909 14.7817 4.10909ZM9.49082 7.85454V9.16363H7.98173V7.85454H9.49082ZM2.65446 14.5455H14.7999C15.4363 14.5455 15.9817 14.0182 15.9635 13.3636V8.36363C14.4181 8.76363 12.6726 9.05454 10.8545 9.16363V9.36363C10.8545 10.0182 10.3272 10.5455 9.67264 10.5455H7.76355C7.109 10.5455 6.58173 10.0182 6.58173 9.36363V9.16363C4.74537 9.05454 3.03627 8.78181 1.47264 8.36363V13.3636C1.47264 14.0182 1.99991 14.5455 2.65446 14.5455Z" />
              </svg>
            );
        }

        if (iconType === 'edit') {
            return (
              <svg className={cn(styles.svg, styles.stroke)} width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.9277 6.66372V3.43797C16.9277 2.44092 16.1653 1.67847 15.1682 1.67847H3.43821C2.44116 1.67847 1.67871 2.44092 1.67871 3.43797V15.168C1.67871 16.165 2.44116 16.9275 3.43821 16.9275H7.25046"
                    strokeWidth="1.7595"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M5.78418 6.95695H12.8222" strokeWidth="1.5249" strokeLinecap="round" />
                  <path d="M5.78418 10.476H9.30318" strokeWidth="1.5249" strokeLinecap="round" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.3494 17.7486L11.062 18.2178C10.6515 18.2764 10.2409 17.9245 10.3582 17.514L10.8274 15.2266L16.1646 9.88945C16.3992 9.65485 16.7511 9.65485 16.9857 9.88945L18.7452 11.649C18.9798 11.8836 18.9798 12.2355 18.7452 12.4701L13.3494 17.7486Z"
                    strokeWidth="1.6422"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
              </svg>
            );
        }

        return null;
    };

    return (
      <div className={styles.container}>
          <img
            className={styles.dotsIcon}
            ref={iconRef}
            src={dotsIcon}
            alt="dotsIcon"
            onClick={() => setShowButton(!showButton)}
          />
          {showButton ? (
            <section className={styles.buttonContainer}>
                {buttons.map((button, i) => (
                  <div
                    key={`dotButton_${i}`}
                    onClick={button.onClick}
                    className={cn(
                      styles.button,
                      (button.iconType === 'delete' || button.iconType === 'logout') && styles.button_red,
                      button.iconType === 'plus' && styles.button_green,
                      button.iconType === 'change' && styles.button_orange,
                      button.iconType === 'edit' && styles.button_grey,
                      button.className,
                    )}
                  >
                      {renderButtonIcon(button.iconType)}
                      <span>{button.buttonText}</span>
                  </div>
                ))}
            </section>
          ) : null}
      </div>
    );
};

export default DotsSettings;

interface IProps {
    buttons: IButton[];
}

export interface IButton {
    buttonText: string;
    iconType?: IconType;
    onClick: () => void;
    className?: string;
}

type IconType = 'logout' | 'delete' | 'plus' | 'change' | 'edit';
