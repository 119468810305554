import * as React from 'react';
import { MultiSelectGroup } from '../../MultiSelectGroup';
import styles from '../../MultiSelectSection/MultiSelectSection.module.scss';
import { IOption } from '../../Common/ExtendedSelect/ExtendedSelect';

const DefaultMultiSelectSearch: React.FC<IProps> = props => (
  <MultiSelectGroup
    label={props.label}
    options={props.options}
    onChange={events => props.onChange(events ? events.map(e => e.value) : [])}
    placeholder={props.placeholder}
    value={props.value && props.value.map(v => String(v))}
    className={styles.selectGroup}
    withoutStrictValue={props.withoutStrictValue}
    disabled={props.disabled}
  />
);

interface IProps {
    label: string;
    withoutStrictValue?: boolean;
    options: IOption[];
    placeholder: string;
    onChange: (values?: string[]) => void;
    value?: (string | number)[];
    disabled?: boolean;
}

export default DefaultMultiSelectSearch;
