import { createModel } from '@rematch/core';
import { QualificationInfoList } from '../../api/ApiClient';
import apiConnector from '../../api/ApiConnector';
import { IFilterPart, ISearchPart } from 'components/models';

const initialState: IState = {
    list: null,
};

export default createModel({
    state: initialState,
    reducers: {
        setList(state, list: IState['list']) {
            return { ...state, list };
        },
        clearList(state) {
            return { ...state, list: null };
        },
    },
    effects: (dispatch: any) => ({
        async getQualificationsAsync(payload: IGetQualifications) {
            const list = await apiConnector.execApi(
              dispatch,
              apiClient => apiClient.getQualifications(
                payload.occupationalStandardId,
                payload.readyToUse,
                payload.residency,
                payload.searchField,
                payload.searchTerm,
                payload.page,
                payload.pageSize,
                payload.sortOrder,
                payload.sortField,
              ),
            );

            this.setList(list);
            return list;
        },
    }),
});

interface IGetQualifications extends IFilterPart, ISearchPart {
    residency?: boolean;
    occupationalStandardId?: string | null;
    readyToUse?: boolean | null;
}


interface IState {
    list: QualificationInfoList | null;
}
