import React from 'react';
import { Card } from 'components';

class ErrorBoundary extends React.Component<{}, IState> {
    state: IState = {
        hasError: false,
        error: null,
    };

    static getDerivedStateFromError(error: Error): IState {
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
        return { hasError: true, error };
    }

    public componentDidCatch(error: Error, info: object) {
        // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
        logErrorToMyService(error, info);
    }

    public render() {
        if (this.state.hasError) {
            // Можно отрендерить запасной UI произвольного вида
            return (
                <Card
                  title="Что-то пошло не так!"
                  category="Непредвиденная ошибка работы приложения!"
                >
                    {this.state.error
                        ? (
                            <div>
                                <h4>{this.state.error.name}</h4>
                                <p>{this.state.error.message}</p>
                                <p>{this.state.error.stack}</p>
                            </div>
                        )
                        : null}
                </Card>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

const logErrorToMyService = (error: Error, info: object) => {
    console.log('TCL: logErrorToMyService -> info', info);
    console.log('TCL: logErrorToMyService -> error', error);
};

interface IState {
    hasError: boolean;
    error: Error | null;
}
