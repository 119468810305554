import * as React from 'react';
import UnAuthorizedLayout from '../layouts/unauth/UnAuthorizedLayout';
import { Redirect, Switch, Route } from 'react-router';
import { Location } from 'history';
import {
    EmailConfirmationPage,
    LoginPage,
    RegisterPage,
    ResetPasswordPage,
} from '../pages';

interface UnAuthorizedHandlerProps {
    location: Location;
    processing: boolean[];
}

const UnAuthorizedHandler: React.FC<UnAuthorizedHandlerProps> = (props) => {
    const { location, processing } = props;

    return (
        <UnAuthorizedLayout processing={processing}>
            <Switch location={location}>
                <Route path="/login" component={LoginPage} />
                <Route path="/register" component={RegisterPage} />
                <Route path="/reset-password" component={ResetPasswordPage} />
                <Route path="/confirm-email/:token/" component={EmailConfirmationPage} />
                <Redirect to="/login" />
            </Switch>
        </UnAuthorizedLayout>
    );
};


export default UnAuthorizedHandler;
