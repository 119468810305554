import { ExamStatus } from '../../enums';
import AwaitingExpertConfirmation from './AwaitingExpertConfirmation';
import ExamInfoState, { IExamInfoState } from './ExamInfoState';
import { IExamDetails } from '../../models';

export default class ExamInfoStates {
    private readonly status: ExamStatus;

    states: Record<string, IExamInfoState>;

    constructor(examDetails: IExamDetails) {
        this.status = examDetails.status;
        this.states = this.initStates(examDetails);
    }

    private initStates = (examDetails: IExamDetails) => ({
        [ExamStatus.AwaitingExpertConfirmation]: new AwaitingExpertConfirmation(examDetails),
        defaultState: new ExamInfoState(examDetails),
    })

    getState = () => {
        if (this.status in this.states) {
            return this.states[this.status];
        }

        return this.states.defaultState;
    }
}
