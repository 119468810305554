import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { LoaderBtn, Captcha } from 'components';
import { IApiError } from '_helpers/ApiConnector/types';
import { formatSecretPhoneNumber } from '_helpers';
import cn from 'classnames';
import styles from './SmsSignModal.module.scss';

const SmsSignModal = ({
    show,
    onHide,
    onSubmit,
    phone,
    onSendPasswordAgain,
    apiError,
    processing,
    title,
    waitingSeconds,
    phoneText,
    submitButtonText,
}: SignProtocolModalProps) => {
    const initialState: SignProtocolModalState = {
        signCode: '',
        timer: 0,
        captcha: '',
        captchaValid: true,
        signCodeValid: true,
    };

    const [signCode, setSignCode] = React.useState(initialState.signCode);
    const [signCodeValid, setSignCodeValid] = React.useState(initialState.signCodeValid);
    const [timer, setTimer] = React.useState(initialState.timer);
    const [captcha, setCaptcha] = React.useState(initialState.captcha);
    const [captchaValid, setCaptchaValid] = React.useState(initialState.captchaValid);
    const inputElement = React.useRef<HTMLInputElement | null>(null);
    const invalidInputText = apiError && apiError.failures && (apiError.failures.code || apiError.failures.verificationCode);

    React.useEffect(() => {
        if (inputElement.current && show) {
            inputElement.current.focus();
        }
    }, [inputElement, show]);

    const usePrevious = (value?: number | null) => {
        const ref = React.useRef(0);

        React.useEffect(() => {
            ref.current = value || 0;
        });
        return ref.current;
    };

    const calcTimer = () => {
        if (timer) {
            setTimer(timer - 1);
        }
    };


    const doValidate = () => {
        const isSignCodeValid = signCode.length === 4;
        const isCaptchaValid = apiError && apiError.failures && apiError.failures.captcha ? captcha.length === 4 : true;

        setSignCodeValid(isSignCodeValid);
        setCaptchaValid(isCaptchaValid);

        return isSignCodeValid && isCaptchaValid;
    };

    const prevTime = usePrevious(waitingSeconds);

    React.useEffect(() => {
        if (waitingSeconds && prevTime !== waitingSeconds) {
            setTimer(waitingSeconds);
        }

        const id = setTimeout(calcTimer, 1000);

        return () => clearTimeout(id);
    }, [waitingSeconds, timer]);

    const handleChangeSignCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSignCode(String(e.target.value));
    };

    const formatPhone = (nPhone: string) => {
        if (!nPhone) return '';
        return formatSecretPhoneNumber(nPhone);
    };

    const sendCodeAgain = () => {
        setSignCode('');
        onSendPasswordAgain(captcha);
    };

    const onCloseModal = () => {
        setSignCode('');
        onHide();
    };

    const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!doValidate()) return;

        setSignCode('');
        setCaptcha('');

        onSubmit(signCode, captcha);
    };

    const onSubmitCaptcha = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isCaptchaValid = apiError && apiError.failures && apiError.failures.captcha ? captcha.length === 4 : true;
        setCaptchaValid(isCaptchaValid);

        if (!isCaptchaValid) return;

        setSignCode('');
        setCaptcha('');

        onSendPasswordAgain(captcha);
    };

    if (!waitingSeconds && apiError && apiError.failures && apiError.failures.captcha) {
        return (
            <Modal
              show={show}
              onHide={onHide}
              centered
              dialogClassName={styles.signModal}
            >
                <Form onSubmit={onSubmitCaptcha}>
                    <Modal.Body>
                        <Captcha captcha={captcha} captchaValid={captchaValid} apiError={apiError} setCaptcha={setCaptcha} />
                        <Form.Control.Feedback className="d-block" type="invalid">
                            {apiError && ((apiError.failures && !(apiError.failures.code || apiError.failures.verificationCode)) || (apiError.type === 'captcha')) && apiError.message}
                        </Form.Control.Feedback>
                    </Modal.Body>
                    <Modal.Footer className={styles.signModal__footer}>
                        <LoaderBtn loading={processing} type="submit" disabled={processing}>Отправить</LoaderBtn>
                        <Button onClick={onCloseModal} variant="outline-secondary">Отмена</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }

    return (
        <Modal
          show={show}
          onHide={onHide}
          centered
          dialogClassName={styles.signModal}
        >
            <Modal.Header
              closeButton
              className={styles.signModal__header}
            >
                <Modal.Title>
                    {title || 'Одобрение проведения аккредитации'}
                </Modal.Title>
            </Modal.Header>
            {show ? (
                <Form onSubmit={onSubmitForm}>
                    <Modal.Body className={styles.signModal__body}>
                        <div className={styles.body__text}>
                            {phoneText ? `${phoneText} ` : 'Для одобрения проведения аккредитационного мероприятия, введите код из СМС-сообщения, которое было отправлено на номер '}
                            {formatPhone(phone)}
                        </div>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control
                              value={signCode}
                              onChange={handleChangeSignCode}
                              className={styles.body__input}
                              isInvalid={Boolean(invalidInputText) || !signCodeValid}
                              ref={inputElement as any}
                              maxLength={4}
                              autoFocus
                              name="code"
                            />
                            <Form.Control.Feedback type="invalid">
                                {!signCodeValid ? 'Минимальная длина кода 4 символа' : invalidInputText ? invalidInputText.join(',') : null}
                            </Form.Control.Feedback>
                        </Form.Group>
                        {timer ? (<span>Времени до повторной отправки: {timer}c</span>) : null}
                        <button disabled={!!timer} onClick={sendCodeAgain} className={cn(styles.body__sendAgain, 'mb-2')} type="button">
                            Отправить код повторно
                        </button>
                        <Captcha captcha={captcha} captchaValid={captchaValid} apiError={apiError} setCaptcha={setCaptcha} />
                        <Form.Control.Feedback className="d-block" type="invalid">
                            {apiError && (!apiError.failures || (apiError.failures && !invalidInputText)) ? apiError.message : null}
                        </Form.Control.Feedback>
                    </Modal.Body>
                    <Modal.Footer className={styles.signModal__footer}>
                        <LoaderBtn loading={processing} type="submit" disabled={processing}>{submitButtonText || 'Подтвердить'}</LoaderBtn>
                        <Button onClick={onCloseModal} variant="outline-secondary">Отмена</Button>
                    </Modal.Footer>
                </Form>
            ) : null}
        </Modal>
    );
};

export default SmsSignModal;

interface SignProtocolModalProps {
    show: boolean;
    phone: string;
    apiError: IApiError | null | undefined;
    waitingSeconds: number | null | undefined;
    processing: boolean;
    title?: string;
    phoneText?: string;
    submitButtonText?: string;
    onHide: () => void;
    onSendPasswordAgain: (captcha: string | undefined) => void;
    onSubmit: (signCode: string, captcha: string) => void;
}

interface SignProtocolModalState {
    signCode: string;
    timer: number;
    captcha: string;
    captchaValid: boolean;
    signCodeValid: boolean;
}
