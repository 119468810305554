import * as React from 'react';
import { Moment } from 'moment';

const DatePeriodColumn: React.FC<IProps> = ({ dateFrom, dateTo }) => (
  <div>
      {dateFrom ? dateFrom.clone().local().format('DD.MM.YYYY') : ''}
      {' - '}
      {dateTo ? dateTo.clone().local().format('DD.MM.YYYY') : ''}
  </div>
);

interface IProps {
    dateFrom?: Moment;
    dateTo?: Moment;
}

export default DatePeriodColumn;
