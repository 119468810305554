/* eslint-disable arrow-parens */
import { useCallback, useState } from 'react';

const useLoading = <T extends unknown, B>(promiseLikeFunction: (...args: B[]) => Promise<T>, deps: Readonly<unknown[]> = []): [boolean, (...args: B[]) => Promise<T>] => {
    const [loading, setLoading] = useState(false);


    const callback = useCallback((...args) => {
        setLoading(true);
        return promiseLikeFunction(...args)
          .then((r) => {
              setLoading(false);
              return r;
          })
          .catch((r) => {
              setLoading(false);
              throw r;
          });
    }, [promiseLikeFunction, ...deps]);

    return [loading, callback];
};


export default useLoading;
