import React from 'react';
import { IExamApplicationDetails } from 'components/models';
import { DiplomaType, DiplomaTypeTitles, IdentityDocTypeTitles } from 'components/enums';
import {
    Card,
    AutoGrowTextArea,
    LoaderBtn,
    SignedExpert,
    renderRow, CustomRadio,
} from 'components';
import { formatPhoneNumber, formatSnils } from '_helpers';
import { Button, Form } from 'react-bootstrap';
import styles from './StatementTab.module.scss';
import cn from 'classnames';

const StatementTab = (
  {
      onCancel,
      onSubmit,
      contentClassName,
      examApplication,
      withoutPadding,
      submitButtonText,
      cancelButtonText,
      processing,
      setProfessionalRetraining,
      setStartStage,
      ...props
  }: IProps,
) => {
    const [showProfessionalRetrainingError, setProfessionalRetrainingError] = React.useState(false);
    const [showStartStageError, setStartStageError] = React.useState(false);

    const { applicant, exam } = React.useMemo(() => examApplication, [examApplication]);

    const identity = examApplication.applicantIdentityDoc;
    const diploma = examApplication.applicantDiploma;
    const examName = exam ? `«${exam.qualificationTitle}»` : '';
    const applicantFullName = `${applicant.lastName} ${applicant.firstName} ${applicant.middleName}`;
    
    const startStage = props.startStage || examApplication.startStage;
    const professionalRetraining = props.professionalRetraining != null ? props.professionalRetraining : examApplication.professionalRetraining;

    const onOpenModal = () => {
        if (onSubmit) {
            const professionalRetrainingError = examApplication.exam.qualificationResidency && professionalRetraining == null;
            const startStageError = startStage == null;
            
            if (!professionalRetrainingError && !startStageError) {
                onSubmit();
            } else {
                setProfessionalRetrainingError(professionalRetrainingError);
                setStartStageError(startStageError);
            }
        }
    };

    const onStartStage = setStartStage && ((val: number) => {
        setStartStage(val);
        setStartStageError(false);
    });

    return (
      <Card
        title="Заявление"
        category="о допуске к аккредитации специалиста"
        contentClassName={contentClassName}
        withoutPadding={withoutPadding}
        withoutPaddingTitle={withoutPadding}
        headerClassName={withoutPadding ? 'pt-0' : undefined}
        withoutBorder
        withoutBottomMargin
      >
            <span className={styles.text}>
                
                {examApplication.exam.qualificationResidency ? (
                    <>
                        Я, <b>{applicantFullName}, дата рождения {applicant.birthDate.format('DD.MM.YYYY')}</b>,  информирую, что успешно завершил(а) освоение образовательной программы по специальности (направлению подготовки) <b>{examName}</b> после окончания:

                        {setProfessionalRetraining ? (
                            <>
                                <br />
                                <div className="d-flex mt-2 flex-column">
                                    <CustomRadio
                                        className="p-2 font-weight-bold"
                                        label="Ординатуры"
                                        name="professional-retraining"
                                        option="residency"
                                        checked={professionalRetraining === false}
                                        onClick={() => { setProfessionalRetraining(false); setProfessionalRetrainingError(false);}}
                                    />
                                    <CustomRadio
                                        className="p-2 font-weight-bold"
                                        label="Профессиональной переподготовки"
                                        name="professional-retraining"
                                        option="professionalRetraining"
                                        checked={professionalRetraining === true}
                                        onClick={() => { setProfessionalRetraining(true); setProfessionalRetrainingError(false);}}
                                    />
                                </div>
                                {showProfessionalRetrainingError ? (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        Обязательное поле для заполнения
                                    </Form.Control.Feedback>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <br /><br />
                                <span className={styles.text_address}>{professionalRetraining ? 'Профессиональной переподготовки' : 'Ординатуры'}</span>
                                <br />
                            </>
                        )}
                        <br />
                        что подтверждается следующим документом:
                        <br />
                    </>
                ) : (
                    <>
                        Я, <b>{applicantFullName}, дата рождения {applicant.birthDate.format('DD.MM.YYYY')}</b>,  информирую, что успешно завершил(а) освоение образовательной программы по специальности (направлению подготовки) <b>{examName}</b>, что подтверждается следующим документом:
                    </>
                )}
                
                
                <br />
                <b>{diploma && `${DiplomaTypeTitles[diploma.type]}, выдан ${diploma.issuedBy} ${diploma.dateOfIssue.format('DD.MM.YYYY')} г.`}</b><br />
                <br />
                <br />
                <br />
                Учитывая, что я намерен(а) осуществлять медицинскую деятельность по специальности <b>{examName}</b>на территории Российской Федерации, прошу допустить меня до прохождения процедуры аккредитации специалиста начиная с:
                <br />

                {setStartStage && onStartStage ? (
                    <>
                        <br />
                        <div className="d-flex mt-2 flex-column">
                            <CustomRadio
                                className="p-2 font-weight-bold"
                                label="Первого этапа"
                                name="start-stage"
                                option="stage-1"
                                checked={startStage === 1}
                                onClick={() => onStartStage(1)}
                            />
                            <CustomRadio
                                className="p-2 font-weight-bold"
                                label="Второго этапа"
                                name="start-stage"
                                option="stage-2"
                                checked={startStage === 2}
                                onClick={() => onStartStage(2)}
                            />
                            <CustomRadio
                                className="p-2 font-weight-bold"
                                label="Третьего этапа"
                                name="start-stage"
                                option="stage-3"
                                checked={startStage === 3}
                                onClick={() => onStartStage(3)}
                            />
                        </div>
                        {showStartStageError ? (
                            <Form.Control.Feedback className="d-block" type="invalid">
                                Обязательное поле для заполнения
                            </Form.Control.Feedback>
                        ) : null}
                    </>
                ) : (
                    <>
                        <br />
                        <span className={styles.text_address}>{startStage === 3 ? 'Третьего' : startStage === 2 ? 'Второго' : 'Первого'} этапа</span>
                        <br />
                    </>
                )}
          </span>
          
          <span className={cn(styles.text, styles.text_end)}>
                С приказом Министерства здравоохранения РФ от 2 июня 2016 г. N 334н "Об утверждении Положения об аккредитации специалистов" (<a href="https://base.garant.ru/71423024" target="_blank">https://base.garant.ru/71423024</a>), ознакомлен(а)<br />
                <br /><br /><br />
                
                Прилагаю копии следующих документов:<br /><br />
                
                1. Копия документа, удостоверяющего личность:<br />
                <b className='pre-wrap'>{IdentityDocTypeTitles[identity.identityDocType]}, выдан {identity.passport
                    ? `${identity.passport.issuedBy} от ${identity.passport.dateOfIssue.format('DD.MM.YYYY')} г.` :
                    identity.otherIdentity ? identity.otherIdentity.data : ''}</b>
                <br /><br />
                
                2. Копия документов об образовании:<br />
                <b>{diploma && `${DiplomaTypeTitles[diploma.type]}, выдан ${diploma.issuedBy} ${diploma.dateOfIssue.format('DD.MM.YYYY')} г.`}</b>
                <br /><br />
                
                3. СНИЛС:<br />
                <b>№{formatSnils(applicant.snils, '')}</b>
                <br /><br />
                <br />
                
                Я согласен(на) на обработку в соответствии с Федеральным законом от 27 июля
                2006 г. № 152-ФЗ «О персональных данных» (Собрание законодательства Российской
                Федерации, 2006, № 31, ст. 3451) моих персональных данных, указанных в настоящем
                заявлении и прилагаемых к нему документах (фамилия, имя, отчество (при наличии), дата
                и место рождения, реквизиты СНИЛС, реквизиты документа, удостоверяющего личность, - наименование документа, серия, номер, кем выдан и когда, месте проживания (регистрации), место
                работы, образование и квалификация), а также результатов прохождения процедуры аккредитации.
                Я уведомлен(а) и понимаю, что под обработкой персональных данных
                подразумевается совершение следующих действий (операций): сбор, обработка, запись,
                систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение,
                использование, подтверждение, передача (распространение, предоставление, доступ),
                обезличивание, блокирование, удаление, уничтожение персональных данных по
                истечении срока действия настоящего согласия в соответствии с Федеральным законом
                от 27 июля 2006 г. № 152-ФЗ «О персональных данных».
          </span>
          {examApplication.signCode && !props.correctionsRequired ? (renderRow('Подпись',
            <SignedExpert
              commissionTitle="Аккредитуемый"
              expert={{
                  fullName: applicantFullName,
                  signed: true,
                  signedCode: examApplication.signCode,
              }}
            />,
            (onSubmit || onCancel) && 'mb-5')) : null}

          {onSubmit ? (
            <LoaderBtn
              variant="primary"
              className={cn('pl-4 pr-4')}
              onClick={onOpenModal}
              loading={processing}
              disabled={processing}
            >
                {submitButtonText || 'Подписать и отправить заявление'}
            </LoaderBtn>
          ) : null}

          {onCancel ? (
            <Button
              className={cn('ml-4')}
              variant="outline-secondary"
              onClick={onCancel}
              disabled={processing}
            >
                {cancelButtonText || 'Вернуться к редактированию заявления'}
            </Button>
          ) : null}
      </Card>
    );
};

export default StatementTab;

interface IProps {
    examApplication: IExamApplicationDetails;
    contentClassName?: string;
    withoutPadding?: boolean;
    submitButtonText?: string;
    cancelButtonText?: string;
    processing?: boolean;
    correctionsRequired?: boolean;
    professionalRetraining?: boolean;
    setProfessionalRetraining?: (professionalRetraining: boolean) => void;
    startStage?: number;
    setStartStage?: (startStage: number) => void;
    onCancel?: () => void;
    onSubmit?: () => void;
}
