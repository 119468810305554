import React from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { ResetPasswordFirstStep, ResetPasswordSecondStep, ResetPasswordThirdStep } from 'components';

const ResetPasswordContainer = ({
    sendResetPasswordVerificationCode,
    checkResetPasswordVerificationCode,
    authProcessing,
    resetPassword,
    clearErrors,
    onGoBack,
    apiError,
    onPush,
}: Props) => {
    const [step, setStep] = React.useState(1);
    const [pagePhone, setPagePhone] = React.useState('');
    const [pageCode, setPageCode] = React.useState('');
    const [waitingSeconds, setWaitingSeconds] = React.useState(60);

    const goToStep = (newStep: number) => {
        setStep(newStep);
    };

    const goToLogin = () => {
        setPagePhone('');
        setPageCode('');
        clearErrors();
        onGoBack();
    };

    const step1Props = {
        apiError,
        initPhone: pagePhone,
        sendCode: async (phone: string, captcha?: string) => {
            setPagePhone(phone);
            const res = await sendResetPasswordVerificationCode({ phone, captcha });

            if (res && res.waitingSeconds) {
                setWaitingSeconds(res.waitingSeconds);
            }

            return res;
        },
        goNext: () => goToStep(2),
        goBack: () => goToLogin(),
    };

    const step2Props = {
        apiError,
        phone: pagePhone,
        waitingSeconds,
        checkCode: async (phone: string, code: string, captcha?: string) => {
            setPageCode(code);
            return !!await checkResetPasswordVerificationCode({ phone, verificationCode: code, captcha });
        },
        resendCode: async () => {
            const res = await sendResetPasswordVerificationCode({ phone: pagePhone });

            return res;
        },
        goNext: () => goToStep(3),
        goBack: () => {
            setPageCode('');
            goToStep(1);
        },
    };

    const step3Props = {
        apiError,
        authProcessing,
        resetPassword: async (password: string, captcha?: string) => {
            const checkVerify = await checkResetPasswordVerificationCode({ phone: pagePhone, verificationCode: pageCode, captcha });

            if (checkVerify) {
                const res = await resetPassword({
                    verificationCode: pageCode,
                    phone: pagePhone,
                    newPassword: password,
                });

                if (res) {
                    onPush('/');
                }
            }
        },
    };

    return (
        <>
            {step === 1 && <ResetPasswordFirstStep {...step1Props} />}
            {step === 2 && <ResetPasswordSecondStep {...step2Props} />}
            {step === 3 && <ResetPasswordThirdStep {...step3Props} />}
        </>
    );
};

const mapState = (state: IRootState) => ({
    apiError: state.errors.error,
    authProcessing: Boolean(state.loading.effects.auth.resetPassword),
});

const mapDispatch = (dispatch: Dispatch) => ({
    resetPassword: dispatch.auth.resetPassword,
    sendResetPasswordVerificationCode: dispatch.auth.sendResetPasswordVerificationCodeAsync,
    checkResetPasswordVerificationCode: dispatch.auth.checkResetPasswordVerificationCodeAsync,
    clearErrors: dispatch.errors.clean,
    onGoBack: () => dispatch(goBack()),
    onPush: (key: string) => dispatch(push(key)),
});

export default connect(mapState, mapDispatch)(ResetPasswordContainer);

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
type Props = connectedProps;

interface IStatus {
    wasSent?: boolean;
    waitingSeconds?: number;
}
