import React from 'react';
import {
    Card,
    PersonalDataTab,
    ExamTable,
    MappedExamStatuses,
    ExamApplicationStatus,
    AnimatedTabs,
    ApplicantQualifications,
} from 'components';
import {
    IApplicantQualificationDetails,
    IApplicant,
    IApplicantQualification,
    IApplicantExamList,
    IApplicantExam,
} from 'components/models';
import { ColorStatus, Color } from '_helpers';
import { Tab } from 'react-bootstrap';
import { IApiError } from '_helpers/ApiConnector/types';

const ApplicantDetailsTabs = ({
    tab,
    applicantQualificationDetails,
    applicantQualifications,
    applicantDetail,
    applicantExams,
    apiError,
    getApplicantQualificationDetails,
    getApplicantExams,
    onCancelClick,
    onChooseExam,
    showLoader,
}: IProps) => {
    const getDefaultTab = () => {
        if (tab === TabKey.ExamsTab || tab === TabKey.InfoTab || tab === TabKey.QualificationsTab) return tab;
        return false;
    };

    React.useEffect(() => {
        setActiveTab(getDefaultTab());
    }, [tab]);

    const [activeTab, setActiveTab] = React.useState(getDefaultTab());

    const EXAM_TABLE_HEADERS = ['Период проведения', 'Специальность', 'Статус'];

    const getExamRows = (exam: IApplicantExam) => {
        const applicantStatus = exam.examApplicationStatus === ExamApplicationStatus.Passed
            ? new ColorStatus(Color.Success, 'Аккредитация пройдена')
            : new ColorStatus(Color.Red, 'Аккредитация не пройдена');

        const t = MappedExamStatuses[exam.examStatus];
        const examStatus = new ColorStatus(t.color, t.text);

        if (applicantStatus.text !== examStatus.text) {
            examStatus.subStatus = {
                color: applicantStatus.color === Color.Red ? Color.Red : Color.Success,
                text: applicantStatus.text,
            };
        }

        return {
            ...exam,
            id: exam.examApplicationId,
            status: examStatus,
            name: exam.qualificationTitle,
        };
    };

    const handleCancelClick = () => {
        onCancelClick();
    };

    const handleChooseExam = (id: string) => {
        if (!applicantExams || !applicantExams.items) return;
        const selectedExam = applicantExams.items.find(x => x.examApplicationId === id);
        if (!selectedExam) return;
        onChooseExam(id);
    };

    return (
        <Card
          title={applicantDetail ? `${applicantDetail.lastName} ${applicantDetail.firstName} ${applicantDetail.middleName}` : ''}
          onClickBack={handleCancelClick}
          apiError={apiError}
          showLoader={showLoader}
          withoutBorder
          withoutPadding
          withBackButton
          smallTitle
        >
            <AnimatedTabs
              activeKey={activeTab || TabKey.InfoTab}
              onSelect={(key: string) => setActiveTab(key as TabKey)}
              id="applicantDetailsTabs"
            >
                <Tab eventKey={TabKey.InfoTab} title="Личные данные">
                    <PersonalDataTab
                      cardCategory="Общая информация об аккредитуемом"
                      personalData={applicantDetail}
                    />
                </Tab>
                <Tab eventKey={TabKey.QualificationsTab} title="Специальности">
                    <ApplicantQualifications
                      qualifications={applicantQualifications}
                      details={applicantQualificationDetails}
                      cardTitle="Специальности"
                      cardCategory="Список подтвержденных аккредитуемым специальностей"
                      onChooseQualification={id => getApplicantQualificationDetails(id)}
                      onClearQualification={() => {}}
                    />
                </Tab>
                <Tab eventKey={TabKey.ExamsTab} title="Аккредитации">
                    <Card
                      title="Аккредитации"
                      category="Список аккредитаций, в которых участвовал аккредитуемый"
                      withoutBorder
                      withoutPadding
                    >
                        <ExamTable
                          header={EXAM_TABLE_HEADERS}
                          tableRows={applicantExams ? applicantExams.items.map(getExamRows) : []}
                          onChooseExam={handleChooseExam}
                          pagination={applicantExams}
                          onChangePagination={({ page, perPage }) => getApplicantExams({ page, pageSize: perPage })}
                          placeholderText="Здесь будут отображаться все аккредитации, в которых участвовал аккредитуемый"
                          placeholderTitle="Нет аккредитаций в списке"
                        />
                    </Card>
                </Tab>
            </AnimatedTabs>
        </Card>
    );
};

export default ApplicantDetailsTabs;

interface IProps {
    applicantDetail: IApplicant | null;
    applicantQualifications: IApplicantQualification[] | null;
    applicantQualificationDetails: IApplicantQualificationDetails | null;
    applicantExams: IApplicantExamList | null;
    apiError: IApiError | null;
    showLoader: boolean;
    tab?: string;
    getApplicantQualificationDetails: (examApplicationId: string) => Promise<IApplicantQualificationDetails | null>;
    getApplicantExams: (data: IGetApplicantExams) => void;
    onCancelClick: () => void;
    onChooseExam: (examApplicationId: string) => void;
}

enum TabKey {
    InfoTab = 'info',
    QualificationsTab = 'qualifications',
    ExamsTab = 'exams'
}

interface IGetApplicantExams {
    page: number;
    pageSize: number;
}
