import React from 'react';
import { Form } from 'react-bootstrap';
import cn from 'classnames';
import _ from 'lodash';

const AutoGrowTextArea = ({
    value, onChange, disabled, className, autoFocus, fieldFailures, name, placeholder,
}: IProps) => {
    const areaRef = React.createRef<HTMLTextAreaElement>();
    const error = React.useMemo(() => (name && fieldFailures && _.has(fieldFailures, name) && fieldFailures[name].length > 0 ? fieldFailures[name] : null), [fieldFailures]);

    React.useEffect(() => {
        if (areaRef.current) {
            autoGrowTextArea(areaRef.current);
        }
    }, [areaRef]);

    return (
        <>
            <textarea
              ref={areaRef}
              className={cn('form-control text-left', className, error && 'is-invalid')}
              value={value || ''}
              onChange={onChange}
              disabled={disabled}
              name={name}
              onFocus={ev => autoGrowTextArea(ev.target as HTMLTextAreaElement)}
              onKeyPress={ev => autoGrowTextArea(ev.target as HTMLTextAreaElement)}
              placeholder={placeholder}
              autoFocus={autoFocus}
            />
            {error ? (
                <Form.Control.Feedback type="invalid" className="mt-0 d-block">
                    {error}
                </Form.Control.Feedback>
            ) : null}
        </>
    );
};

export default AutoGrowTextArea;

interface IProps {
    value: string;
    name?: string;
    className?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    placeholder?: string;
    fieldFailures?: IFieldFailures | null;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

interface IFieldFailures {
    [key: string]: string;
}

const autoGrowTextArea = (textField: HTMLTextAreaElement) => {
    if (textField.clientHeight === 36 && textField.clientHeight === textField.scrollHeight) {
        // eslint-disable-next-line
        textField.style.height = '25px';
    }

    if (textField.clientHeight < textField.scrollHeight) {
        // eslint-disable-next-line
        textField.style.height = `${textField.scrollHeight}px`;
        if (textField.clientHeight < textField.scrollHeight) {
            // eslint-disable-next-line
            textField.style.height = `${textField.scrollHeight * 2 - textField.clientHeight}px`;
        }
    }
};
