import React from 'react';
import { Form } from 'react-bootstrap';
import { ExtendedSelect, IExtendedSelectOption, IExtendedSelectEvent } from 'components';
import cn from 'classnames';
import styles from './MultiSelectGroup.module.scss';

const MultiSelectGroup = ({
    label, options, onChange, placeholder, value, className, withoutStrictValue = true, disabled,
}: IProps) => (
        <Form.Group className={cn(styles.select_group, className)}>
            <Form.Text className={cn(styles.select_text, 'text-muted mt-0')}>
                {label}
            </Form.Text>
            <ExtendedSelect
              name="title"
              options={options}
              onChange={() => true}
              onChangeMulti={onChange}
              className={styles.select}
              placeholder={placeholder}
              value={value}
              withoutStrictValue={withoutStrictValue}
              multi
              disabled={disabled}
            />
        </Form.Group>
    );

export default MultiSelectGroup;

interface IProps {
    label: string;
    options: IExtendedSelectOption[];
    placeholder?: string;
    value?: string[];
    className?: string;
    withoutStrictValue?: boolean;
    onChange: (e: IExtendedSelectEvent[]) => void;
    disabled?: boolean;
}
