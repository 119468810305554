import * as React from 'react';
import DayCell from './DayCell';
import styles from './StageContainer.module.scss';
import ConfirmDateModal from './Modals/ConfirmDateModal';
import getInitialDays, { IFakeExamDay } from './getInitialDays';
import { Moment } from 'moment';
import cn from 'classnames';
import EditDayModal from './Modals/EditDayModal';
import { ExamAssigningApplicationsTable } from '../../../ExamAssigningApplicationsTable';
import {
    IAssignApplicationsForDay,
    IAssignForDayInput,
    ICreateExamDayInput,
    IExamApplication,
    IExamDay,
    IExamDetails,
    IUpdateAssignedExpertsForDay,
    IUpdateExamDay,
    IFinalizeResultPayload,
    IAdmitToExamDay,
    IBaseExamUnAssignedApplicationsList,
    IBaseExpertsForDay,
    IBaseExamApplicationsForDay,
    IBaseExperts,
    IBaseUnratedExamApplications, IBaseApplicantDetails, IBaseExamApplicationsForDayAdmit,
} from 'components/models';
import { IApiError } from '_helpers/ApiConnector/types';
import { Card } from 'components/Card';
import EmptyStage from './Components/EmptyStage';
import { PaginationImpl } from 'components/defaultValues';
import CreateDayModals from './Modals/CreateDayModals';

const StageContainer: React.FC<OwnProps> = (
  {
      stageNumber,
      examUnAssignedApplicationsList,
      examDetails,
    ...props
  },
) => {
    const DEFAULT_DAYS_COUNT = 5;
    const stageInfo = React.useMemo(() => examDetails.examStages[stageNumber - 1], [examDetails.examStages, examDetails]);
    const stageDays = React.useMemo(() => (
      [
          ...(stageInfo.examDays.map((d) => {
              d.time.local();
              return d;
          })),
          ...(examUnAssignedApplicationsList.info && examUnAssignedApplicationsList.info.total && !props.readonly ? getInitialDays(
            DEFAULT_DAYS_COUNT - stageInfo.examDays.length,
            stageInfo.examDays.length ? stageInfo.examDays[stageInfo.examDays.length - 1].dayNumber + 1 : 1,
          ) : []),
      ]
    ), [stageInfo, examDetails, examUnAssignedApplicationsList.info]);

    const [chooseDay, setChooseDay] = React.useState(() => {
        const dayIndex = stageDays.findIndex((d, i) => !d.examId || i === DEFAULT_DAYS_COUNT - 1);
        return dayIndex !== -1 ? dayIndex : 0;
    });
    const lastDay = [...stageInfo.examDays].pop();
    const [showConfirmDate, setShowConfirmDate] = React.useState(false);
    const [showCreateNewDay, setShowCreateNewDay] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);

    const [assignedApplicants, setAssignedApplicants] = React.useState<IExamApplication[]>([]);

    const examDay = React.useMemo<IExamDay | undefined>(() => stageInfo.examDays[chooseDay], [stageInfo, chooseDay]);

    const handleAssignedApplicants = React.useCallback((applicants: IExamApplication[]) => {
        setShowConfirmDate(true);
        setAssignedApplicants(applicants);
    }, []);

    const handleUpdateExperts = async (ids: string[], time?: Moment) => {
        let result;
        if (examDay) {
            result = props.updateExpertsForDay({
                examId: examDetails.id,
                stage: stageNumber,
                examDayId: examDay.id,
                body: {
                    expertIds: ids,
                },
            });
        } else if (time) {
            const createExamDayPayload: ICreateExamDayInput = {
                examId: examDetails.id,
                stage: stageNumber,
                body: {
                    time,
                    expertIds: ids,
                },
            };

            if (assignedApplicants.length) {
                createExamDayPayload.assignApplications = {
                    examApplicationIds: assignedApplicants.map(a => a.examApplicationId),
                };
            }
            result = await props.createExamDay(createExamDayPayload);
        }

        if (result) {
            await examUnAssignedApplicationsList.fetch({ examId: examDetails.id, stage: stageNumber });
        }

        return result;
    };

    const handleConfirmDate = async () => {
        if (examDay) {
            await props.assignApplicationsForDay({
                examId: examDetails.id,
                stage: stageNumber,
                examDayId: examDay.id,
                body: {
                    examApplicationIds: assignedApplicants.map(a => a.examApplicationId),
                },
            });

            await examUnAssignedApplicationsList.fetch({ examId: examDetails.id, stage: stageNumber });

            setShowConfirmDate(false);
        }
    };

    const handleCreateDay = async (ids: string[], time: Moment) => {
        const createExamDayPayload: ICreateExamDayInput = {
            examId: examDetails.id,
            stage: stageNumber,
            body: {
                time,
                expertIds: ids,
            },
        };

        const result = await props.createExamDay(createExamDayPayload);

        if (result) {
            const newDays = result.examStages[stageNumber - 1].examDays;
            setChooseDay(newDays.length - 1);
        }

        return result;
    };

    const handleChangeExamApplicationsFilter = (searchField: string | undefined, searchTerm: string | undefined, pageNumber: number | undefined, pageSize: number | undefined) => {
        examUnAssignedApplicationsList.fetch({
            examId: examDetails.id,
            stage: stageNumber,
            searchTerm,
            searchField,
            pageSize,
            page: pageNumber,
        });
    };

    const handleClickDay = (day: IExamDay | IFakeExamDay, index: number) => {
        setChooseDay(index);
        if (day.examId) {
            setShowEditModal(true);
        } else {
            !props.readonly && setShowCreateNewDay(true);
        }
    };

    const isEmptyStage = !stageInfo.examDays.length;

    return (
      <Card
        withoutPadding
        headerClassName="pb-20"
        withoutBorder
        title={`Проведение ${stageNumber}-го этапа`}
        category={examDetails.accreditationCenterName}
      >
          <div className={cn(styles.admitted, 'ml-30')}>
              <span>Не распределено</span>
              <span>{stageInfo.unassignedApplications || 0} чел.</span>

              <span>Всего аккредитуемых</span>
              <span>{stageInfo.totalApplications || 0} чел.</span>
          </div>

          {isEmptyStage && props.readonly ? (
            <EmptyStage />
          ) : (
            <>
                <div className={cn(styles.dayCells, 'ml-30')}>
                    {stageDays.map((day, i) => (
                      <DayCell
                        readonly={props.readonly}
                        key={day.id}
                        disabled={stageDays[i - 1] ? !stageDays[i - 1].time : false}
                        selected={chooseDay === i}
                        dayNumber={day.dayNumber}
                        group={day.group}
                        date={day.time}
                        count={day.totalApplications || 0}
                        badgeCount={day.unFinalizedApplications}
                        audienceFilledPercents={Math.ceil((day.totalApplications || 0) / (stageInfo.audienceCapacity || 1) * 100)}
                        expertsCount={day.totalExperts}
                        readyForFinalize={day.readyForFinalize}
                        onChoose={() => setChooseDay(i)}
                        orange={!!(day.readyForFinalize && day.unFinalizedApplications)}
                        onEdit={() => handleClickDay(day, i)}
                      />
                    ))}
                </div>
                <br />

                {examUnAssignedApplicationsList.info && !props.readonly && (
                  <ExamAssigningApplicationsTable
                    readonly={props.readonly}
                    withCheckbox
                    withConfirmButton
                    onAssignedApplicants={handleAssignedApplicants}
                    confirmButtonText="Распределить выбранных аккредитуемых"
                    headers={[
                        { value: 'Имя, фамилия, Отчество', key: 'applicantFullName' },
                        { value: 'Телефон', key: 'applicantPhone' },
                    ]}
                    examApplications={examUnAssignedApplicationsList.info.items.map(i => ({ examApplication: i }))}
                    onChange={handleChangeExamApplicationsFilter}
                    pagination={new PaginationImpl(examUnAssignedApplicationsList.info).getPaginationData()}
                  />
                )}
            </>
          )}

          <ConfirmDateModal
            onCreateNewDay={() => setShowCreateNewDay(true)}
            stage={stageInfo}
            show={showConfirmDate}
            onHide={() => setShowConfirmDate(false)}
            onAccess={handleConfirmDate}
            days={stageDays}
            selectedDayNumber={chooseDay}
            chooseDay={setChooseDay}
            apiErrors={props.error}
          />

          <CreateDayModals
            defaultDate={lastDay ? lastDay.time.clone().add(1, 'd') : stageInfo.firstDayDateTime}
            show={showCreateNewDay}
            onHide={() => setShowCreateNewDay(false)}

            onCreateDay={handleCreateDay}
            minAssigningExperts={examDetails.minimumRequiredExpertsNumber}
            expertsForDayList={props.expertsForDayList}
            experts={props.experts}
            apiErrors={props.error}
          />

          {examDay && (
            <EditDayModal
              readonly={props.readonly}
              show={showEditModal}
              day={examDay}
              minAssigningExperts={examDetails.minimumRequiredExpertsNumber}
              onUpdateExperts={handleUpdateExperts}
              onHide={() => setShowEditModal(false)}
              days={stageInfo.examDays}
              examDetails={examDetails}
              stageNumber={stageNumber}
              examApplicationsForDay={props.examApplicationsForDay}
              experts={props.experts}
              expertsForDayList={props.expertsForDayList}
              apiErrors={props.error}
              updateDay={props.updateDay}
              assignApplicationsForDay={props.assignApplicationsForDay}
              unratedExamApplications={props.unratedExamApplications}
              finalizeResult={props.finalizeResult}
              createExamDay={props.createExamDay}
              applicantDetails={props.applicantDetails}
              examApplicationsForDayAdmit={props.examApplicationsForDayAdmit}
              admitToExamDay={props.admitToExamDay}
            />
          )}
      </Card>
    );
};

interface OwnProps {
    readonly?: boolean;
    stageNumber: number;
    examDetails: IExamDetails;
    error: IApiError | null;
    createExamDay: (data: ICreateExamDayInput) => Promise<IExamDetails | null>;
    assignApplicationsForDay: (data: IAssignForDayInput<IAssignApplicationsForDay>) => Promise<unknown>;
    updateExpertsForDay: (data: IAssignForDayInput<IUpdateAssignedExpertsForDay>) => Promise<IExamDetails | null>;

    updateDay: (data: IAssignForDayInput<IUpdateExamDay>) => Promise<IExamDetails | null>;
    finalizeResult: (payload: IFinalizeResultPayload) => Promise<boolean>;
    admitToExamDay: (props: IAssignForDayInput<IAdmitToExamDay>) => void;

    examUnAssignedApplicationsList: IBaseExamUnAssignedApplicationsList;
    expertsForDayList: IBaseExpertsForDay;
    examApplicationsForDay: IBaseExamApplicationsForDay;
    experts: IBaseExperts;
    unratedExamApplications: IBaseUnratedExamApplications;
    applicantDetails: IBaseApplicantDetails;
    examApplicationsForDayAdmit: IBaseExamApplicationsForDayAdmit;
}

export default StageContainer;
