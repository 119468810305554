export const formatPhoneNumber = (phone: string, defaultReturn: string | null = null) => {
    const cleaned = (`${phone}`).replace(/\D/g, '');

    const match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);

    if (match) {
        return `+7 (${match[1]}) ${match[2]}-${match[3]}-${match[4]}`;
    }

    return defaultReturn;
};

export const formatSecretPhoneNumber = (phone: string) => phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) ***-**-$4');
