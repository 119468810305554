import * as React from 'react';
import { EmptySection } from '../../../../Common/EmptySection';
import CalendarIcon from 'components/assets/svg/calendar.svg';

const EmptyStage: React.FC<IProps> = props => (
  <EmptySection
    placeholderTitle={props.placeholderTitle || 'Даты еще не назначены'}
    placeholderText={props.placeholderText || 'Здесь будут отображаться даты проведения этапа аккредитации и распределение'}
    placeholderIcon={props.placeholderIcon || CalendarIcon}
    className={props.className || 'mh-auto'}
  />
);

interface IProps {
    placeholderIcon?: string;
    placeholderTitle?: string;
    placeholderText?: string;
    className?: string;
}

export default EmptyStage;
