import * as React from 'react';
import cn from 'classnames';
import { Form, Col } from 'react-bootstrap';
import styles from '../StageContainer.module.scss';
import { useLoading } from 'hooks';
import { formatPhoneNumber, formatSnils } from '_helpers';
import { IBaseExperts, IExpert } from 'components/models';
import { WarningModal } from 'components/Common/Modals';
import { FetchSelect } from 'components/Common/FetchSelect';

const AssigningOneExpertModal: React.FC<IProps> = (props) => {
    const expertsOptions = React.useMemo(() => (
      props.experts.info
        ? props.experts.info.items
        .filter(e => !props.existedIds.includes(e.id))
        .map(i => ({ value: i.id, label: `${i.lastName} ${i.firstName} ${i.middleName} / ${formatPhoneNumber(i.phone, '')}` }))
        : []
    ), [props.experts.info]);
    const [selectedExpert, setSelectedExpert] = React.useState<IExpert | undefined>(undefined);
    const [handleAssignExpertLoading, handleAssignExpert] = useLoading((e: IExpert) => props.onAccess(e), [props.onAccess]);

    const handleChangeExpert = React.useCallback((value: string) => {
        setSelectedExpert(props.experts.info ? props.experts.info.items.find(i => i.id === value) : undefined);
    }, [props.experts.info]);

    React.useEffect(() => {
        if (!props.show) {
            setSelectedExpert(undefined);
        }
        if (props.show) {
            props.experts.fetch({});
        }
    }, [props.show]);

    const renderExpertRow = ([label, value]: string[]) => (
      <Form.Group key={`expertRow_${value}`} className="row mb-1">
          <Col sm="4" className="p-0">
              <Form.Label column>
                  {label}
              </Form.Label>
          </Col>
          <Col sm="8">
              {value}
          </Col>
      </Form.Group>
    );

    const renderSelectedExpertDetails = () => {
        if (selectedExpert) {
            return [
                ['ФИО', `${selectedExpert.lastName} ${selectedExpert.firstName} ${selectedExpert.middleName}`],
                ['ДАТА РОЖДЕНИЯ', selectedExpert.birthDate.format('DD.MM.YYYY')],
                ['ДОЛЖНОСТЬ', selectedExpert.position],
                //['СТАТУС', selectedExpert.currentStatus],
                ['ПОЧТА', selectedExpert.email],
                ['ТЕЛЕФОН', String(formatPhoneNumber(selectedExpert.phone, ''))],
                ['СНИЛС', formatSnils(selectedExpert.snils, '')],
            ].map(renderExpertRow);
        }

        return null;
    };

    return (
      <WarningModal
        withoutOverflow
        centered={false}
        withoutTitlePadding
        show={props.show}
        title="Назначение членов комиссии"
        onHide={props.onHide}
        accessBtnTittle="Назначить"
        onAccess={() => selectedExpert && handleAssignExpert(selectedExpert)}
        loading={handleAssignExpertLoading}
        closeBtnTittle="Отмена"
      >
          <p>
              Начните вводить в строке поиска ФИО члена комиссии:
          </p>

          <FetchSelect
            name="fullName"
            options={expertsOptions}
            onChange={event => handleChangeExpert(event.value)}
            value={selectedExpert ? selectedExpert.id : undefined}
            onFetch={data => props.experts.fetch({ searchField: 'fullName', searchTerm: data.fieldValue })}
            clearFetch={props.experts.clear}
            className={cn({ 'mb-3': !!selectedExpert }, styles.expertSelect)}
          />

          {renderSelectedExpertDetails()}
      </WarningModal>
    );
};

interface IProps {
    existedIds: string[];
    examDayId?: string;
    show: boolean;
    onHide: () => void;
    onAccess: (selectedExpert: IExpert) => Promise<void>;

    experts: IBaseExperts;
}

export default AssigningOneExpertModal;
