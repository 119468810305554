import * as React from 'react';
import ExamInfoState from '../ExamInfoState';
import AwaitingExpertConfirmationComponent from './AwaitingExpertConfirmationComponent';

export default class AwaitingExpertConfirmation extends ExamInfoState {
    title = 'Аккредитация в процессе создания';

    subTitle = 'Процесс создания аккредитационного мероприятия';

    getInfoElement = () => <AwaitingExpertConfirmationComponent examDetails={this.examDetails} />
}
