import moment from 'moment';
import {
    ExamStatus,
    ExamApplicationStatus,
    DocumentType,
    IdentityDocType,
    Sex,
    PracticalQuestionType,
    TheoreticalQuestionType,
    ExamStageStatus,
    FinalizedResult,
    ExpertStatus,
    FilteredExamStatus,
    AppExceptionType,
    ExpertChangeRequestType,
    ExpertChangeRequestStatus,
    ExpertChangeRequestApprovalStep,
    CitizenshipType,
    MilitaryRelationshipType,
    DiplomaType,
} from './enums';

export interface IExamDay {
    id: string;
    examId: string;
    stageNumber: number;
    dayNumber: number;
    group?: number | undefined;
    time: moment.Moment;
    totalApplications: number;
    unFinalizedApplications: number;
    totalExperts: number;
    readyForFinalize: boolean;
    canAssignApplications: boolean;
}

export interface IExamStage {
    examId: string;
    stageNumber: number;
    accreditationCenterId: string;
    status: ExamStageStatus;
    examDays: IExamDay[];
    totalApplications: number;
    unassignedApplications: number;
    firstDayDateTime: moment.Moment;
    lastDayDateTime?: moment.Moment | undefined;
    readyForAssign: boolean;
    /** Вместительность аудитории АЦ */
    audienceCapacity: number;
    accreditationCenterName: string;
    secretaryPhone?: string | undefined;
    secretaryEmail?: string | undefined;
    secretaryFullName?: string | undefined;
}

export interface IExamDetails {
    examLogs: IExamLog[];
    examStages: IExamStage[];
    rules?: string | undefined;
    /** Минимально необходимое количество членов комиссии */
    minimumRequiredExpertsNumber: number;
    applyingDeadline: moment.Moment;
    regionName: string;
    secretaryFullName: string;
    secretaryPhone: string;
    examCreationProgress: IApprovalProcessStep[];
    eventsState?: { [key: string]: number } | undefined;
    id: string;
    date: moment.Moment;
    lastDate: moment.Moment;
    qualificationId: string;
    attestationSubCommissionId: string;
    qualificationTitle: string;
    stagesNumber: number;
    accreditationCenterName: string;
    attestationCommissionName: string;
    attestationSubCommissionName: string;
    address: string;
    city: string;
    status: ExamStatus;
    statusChangeTime: moment.Moment;
    attestationCommissionId: string;
    /** Количество аккредитуемых */
    applicationsNumber: number;
    /** Номер текущего этапа */
    currentStageNumber?: number | undefined;
    /** Дата проведения первого дня текущего этапа */
    currentStageFirstDayDate?: moment.Moment | undefined;
    /** Дата проведения последнего дня текущего этапа */
    currentStageLastDayDate?: moment.Moment | undefined;
    /** Номер пересдачи если она была, показывать в случае больше нуля */
    currentStageAttemptNumber?: number | undefined;
}

export interface IApprovalProcessStep {
    completionDateTime?: moment.Moment | undefined;
    expertId?: string | undefined;
    label: string;
    value: string;
}

export interface IExamLog {
    statusText: string;
    time: moment.Moment;
}

export interface IExamExpert {
    regNumber: string;
    expertId: string;
    fullName: string;
    email: string;
    phone: string;
    region: string;
    active: boolean;
    signed: boolean;
    chairman: boolean;
    acceptedInvite?: boolean | undefined;
    position?: string | null;
    signedCode?: string | null;
}

export interface IExam {
    id: string;
    date: moment.Moment;
    lastDate: moment.Moment;
    qualificationId: string;
    attestationSubCommissionId: string;
    qualificationTitle: string;
    qualificationResidency: boolean;
    stagesNumber: number;
    accreditationCenterName: string;
    attestationCommissionName: string;
    attestationSubCommissionName: string;
    address: string;
    city: string;
    region: string;
    status: ExamStatus;
    statusChangeTime: moment.Moment;
    attestationCommissionId: string;
    /** Количество аккредитуемых */
    applicationsNumber: number;
    /** Номер текущего этапа */
    currentStageNumber?: number | undefined;
    /** Дата проведения первого дня текущего этапа */
    currentStageFirstDayDate?: moment.Moment | undefined;
    /** Дата проведения последнего дня текущего этапа */
    currentStageLastDayDate?: moment.Moment | undefined;
    /** Номер пересдачи если она была, показывать в случае больше нуля */
    currentStageAttemptNumber?: number | undefined;
}

export interface IApplicantExam {
    examId: string;
    date: moment.Moment;
    lastDate: moment.Moment;
    reviewComment?: string | undefined;
    qualificationTitle: string;
    stagesNumber: number;
    accreditationCenterName: string;
    attestationSubCommissionName: string;
    address: string;
    attestationCommissionName: string;
    city: string;
    region: string;
    examStatus: ExamStatus;
    examApplicationStatus?: ExamApplicationStatus;
    applicationSubmitDate?: moment.Moment | undefined;
    examApplicationId?: string | undefined;
    /** Номер текущего этапа */
    currentStageNumber?: number | undefined;
    /** Дата проведения первого дня текущего этапа */
    currentStageFirstDayDate?: moment.Moment | undefined;
    /** Дата проведения последнего дня текущего этапа */
    currentStageLastDayDate?: moment.Moment | undefined;
    /** Номер пересдачи если она была, показывать в случае больше нуля */
    currentStageAttemptNumber?: number | undefined;
}

export interface IApplicant {
    id: string;
    /** Имя */
    firstName: string;
    /** Фамилия */
    lastName: string;
    /** Отчество */
    middleName?: string | undefined;
    sex: Sex;
    birthDate: moment.Moment;
    email: string;
    emailConfirmed: boolean;
    phone: string;
    /** СНИЛС */
    snils: string;
    regionId: number;
    regionName: string;
    qualificationId: string;
    citizenshipType?: CitizenshipType;
    citizenshipCountryId?: number | undefined;
    citizenshipCountryName?: string | undefined;
    militaryRelationship?: MilitaryRelationshipType;
}

export interface IApplicantIdentityDoc {
    identityDocType: IdentityDocType;
    passport?: IApplicantPassport;
    otherIdentity?: IApplicantOtherIdentity;
}

export interface IApplicantPassport {
    /** серия/номер */
    serialNumber: string;
    /** кем выдан */
    issuedBy: string;
    /** Дата выдачи, время должно быть 00ч. 00м. 00с. по UTC */
    dateOfIssue: moment.Moment;
    /** адрес регистрации */
    registrationAddress: string;
}

/** Другой документ */
export interface IApplicantOtherIdentity {
    /** данные документа */
    data: string;
}

export interface IApplicantDiploma {
    type: DiplomaType;
    /** серия/номер */
    serialNumber: string;
    /** кем выдан */
    issuedBy: string;
    /** Дата выдачи, время должно быть 00ч. 00м. 00с. по UTC */
    dateOfIssue: moment.Moment;
}

export interface IUpdateProfile {
    /** Имя */
    firstName: string;
    /** Фамилия */
    lastName: string;
    /** Отчество */
    middleName: string;
    /** Адрес электронной почты */
    email: string;
    /** Дата рождения, время должно быть 00ч. 00м. 00с. по UTC */
    birthDate: moment.Moment;
    sex: Sex;
    /** СНИЛС */
    snils: string;
    /** Регион */
    regionId: number;
    /** Id специальности */
    qualificationId: string;
    citizenshipType: CitizenshipType;
    /** Страна гражданства */
    citizenshipCountryId?: number | undefined;
    militaryRelationship: MilitaryRelationshipType;

}

export interface IQualificationDocumentTemplateGroup {
    title: string;
    description: string;
    order: number;
    hasVariants: boolean;
    templates: IQualificationDocumentTemplate[];
}

export interface IQualificationDocumentTemplate {
    /** Идентификатор шаблона */
    id: string;
    /** Порядок в списке */
    order: number;
    /** Вариант */
    variant: number;
    /** Описание */
    description: string;
    type: DocumentType;
}

export interface IExamApplicationDocument {
    id: string;
    documentDescription: string;
    variant: number;
    type: DocumentType;
    documentTemplateId?: string | undefined;
    examApplicationId: string;
    applicantId: string;
    submissionDate: moment.Moment;
    sourceFiles: ISourceFile[];
    fileId: string;
    fileExtension: string;
    fileSize: number;
    fileUrl: string;
}

export interface ISourceFile {
    fileName?: string | undefined;
    fileId: string;
    fileExtension: string;
    fileSize: number;
    fileUrl: string;
}

export interface IExamApplication {
    applicantId: string;
    applicantFullName: string;
    applicantPhone: string;
    applicantEmail: string;
    birthDate: moment.Moment;
    applicantSnils: string;
    status: ExamApplicationStatus;
    finalized: boolean;
    statusChangeTime: moment.Moment;
    examApplicationId: string;
    applicationSubmitDate: moment.Moment;
    lastFinalizedStage?: number | undefined;
    lastFinalizedStageResult: FinalizedResult;
    lastFinalizedStageAttemptNumber: number;
}

export interface IExamApplicationDetails {
    exam: IExam;
    applicant: IApplicant;
    applicantIdentityDoc: IApplicantIdentityDoc;
    applicantDiploma?: IApplicantDiploma;
    documents: IExamApplicationDocument[];
    reviewComment?: string | undefined;
    /** Профессиональная переподготовка */
    professionalRetraining?: boolean| undefined;
    /** Запрос на допуск к аккредитации начиная с n-го этапа */
    startStage?: number| undefined;
    signCode?: string | undefined;
    approveCode?: string | undefined;
    examDayApplications: IExamDayApplicationShort[];
    eventsState?: { [key: string]: number; } | undefined;
    status: ExamApplicationStatus;
    finalized: boolean;
    statusChangeTime: moment.Moment;
    examApplicationId: string;
    applicationSubmitDate: moment.Moment;
    lastFinalizedStage?: number | undefined;
    lastFinalizedStageResult: FinalizedResult;
    lastFinalizedStageAttemptNumber: number;
}

export interface IExamDayApplicationShort {
    examDayId: string;
    stageNumber: number;
    attemptNumber: number;
    admitted: boolean;
    finalizedResult: FinalizedResult;
    examDayDateTime: moment.Moment;
}

export interface IApplicantQualificationDetails {
    examDate: moment.Moment;
    accreditationCenterName: string;
    regionName: string;
    acFullName: string;
    applicantId: string;
    /** СВИДЕТЕЛЬСТВО О КВАЛИФИКАЦИИ */
    certificateUrl?: string | undefined;
    examId: string;
    examApplicationId: string;
    title: string;
    stagesNumber: number;
    /** Регистрационный номер специальности */
    regNumber: string;
    /** Дата присвоения */
    issueDate: moment.Moment;
    /** Дата окончания действия */
    expireDate: moment.Moment;
    /** Регистрационный номер свидетельства */
    certRegNumber: string;
}

export interface IApplicantQualification {
    applicantId: string;
    examId: string;
    examApplicationId: string;
    title: string;
    stagesNumber: number;
    /** Регистрационный номер специальности */
    regNumber: string;
    /** Дата присвоения */
    issueDate: moment.Moment;
    /** Дата окончания действия */
    expireDate: moment.Moment;
    /** Регистрационный номер свидетельства */
    certRegNumber: string;
}

export interface IApplicantExamList {
    items: IApplicantExam[];
    page: number;
    pageSize: number;
    total: number;
}

export interface IExpertCertificate {
    id: string;
    /** Регистрационный номер */
    regNumber: string;
    /** Дата выдачи */
    issueDate: moment.Moment;
    /** Дата окончания действия */
    expireDate: moment.Moment;
    fileId: string;
    fileExtension: string;
    fileSize: number;
    fileUrl: string;
}

export interface IExamSessionDetails {
    examApplicationId: string;
    applicantId: string;
    id: string;
    examId: string;
    startTime: moment.Moment;
    theoryFinishTime?: moment.Moment | undefined;
    practiceStartTime?: moment.Moment | undefined;
    isPassedTheory: boolean;
    passed?: boolean | undefined;
    finishTime?: moment.Moment | undefined;
    totalTimeInMinutes: number;
    theoreticalPassPercentage: number;
    totalPassPercentage: number;
    theoreticalPartDuration: number;
    practicalPartDuration: number;
    isPassedPractice?: boolean | undefined;
    correctPracticalAnswersPercentage: number;
    factualTheoreticalQuestionAnswers: IFactualTheoreticalQuestionAnswer[];
    factualPracticalQuestionAnswers: IFactualPracticalQuestionAnswer[];
    manualCheck: boolean;
    theoreticalQuestions: ITheoreticalQuestion[];
    practicalQuestions?: IPracticalQuestion[] | undefined;
    criteriaForRate?: string | undefined;
}

export interface ITheoreticalQuestion {
    /** Id вопроса */
    id: string;
    /** Текст вопроса, в виде HTML */
    text: string;
    type: TheoreticalQuestionType;
    /** Варианты ответов на вопрос */
    answers: ITheoreticalAnswer[];
    /** Варианты ответов для вопросов на сопоставление или с последовательностью */
    matchingAnswers?: ITheoreticalAnswer[] | undefined;
    /** Заголовок над вариантами ответов, для вопросов на сопоставление или с последовательностью */
    answersHeader?: string | undefined;
    /** Заголовок над вариантами ответов, для вопросов на сопоставление или с последовательностью */
    matchingAnswersHeader?: string | undefined;
}

export interface IPracticalQuestion {
    /** Id вопроса */
    id: string;
    /** Текст вопроса, в виде HTML */
    text: string;
    type: PracticalQuestionType;
    /** Таблицы для табличного вопроса */
    tabularQuestionTables?: ITable[] | undefined;
}

export interface IFactualTheoreticalQuestionAnswer {
    questionId: string;
    correct: boolean;
    correctAnswersNumbers: IAnswerPair[];
    factualAnswersNumbers: IAnswerPair[];
    correctAnswer?: string | undefined;
    factualAnswer?: string | undefined;
}

export interface IFactualPracticalQuestionAnswer {
    questionId: string;
    criteriaForRate?: string | undefined;
    type: PracticalQuestionType;
    correct?: boolean | undefined;
    essayAnswer?: IEssayAnswer;
    openAnswerAnswer?: IOpenAnswerAnswer;
    tabularAnswer?: ICheckedTable[] | undefined;
}

export interface ICheckedTable {
    caption?: string | undefined;
    columns: IColumn[];
    rows?: ICheckedCell[][] | undefined;
    showCorrectAndApplicantAnswer: boolean;
    applicantAnswerRows?: string[][] | undefined;
    correctAnswerRows?: string[][] | undefined;
}

export interface ICheckedCell {
    standard: string;
    solution?: string | undefined;
    correct: boolean;
}

interface IAnswerPair {
    /** Номер вопроса */
    number: number;
    /** Номер соответствующего вопроса, заполняется только в случае типов: Matching, Ordering */
    matchingNumber?: number;
}

interface IEssayAnswer {
    factual?: string | undefined;
}

interface IOpenAnswerAnswer {
    correct: string;
    factual?: string | undefined;
}

interface IColumn {
    header: string;
    width: number;
    readonly: boolean;
    numeric: boolean;
    key: boolean;
    multiline: boolean;
}

interface ITheoreticalAnswer {
    number: number;
    text: string;
}

interface ITable {
    caption?: string | undefined;
    columns: IColumn[];
    exactEquality: boolean;
    forbidMoving: boolean;
    initialRows: string[][];
    answerRows?: string[][] | undefined;
}

export interface IUpdateAssignedExpertsForDay {
    expertIds: string[];
}

export interface IAssignApplicationsForDay {
    examApplicationIds: string[];
}

export interface ICreateExamDay {
    /** Дата и время АД */
    time: moment.Moment;
    expertIds: string[];
}

export interface IExperts {
    items: IExpert[];
    page: number;
    pageSize: number;
    total: number;
}

export interface IAscStatus {
    ascId: string;
    status: ExpertStatus;
}

export interface IExpert {
    id: string;
    firstName: string;
    lastName: string;
    middleName: string;
    birthDate: moment.Moment;
    sex: Sex;
    phone: string;
    email: string;
    regionId: number;
    region: string;
    timeZoneOffset: number;
    linkedExpertStatuses: ILinkedExpertStatus[];
    emailConfirmed: boolean;
    registrationCompleted: boolean;
    position: string;
    /** Место работы */
    placeOfWork: string;
    /** СНИЛС */
    snils: string;
}

export interface IOrganizationInfo {
    name: string;
    inn: string;
    ogrn: string;
    region: string;
    city: string;
    address: string;
}

export interface IExpertDetails extends IExpert {
    qualifications: IExpertQualification[];
    linkedExpertStatusesDetails: ILinkedExpertStatusDetails[];
    /** ИД должности */
    positionId?: number | undefined;
    /** Другое название должности */
    otherPositionTitle?: string | undefined;
    placeOfWorkDetails?: IOrganizationInfo;
    /** Членство */
    membership?: string | undefined;
}

export interface IExpertQualification {
    id: string;
    regNumber: string;
    title: string;
    /** Дата начала стажа работы */
    experienceStartDate: moment.Moment;
}

export interface IExamApplicationList {
    admittedCount: number;
    eventsState?: { [key: string]: number } | undefined;
    items: IExamApplication[];
    page: number;
    pageSize: number;
    total: number;
}

export interface ICreateExamDayInput {
    examId: string;
    stage: number;
    assignApplications?: IAssignApplicationsForDay;
    body?: ICreateExamDay;
}

export interface IAssignForDayInput<T> {
    examId: string;
    stage: number;
    examDayId: string;
    body: T;
}

export interface IAdmitToExamDay {
    examApplicationId: string;
}

export interface IFilterPart {
    page?: number;
    pageSize?: number;
    sortOrder?: boolean;
    sortField?: string;
}

export interface ISearchPart {
    searchField?: string;
    searchTerm?: string;
}

export interface IExamIdStagePart {
    examId: string;
    stage: number;
}

export interface IFetchExamUnAssignedApplicationsProps extends IFilterPart, ISearchPart {
    examId: string;
    stage: number;
}

export interface IExamIdBody<T> extends IBodyPart<T>{
    examId: string;
}

export interface IBodyPart<T> {
    body: T;
}

export interface IApplicantPayload {
    applicantId: string;
}

export interface IPayloadExperts extends IFilterPart, ISearchPart {
    attestationSubCommissionId?: string;
    qualificationId?: string;
}

export interface IExamsFilters extends IQualificationIdsFilter {
    filteredExamStatus?: FilteredExamStatus;
    attestationSubCommissionIds?: string[] | null;
    accreditationCenterIds?: string[] | null;
    acIds?: string[];
    regionIds?: number[];
}

export interface ICommissionsFilters {
    regionIds?: number[] | undefined;
    ascIds?: string[] | undefined;
}

export interface IStatusFilter<T> {
    status?: T;
}

export interface IQualificationIdsFilter {
    qualificationIds?: string[] | null;
}

export interface IRegionFilter {
    regionId?: number | null;
}

export interface IExpertsFilters extends IRegionFilter, IQualificationIdsFilter {
    terminated?: boolean;
    regionIds?: number[];
    statuses?: ExpertStatus[] | null;
    ascIds?: string[] | null;
}

export interface IPayloadExpertExams extends IExamsFilters, IFromToDatePart, IFilterPart, ISearchPart {
    expertId: string;
}

export interface IFromToDatePart {
    from?: moment.Moment;
    to?: moment.Moment;
}

export interface IExpertChangeRequestId {
    expertChangeRequestId: string;
}


export interface IFetchExamAssignedApplicationsForDayProps extends IFilterPart, ISearchPart {
    examDayId: string;
    examId: string;
    stage: number;
}

export interface GetExpertPayload extends IFilterPart, ISearchPart {
    qualificationId?: string;
    examId?: string;
    onlyAcEmployees?: boolean;
    onlySelected?: boolean;
}

export interface IUpdateExamDay {
    /** Дата и время АД */
    time: moment.Moment;
}

export interface GetListForDayData extends IFilterPart {
    examDayId: string;
}

export interface IFinalizeResultPayload {
    examId: string;
    stageNumber: number;
    examDayId: string;
    examApplicationIds: string[];
    body: FinalizedResult;
}

export interface IExamDayApplication {
    examId: string;
    examApplicationId: string;
    examDayId: string;
    stageNumber: number;
    attemptNumber: number;
    finalizedResult: FinalizedResult;
    examApplication: IExamApplication;
    admitted: boolean;
    canFinalize: boolean;
}

export interface IAttestationCommissionDetails {
    leaders: IExpert[];
    id: string;
    regNumber: string;
    name: string;
    fullName: string;
    /** Город */
    city: string;
    /** Id региона */
    regionId: number;
    regionName: string;
    secretaryFullName?: string | undefined;
    chairmanFullName?: string | undefined;
}

export interface IAttestationSubCommissionDetails {
    leaders: IExpert[];
    id: string;
    regNumber: string;
    name: string;
    fullName: string;
    city: string;
    address: string;
    /** Id региона */
    regionId: number;
    regionName: string;
    qualificationId: string;
    qualificationTitle: string;
    qualificationStagesNumber: number;
    secretaryFullName?: string | undefined;
    chairmanFullName?: string | undefined;
}

interface IList<T> {
    page: number;
    pageSize: number;
    total: number;
    items: T[];
}

export type IExamDayApplications = IList<IExamDayApplication>;


export interface IAccreditationCenter {
    id: string;
    regNumber: string;
    name: string;
    fullName: string;
    city: string;
    address: string;
    regionId: number;
    regionName: string;
    qualifications: IAccreditationCenterQualification[];
}

export interface IAccreditationCenterDetails extends IAccreditationCenter {
    secretaryPhone?: string | undefined;
    secretaryEmail?: string | undefined;
    secretaryFullName?: string | undefined;
}

export interface IAccreditationCenterQualification {
    id: string;
    regNumber: string;
    title: string;
    audienceCapacity: number;
}

export interface IBaseInfo<I, P, R = unknown> {
    fetch: (payload: P) => Promise<R>;
    clear: () => void;
    info: I | null;
}

export class BaseInfo<I, P> implements IBaseInfo<I, P> {
    info: I | null;

    fetch: (payload: P) => Promise<void>;

    clear: () => void;

    constructor(info: I | null, fetch: (payload: P) => Promise<void>, clear?: () => void) {
        this.clear = clear || (() => {});
        this.fetch = fetch;
        this.info = null;
    }
}

export const EmptyInfo: IBaseInfo<never, never> = { clear: () => {}, fetch: async () => {}, info: null };

export type IBaseApplicantDetails = IBaseInfo<IExamApplicationDetails, string>;

export type IBaseExamApplicationsForDayAdmit = IBaseInfo<IExamDayApplications, IFetchExamAssignedApplicationsForDayProps>;
export type IBaseUnratedExamApplications = IBaseInfo<IExamApplicationList, GetListForDayData>;
export type IBaseExamApplicationsForDay = IBaseInfo<IExamDayApplications, IFetchExamAssignedApplicationsForDayProps>;
export type IBaseExamUnAssignedApplicationsList = IBaseInfo<IExamApplicationList, IFetchExamUnAssignedApplicationsProps>;

export type IBaseExperts = IBaseInfo<IExperts, GetExpertPayload>;
export type IBaseExpertsForDay = IBaseInfo<IExperts, GetListForDayData>;

export interface IPagination {
    total: number;
    pageSize: number;
    page: number;
}


export interface IQualificationInfo {
    id: string;
    title: string;
    stagesNumber: number;
    regNumber: string;
    occupationalStandardId: string;
    occupationalStandardTitle: string;
    readyToUse: boolean;
    residency: boolean;
}

export interface IApiError {
    code: number;
    type: AppExceptionType;
    message: string;
    failures?: { [key: string]: string[] } | undefined;
}

export interface IRegion {
    id: number;
    name: string;
    timeZoneOffset: number;
}


export interface IQualificationInfoList {
    items: IQualificationInfo[];
    page: number;
    pageSize: number;
    total: number;
}


export interface IAccreditationCenters {
    items: IAccreditationCenter[];
    page: number;
    pageSize: number;
    total: number;
}

export interface IAttestationSubCommissionsList {
    items: IAttestationSubCommission[];
    page: number;
    pageSize: number;
    total: number;
}

export interface IAttestationSubCommission {
    id: string;
    regNumber: string;
    name: string;
    fullName: string;
    city: string;
    address: string;
    /** Id региона */
    regionId: number;
    regionName: string;
    qualificationId: string;
    qualificationTitle: string;
    qualificationStagesNumber: number;
    secretaryFullName?: string | undefined;
    chairmanFullName?: string | undefined;
    newCommissionFormationCompleted?: boolean | undefined;
}

export interface ILinkedExpertStatus {
    acId: string;
    ascId?: string | undefined;
    status: ExpertStatus;
}

export interface ILinkedExpert {
    status: ExpertStatus;
    attestationCommissionId: string;
    attestationSubCommissionId?: string | undefined;
    qualificationId?: string | undefined;
    attestationCommissionName: string;
    attestationSubCommissionName?: string | undefined;
    qualifications: IExpertQualification[];
    id: string;
    firstName: string;
    lastName: string;
    middleName: string;
    birthDate: moment.Moment;
    sex: Sex;
    phone: string;
    email: string;
    regionId: number;
    region: string;
    timeZoneOffset: number;
    currentStatus: ExpertStatus;
    linkedExpertStatuses: ILinkedExpertStatus[];
    chairman: boolean;
    emailConfirmed: boolean;
    registrationCompleted: boolean;
    position: string;
    /** Место работы */
    placeOfWork: string;
    /** СНИЛС */
    snils: string;
}


export interface IExamList {
    eventsState?: { [key: string]: number } | undefined;
    items: IExam[];
    page: number;
    pageSize: number;
    total: number;
}


export interface ILinkedExpertStatusDetails {
    regionName: string;
    qualificationTitle?: string | undefined;
    acId: string;
    ascId?: string | undefined;
    status: ExpertStatus;
}

export interface IExpertChangeRequest {
    id: string;
    dateTime: moment.Moment;
    expertId: string;
    expertChangeRequestsOrderId: string;
    requestType: ExpertChangeRequestType;
    status: ExpertChangeRequestStatus;
    approvalStep?: ExpertChangeRequestApprovalStep;
    changeRequestTitle: string;
    fullName: string;
    position: string;
    placeOfWork: string;
    newExpertStatus?: string | undefined;
    attestationSubCommissionId: string;
    attestationSubCommissionName: string;
}

export interface IExpertChangeRequests {
    items: IExpertChangeRequest[];
    page: number;
    pageSize: number;
    total: number;
}

export interface ICountry {
    id: number;
    name: string;
}