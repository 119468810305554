import React from 'react';
import { Card } from 'components';
import { Button } from 'react-bootstrap';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

const PaymentResultContainer = ({ onPush, apiError }: Props) => {
    const success = window.location.pathname.indexOf('success') !== -1;

    return (
        <Card
          title={success ? 'Вы успешно оплатили аккредитацию' : 'Во время оплаты произошла ошибка, попробуйте повторить операцию позже'}
          apiError={apiError}
        >
            <Button variant="primary" onClick={() => onPush('/applicant-exams')}>Продолжить</Button>
        </Card>
    );
};

const mapState = (state: IRootState) => ({
    apiError: state.errors.error,
});

const mapDispatch = (dispatch: Dispatch) => ({
    onPush: (key: string) => dispatch(push(key)),
});

export default connect(mapState, mapDispatch)(PaymentResultContainer);

type connectedProps = ReturnType<typeof mapDispatch> & ReturnType<typeof mapState>;

type Props = connectedProps;
