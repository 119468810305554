import * as React from 'react';
import { Provider } from 'react-redux';
import Routes from './routes/Routes';
import { Store } from 'redux';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';

const Root: React.FC<RootProps> = props => (
  <Provider store={props.store}>
      <ConnectedRouter history={props.history}>
          <Routes />
      </ConnectedRouter>
  </Provider>
);

export default Root;


interface RootProps {
    store: Store;
    history: History;
}
