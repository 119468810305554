import * as React from 'react';
import moment from 'moment';
import { IApplicantQualification, IApplicantQualificationDetails } from 'components/models';
import {
    Card, DatePeriodColumn, renderRow, SimpleTable, StatusColumn, WarningModal,
} from 'components';
import QualificationsEmptyIcon from 'components/assets/svg/certificate.svg';
import { Color, ColorStatus } from '_helpers';

const ApplicantQualifications: React.FC<IProps> = ({ qualifications, details, ...props }) => {
    const [showInfo, setShowInfo] = React.useState(false);

    const tableHeaders = [
        { title: 'СПЕЦИАЛЬНОСТЬ' },
        { title: 'СРОК ДЕЙСТВИЯ' },
        { title: 'СТАТУС' },
    ];

    const getStatus = (expireDate: moment.Moment) => (
      <StatusColumn
        status={expireDate.clone().local().isAfter(moment().local())
          ? new ColorStatus(Color.Success, 'Действует')
          : new ColorStatus(Color.Red, 'Не действует')}
      />
    );

    const tableRow = qualifications ? qualifications.map(q => ({
        id: q.examApplicationId,
        columns: [
            q.title,
            <DatePeriodColumn
              dateFrom={q.issueDate}
              dateTo={q.expireDate}
            />,
            getStatus(q.expireDate),
        ],
    })) : [];


    return qualifications ? (
      <Card
        withoutBorder
        withoutPadding
        title={props.cardTitle || 'Специальности'}
        category={props.cardCategory || 'Список подтвержденных специальностей'}
      >
          <SimpleTable
            tableRows={tableRow}
            tableHeaders={tableHeaders}
            handleRowClick={(id: string) => {
                props.onChooseQualification(id);
                setShowInfo(true);
            }}
            placeholderTitle="Нет ничего в списке"
            placeholderText="Здесь будут отображаться подтвержденные специальности"
            placeholderIcon={QualificationsEmptyIcon}
            tableClassName="mb-80"
          />

          <WarningModal
            large
            withoutCancelButton
            show={showInfo}
            title="Информация по специальности"
            onHide={() => {
                setShowInfo(false);
                props.onClearQualification();
            }}
          >
              {!!details && [
                  renderRow('СПЕЦИАЛЬНОСТЬ', details.title),
                  renderRow('РЕГИОН', details.regionName),
                  renderRow('ДАТА ПРИСВОЕНИЯ', details.issueDate.clone().local().format('DD.MM.YYYY')),
                  renderRow('ДАТА ОКОНЧАНИЯ', details.expireDate.clone().local().format('DD.MM.YYYY')),
                  renderRow('СТАТУС', getStatus(details.expireDate)),
              ]}
          </WarningModal>
      </Card>
    ) : null;
};

interface IProps {
    qualifications: IApplicantQualification[] | null;
    details: IApplicantQualificationDetails | null;
    onChooseQualification: (id: string) => void;
    onClearQualification: () => void;
    cardTitle?: string | null;
    cardCategory?: string | null;
}

export default ApplicantQualifications;
