import React from 'react';
import {
    Card,
    ExamStatus,
    ExamTable,
    MappedExamApplicationStatuses,
} from 'components';
import { connect } from 'react-redux';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { ExamApplicationStatus, IApplicantExam, MyExamStatus } from '../../api/ApiClient';
import { Color, ColorStatus } from '_helpers';
import { useEffectProcessing, useLocalErrorHandler, usePushFrom } from 'hooks';

const ApplicantExamsContainer = (
  {
      getMyExamApplications, examsList, apiError, setErrorHandler, clearMyExamApplicationList,
  }: Props,
) => {
    const [handleChooseExam] = usePushFrom('exam-application');

    useLocalErrorHandler(setErrorHandler);

    const processing = useEffectProcessing(
      async () => {
          await getMyExamApplications({ myExamStatus: MyExamStatus.All, sortField: 'date', sortOrder: false });
      },
      [],
      () => {
          clearMyExamApplicationList();
      },
    );

    const HEADER = [
        'ПЕРИОД ПРОВЕДЕНИЯ',
        'Специальность',
        'СТАТУС',
    ];

    const getStatus = (exam: IApplicantExam) => (
      statuses[exam.examApplicationStatus || exam.examStatus]
      || new ColorStatus(Color.Default, (exam.examApplicationStatus || exam.examStatus).toString())
    );

    const getExamData = (exam: IApplicantExam) => ({
        ...exam,
        status: getStatus(exam),
        id: exam.examApplicationId,
        name: exam.qualificationTitle,
    });

    const tableRows = examsList ? examsList.items.map(getExamData) : [];

    return (
        <Card
          title="Список всех аккредитаций"
          category="Список всех ваших аккредитаций"
          apiError={apiError}
          showLoader={processing}
          withoutPadding
        >
            <ExamTable
              header={HEADER}
              tableRows={tableRows}
              onChooseExam={handleChooseExam}
              placeholderTitle="Аккредитации отсутствуют"
              placeholderText="Здесь будут отображаться все Ваши аккредитации"
            />
        </Card>
    );
};

const mapState = (state: IRootState) => ({
    examsList: state.examApplications.myExamApplicationsList,
    apiError: state.errors.error,
});

const mapDispatch = (dispatch: Dispatch) => ({
    getMyExamApplications: dispatch.examApplications.getMyExamApplications,
    clearMyExamApplicationList: () => dispatch.examApplications.setMyExamApplicationList(null),
    setErrorHandler: dispatch.errors.setErrorHandler,
});

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
type Props = connectedProps;

export default connect(mapState, mapDispatch)(ApplicantExamsContainer);

const statuses = {
    ...MappedExamApplicationStatuses,
    [ExamStatus.IsApplying as string]: new ColorStatus(Color.Success, 'Идет запись'),
    [ExamStatus.ApplyingFinished as string]: new ColorStatus(Color.Orange, 'Запись окончена'),
    [ExamApplicationStatus.Passed as string]: new ColorStatus(Color.Default, 'Обработка'),
    [ExamApplicationStatus.NotPassed as string]: new ColorStatus(Color.Default, 'Обработка'),
};
