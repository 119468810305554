import React from 'react';
import styles from './Details.module.scss';
import cn from 'classnames';
import { Form, Row } from 'react-bootstrap';

const Details = ({
    title, infoRows, withDarkRowLabel, centeredRow,
}: DetailsProps) => {
    const renderSwitch = (row: StringValue | ArrayStringValue | LinkStringValue | ReactNodeValue) => {
        if (row instanceof StringValue) {
            return (
                <div style={{ color: `var(${row.color})` }}>
                    {row.value}
                </div>
            );
        }
        if (row instanceof ArrayStringValue) {
            return row.value.map((value: string, i: number) => (
                <div className={styles.info__valueManyRow} key={i}>
                    {value}
                </div>
            ));
        }

        if (row instanceof LinkStringValue) {
            return row.value + row.link;
        }

        if (row instanceof ReactNodeValue) {
            return row.value;
        }

        return 'Unexpected row type';
    };

    const renderInfoRow = (row: StringValue | ArrayStringValue | LinkStringValue | ReactNodeValue, i: number) => (
        <Form.Group as={Row} controlId="formPlaintextEmail" className={cn(styles.info__row, { 'align-items-center': centeredRow })} key={`row_${i}`}>
            <div className={cn(styles.info__label, withDarkRowLabel && styles.info__label_dark)}>
                {row.label}
            </div>

            <div className={styles.info__value}>
                {renderSwitch(row)}
            </div>
        </Form.Group>
    );

    return (
        <div>
            {title ? (
                <div className={styles.title}>
                    {title}
                </div>
            ) : null}

            <div>
                {infoRows.map(renderInfoRow)}
            </div>
        </div>
    );
};

interface DetailsProps {
    infoRows: (StringValue | ArrayStringValue | LinkStringValue | ReactNodeValue)[];
    title?: string;
    withDarkRowLabel?: boolean;
    centeredRow?: boolean;
}

export default Details;

interface Value {
    label: string;
    value: any;
}

export class StringValue implements Value {
    public label: string;

    public value: string;

    public color?: string;

    public constructor(label: string, value: string | undefined, color?: string) {
        this.label = label;
        this.value = value || '';
        this.color = color;
    }
}

export class ArrayStringValue implements Value {
    public label: string;

    public value: string[];

    public constructor(label: string, value: string[] | undefined) {
        this.label = label;
        this.value = value || [];
    }
}

export class LinkStringValue implements Value {
    public label: string;

    public value: string;

    public link: string;

    public constructor(label: string, value: string, link: string) {
        this.label = label;
        this.value = value;
        this.link = link;
    }
}

export class ReactNodeValue implements Value {
    public label: string;

    public value: React.ReactNode;

    public constructor(label: string, value: React.ReactNode) {
        this.label = label;
        this.value = value;
    }
}
