import React from 'react';
import { Collapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Location } from 'history';
import styles from './Sidebar.module.scss';
import logo from '../assets/png/logo_auth.png';
import avatar from '../assets/svg/avatar.svg';
import NavList, { Tab } from './NavList';
import cx from 'classnames';
import _ from 'lodash';
import './SidebarHideStyles.scss';

const Sidebar = ({
    adaptive,
    location,
    profile,
    profileTabs,
    tabs,
    children,
    onLogout,
}: SidebarProps) => {
    const sidebarRef: React.RefObject<HTMLDivElement> = React.createRef();
    const [openAvatar, setOpenAvatar] = React.useState(false);

    const detectMobileOutsideClick = (event: MouseEvent) => {
        const body = document.querySelector('body');

        if (body
            && body.classList.contains('hide_sidebar')
            && document.body.clientWidth <= 740
            && !(event.target as Element).closest('.sidebar')
            && !(event.target as Element).closest('#minimizeSidebar')) {
            body.classList.remove('hide_sidebar');
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', detectMobileOutsideClick);

        return () => {
            document.removeEventListener('click', detectMobileOutsideClick);
        };
    }, []);

    const isSamePath = (pathname: string, routeName: string) => pathname === routeName || pathname === `${routeName}/`;

    const isActiveRoute = (routeName: string) => {
        const { pathname } = location;
        return isSamePath(pathname, routeName);
    };

    const hideMobileSidebar = () => {
        const body = document.querySelector('body');

        if (body && body.classList.contains('hide_sidebar')) {
            body.classList.remove('hide_sidebar');
        }
    };

    const logout = () => {
        onLogout();
        window.location.reload();
    };

    return (
        <div
          className={cx(
                styles.sidebar, 'sidebar',
                { [styles.adaptive]: adaptive },
            )}
        >
            <div className={cx('sidebar-background', styles.bg)} />
            <div className={cx(styles.logo__container)}>
                <Link
                  to="#"
                  className={cx(styles.logo)}
                >
                    <img src={logo} alt="main-logo" height={70} />
                </Link>

                <span id="mobileHideSidebarIcon" className={styles.mobileHideSidebarIcon} onClick={hideMobileSidebar} />
            </div>
            <div className={cx(styles.sidebar__wrapper, 'sidebar-wrapper')} ref={sidebarRef}>
                <div className="user">
                    <div className="photo" style={{ background: 'none' }}>
                        {/* {profile && profile.avatar
                            ? <img src={profile.avatar} alt="Avatar" />
                            : profile && ((profile.lastName ? profile.lastName.charAt(0) : '')
                            + (profile.firstName ? profile.firstName.charAt(0) : '')).toUpperCase()
                          } */}
                        <img src={avatar} alt="Avatar" />
                    </div>
                    <div className="info">
                        <a className={styles.info_link} onClick={() => setOpenAvatar(!openAvatar)}>
                            <span>
                                <span
                                  className={styles.user_name}
                                  title={profile ? `${profile.lastName} ${profile.firstName}` : 'Имя Фамилия'}
                                >
                                    {profile ? `${profile.lastName} ${profile.firstName}` : 'Имя Фамилия'}
                                </span>
                                {Boolean(profileTabs && profileTabs.length) && (
                                    <b
                                      className={cx(styles.caret, openAvatar && styles['rotate-180'])}
                                    />
                                )}
                            </span>
                        </a>
                        <Collapse in={openAvatar}>
                            <ul className="nav">
                                {profileTabs.map(({ path, name, mini }, key) => (
                                    <li
                                      className={cx(styles.navButtons, isActiveRoute(path) && 'active', key === 0 && styles.navButtons_margin)}
                                      key={key}
                                    >
                                        <Link to={path}>
                                            <span className="sidebar-mini">{mini}</span>
                                            <span className="sidebar-normal">{name}</span>
                                        </Link>
                                    </li>
                                ))}
                                <li
                                  className={cx(styles.navButtons, !profileTabs.length && styles.navButtons_margin)}
                                  onClick={logout}
                                >
                                    <a>
                                        <span className="sidebar-mini">ВС</span>
                                        <span className="sidebar-normal">Выйти из системы</span>
                                    </a>
                                </li>
                            </ul>
                        </Collapse>
                    </div>
                </div>
                {tabs && tabs.length
                    ? <NavList links={tabs} location={location} />
                    : children
                }
            </div>
        </div>
    );
};

export default Sidebar;

interface SidebarProps {
    location: Location;
    profile: {
        firstName?: string;
        lastName?: string;
        avatar?: string;
    } | null;
    tabs: Tab[];
    profileTabs: ProfileTab[];
    adaptive?: boolean;
    children?: React.ReactNode;
    onLogout: () => void;
}

export interface ProfileTab {
    name: string;
    path: string;
    mini: string;
}

export interface SidebarMenuMapType { [key: string]: string | string[] }

export const updateTabEvents = (tab: Tab, events: { [key: string]: number }, menuMap: SidebarMenuMapType): Tab => {
    const newList = tab.list ? tab.list.map(l => ({
        ...l,
        events: (menuMap[l.name] && _.isArray(menuMap[l.name]) ? getEventsSum(events, menuMap[l.name] as string[]) : events[(menuMap[l.name] as string)]) || 0,
    })) : [];
    const newTab = {
        ...tab,
        events: newList.reduce((acc, l) => (l.events ? acc + l.events : acc), 0),
        list: newList,
    };
    return newTab;
};

const getEventsSum = (events: { [key: string]: number }, eventsArr: string[]) => eventsArr.reduce((acc, event) => (events[event] ? acc + events[event] : acc), 0);
