import React from 'react';
import { ColorStatus } from '_helpers';
import styles from './Status.module.scss';

const Status = ({ status }: IProps) => {
    if (!status) return null;

    const { subStatus } = status;

    return (
        <div className={styles.status}>
            <span style={{ color: `var(${status.color})` }}>
                {status.text}
            </span>
            {subStatus && (
                <span style={{ color: `var(${subStatus.color})` }} className={styles.subStatus}>
                    {subStatus.text}
                </span>
            )}
        </div>
    );
};

export default Status;

interface IProps {
    status?: ColorStatus;
}
