import React from 'react';

const useMouseLeaveDetector = (isActive: boolean, callback: Function, delay: number = 3000) => {
    const leaveTimeout = React.useRef({ index: 0 });
    const [isLeaved, setIsLeaved] = React.useState(false);

    const mouseLeaveHandler = React.useCallback(() => {
        setIsLeaved(true);
    }, []);

    const mouseEnterHandler = React.useCallback(() => {
        setIsLeaved(false);
    }, []);

    React.useEffect(() => {
        if (!isLeaved && !leaveTimeout) return;

        if (isLeaved) {
            window.clearTimeout(leaveTimeout.current.index);
            leaveTimeout.current.index = window.setTimeout(
              callback,
              delay,
            );
        } else {
            window.clearTimeout(leaveTimeout.current.index);
            leaveTimeout.current.index = 0;
        }
    }, [isLeaved]);

    React.useEffect(() => {
        if (!isActive && leaveTimeout.current.index) {
            window.clearTimeout(leaveTimeout.current.index);
        }
    }, [isActive, leaveTimeout.current.index]);

    React.useEffect(() => {
        unsubscribeToEvents(mouseLeaveHandler, mouseEnterHandler);

        if (!isActive) {
            return () => null;
        }

        subscribeToEvents(mouseLeaveHandler, mouseEnterHandler);
        return () => unsubscribeToEvents(mouseLeaveHandler, mouseEnterHandler);
    }, [isActive]);
};

function subscribeToEvents(mouseLeaveHandler: EventListener, mouseEnterHandler: EventListener) {
    document.body.addEventListener('mouseleave', mouseLeaveHandler);
    document.body.addEventListener('mouseenter', mouseEnterHandler);
}

function unsubscribeToEvents(mouseLeaveHandler: EventListener, mouseEnterHandler: EventListener) {
    document.body.removeEventListener('mouseleave', mouseLeaveHandler);
    document.body.removeEventListener('mouseenter', mouseEnterHandler);
}


export default useMouseLeaveDetector;
