import React from 'react';
import {
    NotificationSystem,
    WarningModal,
    Captcha,
    Loader,
} from 'components';
import { Form } from 'react-bootstrap';
import { IApiError } from '_helpers/ApiConnector/types';
import styles from './EmailConfirmationNotification.module.scss';

const EmailConfirmationNotification = ({
    apiError, onPush, sendConfirmationEmail, cleanNotifications, profile,
}: IProps) => {
    const [showVerifyEmailModal, setShowVerifyEmailModal] = React.useState(false);
    const [captcha, setCaptcha] = React.useState('');
    const [captchaValid, setCaptchaValid] = React.useState(true);
    const [processing, setProcessing] = React.useState(false);
    const captchaError = Boolean(apiError && apiError.failures && apiError.failures.captcha);

    const doValidate = () => {
        const isCaptchaValid = captchaError ? captcha.length === 4 : true;

        setCaptchaValid(isCaptchaValid);
        return isCaptchaValid;
    };

    const goToProfile = () => {
        setShowVerifyEmailModal(false);
        onPush('/my-profile');
    };

    const sendEmail = async () => {
        setProcessing(true);
        await sendConfirmationEmail(captcha);
        setProcessing(false);
    };

    const sendEmailAgain = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
        }

        if (!doValidate()) return;
        sendEmail();
        setCaptcha('');
    };

    return profile && !profile.emailConfirmed ? (
        <>
            <NotificationSystem
              notifications={[{
                    message: undefined,
                    appearance: 'warning',
                    children: (<span className={styles.notificationText} onClick={() => setShowVerifyEmailModal(true)}>Внимание - Необходимо подтвердить адрес электронной почты</span>),
                    autoDismiss: false,
                }]}
              cleanNotifications={cleanNotifications}
              className={styles.toastContainer}
              placement="bottom-center"
            />
            <WarningModal
              title="Подтверждение почты"
              closeBtnTittle="Понятно"
              accessBtnTittle="Отправить"
              titleClassNames={styles.warningTitle}
              show={showVerifyEmailModal}
              onHide={() => setShowVerifyEmailModal(false)}
              onAccess={captchaError ? () => sendEmailAgain() : undefined}
              withoutCancelButton={captchaError}
              loading={processing}
            >
                {processing ? <Loader /> : (
                    <>
                        {!captchaError && (
                            <span>
                                <b className="d-block mb-2">Необходимо подтвердить электронную почту.</b>
                                На <b>{profile.email}</b> было отправлено письмо с ссылкой для подтверждения электронной почты.<br />
                                Если вы не видите письмо, его можно <a href="#" onClick={sendEmailAgain}>отправить повторно</a>.<br />
                                Также вы можете <a href="#" onClick={goToProfile}>изменить</a> адрес электронной почты на другой.
                            </span>
                        )}
                        <Captcha
                          captcha={captcha}
                          captchaValid={captchaValid}
                          apiError={apiError}
                          setCaptcha={setCaptcha}
                        />
                        <Form.Control.Feedback className="d-block" type="invalid">
                            {apiError && ((apiError.failures && apiError.type === 'captcha')) && apiError.message}
                        </Form.Control.Feedback>
                    </>
                )}
            </WarningModal>
        </>
    ) : null;
};

export default EmailConfirmationNotification;

interface IProps {
    apiError: IApiError | null;
    profile: IProfile | null;
    onPush: (key: string) => void;
    sendConfirmationEmail: (captcha: string) => void;
    cleanNotifications: () => void;
}

interface IProfile {
    email: string;
    emailConfirmed: boolean;
}
