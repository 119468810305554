import { Color, ColorStatus } from '_helpers';

export enum ExamStatus {
    AwaitingExpertConfirmation = 'awaitingExpertConfirmation',
    IsApplying = 'isApplying',
    ApplyingFinished = 'applyingFinished',
    Canceled = 'canceled',
    Staging = 'staging',
    Finishing = 'finishing',
    Finished = 'finished',
}

export enum FilteredExamStatus {
    AwaitingExpertConfirmation = 'awaitingExpertConfirmation',
    IsApplying = 'isApplying',
    ApplyingFinished = 'applyingFinished',
    Staging = 'staging',
    Finished = 'finished',
    Canceled = 'canceled',
}

export const MappedFilteredExamStatus = {
    [FilteredExamStatus.AwaitingExpertConfirmation as string]: new ColorStatus(Color.Orange, 'Идет создание'),
    [FilteredExamStatus.IsApplying as string]: new ColorStatus(Color.Success, 'Идет запись'),
    [FilteredExamStatus.ApplyingFinished as string]: new ColorStatus(Color.Default, 'Запись окончена'),
    [FilteredExamStatus.Staging as string]: new ColorStatus(Color.Success, 'В процессе проведения'),
    [FilteredExamStatus.Canceled as string]: new ColorStatus(Color.Red, 'Отменены'),
    [FilteredExamStatus.Finished as string]: new ColorStatus(Color.Default, 'Завершено'),
};
export const MappedFilteredExamStatusOptions = Object.keys(MappedFilteredExamStatus).map(k => ({ label: MappedFilteredExamStatus[k].text, value: k }));

export const MappedExamStatuses = {
    [ExamStatus.AwaitingExpertConfirmation as string]: new ColorStatus(Color.Orange, 'Идет создание'),
    [ExamStatus.IsApplying as string]: new ColorStatus(Color.Success, 'Идет запись'),
    [ExamStatus.ApplyingFinished as string]: new ColorStatus(Color.Default, 'Запись окончена'),
    [ExamStatus.Staging as string]: new ColorStatus(Color.Success, 'В процессе проведения'),
    [ExamStatus.Canceled as string]: new ColorStatus(Color.Red, 'Отменено'),
    [ExamStatus.Finishing as string]: new ColorStatus(Color.Default, 'Завершено'),
    [ExamStatus.Finished as string]: new ColorStatus(Color.Default, 'Завершено'),
};

export const getExamStatusMapping = (status: ExamStatus, currentStageNumber?: number | undefined) => {
    if (status === ExamStatus.Staging) return new ColorStatus(Color.Default, `В процессе ${currentStageNumber ? `${currentStageNumber}-й этап` : ''}`);
    return MappedExamStatuses[status] || new ColorStatus(Color.Default, status);
};

export enum ExamApplicationStatus {
    Draft = 'draft',
    InReview = 'inReview',
    CorrectionsRequired = 'correctionsRequired',
    Canceled = 'canceled',
    Approved = 'approved',
    Staging = 'staging',
    Absent = 'absent',
    NotPassed = 'notPassed',
    Passed = 'passed',
}

export const MappedAdmittedResultColor = {
    true: new ColorStatus(Color.Success, 'Допущен'),
    false: new ColorStatus(Color.Default, 'Ожидает допуска'),
};

export enum FinalizedResult {
    None = 'none',
    Passed = 'passed',
    NotPassed = 'notPassed',
    Absent = 'absent',
}

export const MappedFinalizedResult: Record<FinalizedResult, string> = {
    [FinalizedResult.Passed]: 'Этап успешно пройден',
    [FinalizedResult.NotPassed]: 'Этап не пройден',
    [FinalizedResult.Absent]: 'Неявка',
    [FinalizedResult.None]: 'Ожидает финализации',
};

export const MappedFinalizedResultColor: Record<FinalizedResult, ColorStatus> = {
    [FinalizedResult.Passed]: new ColorStatus(Color.Success, MappedFinalizedResult[FinalizedResult.Passed]),
    [FinalizedResult.NotPassed]: new ColorStatus(Color.LightRed, MappedFinalizedResult[FinalizedResult.NotPassed]),
    [FinalizedResult.Absent]: new ColorStatus(Color.Orange, MappedFinalizedResult[FinalizedResult.Absent]),
    [FinalizedResult.None]: new ColorStatus(Color.Default, MappedFinalizedResult[FinalizedResult.None]),
};

export const MappedExamApplicationStatuses = {
    [ExamApplicationStatus.Draft as string]: new ColorStatus(Color.Primary, 'Черновик'),
    [ExamApplicationStatus.InReview as string]: new ColorStatus(Color.Default, 'На рассмотрении'),
    [ExamApplicationStatus.CorrectionsRequired as string]: new ColorStatus(Color.Red, 'Требуются исправления'),
    [ExamApplicationStatus.Canceled as string]: new ColorStatus(Color.Red, 'Отменено'),
    [ExamApplicationStatus.Approved as string]: new ColorStatus(Color.Success, 'Запись подтверждена'),
    [ExamApplicationStatus.Staging as string]: new ColorStatus(Color.Default, 'В процессе проведения'),
    [ExamApplicationStatus.Absent as string]: new ColorStatus(Color.Orange, 'Неявка'),
    [ExamApplicationStatus.NotPassed as string]: new ColorStatus(Color.Red, 'Аккредитация не пройдена'),
    [ExamApplicationStatus.Passed as string]: new ColorStatus(Color.Success, 'Аккредитация пройдена'),
};

export enum ExamSessionStatus {
    Active = 'active',
    Completed = 'completed',
}

export enum ExamSessionReadyStatuses {
    WaitingForStart = 'waitingForStart',
    ExamStaging = 'examStaging',
    TheoreticalExamStagingAndSessionStarted = 'theoreticalExamStagingAndSessionStarted',
    ExamSessionTimeout = 'examSessionTimeout',
    PracticalExamStagingAndSessionStarted = 'practicalExamStagingAndSessionStarted',
    ExamFinished = 'examFinished',
    SessionFinished = 'sessionFinished',
}

export const MappedExamSessionReadyStatuses = {
    [ExamSessionReadyStatuses.WaitingForStart as string]: new ColorStatus(Color.Default, 'Ожидает начала'),
    [ExamSessionReadyStatuses.ExamStaging as string]: new ColorStatus(Color.Default, 'В процессе проведения'),
    [ExamSessionReadyStatuses.TheoreticalExamStagingAndSessionStarted as string]: new ColorStatus(Color.Default, 'В процессе проведения теоретической части аккредитации'),
    [ExamSessionReadyStatuses.ExamSessionTimeout as string]: new ColorStatus(Color.Default, 'Перерыв'),
    [ExamSessionReadyStatuses.PracticalExamStagingAndSessionStarted as string]: new ColorStatus(Color.Default, 'В процессе проведения практической части аккредитации'),
    [ExamSessionReadyStatuses.ExamFinished as string]: new ColorStatus(Color.Default, 'Аккредитация завершена'),
    [ExamSessionReadyStatuses.SessionFinished as string]: new ColorStatus(Color.Default, 'Сессия завершена'),
};

export enum AppType {
    Applicant = 'applicant',
    Ac = 'ac',
    Asc = 'asc',
    Main = 'main',
    Exam = 'exam',
    Expert = 'expert'
}

export enum ExamType {
    Intramural = 'intramural',
    Recertification = 'recertification',
    Online = 'online',
}

export enum DocumentType {
    Other = 'other',
    IdentityDoc = 'identityDoc',
    SecondaryEducationConfirmation = 'secondaryEducationConfirmation',
    HigherEducationConfirmation = 'higherEducationConfirmation',
    WorkExperienceConfirmation = 'workExperienceConfirmation',
    ContinuingEducationConfirmation = 'continuingEducationConfirmation',
    Snils = 'snils',
    NoCriminalRecord = 'noCriminalRecord',
    QualificationCertificate = 'qualificationCertificate',
    ConsentToProcessingPersonalData = 'consentToProcessingPersonalData',
}

export const DocumentTypeTitles = {
    [DocumentType.Other]: 'Другое',
    [DocumentType.IdentityDoc]: 'Документ, удостоверяющий личность',
    [DocumentType.SecondaryEducationConfirmation]: 'Документ о среднем образовании',
    [DocumentType.HigherEducationConfirmation]: 'Документ о высшем образовании',
    [DocumentType.WorkExperienceConfirmation]: 'Документ, подтверждающий стаж работы',
    [DocumentType.ContinuingEducationConfirmation]: 'Документ о повышении квалификации',
    [DocumentType.Snils]: 'СНИЛС',
    [DocumentType.NoCriminalRecord]: 'Документ об отсутствии судимости',
    [DocumentType.QualificationCertificate]: 'Квалификационный аттестат',
    [DocumentType.ConsentToProcessingPersonalData]: 'Согласие на обработку персональных данных',
};

export enum IdentityDocType {
    Passport = 'passport',
    Residence = 'residence',
    MilitaryTicket = 'militaryTicket',
    TemporaryIdentity = 'temporaryIdentity',
    DiplomaticPassport = 'diplomaticPassport',
    StatelessPersonIdentity = 'statelessPersonIdentity',
    InternationalPassport = 'internationalPassport',
    ForeignCitizenPassport = 'foreignCitizenPassport',
    TemporaryResidencePermit = 'temporaryResidencePermit',
    ServicePassport = 'servicePassport',
    RefugeeIdentity = 'refugeeIdentity',
    ServicemanIdentity = 'servicemanIdentity',
    SeafarerIdentity = 'seafarerIdentity',
}

export const IdentityDocTypeTitles = {
    [IdentityDocType.Passport]: 'Паспорт гражданина Российской Федерации',
    [IdentityDocType.Residence]: 'Вид на жительство, выдаваемое лицу без гражданства',
    [IdentityDocType.MilitaryTicket]: 'Военный билет (у лиц, проходящих военную службу)',
    [IdentityDocType.TemporaryIdentity]: 'Временное удостоверение личности гражданина Российской Федерации (форма № 2П)',
    [IdentityDocType.DiplomaticPassport]: 'Дипломатический паспорт',
    [IdentityDocType.StatelessPersonIdentity]: 'Документ, удостоверяющий личность лица без гражданства, выданный иностранным государством',
    [IdentityDocType.InternationalPassport]: 'Заграничный паспорт гражданина Российской Федерации',
    [IdentityDocType.ForeignCitizenPassport]: 'Паспорт иностранного гражданина или иной документ, удостоверяющий личность иностранного гражданина',
    [IdentityDocType.TemporaryResidencePermit]: 'Разрешение на временное проживание, выдаваемое лицу без гражданства',
    [IdentityDocType.ServicePassport]: 'Служебный паспорт',
    [IdentityDocType.RefugeeIdentity]: 'Удостоверение беженца',
    [IdentityDocType.ServicemanIdentity]: 'Удостоверение личности военнослужащего Российской Федерации',
    [IdentityDocType.SeafarerIdentity]: 'Удостоверение личности моряка',
};

export enum Sex {
    Male = 'male',
    Female = 'female',
}

export const MappedExamTypes = {
    [ExamType.Online as string]: new ColorStatus(Color.Default, 'Онлайн'),
    [ExamType.Recertification as string]: new ColorStatus(Color.Default, 'Признание'),
    [ExamType.Intramural as string]: new ColorStatus(Color.Default, 'Очный'),
};

export enum PracticalQuestionType {
    OpenAnswer = 'openAnswer',
    Essay = 'essay',
    ManualCheckTabular = 'manualCheckTabular',
    AutoCheckTabular = 'autoCheckTabular',
    Offline = 'offline',
}

export enum TheoreticalQuestionType {
    MultipleChoice = 'multipleChoice',
    Matching = 'matching',
    OpenAnswer = 'openAnswer',
    Ordering = 'ordering',
}

export enum ExamPartType {
    Theoretical = 'theoretical',
    Practical = 'practical',
}

export enum ExamStageStatus {
    Assignment = 'assignment',
    Finished = 'finished',
}


export enum ExpertStatus {
    AscMember = 'ascMember',
    AscSecretary = 'ascSecretary',
    AscChairman = 'ascChairman',
    AscViceChairman = 'ascViceChairman',
    AcSecretary = 'acSecretary',
    AcChairman = 'acChairman',
    AcViceChairman = 'acViceChairman',
}


export const ExpertStatusNames = {
    ascMember: 'Член АПК',
    ascChairman: 'Председатель АПК',
    ascViceChairman: 'Заместитель председателя АПК',
    ascSecretary: 'Секретарь АПК',
    acSecretary: 'Секретарь АК',
    acChairman: 'Председатель АК',
    acViceChairman: 'Заместитель председателя АК',
};

export const MappedExpertStatus: Record<string, ColorStatus> = {
    [ExpertStatus.AscMember]: new ColorStatus(Color.Default, ExpertStatusNames[ExpertStatus.AscMember]),
    [ExpertStatus.AscChairman]: new ColorStatus(Color.Default, ExpertStatusNames[ExpertStatus.AscChairman]),
    [ExpertStatus.AscViceChairman]: new ColorStatus(Color.Default, ExpertStatusNames[ExpertStatus.AscViceChairman]),
    [ExpertStatus.AscSecretary]: new ColorStatus(Color.Default, ExpertStatusNames[ExpertStatus.AscSecretary]),
    [ExpertStatus.AcSecretary]: new ColorStatus(Color.Default, ExpertStatusNames[ExpertStatus.AcSecretary]),
    [ExpertStatus.AcChairman]: new ColorStatus(Color.Default, ExpertStatusNames[ExpertStatus.AcChairman]),
    [ExpertStatus.AcViceChairman]: new ColorStatus(Color.Default, ExpertStatusNames[ExpertStatus.AcViceChairman]),
};

export const ExpertStatusOptions = [
    { label: 'Все', value: '' },
    { label: ExpertStatusNames[ExpertStatus.AscMember], value: ExpertStatus.AscMember },
    { label: ExpertStatusNames[ExpertStatus.AscChairman], value: ExpertStatus.AscChairman },
    { label: ExpertStatusNames[ExpertStatus.AscViceChairman], value: ExpertStatus.AscViceChairman },
    { label: ExpertStatusNames[ExpertStatus.AscSecretary], value: ExpertStatus.AscSecretary },
];


export enum ExpertChangeRequestType {
    IncludeInAsc = 'includeInAsc',
    ExcludeFromAsc = 'excludeFromAsc',
    UpdateAscStatus = 'updateAscStatus',
    UpdateFullName = 'updateFullName',
    UpdatePosition = 'updatePosition',
}

export const MappedExpertChangeRequestType = {
    [ExpertChangeRequestType.IncludeInAsc]: new ColorStatus(Color.Success, 'Включение в состав АПК'),
    [ExpertChangeRequestType.ExcludeFromAsc]: new ColorStatus(Color.Red, 'Исключение из состава АПК'),
    [ExpertChangeRequestType.UpdateAscStatus]: new ColorStatus(Color.Default, 'Изменение статуса'),
    [ExpertChangeRequestType.UpdateFullName]: new ColorStatus(Color.Default, 'Изменение личных данных'),
    [ExpertChangeRequestType.UpdatePosition]: new ColorStatus(Color.Default, 'Изменение должности'),
};

export enum ExpertChangeRequestStatus {
    Approving = 'approving',
    Approved = 'approved',
    Rejected = 'rejected',
}

export const MappedExpertChangeRequestStatus = {
    [ExpertChangeRequestStatus.Approving]: new ColorStatus(Color.Orange, 'В процессе'),
    [ExpertChangeRequestStatus.Approved]: new ColorStatus(Color.Success, 'Утвержден'),
    [ExpertChangeRequestStatus.Rejected]: new ColorStatus(Color.Red, 'Отклонен'),
};

export const NeedingAttentionMappedExamStatuses = {
    ...MappedExamStatuses,
    [ExamStatus.AwaitingExpertConfirmation as string]: new ColorStatus(Color.Orange, 'Подтвердите участие'),
};

export enum ExpertChangeRequestsOrderStatus {
    Formatting = 'formatting',
    Approving = 'approving',
    Approved = 'approved',
}

export const MappedExpertChangeRequestsOrderStatus = {
    [ExpertChangeRequestsOrderStatus.Formatting]: new ColorStatus(Color.Default, 'Формируется'),
    [ExpertChangeRequestsOrderStatus.Approving]: new ColorStatus(Color.Orange, 'На утверждении'),
    [ExpertChangeRequestsOrderStatus.Approved]: new ColorStatus(Color.Success, 'Утверждено'),
};

export const ResidencyOptions = [
    {
        label: 'Высшее (специалитет)',
        value: 'false',
    },
    {
        label: 'Высшее (ординатура) / Переподготовка',
        value: 'true',
    },
];

export enum AppExceptionType {
    Unspecified = 'unspecified',
    Validation = 'validation',
    NotFound = 'notFound',
    AlreadyExists = 'alreadyExists',
    WrongVerificationCode = 'wrongVerificationCode',
    TooManyRequests = 'tooManyRequests',
    WrongCredentials = 'wrongCredentials',
    AuthUserNotFound = 'authUserNotFound',
    Forbidden = 'forbidden',
    Captcha = 'captcha',
    WrongIp = 'wrongIp',
}

export enum ExpertChangeRequestApprovalStep {
    ApprovalByAscChairman = 'approvalByAscChairman',
    ApprovalByAcSecretary = 'approvalByAcSecretary',
    ApprovalByAcChairman = 'approvalByAcChairman',
}

export enum CitizenshipType {
    Russian = 'russian',
    RussianWithOther = 'russianWithOther',
    Foreigner = 'foreigner',
    WithoutCitizenship = 'withoutCitizenship',
}

export const CitizenshipTypeTitles = {
    [CitizenshipType.Russian]: 'Гражданин РФ',
    [CitizenshipType.RussianWithOther]: 'Гражданин РФ и иностранного государства (двойное гражданство)',
    [CitizenshipType.Foreigner]: 'Иностранный гражданин',
    [CitizenshipType.WithoutCitizenship]: 'Лицо без гражданства',
};
/** Отношение к воинской службе */
export enum MilitaryRelationshipType {
    Reservist = 'reservist',
    Serviceman = 'serviceman',
    NonLiable = 'nonLiable',
    Conscript = 'conscript',
    CustomsOfficer = 'customsOfficer',
}

export const MilitaryRelationshipTypeTitles = {
    [MilitaryRelationshipType.Reservist]: 'Военнообязанный',
    [MilitaryRelationshipType.Serviceman]: 'Военнослужащий',
    [MilitaryRelationshipType.NonLiable]: 'Невоеннообязанный',
    [MilitaryRelationshipType.Conscript]: 'Призывник',
    [MilitaryRelationshipType.CustomsOfficer]: 'Служащий таможенных органов',
};

export enum DiplomaType {
    Specialist = 'specialist',
    Bachelor = 'bachelor',
    Spo = 'spo',
    Residency = 'residency',
    Pp = 'pp',
    ExtractFromProtocolGIA = 'extractFromProtocolGIA',
}

export const DiplomaTypeTitles = {
    [DiplomaType.Specialist]: 'Диплом специалиста',
    [DiplomaType.Bachelor]: 'Диплом бакалавра',
    [DiplomaType.Spo]: 'Диплом СПО',
    [DiplomaType.Residency]: 'Диплом об окончании ординатуры',
    [DiplomaType.Pp]: 'Диплом о ПП',
    [DiplomaType.ExtractFromProtocolGIA]: 'Выписка из протокола ГИА',
};


export type ErrorHandlerType = 'local' | 'global';

// Для преобразованиея анотаций в тайтлы
//  /// <summary>\n/// ([^<].+)\n.+\n^(\w+).+\n{0,1} -> [IdentityDocType.$2]: '$1',