import React from 'react';
import {
    IExtendedSelectEvent,
    IExtendedSelectOption,
    ExtendedSelect,
    MultiSelectGroup,
    PaginationData,
    DateTimePicker,
} from 'components';
import styles from './MultiSelectSection.module.scss';
import moment from 'moment';
import cn from 'classnames';
import { IAccreditationCenter } from '../models';
import { FilteredExamStatus } from '../enums';

const MultiSelectSection = ({
    fetchList,
    acList,
    qualificationList,
    accList,
    examSelectStatusName,
    examStatusOptions,
    withoutSelectStatus,
    withoutFromToDatePicker,
    beforeSelectsChildren,
    defaultValue,
    defaultIds,
    defaultFrom,
    defaultTo,
}: IProps) => {
    const initialState: IState = {
        selectedExamStatus: defaultValue || (examStatusOptions && examStatusOptions[0].value) || ExamStatusOptions[0].value,
        pageOptions: { pages: 10, perPage: 10, page: 1 },
        acIds: (defaultIds && defaultIds.acIds) || [],
        qualificationIds: (defaultIds && defaultIds.qualificationIds) || [],
        attestationSubCommissionIds: (defaultIds && defaultIds.attestationSubCommissionIds) || [],
        customProps: undefined,
        from: defaultFrom || undefined,
        to: defaultTo || undefined,
    };

    const [selectedExamStatus, setSelectedExamStatus] = React.useState(initialState.selectedExamStatus);
    const [pageOptions, setPageOptions] = React.useState(initialState.pageOptions);
    const [acIds, setAcIds] = React.useState(initialState.acIds);
    const [qualificationIds, setQualificationIds] = React.useState(initialState.qualificationIds);
    const [accreditationCenterIds, setAccreditationCenterIds] = React.useState(initialState.attestationSubCommissionIds);
    const [from, setFrom] = React.useState(initialState.from);
    const [to, setTo] = React.useState(initialState.to);

    const getFetchListProps = () => ({
        ...pageOptions,
        accreditationCenterIds,
        qualificationIds,
        acIds,
        from: from || undefined,
        to: to || undefined,
        filteredExamStatus: selectedExamStatus !== 'All' ? selectedExamStatus as FilteredExamStatus : undefined,
    });

    const handleFilterChange = (event: IExtendedSelectEvent) => {
        setPageOptions({ ...pageOptions, page: 1 });
        setSelectedExamStatus(event.value);

        fetchList({
            ...getFetchListProps(),
            filteredExamStatus: event.value !== 'All' ? event.value as FilteredExamStatus : undefined,
        });
    };

    const getAcOptions = (): IExtendedSelectOption[] => (acList ? acList.items.map(ac => ({
        value: ac.id,
        label: ac.name,
    })) : []);

    const getAscOptions = (): IExtendedSelectOption[] => (accList ? accList.map(asc => ({
        value: asc.id,
        label: asc.fullName,
    })) : []);

    const getQualificationsOptions = (): IExtendedSelectOption[] => (qualificationList ? qualificationList.items.map(qualification => ({
        value: qualification.id,
        label: `${qualification.regNumber} - ${qualification.title}`,
    })) : []);

    const onChangeSelect = (e: IExtendedSelectEvent[], func: (ids: string[], filter?: IFetchList) => void) => {
        let ids: string[] = [];

        if (e) {
            ids = [...e.map(el => el.value)];
        }

        func(ids);
        setPageOptions({ ...pageOptions, page: 1 });
    };

    const onChangeAcSelect = (ids: string[], filterProps?: IFetchList) => {
        setAcIds(ids);
        fetchList({
            ...getFetchListProps(),
            ...filterProps,
            acIds: ids,
        });
    };

    const onChangeAscSelect = (ids: string[], filterProps?: IFetchList) => {
        setAccreditationCenterIds(ids);
        fetchList({
            ...getFetchListProps(),
            ...filterProps,
            accreditationCenterIds: ids,
        });
    };

    const onChangeQualificationsSelect = (ids: string[], filterProps?: IFetchList) => {
        setQualificationIds(ids);
        fetchList({
            ...getFetchListProps(),
            ...filterProps,
            qualificationIds: ids,
        });
    };

    const onChangeDate = (props?: IFetchList) => fetchList({
        ...getFetchListProps(),
        ...props,
    });

    return (
        <div className={styles.container} data-test-multi-select-section>
            {!withoutSelectStatus ? (
                <div className={styles.fields}>
                    <div className={styles.field_title}>СТАТУС</div>
                    <div className={styles.field_value}>
                        <ExtendedSelect
                          name={examSelectStatusName || ''}
                          value={selectedExamStatus}
                          options={examStatusOptions || ExamStatusOptions}
                          onChange={handleFilterChange}
                          withoutDetectChanges
                        />
                    </div>
                </div>
            ) : null}
            {!withoutFromToDatePicker ? (
                <div className={styles.fields}>
                    <div className={styles.field_title}>ПЕРИОД</div>
                    <DateTimePicker
                      from={from}
                      to={to}
                      fetch={onChangeDate}
                      setFrom={setFrom}
                      setTo={setTo}
                      firstDateProps={{ placeholder: 'Дата начала' }}
                      secondDateProps={{ placeholder: 'Окончание' }}
                      containerClassName={cn(styles.field_value, styles.date_container)}
                      groupClassName={styles.date_group}
                      withoutLabels
                    />
                </div>

            ) : null}
            {beforeSelectsChildren}
            {acList && (
                <MultiSelectGroup
                  label="АК"
                  options={getAcOptions()}
                  onChange={e => onChangeSelect(e, onChangeAcSelect)}
                  placeholder="Выберите один или несколько Центров Оценки Квалификаций"
                  value={acIds.length ? acIds : undefined}
                  className={styles.selectGroup}
                />
            )}
            {accList && (
                <MultiSelectGroup
                  label="ПЛОЩАДКА"
                  options={getAscOptions()}
                  onChange={e => onChangeSelect(e, onChangeAscSelect)}
                  placeholder="Выберите один или несколько аккредитационных центров"
                  value={accreditationCenterIds.length ? accreditationCenterIds : undefined}
                  className={styles.selectGroup}
                />
            )}
            {qualificationList && (
                <MultiSelectGroup
                  label="СПЕЦИАЛЬНОСТЬ"
                  options={getQualificationsOptions()}
                  onChange={e => onChangeSelect(e, onChangeQualificationsSelect)}
                  placeholder="Выберите одну или несколько специальностей"
                  value={qualificationIds.length ? qualificationIds : undefined}
                  className={styles.selectGroup}
                />
            )}
        </div>
    );
};

export default MultiSelectSection;

export interface IFetchList {
    paid?: boolean;
    qualificationIds?: string[];
    accreditationCenterIds?: string[];
    acIds?: string[];
    page?: number;
    pageSize?: number;
    sortOrder?: boolean;
    sortField?: string;
    filteredExamStatus?: FilteredExamStatus;
    from?: moment.Moment;
    to?: moment.Moment;
}

interface IProps {
    qualificationList?: IQualificationInfoList | null;
    acList?: IAttestationCommissions | null;
    accList?: IAccreditationCenter[] | null;
    examSelectStatusName?: string;
    examStatusOptions?: IExtendedSelectOption[];
    beforeSelectsChildren?: React.ReactNode;
    defaultValue?: string | null;
    defaultIds?: IDefaultIds;
    withoutSelectStatus?: boolean;
    withoutFromToDatePicker?: boolean;
    defaultFrom?: moment.Moment;
    defaultTo?: moment.Moment;
    fetchList: (data: IFetchList) => void;
}

interface IDefaultIds {
    acIds?: string[];
    qualificationIds?: string[];
    attestationSubCommissionIds?: string[];
}

const ExamStatusOptions: IExtendedSelectOption[] = [
    { label: 'Все', value: 'All' },
    { label: 'Создание', value: FilteredExamStatus.AwaitingExpertConfirmation },
    { label: 'Идет запись', value: FilteredExamStatus.IsApplying },
    { label: 'Запись окончена', value: FilteredExamStatus.ApplyingFinished },
    { label: 'В процессе проведения', value: FilteredExamStatus.Staging },
    { label: 'Завершено', value: FilteredExamStatus.Finished },
    { label: 'Отменен', value: FilteredExamStatus.Canceled },
];

interface IAttestationSubCommissionsList {
    items: IAttestationSubCommission[];
    page: number;
    pageSize: number;
    total: number;
}

interface IAttestationSubCommission {
    id: string;
    regNumber: string;
    name: string;
    fullName: string;
    city: string;
    address: string;
    default: boolean;
}

interface IAttestationCommissions {
    items: IAttestationCommission[];
    page: number;
    pageSize: number;
    total: number;
}

interface IAttestationCommission {
    id: string;
    regNumber: string;
    name: string;
    fullName: string;
    city: string;
}

interface IQualificationInfoList {
    items: IQualificationInfo[];
    page: number;
    pageSize: number;
    total: number;
}

interface IQualificationInfo {
    id: string;
    title: string;
    stagesNumber: number;
    regNumber: string;
    occupationalStandardId: string;
    occupationalStandardTitle: string;
}

interface IState {
    selectedExamStatus: string;
    pageOptions: PaginationData;
    acIds: string[];
    qualificationIds: string[];
    attestationSubCommissionIds: string[];
    customProps?: IFetchList;
    from?: moment.Moment;
    to?: moment.Moment;
}
