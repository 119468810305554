import moment from 'moment-timezone';

export const getMoscowTime = (date: moment.Moment) => {
    const localDate = date.clone();
    const moscowTime = localDate.tz('Europe/Moscow');
    const moscowHours = moscowTime.format('HH');
    const moscowMinutes = moscowTime.format('mm');

    return { moscowHours, moscowMinutes };
};

export const createExamDate = (timeDate: moment.Moment, date?: moment.Moment) => {
    const localDate = timeDate.clone();
    const moscowLocalTime = localDate.tz('Europe/Moscow');
    const moscowTime = moscowLocalTime.format('HH:mm по МСК');
    const localTime = timeDate.format('HH:mm');
    const moscowTimezone = moscowLocalTime.hour() === timeDate.hour();
    const time = moscowTimezone ? moscowTime : `${localTime} по местному (${moscowTime})`;

    if (date) {
        return `${date.format('DD.MM.YYYY')} в ${time}`;
    }

    return time;
};
