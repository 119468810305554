import React from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import DateTime from 'react-datetime';
import styles from './DateTimePicker.module.scss';
import cn from 'classnames';

const DateTimePicker = ({
    from,
    firstDateProps,
    secondDateProps,
    to,
    setFrom,
    setTo,
    fetch,
    containerClassName,
    fieldClassName,
    groupClassName,
    withoutLabels,
    withStyledBorderSelect,
    withoutTime,
    withoutClear,
}: IProps) => {
    const checkSameDates = (nDate?: moment.Moment) => from && to && nDate && nDate.isSame(from) && nDate.isSame(to);

    const processSameDates = (nDate?: moment.Moment) => {
        if (!nDate) return;

        const toDate = nDate.clone().set({ hour: 23, minute: 59 });
        const fromDate = nDate.clone().set({ hour: 0, minute: 0 });

        setTo(toDate);
        setFrom(fromDate);
        fetch({ to: toDate, from: fromDate });
    };

    const firstDateChange = (date?: moment.Moment | string | null) => {
        const nDate = date ? moment(date) : undefined;
        setFrom(nDate);

        if (checkSameDates(nDate)) {
            processSameDates();
        } else if (to && nDate && (nDate.diff(to) > 0)) {
            const dayDate = nDate.clone().set({ hour: 23, minute: 59 });

            setTo(dayDate);
            fetch({ from: nDate, to: dayDate });
        } else {
            const dayDate = to ? to.clone().set({ hour: 23, minute: 59 }) : undefined;

            fetch({ from: nDate, to: dayDate });
        }
    };

    const secondDateChange = (date: moment.Moment | string) => {
        const nDate = date ? moment(date) : undefined;

        if (checkSameDates(nDate)) {
            processSameDates();
        } else if (!from || (from && nDate && nDate.diff(from) >= 0)) {
            const dayDate = !to && nDate ? nDate.clone().set({ hour: 23, minute: 59 }) : nDate;

            setTo(dayDate);
            fetch({ to: dayDate });
        } else if (!to && from && nDate && nDate.diff(from) <= 0) {
            const dayDate = from.clone().set({ hour: 23, minute: 59 });

            setTo(dayDate);
            fetch({ to: dayDate });
        } else if (!nDate) {
            setTo(undefined);
            fetch({ to: undefined });
        }
    };

    const renderDateInput = (onChange: (date: moment.Moment | string) => void) => (inputProps: React.HTMLProps<HTMLInputElement>) => (
      <div className="d-flex">
          <input {...inputProps} onChange={() => true} style={{ ...inputProps.style, caretColor: 'transparent' }} />
          {!!inputProps.value && !withoutClear && (
            <svg
              onClick={() => onChange('')}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.clearDateInput}
            >
                <path
                  d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                  fill="#333333"
                />
            </svg>
          )}
      </div>
    );

    return (
        <Form.Group className={cn(styles.date_container, containerClassName)}>
            <div className={cn(styles.date_group, groupClassName)}>
                {!withoutLabels ? (
                    <Form.Text className={cn(styles.date_text, 'text-muted mr-3 mt-0')}>
                        С
                    </Form.Text>
                ) : null}

                {/* Параметр renderInput не включили в .d.ts файл, потому TS не находит его, но в документации он есть */}
                <DateTime
                    // @ts-ignore
                  renderInput={renderDateInput(firstDateChange)}
                  value={from || undefined}
                  className={cn(styles.date, withStyledBorderSelect && styles.date_border, fieldClassName)}
                  inputProps={firstDateProps}
                  onChange={firstDateChange}
                  timeFormat={!withoutTime}
                  closeOnSelect
                />
            </div>
            <div className={cn(styles.date_group, groupClassName)}>
                {!withoutLabels ? (
                    <Form.Text className={cn(styles.date_text, 'text-muted ml-3 mr-1 mt-0')}>
                        ПО
                    </Form.Text>
                ) : null}

                {/* Параметр renderInput не включили в .d.ts файл, потому TS не находит его, но в документации он есть */}
                <DateTime
                    // @ts-ignore
                  renderInput={renderDateInput(secondDateChange)}
                  value={to || undefined}
                  className={cn(styles.date, withStyledBorderSelect && styles.date_border, fieldClassName)}
                  inputProps={secondDateProps}
                  onChange={secondDateChange}
                  timeFormat={!withoutTime}
                  closeOnSelect
                />
            </div>
        </Form.Group>
    );
};

export default DateTimePicker;

interface IProps {
    setFrom: (date?: moment.Moment) => void;
    setTo: (date?: moment.Moment) => void;
    fetch: (date: IReturnDate) => void;
    secondDateProps?: React.HTMLProps<HTMLInputElement>;
    firstDateProps?: React.HTMLProps<HTMLInputElement>;
    from?: moment.Moment;
    to?: moment.Moment;
    containerClassName?: string;
    groupClassName?: string;
    fieldClassName?: string;
    withoutLabels?: boolean;
    withStyledBorderSelect?: boolean;
    withoutTime?: boolean;
    withoutClear?: boolean;
}

interface IReturnDate {
    to?: moment.Moment;
    from?: moment.Moment;
}
