import React from 'react';
import { Form } from 'react-bootstrap';
import styles from './Textarea.module.scss';
import cn from 'classnames';

const Textarea = ({
    name,
    onChange,
    validErrors,
    resize,
    ...formProps
}: IProps) => {
    return (
        <>
            <Form.Control
                as="textarea"
                rows={2}
                onChange={e => onChange((e.target as HTMLInputElement).value)}
                className={cn(styles.textarea, resize && styles.textarea_resize)}
                isInvalid={validErrors ? !!validErrors[name] : undefined}
                autoComplete="new-password"
                {...formProps}
            />
            {validErrors ? <Form.Control.Feedback type="invalid">{validErrors[name]}</Form.Control.Feedback> : null}
        </>
    )
}

export default Textarea;

interface IProps {
    value: string;
    name: string;
    validErrors?: IInitErrors;
    placeholder?: string;
    resize?: boolean;
    disabled?: boolean;
    rows?: number;
    required?: boolean;
    style?: React.CSSProperties;
    onChange: (value: string) => void;
}

interface IInitErrors {
    [key: string]: string;
}
