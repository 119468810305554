import React from 'react';
import {
    ApplicantQualifications,
} from 'components';
import { connect } from 'react-redux';
import { useEffectProcessing } from 'hooks';
import { Dispatch, IRootState } from '../../redux/store/configureStore';

const QualificationsListContainer: React.FC<Props> = (
  {
      ...props
  },
) => {
    useEffectProcessing(
      () => props.getQualifications(),
      [],
      () => props.clearQualifications(),
    );

    return (
      <ApplicantQualifications
        details={props.qualificationDetails}
        qualifications={props.qualificationList}
        onChooseQualification={id => props.getQualificationDetails(id)}
        onClearQualification={props.clearDetails}
        cardTitle="Мои специальности"
        cardCategory="Список моих подтвержденных специальностей"
      />
    );
};

const mapState = (state: IRootState) => ({
    qualificationList: state.applicant.qualificationsList,
    qualificationDetails: state.applicant.qualificationDetails,
    apiError: state.errors.error,
});

const mapDispatch = (dispatch: Dispatch) => ({
    getQualificationDetails: dispatch.applicant.getQualificationDetailsAsync,
    getQualifications: dispatch.applicant.getQualificationsListAsync,
    clearQualifications: dispatch.qualifications.clearList,
    clearDetails: dispatch.applicant.clearQualificationDetails,
    setErrorHandler: dispatch.errors.setErrorHandler,
});

export default connect(mapState, mapDispatch)(QualificationsListContainer);

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
