import React from 'react';
import { ExamApplicationDetailsContainer } from '../../containers';
import { RouteComponentProps } from 'react-router';

const ExamApplicationDetailsPage = ({ match }: Props) => <ExamApplicationDetailsContainer id={match.params.id} />;

export default ExamApplicationDetailsPage;

interface OwnProps {
    id: string;
}

type Props = RouteComponentProps<OwnProps>
