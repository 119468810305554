export function saveToLocalStorage(key: string, data: string): void {
    return localStorage.setItem(key, data);
}

export function loadFromLocalStorage(key: string): string | null {
    return localStorage.getItem(key);
}

export function removeFromLocalStorage(key: string): void {
    return localStorage.removeItem(key);
}
