import React from 'react';
import { InputText } from 'components';
import { IMatchingTheoreticalAnswer, IFieldsError } from './types';
import QuestionSelect, { IChangeSelect } from './QuestionSelect';
import styles from './TheoreticalQuestionRedactor.module.scss';
import cn from 'classnames';

const MatchingRightVariant = ({
    answer,
    formFieldErrors,
    handleChangeOrderingAnswerText,
    num,
    correct,
    right,
    setFormFieldErrors,
    onChangeSelect,
}: IProps) => (
        <section className={cn('mb-4', styles.answer_container)}>
            <InputText
              inputGroupClassNames={styles.answer_inputGroup}
              inputClassNames="p-0"
              inputColSize={12}
              withoutLabel
              name={`answer_right_${answer.number}`}
              value={answer.text}
              onChange={handleChangeOrderingAnswerText(answer.number)}
              fieldFailures={formFieldErrors}
            />
            <QuestionSelect
              key={`questionSelect_${num}`}
              correct={correct}
              formFieldErrors={formFieldErrors}
              right={right}
              setFormFieldErrors={setFormFieldErrors}
              onChangeSelect={onChangeSelect}
              answer={answer}
              num={num}
              withoutNum
            />
        </section>
    );

export default MatchingRightVariant;

interface IProps {
    answer: IMatchingTheoreticalAnswer;
    formFieldErrors: IFieldsError;
    num: number;
    correct: { [key: string]: number };
    right: IMatchingTheoreticalAnswer[];
    setFormFieldErrors: (error: IFieldsError) => void;
    onChangeSelect: (data: IChangeSelect) => void;
    handleChangeOrderingAnswerText: (answerNumber: number) => (name: string, value: string) => void;
}
