import React, { useState } from 'react';
import {
    Col,
    Form,
    FormGroup,
    FormLabel,
} from 'react-bootstrap';
import {
    Card,
    InputPassword,
    LoaderBtn,
    Captcha,
    PhoneInput,
    InputText,
} from 'components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { RegExpConst, mapAPIError, RegExpErrorsText } from '_helpers';
import { SendVerificationCode } from '../../api/ApiClient';
import styles from './RegistrationStep.module.scss';

const defaultState: IState = {
    lastName: '',
    firstName: '',
    middleName: '',
    phone: '',
    password: '',
};

const RegistrationStep1 = ({
    apiError, sendRegistrationVerificationCode, setRegisterForm, nextStep, clearErrors,
}: Props) => {
    const [form, setForm] = useState(defaultState);
    const [errors, setErrors] = useState(defaultState);
    const [captcha, setCaptcha] = React.useState('');
    const [captchaValid, setCaptchaValid] = React.useState(true);
    const [processing, setProcessing] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const fieldFailures = errors as any as { [key: string]: string };

    React.useEffect(() => {
        clearErrors();
    }, []);

    React.useEffect(() => {
        setErrors({
            ...errors,
            ...mapAPIError(apiError),
        });
    }, [apiError]);

    const doValidate = (formData: IState) => {
        const test = (field: string, regexp: RegExp) => (regexp.test(field) ? '' : 'Error');
        const validateErrors = { ...errors, general: '' };
        validateErrors.phone = test(formData.phone, RegExpConst.PHONE) && RegExpErrorsText.PHONE;
        validateErrors.firstName = test(formData.firstName, RegExpConst.NAME) && RegExpErrorsText.NAME;
        validateErrors.lastName = test(formData.lastName, RegExpConst.NAME) && RegExpErrorsText.NAME;
        validateErrors.middleName = test(formData.middleName, RegExpConst.NAME) && RegExpErrorsText.NAME;
        validateErrors.password = test(formData.password, RegExpConst.PASSWORD) && RegExpErrorsText.PASSWORD;
        validateErrors.captcha = '';

        const isCaptchaValid = apiError && apiError.failures && apiError.failures.captcha ? captcha.length === 4 : true;
        const isFormValid = Object.values(validateErrors).every(err => !err);

        setCaptchaValid(isCaptchaValid);
        setErrors(validateErrors);

        return isFormValid && isCaptchaValid;
    };

    const handleChangeInput = (name: string, value: string) => {
        const newForm = {
            ...form,
            [name]: value,
        };

        setForm(newForm);

        if (formSubmit) {
            doValidate(newForm);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setFormSubmit(true);
        if (!doValidate(form)) return;

        setProcessing(true);
        const result = await sendRegistrationVerificationCode(new SendVerificationCode({
            phone: form.phone,
            captcha,
        }));

        setProcessing(false);
        setCaptcha('');

        if (result) {
            setRegisterForm(form);
            setFormSubmit(false);
            nextStep();
        }
    };

    return (
        <>
            <Col className="auth-forms--container">
                <form onSubmit={handleSubmit}>
                    <Card
                      title="Регистрация"
                      category="для записи и прохождения аккредитаций"
                      apiError={apiError}
                      contentClassName={styles.content}
                      ftTextCenter
                      legend={(
                            <LoaderBtn
                              variant="primary"
                              type="submit"
                              disabled={processing || true}
                              loading={processing}
                            >
                                Зарегистрироваться
                            </LoaderBtn>
                        )}
                    >
                        <InputText
                          labelTitle="Фамилия"
                          labelColSize={12}
                          inputColSize={12}
                          inputClassNames="p-0"
                          value={form.lastName}
                          onChange={handleChangeInput}
                          name="lastName"
                          fieldFailures={fieldFailures}
                          autoFocus
                        />

                        <InputText
                          labelTitle="Имя"
                          labelColSize={12}
                          inputColSize={12}
                          inputClassNames="p-0"
                          value={form.firstName}
                          onChange={handleChangeInput}
                          name="firstName"
                          fieldFailures={fieldFailures}
                        />

                        <InputText
                          labelTitle="Отчество"
                          labelColSize={12}
                          inputColSize={12}
                          inputClassNames="p-0"
                          value={form.middleName}
                          onChange={handleChangeInput}
                          name="middleName"
                          fieldFailures={fieldFailures}
                        />

                        <PhoneInput
                          labelTitle="Мобильный телефон"
                          labelColSize={12}
                          inputColSize={12}
                          inputClassNames="p-0"
                          value={form.phone}
                          onChange={handleChangeInput}
                          fieldFailures={fieldFailures}
                        />

                        <FormGroup data-test-password-group>
                            <FormLabel>Пароль</FormLabel>
                            <InputPassword
                              name="password"
                              onChange={e => handleChangeInput(e.target.name || '', e.target.value ? e.target.value.toString() : '')}
                              isInvalid={!!errors.password}
                              value={form.password}
                            />

                            {!!errors.password && (
                                <div className={styles.input_error_block}>
                                    Пароль должен быть от 6-ти до 15-ти символов, можно использовать только
                                    цифры, символы или латиницу
                                </div>
                            )}
                        </FormGroup>
                        <Captcha captcha={captcha} captchaValid={captchaValid} apiError={apiError} setCaptcha={setCaptcha} />

                        <Form.Control.Feedback className="d-block" type="invalid">
                            {apiError
                                && ((apiError.failures
                                    && !(apiError.failures.lastName || apiError.failures.firstName || apiError.failures.middleName || apiError.failures.phone || apiError.failures.password))
                                    || (apiError.type === 'captcha'))
                                && apiError.message
                            }
                        </Form.Control.Feedback>
                    </Card>
                </form>
            </Col>
            <div className={styles.register_link_container}>
                Уже зарегистрированы? <Link to="/login" className={styles.register_link}>Войти</Link>
            </div>
        </>
    );
};

const mapState = (state: IRootState) => ({
    apiError: state.errors.error,
});

const mapDispatch = (dispatch: Dispatch) => ({
    sendRegistrationVerificationCode: dispatch.auth.sendRegistrationVerificationCode,
    clearErrors: dispatch.errors.clean,
    setRegisterForm: dispatch.auth.setRegisterForm,
});

export default connect(mapState, mapDispatch)(RegistrationStep1);

type connectedProps = ReturnType<typeof mapDispatch> & ReturnType<typeof mapState>
type Props = connectedProps & OwnProps;

interface OwnProps {
    nextStep: () => void;
}

interface IState {
    lastName: string;
    firstName: string;
    middleName: string;
    phone: string;
    password: string;
    captcha?: string;
}
