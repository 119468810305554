import React from 'react';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import activeIcon from './icons/arrow.svg';
import dropIcon from './icons/drop.svg';
import styles from './Dropdown.module.scss';
import cn from 'classnames';

const Dropdown = ({ items, title }: IProps) => {
    const [open, setOpen] = React.useState(false);

    const CustomMenu = React.forwardRef((aProps: HeaderDropdownToggleProps, ref: any) => {
        const handleClick = (e: React.MouseEvent) => {
            e.preventDefault();
            aProps.onClick(e);
        };

        return (
            <a
              ref={ref}
              onClick={handleClick}
            >
                {aProps.children}
            </a>
        );
    });

    const renderDropdownContent = (item: DropdownItem) => (
        <div className={styles.commonButton}>
            {item.active ? <img className={styles.activeIcon} src={activeIcon} alt="activeIcon" /> : null}
            <label>{item.label}</label>
        </div>
    );

    return (
        <BootstrapDropdown className={styles.dropdown} onClick={() => setOpen(!open)}>
            <BootstrapDropdown.Toggle
              as={CustomMenu}
              id="dropdown"
            >
                <div className={styles.menuContainer}>
                    {title ? <span>{title}</span> : null}
                    <img className={cn(styles.dropIcon, open && styles.dropIcon_rotate)} src={dropIcon} alt="dropIcon" />
                </div>
            </BootstrapDropdown.Toggle>

            <BootstrapDropdown.Menu className={styles.dropdown__menu}>
                {items.map((item: DropdownItem, i) => ((item.to || item.onClick) ? (
                    <div key={`DropdownItem_${i}`}>
                        {item.to ? (
                            <BootstrapDropdown.Item eventKey="1" className={styles.dropdown__item} as={Link} to={item.to}>
                                {renderDropdownContent(item)}
                            </BootstrapDropdown.Item>
                        ) : null}

                        {item.onClick ? (
                            <BootstrapDropdown.Item eventKey="1" className={styles.dropdown__item} onClick={item.onClick}>
                                {renderDropdownContent(item)}
                            </BootstrapDropdown.Item>
                        ) : null}

                        {i !== items.length - 1 ? <BootstrapDropdown.Divider className={styles.dropdown__divider} /> : null}
                    </div>
                ) : null))}
            </BootstrapDropdown.Menu>
        </BootstrapDropdown>
    );
};

export default Dropdown;

interface IProps {
    items: DropdownItem[];
    title?: string;
}

interface HeaderDropdownToggleProps {
    onClick: React.MouseEventHandler;
    children: React.ReactNode;
}

export interface DropdownItem {
    label: string;
    active?: boolean;
    to?: string;
    onClick?: () => void;
}
