import React from 'react';
import { LoaderBtn, DotsSettings, IDotsButtonProps } from 'components';
import { Modal, Button } from 'react-bootstrap';
import styles from './WarningModal.module.scss';
import cn from 'classnames';

const WarningModal = ({
    show,
    onHide,
    title,
    onAccess,
    loading,
    appendFooterItem,
    titleClassNames,
    bodyClassNames,
    footerClassNames,
    accessBtnTittle,
    closeBtnTittle,
    children,
    dialogClassName,
    withoutCancelButton,
    withoutCloseIcon,
    withoutTitlePadding,
    withoutTitleMargin,
    withoutOverflow,
    large,
    dotsButtons,
    disableAccessButton,
    headerClassNames,
    centered = true,
    card,
}: WarningModalProps) => (
        <Modal
          show={show}
          onHide={onHide}
          dialogClassName={cn(styles.modal, large && styles.modal_large, withoutOverflow && styles.modal_overflow_off, card && 'modal-p0', dialogClassName)}
          data-test-warning-modal
          centered={centered}
        >
            <Modal.Header
              closeButton={!withoutCloseIcon}
              className={cn(styles.modal__header, withoutTitlePadding && 'pb-0', headerClassNames, card && 'px-32 pt-32')}
            >
                <Modal.Title className={cn('d-flex', !withoutTitleMargin && 'mb-4', titleClassNames || '')}>
                    {title}
                    {dotsButtons ? <DotsSettings buttons={dotsButtons} /> : null}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={cn(styles.modal__body, bodyClassNames || '', withoutTitlePadding && 'pt-0', card && 'p-0')}>
                {children}
            </Modal.Body>
            <Modal.Footer className={cn(styles.modal__footer, footerClassNames || '')}>
                {!!onAccess && (
                  <LoaderBtn
                    onClick={onAccess}
                    variant="primary"
                    loading={loading}
                    disabled={disableAccessButton}
                    className={cn(styles.submitButton, withoutCancelButton && 'ml-0')}
                  >
                      {accessBtnTittle || 'Подтвердить'}
                  </LoaderBtn>
                )}
                {!withoutCancelButton && <Button onClick={onHide} variant="outline-secondary" className={cn(!onAccess && 'ml-0')}>{closeBtnTittle || 'Закрыть'}</Button>}
                {appendFooterItem && appendFooterItem()}
            </Modal.Footer>
        </Modal>
    );

interface WarningModalProps {
    title: string;
    show: boolean;
    centered?: boolean;
    accessBtnTittle?: string;
    closeBtnTittle?: string;
    titleClassNames?: string;
    bodyClassNames?: string;
    headerClassNames?: string;
    footerClassNames?: string;
    dialogClassName?: string;
    loading?: boolean;
    children?: React.ReactNode;
    withoutCancelButton?: boolean;
    withoutCloseIcon?: boolean;
    withoutTitlePadding?: boolean;
    withoutTitleMargin?: boolean;
    withoutOverflow?: boolean;
    large?: boolean;
    card?: boolean;
    dotsButtons?: IDotsButtonProps[];
    disableAccessButton?: boolean;
    onHide: () => void;
    onAccess?: () => void;
    appendFooterItem?: () => JSX.Element | string | null;
}


export default WarningModal;
