import React from 'react';
import { Button } from 'react-bootstrap';
import { Card, WarningModal } from 'components';
import styles from './SystemSettings.module.scss';
import { OnlineModal } from '../OnlineModal';

const SystemSettings: React.FC<IProps> = ({ title, onGoBack }) => {
    const link = React.useRef<HTMLAnchorElement | null>(null);
    const [showBrowserModal, setShowBrowserModal] = React.useState(false);
    const [showVersionModal, setShowVersionModal] = React.useState(false);
    const [showSettingsModal, setShowSettingsModal] = React.useState(false);

    const getChromeVersion = () => {
        const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

        return raw ? parseInt(raw[2], 10) : false;
    };

    const onStartCheckClick = () => {
        const nWindow: any = window;
        const isChrome = !!nWindow.chrome && (!!nWindow.chrome.webstore || !!nWindow.chrome.runtime) && !nWindow.yandex && !nWindow.opr;

        if (isChrome) {
            const latestVersion = getChromeVersion() > 80;

            if (!latestVersion) {
                setShowVersionModal(true);
            } else {
                setShowSettingsModal(true);
            }
        } else {
            setShowBrowserModal(true);
        }
    };

    const handleCloseSettingModal = React.useCallback(() => {
        setShowSettingsModal(false);
        if (JSON.parse(localStorage.getItem('readyForOnlineExam') || 'false')) {
            onGoBack();
        }
    }, []);

    const handleSuccess = React.useCallback(() => {
        localStorage.setItem('readyForOnlineExam', 'true');
    }, []);

    const onModalSubmit = () => {
        if (link && link.current) {
            link.current.click();
        }
    };

    return (
        <Card
          title={title}
          onClickBack={onGoBack}
          contentClassName="pt-0 pb-0"
          withoutTitleMargin
          withBackButton
          smallTitle
        >
            <Card
              title="Предварительная проверка системы"
              category="Техническая проверка и настройка системы перед аккредитацией"
              contentClassName="pb-0"
              withoutBottomMargin
              withoutPaddingTitle
              withoutPadding
              withoutBorder
            >
                <div className={styles.container}>
                    <span className={styles.container_title}>Для чего нужна проверка системы?</span>
                    <span className={styles.container_text}>
                        Предварительная проверка системы требуется для того, чтобы убедиться в том,
                        что ваш компьютер полностью соответствует всем техническим требованиям, в
                        соответствии с Регламентом проведения независимой оценки специальности в дистанционном формате.
                        Если вдруг что-то окажется не так, то у вас будет достаточно времени, чтобы успеть решить проблему до аккредитации.
                    </span>

                    <span className={styles.container_title}>Что потребуется для прохождения проверки системы?</span>
                    <span className={styles.container_text}>
                        Для прохождения проверки потребуются ваш компьютер, на котором вы планируете проходить
                        сдачу квалификационного аккредитации в дистанционном формате с подключенными веб-камерой и
                        микрофоном. Компьютер, в свою очередь, должен быть подключен к сети интернет. Пожалуйста,
                        обратите внимание на то, что необходимо использовать именно тот компьютер, который будет
                        использоваться для сдачи аккредитации. В противном случае, данная проверка не будет иметь никакого смысла.
                    </span>

                    <span className={styles.container_title}>Как проходит процесс проверки системы?</span>
                    <span className={styles.container_text}>
                        В процессе предварительной проверки мы попросим вас предоставить временный доступ к вашей
                        веб-камере, микрофону, а также рабочему столу. Данная проверка носит 100% автоматический характер.
                        Поэтому, кроме вас в ней никто участвовать не будет. В среднем, процесс проверки занимает не более 5 минут.
                    </span>
                </div>

                <Button
                  className="action-button"
                  variant="primary"
                  onClick={onStartCheckClick}
                >
                    Пройти проверку системы сейчас
                </Button>
            </Card>

            <WarningModal
              withoutCancelButton
              title="Требуется Google Chrome"
              show={showBrowserModal}
              onHide={() => setShowBrowserModal(false)}
              onAccess={() => {
                    setShowBrowserModal(false);
                    onModalSubmit();
                }}
              accessBtnTittle="Скачать браузер Google Chrome"
            >
                <span>
                    К сожалению, ваш браузер не подходит для сдачи онлайн-аккредитации.<br />
                    В соответствии с техническим регламентом, вам необходимо использовать браузер Google Chrome.
                </span>
                <span className="d-block mt-2">
                    Вы можете скачать последнюю версию браузера Google Chrome по этой кнопке:
                </span>
            </WarningModal>

            <WarningModal
              withoutCancelButton
              title="Старая версия Google Chrome"
              show={showVersionModal}
              onHide={() => setShowVersionModal(false)}
              onAccess={() => {
                    setShowVersionModal(false);
                    onModalSubmit();
                }}
              accessBtnTittle="Скачать последнюю версию Google Chrome"
            >
                <span>
                    К сожалению, версия вашего браузера Google Chrome не подходит для сдачи онлайн-аккредитации.<br />
                    В соответствии с техническим регламентом, вам необходимо использовать последнюю доступную версию Google Chrome.
                </span>
                <span className="d-block mt-2">
                    Обновите ваш браузер, либо скачайте последнюю версию браузера Google Chrome по этой кнопке:
                </span>
            </WarningModal>

            <OnlineModal
              title="Техническая проверка системы"
              show={showSettingsModal}
              onClose={handleCloseSettingModal}
              onSuccess={handleSuccess}
            />
            <a ref={link} style={{ display: 'none' }} href="https://www.google.com/intl/ru/chrome/" target="__blank">chrome browser</a>
        </Card>
    );
};

export default SystemSettings;

interface OwnProps {
    title: string;
    onGoBack: () => void;
}

type IProps = OwnProps;
