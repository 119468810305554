import { createModel } from '@rematch/core';
import { INotification } from 'components';
// import apiConnector from '../../api/ApiConnector';
import _ from 'lodash';

// let intervalTimer = 0;
// const UPDATE_INTERVAL = 60000;

const initialState: IState = {};

const eventsState = createModel({
    state: initialState,
    reducers: {
        setEventsState(state: IState, payload: { [key: string]: number }): IState {
            return _.isEqual(state, payload) ? state : { ...payload };
        },
    },
    effects: (dispatch: any) => ({
        async getEventState() {
            // const result = await apiConnector.execApi(dispatch, api => api.getEventsState(), { eventStateRequest: true });
            // if (!result) return;

            // this.updateEventState(result.eventsState);
        },
        updateEventState(updateEventState: { [key: string]: number } | undefined, state) {
            if (updateEventState) {
                const notifications = getNewNotifications(state.eventsState, updateEventState);
                if (notifications.length > 0) {
                    const notificationsArr: INotification[] = notifications.map(n => ({ message: n, appearance: 'info' }));
                    dispatch.notifications.addNotification(notificationsArr);
                }
            }
            // this.setEventsState(updateEventState || {});
        },
        startEventStatePoll() {
            // this.getEventState();
            // intervalTimer = window.setInterval(this.getEventState.bind(this), UPDATE_INTERVAL);
        },
        stopEventStatePoll() {
            // clearInterval(intervalTimer);
        },
    }),
});

export default eventsState;

const getNewNotifications = (prevNotif: {[key: string]: number}, nextNotif: {[key: string]: number}) => {
    const actualNotificationKeys = Object.keys(nextNotif).filter((k) => {
        if (!prevNotif[k]) return true;
        if (prevNotif[k] >= nextNotif[k]) return false;
        return true;
    });
    return actualNotificationKeys.map(n => (notificationMessageMap[n] ? notificationMessageMap[n] : `Unknow message. Key: ${n}`));
};

const notificationMessageMap: {[key: string]: string} = {
    ApplicantExamApplications: 'Обновлен статус заявки',
    ApplicantMyExamApplications: 'Добавлена новая аккредитация',
};

interface IState {
    [key: string]: number;
}
