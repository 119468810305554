import * as React from 'react';

const LikeIcon = () => (
  <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.44351 21.9361H6.05399V8.43774H0.0620117L2.44351 21.9361Z" fill="white" />
      <path
        d="M26.3378 10.5308C26.3378 9.0873 25.1833 8.86922 25.1833 8.86922H17.6758C17.6758 8.86922 17.1696 8.22105 17.6027 6.8488C18.0355 5.47682 18.109 3.4564 17.7473 2.59015C17.3874 1.72417 16.7367 0.135496 15.0763 0.0637207C14.1025 0.212771 13.7769 1.00147 13.7769 1.00147V4.1791C13.7769 4.1791 10.6741 9.80835 10.2412 9.87985C9.80699 9.953 8.07861 10.1697 8.07861 10.1697V20.1316H8.58076C8.58076 20.1316 12.2616 21.9358 12.9827 21.9358C13.7054 21.9358 22.0791 21.9358 22.0791 21.9358C22.0791 21.9358 23.3782 21.6463 23.3782 20.2036C23.3782 19.2659 22.584 18.6867 22.584 18.6867C22.584 18.6867 24.5341 18.5429 24.5341 16.883C24.5341 16.2013 23.5944 15.5836 23.5944 15.5836C23.5944 15.5836 25.3998 15.2223 25.3283 13.707C25.1833 12.7687 24.6776 12.2627 24.6776 12.2627C24.6776 12.2627 26.3378 11.9737 26.3378 10.5308Z"
        fill="white"
      />
  </svg>
);

export default LikeIcon;
