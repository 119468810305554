import {
    DragSource, DropTarget, DragSourceConnector, DropTargetMonitor, DropTargetConnector,
} from 'react-dnd';

export interface RequiredProps {
    rowIndex: number;
    onRowDrop: (fromIndex: number, toIndex: number) => void;
}

function rowSourceCollect(connect: DragSourceConnector) {
    return {
        // Call this function inside render()
        // to let React DnD handle the drag events:
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
    };
}

/**
 * Specifies the drag source contract.
 * Only `beginDrag` function is required.
 */
const rowSourceSpec = {
    beginDrag(props: RequiredProps) {
        return {
            rowIndex: props.rowIndex,
        };
    },
};

function rowTargetCollect(connect: DropTargetConnector, monitor: DropTargetMonitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver() && monitor.canDrop(),
    };
}

const rowTargetSpec = {
    canDrop(props: RequiredProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem();
        return props.rowIndex !== item.rowIndex;
    },

    drop(props: RequiredProps, monitor: DropTargetMonitor) {
        if (monitor.didDrop()) {
            return;
        }
        // Obtain the dragged item
        const { rowIndex: fromIndex } = monitor.getItem();
        const { rowIndex: toIndex, onRowDrop } = props;
        onRowDrop(fromIndex, toIndex);
    },
};

export const rowDragSource = DragSource('row', rowSourceSpec, rowSourceCollect);
export const rowDropTarget = DropTarget('row', rowTargetSpec, rowTargetCollect);
