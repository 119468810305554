import { IExamApplicationDetails } from 'components/models';
import { ExamApplicationStatus, getExamStatusMapping, MappedExamApplicationStatuses } from 'components/enums';
import { Color, ColorStatus } from '_helpers';

export default (details: IExamApplicationDetails) => ({
    ...MappedExamApplicationStatuses,
    [ExamApplicationStatus.Approved as string]: getExamStatusMapping(details.exam.status, details.exam.currentStageNumber),
    [ExamApplicationStatus.Staging as string]: getExamStatusMapping(details.exam.status, details.exam.currentStageNumber),
    [ExamApplicationStatus.InReview as string]: new ColorStatus(Color.Default, 'Заявка находится на рассмотрении'),
    [ExamApplicationStatus.CorrectionsRequired as string]: new ColorStatus(Color.Red, 'Требуются корректировки по заявке:'),
})[details.status];
