import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import uploadIcon from './upload_icon.svg';
import deleteIcon from './delete_icon.svg';
import styles from './UploadedDocument.module.scss';
import cn from 'classnames';
// import moment from 'moment';

const UploadedDocument = ({
    file, onDeleteFile, getDocFromTemplate, onDeleteSource,
}: IProps) => {
    const {
        fileId, fileExtension, fileName, fileSize, documentTemplateId, isSource, progress,
    } = file;

    const onDeleteFileClick = () => {
        if (documentTemplateId) {
            if (!isSource) {
                onDeleteFile(documentTemplateId);
            } else if (getDocFromTemplate && onDeleteSource) {
                const document = getDocFromTemplate(documentTemplateId);

                if (document) {
                    onDeleteSource(document.id, fileId);
                }
            }
        }
    };

    const progressVal = progress !== undefined ? progress : 100;
    const size = parseFloat((fileSize / 1000).toFixed(2));

    return (
        <div className={styles.uploadedFile}>
            <img src={uploadIcon} alt="uploadIcon" />
            <div className={styles.uploadedFile_textContainer}>
                <span className={styles.uploadedFile_text}>{fileName || fileExtension}</span>
                <ProgressBar className={styles.uploadedFile_progressBar} now={progressVal} />
                <span className={styles.uploadedFile_text}>
                    {progressVal ? (progressVal / 100) * size : 0} из {size} КБ
                </span>
                <span className={cn(styles.uploadedFile_text, styles.uploadedFile_text_progress)}>{`Загружено ${progressVal}%`}</span>
            </div>
            <img
              className={styles.uploadedFile_deleteIcon}
              src={deleteIcon}
              alt="deleteIcon"
              onClick={onDeleteFileClick}
            />
        </div>
    );
};

export default UploadedDocument;

export interface IProps {
    file: IFile;
    onDeleteFile: (templateId: string) => void;
    getDocFromTemplate?: (templateId: string) => IUploadedDocument | undefined;
    onDeleteSource?: (documentId: string, sourceFileId: string) => void;
}

export interface IUploadedDocument {
    id: string;
    documentDescription: string;
    examApplicationId: string;
    applicantId: string;
    // submissionDate: moment.Moment;
    sourceFiles: ISourceFile[];
    fileId: string;
    fileExtension: string;
    fileSize: number;
    fileUrl: string;
    documentTemplateId?: string;
}

interface IFile {
    fileId: string;
    fileExtension: string;
    fileName?: string;
    fileSize: number;
    isSource?: boolean;
    progress?: number;
    documentTemplateId?: string;
}

interface ISourceFile {
    fileId: string;
    fileExtension: string;
    fileSize: number;
    fileUrl: string;
}
