import * as React from 'react';
import {
    ExamAssigningApplicationsColumnHeader,
    ExamAssigningApplicationsTable,
} from 'components/ExamAssigningApplicationsTable';
import FinalizeResultModals, { IFinalizeResultModalsProps } from '../FinalizeResultModals';
import { IBaseExamApplicationsForDay, IExamApplication, IExamStage } from 'components/models';
import { ExamStageStatus } from 'components/enums';

const FinalizedExamApplicationsTab: React.FC<IProps> = (props) => {
    const applications = props.examApplicationsForDay.info ? props.examApplicationsForDay.info.items : [];


    const [showFinalizingResultModal, setShowFinalizingResultModal] = React.useState(false);
    const [assigningApplicantsForReplace, setAssigningApplicantsForReplace] = React.useState<IExamApplication[]>([]);

    const handleAssignedApplicants = React.useCallback((applicants: IExamApplication[]) => {
        setShowFinalizingResultModal(true);
        setAssigningApplicantsForReplace(applicants);
    }, []);

    const applicantHeaders: ExamAssigningApplicationsColumnHeader[] = [
        { value: 'Имя, фамилия, Отчество', key: 'applicantFullName' },
        { value: 'Телефон', key: 'applicantPhone' },
        { value: 'Статус', key: 'finalizedResult', isStatus: true },
    ];

    const readOnlyResult = props.readonly
      || (!!props.stageInfo && props.stageInfo.status !== ExamStageStatus.Assignment)
      || applications.every(a => !a.canFinalize);

    return (
      <>
          <ExamAssigningApplicationsTable
            finalizing
            readonly={readOnlyResult}
            withCheckbox
            withConfirmButton
            onAssignedApplicants={handleAssignedApplicants}
            confirmButtonText="Финализировать результат для выбранных"
            headers={applicantHeaders}
            examApplications={applications}
          />

          {!!props.stageInfo && (
            <FinalizeResultModals
              stage={props.stageInfo}
              finalizeResult={props.finalizeResult}
              unratedExamApplications={props.unratedExamApplications}
              experts={props.experts}
              examId={props.examId}
              stageNumber={props.stageNumber}
              examDayId={props.examDayId}
              onCreateRetakeDay={props.onCreateRetakeDay}
              minAssigningExperts={props.minAssigningExperts}
              error={props.error}
              applicantsForRate={assigningApplicantsForReplace}
              setApplicantsForRate={setAssigningApplicantsForReplace}
              showFinalizingResultModal={showFinalizingResultModal}
              hideFinalizingResultModal={() => setShowFinalizingResultModal(false)}
              assignApplicationsForDay={props.assignApplicationsForDay}
            />
          )}
      </>
    );
};

interface IProps extends IFinalizeResultModalsProps {
    readonly?: boolean;
    examApplicationsForDay: IBaseExamApplicationsForDay;
    stageInfo?: IExamStage | null;
}

export default FinalizedExamApplicationsTab;
