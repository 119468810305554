/* eslint-disable arrow-parens */
import React from 'react';
import { CustomRadio } from 'components';
import styles from './CustomRadioGroup.module.scss';
import cn from 'classnames';

const CustomRadioGroup = <T extends string>(
  {
      radios,
      radiosGroupName,
      disabled,
      onChangeRadio,
      withoutFormDetect,
      containerClassName,
  }: IProps<T>,
) => {
    const [selectedOption, setSelectedOption] = React.useState(radios[0].option);

    React.useEffect(() => {
        if (onChangeRadio) {
            onChangeRadio(selectedOption);
        }
    }, [selectedOption]);

    return (
      <div className={cn(styles.container, containerClassName)}>
          {radios.map(radio => (
            <CustomRadio
              key={`radio_${radio.option}`}
              label={radio.label}
              name={radiosGroupName}
              className="mr-3"
              option={radio.option}
              checked={selectedOption === radio.option}
              onClick={() => setSelectedOption(radio.option)}
              disabled={disabled}
              withoutFormDetect={withoutFormDetect}
            />
          ))}
      </div>
    );
};

export default CustomRadioGroup;

interface IProps<T = string> {
    radios: IRadio<T>[];
    radiosGroupName: string;
    disabled?: boolean;
    withoutFormDetect?: boolean;
    containerClassName?: string;
    onChangeRadio: (selectedOption: IRadio<T>['option']) => void;
}

interface IRadio<T> {
    label: string;
    option: T;
}
