import React from 'react';
import Select, { OptionTypeBase, Styles } from 'react-select';
import styles from './FetchSelect.module.scss';
import cn from 'classnames';
import { formDetector } from '_helpers';
import './FetchSelectFixStyles.scss';

const FetchSelect = ({
    options,
    value,
    onChange,
    disabled,
    className,
    placeholder,
    name,
    isInvalid,
    invalidTitle,
    withoutDetectChanges,
    onFetch,
    clearFetch,
}: Props) => {
    const [typingTimer, setTypingTimer] = React.useState<NodeJS.Timeout | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [selectValue, setSelectValue] = React.useState<IOption | null | undefined>(options.find(x => x.value === value));
    const doneTypingInterval = 500;

    const onInputKeyup = (fieldValue: string) => {
        const fetchData = { fieldName: name, fieldValue };
        setInputValue(fieldValue);

        if (!fieldValue.length) {
            clearFetch();
        }

        if (typingTimer) {
            clearTimeout(typingTimer);
        }

        setTypingTimer(setTimeout(() => {
            handleInputChange(fetchData);
        }, doneTypingInterval));
    };

    const handleInputChange = (data: IFetchInput) => {
        if (data && data.fieldValue.length) {
            onFetch(data);
        }
    };

    const onInputKeydown = () => {
        if (typingTimer) {
            clearTimeout(typingTimer);
        }
    };

    const onOptionChange = (e: IOption) => {
        onChange({ name, value: e.value });

        if (!withoutDetectChanges) {
            formDetector.setChangedInput(name, e.value);
        }
    };

    const onMenuClose = () => {
        clearFetch();
        setSelectValue(null);
    };

    const createBoldLabel = (label: string) => {
        if (label.indexOf(inputValue) === -1) {
            const index = label.toLowerCase().indexOf(inputValue);

            if (index !== -1) {
                const arr = label.split('');

                const matchingLabelWord = label.slice(index, index + inputValue.length);
                arr.splice(index, inputValue.length, `<strong>${matchingLabelWord}</strong>`);
                return arr.join('');
            }
        }

        return label.replace(inputValue, `<strong>${inputValue}</strong>`);
    };

    const formatOptionLabel = (option: IOption) => <span dangerouslySetInnerHTML={{ __html: createBoldLabel(option.label) }} />;

    const customStyle: Styles<OptionTypeBase, false> = {
        control: base => ({
            ...base,
            border: isInvalid ? '1px solid red !important' : '',
        }),
    };

    return (
        <div className={cn(styles.container, className)} data-test-extended-select>
            <Select
              noOptionsMessage={() => null}
              isDisabled={disabled}
              styles={customStyle}
              name={name}
              className="fetch-select react-select-container"
              classNamePrefix="react-select"
              value={selectValue}
              onChange={e => onOptionChange(e as IOption)}
              options={options}
              placeholder={placeholder || 'Выберите из списка'}
              onInputChange={onInputKeyup}
              onKeyDown={onInputKeydown}
              onMenuClose={onMenuClose}
              formatOptionLabel={formatOptionLabel}
            />
            {isInvalid ? (
                <div className={`${styles.feedbackText} text-danger`}>
                    {invalidTitle || null}
                </div>
            ) : null}
        </div>
    );
};

export default FetchSelect;

export interface IOption {
    value: string;
    label: string;
}

export interface ISelectEvent {
    name: string;
    value: string;
}

interface IFetchInput {
    fieldName: string;
    fieldValue: string;
}

interface Props {
    name: string;
    options: IOption[];
    value?: string | null;
    onChange: (event: ISelectEvent) => void;
    onFetch: (data: IFetchInput) => void;
    clearFetch: () => void;

    className?: string;
    placeholder?: string;
    disabled?: boolean;

    isInvalid?: boolean;
    invalidTitle?: string;
    withoutDetectChanges?: boolean;
}
