import { useEffect, useState } from 'react';
import { Location } from 'history';

const useReloader = (location: Location) => {
    const [oldLocation, setOldLocation] = useState('');
    const [reload, setReload] = useState(false);

    useEffect(() => {
        setReload(false);
    }, [reload]);

    useEffect(() => {
        if (location.pathname !== oldLocation) {
            setOldLocation(location.pathname);
        } else {
            setReload(true);
        }
    }, [location]);

    return reload;
};

export default useReloader;
