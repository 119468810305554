import React from 'react';
import {
    Card,
    LoaderBtn,
    Captcha,
    PhoneInput,
} from 'components';
import { Col, Form, Button } from 'react-bootstrap';
import { IApiError } from '../../_helpers/ApiConnector/types';
import { Link } from 'react-router-dom';

const ResetPasswordFirstStep = ({
    sendCode, goNext, goBack, apiError, initPhone,
}: IProps) => {
    const [phone, setPhone] = React.useState(initPhone);
    const [phoneValid, setPhoneValid] = React.useState(true);
    const [captcha, setCaptcha] = React.useState('');
    const [captchaValid, setCaptchaValid] = React.useState(true);
    const [processing, setProcessing] = React.useState(false);

    const doValidate = (): boolean => {
        const isPhoneValid = /^[0-9]{10}$/.test(phone);
        const isCaptchaValid = apiError && apiError.failures && apiError.failures.captcha ? captcha.length === 4 : true;

        setPhoneValid(isPhoneValid);
        setCaptchaValid(isCaptchaValid);

        return isPhoneValid && isCaptchaValid;
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!doValidate()) return;

        setProcessing(true);
        const phoneExists = await sendCode(phone, captcha);
        setProcessing(false);

        if (phoneExists) {
            goNext();
        }
    };

    const onChangePhone = (name: string, value: string) => {
        setPhone(value);
        setPhoneValid(true);
    };

    return (
        <>
            <Col className="auth-forms--container">
                <form onSubmit={onSubmit}>
                    <Card
                      title="Сброс пароля"
                      ftTextCenter
                      legend={(
                            <>
                                <LoaderBtn disabled={processing} loading={processing} variant="primary" type="submit">
                                    Продолжить
                                </LoaderBtn>
                                <Button variant="outline-secondary" onClick={goBack}>
                                    Назад
                                </Button>
                            </>
                        )}
                    >
                        <>
                            <div className="sub-title">
                                для восстановления доступа в
                                личный кабинет
                            </div>

                            <PhoneInput
                              value={phone}
                              onChange={onChangePhone}
                              labelTitle="Укажите ваш номер телефона"
                              labelColSize={12}
                              inputColSize={12}
                              inputClassNames="p-0"
                              fieldFailures={{
                                    phone: !phoneValid
                                        ? 'Неверный формат телефона'
                                        : ((apiError && apiError.failures && apiError.failures.phone && apiError.failures.phone.join(',')) || ''),
                                }}
                            />
                            <Captcha captcha={captcha} captchaValid={captchaValid} apiError={apiError} setCaptcha={setCaptcha} />
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {apiError && !(apiError.failures && apiError.failures.phone) && apiError.message}
                            </Form.Control.Feedback>
                        </>
                    </Card>
                </form>
            </Col>
            <div className="register-link--container">
                Ещё нет аккаунта? <Link to="/register" className="register-link">Регистрация</Link>
            </div>
        </>
    );
};

export default ResetPasswordFirstStep;

interface IProps {
    apiError?: IApiError | null;
    initPhone: string;
    sendCode: (phone: string, captcha?: string) => Promise<IStatus | null>;
    goNext: () => void;
    goBack: () => void;
}

interface IStatus {
    wasSent?: boolean;
    waitingSeconds?: number;
}
