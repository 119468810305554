import React from 'react';
import { WarningModal } from 'components';
import { formDetector } from '_helpers';
import { RouteComponentProps, withRouter, Prompt } from 'react-router';
import * as H from 'history';

const UnsavedFormModal = ({ history, saveAndCloseDetectFunc }: Props) => {
    const [modalVisible, setModalVisible] = React.useState(false);
    const [lastLocation, setLastLocation] = React.useState<H.Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);

    React.useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            formDetector.resetDetectState();
            history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation]);

    const showModal = (location: H.Location) => {
        setModalVisible(true);
        setLastLocation(location);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleBlockedNavigation = (nextLocation: H.Location) => {
        if (!confirmedNavigation) {
            showModal(nextLocation);
            return false;
        }

        return true;
    };

    const handleConfirmNavigationClick = async () => {
        let res;
        if (saveAndCloseDetectFunc) {
            res = await saveAndCloseDetectFunc();
        }

        closeModal();

        if (lastLocation && (saveAndCloseDetectFunc ? res : true)) {
            setConfirmedNavigation(true);
        }
    };

    if (formDetector.getShowModal()) {
        window.onbeforeunload = () => true;
    } else {
        window.onbeforeunload = null;
    }

    return (
        <>
            <Prompt when={formDetector.getShowModal()} message={handleBlockedNavigation} />
            <WarningModal
              onHide={closeModal}
              onAccess={handleConfirmNavigationClick}
              title="Вы уверены что хотите покинуть страницу?"
              show={modalVisible}
              bodyClassNames="pb-0"
              accessBtnTittle={saveAndCloseDetectFunc ? 'Сохранить и выйти' : 'Покинуть'}
              closeBtnTittle="Остаться"
            >
                <span className="mb-4 d-block">У вас несохраненные изменения</span>
            </WarningModal>
        </>
    );
};

export default withRouter(UnsavedFormModal);

interface IProps {
    saveAndCloseDetectFunc?: () => Promise<boolean>;
}

type Props = RouteComponentProps & IProps;
