import React from 'react';
import {
    PhoneModal,
    LoaderBtn,
    Captcha,
    ResetPasswordModal,
    InputText,
    DatePicker,
    PhoneInput,
    VerifyEmailInput,
    SexRadios,
} from 'components';
import { Sex } from 'components/enums';
import { Button, Form } from 'react-bootstrap';
import { IApiError } from '../../_helpers/ApiConnector/types';
import { RegExpConst, RegExpErrorsText } from '_helpers';
import styles from './ProfileInfoBlock.module.scss';
import moment from 'moment';
import cn from 'classnames';
import _ from 'lodash';

const ProfileInfoBlock = ({
    profile,
    disabled,
    canChange,
    submitButtonText,
    cancelButtonText,
    onSubmit,
    onCancel,
    onTryChange,
    onSubmitPhone,
    onSubmitCode,
    onUpdatePhone,
    apiError,
    processing,
    hideChangeButtons,
    sendConfirmationEmail,
    onChangePassword,
    canChangeOnlyPhone,
    withoutBirthDate,
    withoutSex,
}: IProps) => {
    const initErrors: IInitErrors = {
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        email: '',
        birthDate: '',
        sex: '',
    };

    const [formData, setFormData] = React.useState<IProfileInfo>(profile);
    const [showModal, setShowModal] = React.useState(false);
    const [waitingSeconds, setWaitingSeconds] = React.useState<number>(0);
    const [captcha, setCaptcha] = React.useState('');
    const [captchaValid, setCaptchaValid] = React.useState(true);
    const [showResetPassModal, setShowResetPassModal] = React.useState(false);
    const [validErrors, setValidErrors] = React.useState(initErrors);
    const captchaError = apiError && apiError.failures && apiError.failures.captcha;

    React.useEffect(() => {
        setFormData(profile);
    }, [profile]);

    React.useEffect(() => {
        if (apiError && apiError.failures) {
            const errors = apiError.failures;

            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key) && errors[key]) {
                    const error = errors[key].join(',');
                    const newErrors = validErrors;
                    newErrors[key] = error;

                    setValidErrors({ ...newErrors });
                }
            }
        } else {
            setValidErrors(initErrors);
        }
    }, [apiError]);

    const checkFieldsErrors = (data: IProfileInfo) => _.mapValues(data, (val, key) => {
        const test = (field: string, regexp: RegExp) => (!regexp.test(field));

        if (val === '') return 'Обязательное поле для заполнения';
        if (key === 'birthDate' && !val) return 'Обязательное поле для заполнения';
        if ((key === 'firstName' || key === 'lastName' || key === 'middleName') && typeof val === 'string') {
            const error = test(val, RegExpConst.NAME);

            if (error) {
                return RegExpErrorsText.NAME;
            }

            return '';
        }
        if (key === 'email' && typeof val === 'string') {
            const error = test(val, RegExpConst.EMAIL);

            if (error) {
                return RegExpErrorsText.EMAIL;
            }

            return '';
        }

        return '';
    });

    const isValidFormData = (fieldsError: IInitErrors): boolean => _.values(fieldsError).every(e => e.length === 0);

    const doValidate = () => {
        const isCaptchaValid = captchaError ? captcha.length === 4 : true;

        setCaptchaValid(isCaptchaValid);
        return isCaptchaValid;
    };

    const sendEmailAgain = () => {
        if (!doValidate() || !sendConfirmationEmail) return;

        sendConfirmationEmail({ captcha });
    };

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = checkFieldsErrors(formData) as IInitErrors;
        setValidErrors(errors);

        if (onSubmit && canChange && isValidFormData(errors) && formData.birthDate) {
            onSubmit({
                ...formData,
                middleName: formData.middleName || '',
                birthDate: formData.birthDate,
                sex: formData.sex || Sex.Male,
            });
        }
    };

    const onSendCode = async (body: ICheckCode) => {
        if (onSubmitCode && onUpdatePhone) {
            const res = await onSubmitCode(body);

            if (res) {
                await onUpdatePhone(body);
                setShowModal(false);
            }

            return res;
        }

        return false;
    };

    const sendPhone = async (phone: string, password: string, phoneCaptcha?: string) => {
        if (onSubmitPhone) {
            const sendStatus = await onSubmitPhone({ phone, password, captcha: phoneCaptcha });

            if (sendStatus && sendStatus.waitingSeconds !== undefined) {
                setWaitingSeconds(sendStatus.waitingSeconds);
            }
        }
    };

    const onChangeInput = (name: string, value: string) => {
        setFormData({ ...formData, [name]: value });
        setValidErrors({ ...validErrors, [name]: '' });
    };

    const renderChangeButton = () => (disabled && !hideChangeButtons
        ? (<span className={styles.change_button} onClick={onTryChange}>Редактировать</span>)
        : null);

    const renderPhoneChild = () => (canChange ? (
        <>
            {(canChangeOnlyPhone || !disabled) && (
                <span
                  className={styles.change_button}
                  onClick={() => setShowModal(true)}
                >
                    Изменить номер
                </span>
            )}
            {renderChangeButton()}
        </>
    ) : null);

    return (
        <form onSubmit={submit}>
            <div className={styles.profile_data_container}>
                <InputText
                  name="lastName"
                  value={formData.lastName}
                  labelTitle="ФАМИЛИЯ"
                  labelColSize={2}
                  inputColSize={10}
                  disabled={disabled}
                  fieldFailures={validErrors}
                  inputGroupClassNames={disabled && !hideChangeButtons ? 'mb-4' : ''}
                  inputClassNames={styles.profile_data__value}
                  onChange={onChangeInput}
                  withDarkLabel
                >
                    {renderChangeButton()}
                </InputText>
                <InputText
                  name="firstName"
                  value={formData.firstName}
                  labelTitle="ИМЯ"
                  labelColSize={2}
                  inputColSize={10}
                  disabled={disabled}
                  fieldFailures={validErrors}
                  inputGroupClassNames={disabled && !hideChangeButtons ? 'mb-4' : ''}
                  inputClassNames={styles.profile_data__value}
                  onChange={onChangeInput}
                  withDarkLabel
                >
                    {renderChangeButton()}
                </InputText>
                <InputText
                  name="middleName"
                  value={formData.middleName || ''}
                  labelTitle="ОТЧЕСТВО"
                  labelColSize={2}
                  inputColSize={10}
                  disabled={disabled}
                  fieldFailures={validErrors}
                  inputGroupClassNames={disabled && !hideChangeButtons ? 'mb-4' : ''}
                  inputClassNames={styles.profile_data__value}
                  onChange={onChangeInput}
                  withDarkLabel
                >
                    {renderChangeButton()}
                </InputText>
                {!withoutBirthDate ? (
                    <div className={cn(
                        styles.field_container,
                        styles.marginRow,
                        disabled && !hideChangeButtons && styles.field_container_disabled,
                    )}
                    >
                        <div className={cn(styles.dateLabel, `col-${2} p-0`)}>ДАТА РОЖД.</div>
                        <div className={cn(styles.profile_data__value, 'col-sm-10')}>
                            <DatePicker
                              disabled={disabled}
                              initialDate={formData.birthDate}
                              onChange={(val, newMoment) => setFormData({ ...formData, birthDate: newMoment })}
                              isInvalid={validErrors.birthDate}
                            />
                            {renderChangeButton()}
                        </div>
                    </div>
                ) : null}

                {!withoutSex ? (
                    <SexRadios
                      sex={formData.sex || Sex.Male}
                      validErrors={validErrors}
                      disabled={disabled}
                      onClick={sex => setFormData({ ...formData, sex })}
                      className="p-0"
                    >
                        {renderChangeButton()}
                    </SexRadios>
                ) : null}

                <PhoneInput
                  value={formData.phone}
                  labelColSize={2}
                  inputColSize={10}
                  fieldFailures={validErrors}
                  inputGroupClassNames={disabled && !hideChangeButtons ? 'mb-4' : ''}
                  inputClassNames={styles.phone_form__container}
                  onChange={onChangeInput}
                  withDarkLabel
                  disabled
                >
                    {renderPhoneChild()}
                </PhoneInput>

                <VerifyEmailInput
                  value={formData.email}
                  labelColSize={2}
                  inputColSize={10}
                  disabled={disabled}
                  fieldFailures={validErrors}
                  inputGroupClassNames={disabled && !hideChangeButtons ? 'mb-4' : ''}
                  inputClassNames={styles.profile_data__value}
                  sendConfirmationEmail={canChange && !disabled && !profile.emailConfirmed ? sendEmailAgain : undefined}
                  onChange={onChangeInput}
                  lowRightIconPosition
                  withDarkLabel
                >
                    {canChange && !disabled && !profile.emailConfirmed ? (
                        <div className="mt-4">
                            <Captcha
                              captcha={captcha}
                              captchaValid={captchaValid}
                              apiError={apiError}
                              setCaptcha={setCaptcha}
                            />
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {captchaError && apiError && apiError.message}
                            </Form.Control.Feedback>
                        </div>
                    ) : null}

                    {renderChangeButton()}
                </VerifyEmailInput>

                {onChangePassword && (
                    <div className="offset-2">
                        <div className={styles.profile_data__title} />
                        <div className={styles.profile_data__value}>
                            <Button onClick={() => setShowResetPassModal(true)} variant="outline-secondary">Изменить пароль</Button>
                        </div>
                        <ResetPasswordModal
                          show={showResetPassModal}
                          onHide={() => setShowResetPassModal(false)}
                          apiError={apiError}
                          onChangePassword={onChangePassword}
                        />
                    </div>
                )}
            </div>

            {canChange && (
                <div className={cn(styles.profile__btnContainer, 'offset-2')}>
                    <LoaderBtn className={styles.saveBtn} loading={processing} type="submit">
                        {submitButtonText || 'Сохранить'}
                    </LoaderBtn>
                    {onCancel && (
                        <Button
                          variant="outline-secondary"
                          className={styles.cancelBtn}
                          onClick={() => {
                                setValidErrors(initErrors);
                                onCancel();
                            }}
                        >
                            {cancelButtonText || 'Отмена'}
                        </Button>
                    )}
                </div>
            )}

            <PhoneModal
              title="Изменение номера мобильного телефона"
              show={showModal}
              onSubmitPhone={sendPhone}
              onSubmitCode={async (body) => {
                    const res = await onSendCode(body);
                    if (res) {
                        setFormData({ ...formData, phone: body.phone });
                    }
                    return res;
                }}
              onHide={() => setShowModal(false)}
              waitingSeconds={waitingSeconds}
              error={apiError}
            />
        </form>
    );
};

export default ProfileInfoBlock;

export interface ICheckCode {
    phone: string;
    verificationCode: string;
    captcha?: string;
}

export interface ISubmit {
    phone: string;
    password: string;
    captcha?: string;
}

export interface IPhoneSendStatus {
    wasSent?: boolean;
    waitingSeconds?: number;
}

export interface IUpdateProfile {
    firstName: string;
    lastName: string;
    middleName: string;
    email: string;
    birthDate: moment.Moment;
    sex: Sex;
}

interface IProfileInfo {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    emailConfirmed: boolean;
    phone: string;
    sex?: Sex;
    birthDate?: moment.Moment;
    middleName?: string;
}

interface IProps {
    profile: IProfileInfo;
    disabled?: boolean;
    hideChangeButtons?: boolean;
    canChange?: boolean;
    canChangeOnlyPhone?: boolean;
    submitButtonText?: string;
    cancelButtonText?: string;
    apiError?: IApiError | null;
    processing?: boolean;
    withoutBirthDate?: boolean;
    withoutSex?: boolean;
    onTryChange?: () => void; // redirect to profile
    onSubmit?: (data: IUpdateProfile) => void;
    onSubmitPhone?: (body: ISubmit) => Promise<IPhoneSendStatus | null>;
    onSubmitCode?: (body: ICheckCode) => Promise<boolean | null>;
    sendConfirmationEmail?: (data: ISendConfirmationEmail) => Promise<boolean | null>;
    onCancel?: () => void;
    onUpdatePhone?: (body: ICheckCode) => void;
    onChangePassword?: (body: IChangePassword) => Promise<boolean | null>;
}

interface IChangePassword {
    newPassword: string;
    oldPassword?: string;
    captcha?: string;
}

interface ISendConfirmationEmail {
    captcha?: string;
}

interface IInitErrors {
    [key: string]: string;
}
