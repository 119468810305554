import React from 'react';
import {
    Card,
    ExamTable,
    MappedExamApplicationStatuses,
} from 'components';
import {
    ExamStatus,
    IApplicantExam,
    ExamReviewStatus,
} from '../../api/ApiClient';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Color, ColorStatus } from '_helpers';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { useEffectProcessing, useLocalErrorHandler, usePushFrom } from 'hooks';

const ExamApplicationsContainer = ({
    fetchExamApplications, setErrorHandler, examApplicationsList, apiError, onPush, clearExamApplicationList,
}: Props) => {
    useLocalErrorHandler(setErrorHandler);

    const [handleChooseExam] = usePushFrom('exam-application');

    const processing = useEffectProcessing(
      async () => {
          await fetchExamApplications({ examReviewStatus: ExamReviewStatus.All });
      },
      [],
      () => clearExamApplicationList(),
    );


    const checkApplicantStatus = (app: IApplicantExam) => statuses[app.examApplicationStatus || ''];

    const getFormatTableRows = (examApplicationsListItem: IApplicantExam) => ({
        ...examApplicationsListItem,
        id: examApplicationsListItem.examApplicationId,
        name: examApplicationsListItem.qualificationTitle,
        status: checkApplicantStatus(examApplicationsListItem),
        date: examApplicationsListItem.applicationSubmitDate,
    });

    const examApplications = (examApplicationsList && examApplicationsList.items) || [];
    const tableRows = examApplications.map(getFormatTableRows);

    return (
        <Card
          withoutPadding
          title="Мои заявки"
          category="Поданные заявки на прохождение аккредитации"
          apiError={apiError}
          showLoader={processing}
        >
            <ExamTable
              header={HEADER}
              tableRows={tableRows}
              onChooseExam={handleChooseExam}
              placeholderTitle="Нет поданных заявок"
              placeholderText="Здесь будут отображаться все поданные заявки на прохождение аккредитаций"
              placeholderButtonText="Выбрать аккредитацию"
              onClickPlaceholderButton={() => onPush('/exam-timetables')}
            />
        </Card>
    );
};

const HEADER = [
    'ДАТА И ВРЕМЯ ПОДАЧИ ЗАЯВКИ',
    'Специальность',
    'Статус',
];

const statuses = {
    ...MappedExamApplicationStatuses,
    [ExamStatus.IsApplying as string]: new ColorStatus(Color.Success, 'Идет запись'),
    [ExamStatus.ApplyingFinished as string]: new ColorStatus(Color.Orange, 'Запись окончена'),
};


const mapState = (state: IRootState) => ({
    examApplicationsList: state.examApplications.examApplicationsList,
    apiError: state.errors.error,
});

const mapDispatch = (dispatch: Dispatch) => ({
    fetchExamApplications: dispatch.examApplications.fetchExamApplicationsAsync,
    clearExamApplicationList: () => dispatch.examApplications.setExamApplicationList(null),
    setErrorHandler: dispatch.errors.setErrorHandler,
    onPush: (key: string) => dispatch(push(key)),
});

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
type Props = connectedProps;

export default connect(mapState, mapDispatch)(ExamApplicationsContainer);
