import React from 'react';
import { InputText, InputTextProps } from 'components';
import { formatPhoneNumber } from '_helpers';
import styles from './PhoneInput.module.scss';
import cn from 'classnames';

const PhoneInput = (props: IProps) => {
    const onChange = (name: string, value: string) => {
        const val = value.match(/\d+/g);

        props.onChange(name, val ? val.join('') : '');
    };

    return (
        <InputText
          {...props}
          value={props.disabled ? (formatPhoneNumber(props.value) || props.value) : props.value}
          onChange={onChange}
          inputClassNames={cn(styles.phone_form__container, props.inputClassNames)}
          name="phone"
          type="tel"
          labelTitle={props.labelTitle || 'ТЕЛЕФОН'}
          maxLength={10}
        >
            {!props.disabled ? (
                <span className={cn(
                    styles.phone_input__helper,
                    props.phoneHelperClassName,
                    props.fixTop && styles.phone_input__helper_fixTop,
                    props.fixLeft && styles.phone_input__helper_withPadding,
                )}
                >
                    +7
                </span>
            ) : null}
            {props.children}
        </InputText>
    );
};

export default PhoneInput;

interface IProps extends Omit<InputTextProps, 'name'> {
    phoneHelperClassName?: string;
    name?: string;
    fixTop?: boolean;
    fixLeft?: boolean;
}
