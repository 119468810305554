import React from 'react';
import { IRowRendererProps } from 'components';
import { rowDragSource, rowDropTarget } from 'components/DragDrop/drag-drop'; // !!! только напрямую работает
import dndIcon from 'components/assets/svg/dnd_icon.svg';
import deleteIcon from 'components/assets/svg/cancel_icon.svg';
import addIcon from 'components/assets/svg/add_icon.svg';
import styles from './EditableTable.module.scss';
import cn from 'classnames';

const RowRendererDrag = rowDragSource(rowDropTarget((props: Props) => {
    const {
        isOver,
        disabledMoving,
        gridLength,
        handleDeleteClick,
        connectDropTarget,
        connectDragPreview,
        connectDragSource,
        children,
        rowIndex,
    } = props;
    const className = isOver ? styles.drop_target : '';

    const renderDeleteButton = () => (!disabledMoving && gridLength - 1 > 0 ? (
        <td className={styles.delete_button}>
            <div className={styles.delete_row_btn} onClick={() => handleDeleteClick(rowIndex)}>
                <img src={deleteIcon} alt="deleteRow" />
            </div>
        </td>
    ) : null);

    return (
        <RowRendererNotDrag
          {...props}
          dragChild={(
                <>
                    {connectDropTarget && connectDragPreview && connectDragSource ? (
                        connectDropTarget(connectDragPreview(
                            <tr className={cn(className)}>
                                {connectDragSource(
                                    <td className={styles.dnd_button}>
                                        <img src={dndIcon} alt="dnd-btn" />
                                    </td>,
                                )}
                                {children}
                                {renderDeleteButton()}
                            </tr>,
                        ))) : null}
                </>
            )}
        />
    );
}));

const RowRendererNotDrag = ({
    headersLength,
    gridLength,
    disabledMoving,
    handleCLickRowAdd,
    handleDeleteClick,
    children,
    rowIndex,
    dragChild,
}: IProps) => {
    const renderAddRowBtn = () => (
        <tr className={styles.add_btn_row}>
            <td colSpan={headersLength + 1}>
                <div className={cn(styles.add_row_btn, 'mb-3')} onClick={handleCLickRowAdd}>
                    <img src={addIcon} alt="addRow" />
                    <p className="text-primary">Добавить строку</p>
                </div>
            </td>
        </tr>
    );

    const renderDeleteButton = () => (!disabledMoving && gridLength - 1 > 0 ? (
        <td>
            <div className={styles.delete_row_btn} onClick={() => handleDeleteClick(rowIndex)}>
                <img src={deleteIcon} alt="deleteRow" />
            </div>
        </td>
    ) : null);

    if (rowIndex === (gridLength - 1)
        && children
        && children[0]
        && children[0].props
        && children[0].props['data-not-move'] === true
    ) {
        return (
            <>
                {!disabledMoving && renderAddRowBtn()}
                <tr className={styles.total_row}>
                    {!disabledMoving && <td />}
                    {children}
                </tr>
            </>
        );
    }

    return (
        <>
            {dragChild || (
                <tr>
                    {children}
                    {renderDeleteButton()}
                </tr>
            )}
            {rowIndex === (gridLength - 1) && !disabledMoving && renderAddRowBtn()}
        </>
    );
};


const RowRenderer = (props: Props) => (!props.disabledMoving ? <RowRendererDrag {...props} /> : <RowRendererNotDrag {...props} />);

export default RowRenderer;

interface IProps {
    gridLength: number;
    headersLength: number;
    disabledMoving: boolean;
    rowIndex: number;
    children: any;
    dragChild?: React.ReactNode;
    handleDeleteClick: (row: number) => void;
    handleCLickRowAdd: () => void;
    onRowDrop: (from: number, to: number) => void;
}

type Props = IProps & IRowRendererProps;
