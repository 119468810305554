import React from 'react';
import {Card, DatePicker, ExtendedSelect, InputText, LoaderBtn, SexRadios, Textarea,} from 'components';
import {
    IApplicant,
    IApplicantIdentityDoc,
    IApplicantOtherIdentity,
    IApplicantPassport,
    IUpdateProfile,
    ICountry,
    IApplicantDiploma,
} from 'components/models';
import {
    Sex,
    IdentityDocType,
    IdentityDocTypeTitles,
    CitizenshipType,
    CitizenshipTypeTitles,
    MilitaryRelationshipType,
    MilitaryRelationshipTypeTitles,
    DiplomaType,
    DiplomaTypeTitles,
} from 'components/enums';
import {Button, Col, Form} from 'react-bootstrap';
import {IApiError} from '_helpers/ApiConnector/types';
import {RegExpConst, RegExpErrorsText} from '_helpers';
import identificationStyles from './IdentificationStyles.module.scss';
import profileStyles from './ProfileStyles.module.scss';
import moment from 'moment';
import cn from 'classnames';
import _ from 'lodash';

const IdentificationTab = ({
       identification,
       diploma,
       countries,
       profile,
       apiError,
       setApplicantPassport,
       setApplicantOtherIdentity,
       updateApplicantDiploma,
       contentClassName,
       onUpdateProfile,
       goBack,
       goNext,
   }: IProps) => {
    const passportDateOfIssueDifference = -30;
    const initIdentificationErrors: IInitIdentificationErrors = {
        identityDocType: '',
        serialNumber: '',
        issuedBy: '',
        dateOfIssue: '',
        registrationAddress: '',
        title: '',
        data: '',
    };

    const initProfileErrors: IInitProfileErrors = {
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        sex: '',
        email: '',
        birthDate: '',
        citizenshipType: '',
        citizenshipCountryId: '',
        citizenshipCountryName: '',
        militaryRelationship: '',
        snils: '',
    };

    const initDiplomaErrors: IApplicantDiplomaErrors = {
        type: '',
        serialNumber: '',
        issuedBy: '',
        dateOfIssue: '',
    }

    const initStatePassport: IInitApplicantPassport = {
        serialNumber: '',
        issuedBy: '',
        dateOfIssue: undefined,
        registrationAddress: '',
    };

    const initStateIdentification: IApplicantOtherIdentity = {
        data: '',
    };

    const initStateProfile: IInitProfile = {
        lastName: '',
        firstName: '',
        middleName: '',
        email: '',
        birthDate: undefined,
        sex: Sex.Male,
        citizenshipType: undefined,
        citizenshipCountryId: undefined,
        citizenshipCountryName: undefined,
        militaryRelationship: undefined,
        snils: '',
    };

    const initStateDiploma: IInitApplicantDiploma = {
        type: undefined,
        serialNumber: '',
        issuedBy: '',
        dateOfIssue: undefined,
    }

    // profile
    const [stateProfile, setStateProfile] = React.useState(initStateProfile);
    const [validProfileErrors, setValidProfileErrors] = React.useState(initProfileErrors);


    // identification
    const [identityDocType, setIdentityDocType] = React.useState(IdentityDocType.Passport);
    const [validIdentificationErrors, setIdentificationValidErrors] = React.useState(initIdentificationErrors);
    const [processing, setProcessing] = React.useState(false);

    const [statePassport, setStatePassport] = React.useState(initStatePassport);
    const [stateIdentification, setStateIdentification] = React.useState(initStateIdentification);

    // diploma
    const [stateDiploma, setStateDiploma] = React.useState(initStateDiploma);
    const [validDiplomaErrors, setValidDiplomaErrors] = React.useState(initDiplomaErrors);

    const docTypeOptions = Object.keys(IdentityDocTypeTitles).map(k => ({
        value: k,
        label: IdentityDocTypeTitles[k as keyof typeof IdentityDocTypeTitles],
    }));

    const citizenshipTypeOptions = Object.keys(CitizenshipTypeTitles).map(k => ({
        value: k,
        label: CitizenshipTypeTitles[k as keyof typeof CitizenshipTypeTitles],
    }));

    const militaryRelationshipTypeOptions = Object.keys(MilitaryRelationshipTypeTitles).map(k => ({
        value: k,
        label: MilitaryRelationshipTypeTitles[k as keyof typeof MilitaryRelationshipTypeTitles],
    }));

    const diplomaTypeOptions = Object.keys(DiplomaTypeTitles).map(k => ({
        value: k,
        label: DiplomaTypeTitles[k as keyof typeof DiplomaTypeTitles],
    }));

    React.useEffect(() => {
        setStateProfile({...profile, middleName: profile.middleName || ''});
    }, [profile]);

    React.useEffect(() => {
        if (identification && identification.passport) {
            setStatePassport(identification.passport);
            setStateIdentification(initStateIdentification);
            setIdentityDocType(IdentityDocType.Passport);
        }

        if (identification && identification.otherIdentity) {
            setStateIdentification(identification.otherIdentity);
            setStatePassport(initStatePassport);
            setIdentityDocType(identification.identityDocType);
        }
    }, [identification]);

    React.useEffect(() => {
        diploma && setStateDiploma({...diploma});
    }, [diploma]);

    React.useEffect(() => {
        if (apiError && apiError.failures) {
            const errors = apiError.failures;

            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key) && errors[key]) {
                    const error = errors[key].join(',');
                    const newIdentificationErrors = validIdentificationErrors;
                    const newProfileErrors = validProfileErrors;

                    newIdentificationErrors[key] = error;
                    newProfileErrors[key] = error;

                    setIdentificationValidErrors({ ...newIdentificationErrors });
                    setValidProfileErrors({ ...newProfileErrors });
                }
            }
        }
    }, [apiError]);

    const checkFieldsErrors = (submitData: IInitProfile) => _.mapValues(submitData, (val, key) => {
        if (val === '') return 'Обязательное поле для заполнения';

        // profile
        const test = (field: string, regexp: RegExp) => (!regexp.test(field));

        if (val === '' || val === undefined) return 'Обязательное поле для заполнения';
        if ((key === 'birthDate' || key === 'citizenshipType' || key === 'militaryRelationship') && !val) {
            return 'Обязательное поле для заполнения';
        }
        if (stateProfile.citizenshipType !== CitizenshipType.Russian && stateProfile.citizenshipType !== CitizenshipType.WithoutCitizenship && stateProfile.citizenshipType != null && key === 'citizenshipCountryId' && !val) {
            return 'Обязательное поле для заполнения';
        }
        
        if (typeof val !== 'string') return '';
        
        if (key === 'firstName' || key === 'lastName' || key === 'middleName') {
            const error = test(val, RegExpConst.NAME);

            if (error) {
                return RegExpErrorsText.NAME;
            }

            return '';
        }
        if (key === 'email') {
            const error = test(val, RegExpConst.EMAIL);

            if (error) {
                return RegExpErrorsText.EMAIL;
            }

            return '';
        }
        if (key === 'snils') {
            const error = test(val, RegExpConst.SNILS);

            if (error) {
                return RegExpErrorsText.SNILS;
            }

            return '';
        }
        return '';
    });

    const checkIdentificationErrors = (submitData: IInitApplicantPassport | IApplicantOtherIdentity) => _.mapValues(submitData, (val, key) => {
        if ((key === 'dateOfIssue' || key === 'type') && !val) return 'Обязательное поле для заполнения';
        if (val === '') return 'Обязательное поле для заполнения';
        return '';
    });

    const checkDiplomaErrors = (submitData: IInitApplicantDiploma) => _.mapValues(submitData, (val, key) => {
        if ((key === 'dateOfIssue' || key === 'type') && !val) return 'Обязательное поле для заполнения';
        if (val === '') return 'Обязательное поле для заполнения';
        return '';
    });

    const isValidFormData = (fieldsError: IInitIdentificationErrors | IInitProfileErrors): boolean => _.values(fieldsError).every(e => e.length === 0);

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let valid = {identification: false, profile: false, diploma: false};

        {
            const errors = checkFieldsErrors(stateProfile) as IInitProfileErrors;
            setValidProfileErrors(errors);
            valid.profile = !!(isValidFormData(errors) && stateProfile.birthDate);
        }
        
        if (identityDocType === IdentityDocType.Passport && statePassport) {
            const errors = checkIdentificationErrors(statePassport) as IInitIdentificationErrors;
            setIdentificationValidErrors(errors);
            valid.identification = !!(isValidFormData(errors) && statePassport.dateOfIssue);
            
        } else if (identityDocType !== IdentityDocType.Passport) {
            const errors = checkIdentificationErrors(stateIdentification) as IInitIdentificationErrors;
            setIdentificationValidErrors(errors);
            valid.identification = isValidFormData(errors);
        }
        
        {
            const errors = checkDiplomaErrors(stateDiploma) as IApplicantDiplomaErrors;
            const {dateOfIssue, type} = stateDiploma;
            setValidDiplomaErrors(errors);
            valid.diploma = !!(_.values(errors).every(e => e.length === 0) && dateOfIssue && type);
        }

        if (valid.identification && valid.profile && valid.diploma) {
            
            setProcessing(true);
            
            if (identityDocType === IdentityDocType.Passport && statePassport) {
                valid.identification = !!statePassport.dateOfIssue && await setApplicantPassport({...statePassport, dateOfIssue: statePassport.dateOfIssue});
            } else if (identityDocType !== IdentityDocType.Passport) {
                valid.identification = await setApplicantOtherIdentity(stateIdentification, identityDocType);
            }

            valid.diploma = valid.identification && !!(stateDiploma.dateOfIssue && stateDiploma.type) && 
                await updateApplicantDiploma({...stateDiploma, dateOfIssue: stateDiploma.dateOfIssue, type: stateDiploma.type});

            valid.profile = valid.diploma && !!stateProfile.birthDate && await onUpdateProfile({
                ...profile, ...stateProfile, birthDate: stateProfile.birthDate,
                citizenshipType: stateProfile.citizenshipType || CitizenshipType.Russian,
                militaryRelationship: stateProfile.militaryRelationship || MilitaryRelationshipType.Reservist
            });
            
            setProcessing(false);

            valid.profile && goNext();
        }
    };

    return (
        <Card
            title="Информация по документам"
            category="Укажите информацию, в соответствии с предоставляемыми документами"
            cardClassName="mb-0"
            contentClassName={contentClassName}
            apiError={apiError}
            withoutBorder
        >
            <Form onSubmit={submit}>
                <div className={profileStyles.profile_data_container}>
                    <InputText
                        name="lastName"
                        value={stateProfile.lastName}
                    labelTitle="ФАМИЛИЯ"
                    labelColSize={2}
                    inputColSize={10}
                    fieldFailures={validProfileErrors}
                    inputGroupClassNames="mb-4"
                    labelClassNames={profileStyles.profile_data__title}
                    inputClassNames={profileStyles.profile_data__value}
                    onChange={(name, value) => {
                        setStateProfile({ ...stateProfile, lastName: value });
                        setValidProfileErrors({ ...validProfileErrors, lastName: '' });
                    }}
                    withDarkLabel
                  />
                  <InputText
                    name="firstName"
                    value={stateProfile.firstName}
                    labelTitle="ИМЯ"
                    labelColSize={2}
                    inputColSize={10}
                    fieldFailures={validProfileErrors}
                    inputGroupClassNames="mb-4"
                    labelClassNames={profileStyles.profile_data__title}
                    inputClassNames={profileStyles.profile_data__value}
                    onChange={(name, value) => {
                        setStateProfile({ ...stateProfile, firstName: value });
                        setValidProfileErrors({ ...validProfileErrors, firstName: '' });
                    }}
                    withDarkLabel
                  />
                  <InputText
                      name="middleName"
                      value={stateProfile.middleName}
                      labelTitle="ОТЧЕСТВО"
                      labelColSize={2}
                      inputColSize={10}
                      fieldFailures={validProfileErrors}
                      inputGroupClassNames="mb-4"
                      labelClassNames={profileStyles.profile_data__title}
                      inputClassNames={profileStyles.profile_data__value}
                      onChange={(name, value) => {
                          setStateProfile({...stateProfile, middleName: value});
                          setValidProfileErrors({...validProfileErrors, middleName: ''});
                      }}
                      withDarkLabel
                   />
                   <SexRadios
                        className="p-0 mt-5"
                        sex={stateProfile.sex || Sex.Male}
                        validErrors={validProfileErrors}
                        onClick={sex => {
                            setStateProfile({...stateProfile, sex});
                            setValidProfileErrors({...validProfileErrors, sex: ''});
                        }}
                    >
                    </SexRadios>
                    <div className={cn(
                        profileStyles.field_container,
                        profileStyles.marginRow,
                    )}
                    >
                        <div className={cn(profileStyles.profile_data__title, `col-${2} p-0`)}>ДАТА РОЖДЕНИЯ</div>
                        <div className={cn(profileStyles.profile_data__value, 'col-sm-10')}>
                            <DatePicker
                                initialDate={stateProfile.birthDate}
                                onChange={(val, newMoment) => setStateProfile({...stateProfile, birthDate: newMoment})}
                          />
                      </div>
                  </div>
              </div>

              <Form.Group className={cn('row', identificationStyles.align_center, 'mt-5')}>
                  <Col sm="2" className="p-0">
                      <Form.Label column className={identificationStyles.fontStretchCondensed}>
                        УДОСТОВЕРЕНИЕ ЛИЧНОСТИ
                      </Form.Label>
                  </Col>
                  <Col sm="10" className={cn('p-0', identificationStyles.col)}>
                      <ExtendedSelect
                          name="identityDocType"
                          value={identityDocType}
                          options={docTypeOptions}
                          onChange={(e) => {
                              setIdentityDocType(e.value as IdentityDocType);
                              setIdentificationValidErrors(initIdentificationErrors);
                        }}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                      >
                          {validIdentificationErrors.identityDocType}
                      </Form.Control.Feedback>
                  </Col>
              </Form.Group>

                {identityDocType === IdentityDocType.Passport && statePassport ? (
                    <>
                     <InputText
                            name="serialNumber"
                            value={statePassport.serialNumber}
                            onChange={(name, value) => {
                                setStatePassport({...statePassport, serialNumber: value});
                                setIdentificationValidErrors({...validIdentificationErrors, serialNumber: ''});
                            }}
                            labelTitle="СЕРИЯ/НОМЕР"
                            labelColSize={2}
                      inputColSize={10}
                      fieldFailures={validIdentificationErrors}
                      labelClassNames={profileStyles.profile_data__title}
                      inputClassNames={profileStyles.profile_data__value}
                      withDarkLabel
                    />
                    <Form.Group className="row">
                        <Col sm="2" className="p-0">
                            <Form.Label column className="pt-2">
                                КЕМ ВЫДАН
                            </Form.Label>
                        </Col>
                        <Col sm="10" className={cn('p-0', identificationStyles.col)}>
                            <Textarea
                              name="issuedBy"
                              validErrors={validIdentificationErrors}
                              value={statePassport.issuedBy}
                              onChange={(issuedBy) => {
                                  setStatePassport({ ...statePassport, issuedBy });
                                  setIdentificationValidErrors({ ...validIdentificationErrors, issuedBy: '' });
                              }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group className={`row ${identificationStyles.align_center}`}>
                        <Col sm="2" className="p-0">
                            <Form.Label column>
                                ДАТА ВЫДАЧИ
                            </Form.Label>
                        </Col>
                        <Col sm="10" className={cn('p-0', identificationStyles.col)}>
                            <DatePicker
                              from={passportDateOfIssueDifference}
                              to={0}
                              onChange={(val, momentDate) => {
                                  setStatePassport({ ...statePassport, dateOfIssue: momentDate });
                                  setIdentificationValidErrors({ ...validIdentificationErrors, dateOfIssue: '' });
                              }}
                              initialDate={statePassport.dateOfIssue}
                              isInvalid={validIdentificationErrors.dateOfIssue}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group className="row">
                        <Col sm="2" className="p-0">
                            <Form.Label column className="pt-2">
                                АДРЕС РЕГИСТРАЦИИ
                            </Form.Label>
                        </Col>
                        <Col sm="10" className={cn('p-0', identificationStyles.col)}>
                            <Textarea
                              name="registrationAddress"
                              validErrors={validIdentificationErrors}
                              value={statePassport.registrationAddress}
                              onChange={(registrationAddress) => {
                                  setStatePassport({ ...statePassport, registrationAddress });
                                  setIdentificationValidErrors({
                                      ...validIdentificationErrors,
                                      registrationAddress: '',
                                  });
                              }}
                            />
                        </Col>
                    </Form.Group>
                </>
              ) : (
                <>
                    <Form.Group className="row">
                        <Col sm="2" className="p-0">
                            <Form.Label column className="pt-2">
                                ДАННЫЕ ДОКУМЕНТА
                            </Form.Label>
                        </Col>

                        <Col sm="6" className={cn('p-0', identificationStyles.col)}>
                            <Textarea
                              name="data"
                              placeholder="Укажите полную информацию о документе, включая серию/номер, дату выдачи, кем выдан итп."
                              validErrors={validIdentificationErrors}
                              value={stateIdentification.data}
                              onChange={(data) => {
                                  setStateIdentification({...stateIdentification, data});
                                  setIdentificationValidErrors({...validIdentificationErrors, data: ''});
                              }}
                            />
                        </Col>

                    </Form.Group>
                </>
                )}

                <Form.Group className={cn('row', identificationStyles.align_center, 'mt-5')}>
                    <Col sm="2" className="p-0">
                        <Form.Label column>
                            ТИП ГРАЖДАНСТВА
                        </Form.Label>
                    </Col>
                    <Col sm="10" className={cn('p-0', identificationStyles.col)}>
                        <ExtendedSelect
                            name="citizenshipType"
                            value={stateProfile.citizenshipType}
                            options={citizenshipTypeOptions}
                            onChange={(e) => {
                                setStateProfile({...stateProfile, citizenshipType: e.value as CitizenshipType});
                                setValidProfileErrors({...validProfileErrors, citizenshipType: ''});
                            }}
                        />
                        <Form.Control.Feedback type="invalid" className='d-block'>
                            {validProfileErrors.citizenshipType}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                {(stateProfile.citizenshipType !== CitizenshipType.Russian && stateProfile.citizenshipType !== CitizenshipType.WithoutCitizenship && stateProfile.citizenshipType != null) && (
                    <Form.Group className={cn('row', identificationStyles.align_center)}>
                        <Col sm="2" className="p-0">
                            <Form.Label column>
                                ГРАЖДАНСТВО
                            </Form.Label>
                        </Col>
                        <Col sm="10" className={cn('p-0', identificationStyles.col)}>
                            <ExtendedSelect
                                name="citizenshipCountryId"
                                value={String(stateProfile.citizenshipCountryId)}
                                options={countries ? countries.map(r => ({
                                    value: String(r.id),
                                    label: r.name,
                                })) : []}
                                onChange={(e) => {
                                    setStateProfile({...stateProfile, citizenshipCountryId: Number(e.value)});
                                    setValidProfileErrors({...validProfileErrors, citizenshipCountryId: ''});
                                }}
                            />
                            <Form.Control.Feedback type="invalid" className='d-block'>
                                {validProfileErrors.citizenshipCountryId}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                )}

                <Form.Group className={cn('row', identificationStyles.align_center, 'mt-5')}>
                    <Col sm="2" className="p-0">
                        <Form.Label column>
                            ВОИНСКАЯ СЛУЖБА
                        </Form.Label>
                    </Col>
                    <Col sm="10" className={cn('p-0', identificationStyles.col)}>
                        <ExtendedSelect
                            name="militaryRelationship"
                            value={stateProfile.militaryRelationship}
                            options={militaryRelationshipTypeOptions}
                            onChange={(e) => {
                                setStateProfile({
                                    ...stateProfile,
                                    militaryRelationship: e.value as MilitaryRelationshipType
                                });
                                setValidProfileErrors({...validProfileErrors, militaryRelationship: ''});
                            }}
                        />
                        <Form.Control.Feedback type="invalid" className='d-block'>
                            {validProfileErrors.militaryRelationship}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>



                <Form.Group className={cn('row', identificationStyles.align_center, 'mt-5')}>
                    <Col sm="2" className="p-0">
                        <Form.Label column className={identificationStyles.fontStretchCondensed}>
                            ДОКУМЕНТ ОБ ОБРАЗОВАНИИ
                        </Form.Label>
                    </Col>
                    <Col sm="10" className={cn('p-0', identificationStyles.col)}>
                        <ExtendedSelect
                            name="diplomaType"
                            value={stateDiploma.type}
                            options={diplomaTypeOptions}
                            onChange={(e) => {
                                setStateDiploma({
                                    ...stateDiploma,
                                    type: e.value as DiplomaType
                                });
                                setValidDiplomaErrors({...validDiplomaErrors, type: ''});
                            }}
                        />
                        <Form.Control.Feedback type="invalid" className='d-block'>
                            {validDiplomaErrors.type}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <InputText
                    name="serialNumber"
                    value={stateDiploma.serialNumber}
                    onChange={(name, value) => {
                        setStateDiploma({...stateDiploma, serialNumber: value});
                        setValidDiplomaErrors({...validDiplomaErrors, serialNumber: ''});
                    }}
                    labelTitle="НОМЕР ДОКУМЕНТА"
                    labelColSize={2}
                    inputColSize={10}
                    fieldFailures={validDiplomaErrors}
                    inputGroupClassNames="mb-4"
                    labelClassNames={profileStyles.profile_data__title}
                    inputClassNames={profileStyles.profile_data__value}
                    withDarkLabel
                />
                <Form.Group className="row">
                    <Col sm="2" className="p-0">
                        <Form.Label column className="pt-2">
                            КЕМ ВЫДАН
                        </Form.Label>
                    </Col>
                    <Col sm="10" className={cn('p-0', identificationStyles.col)}>
                        <Textarea
                            name="issuedBy"
                            validErrors={validDiplomaErrors}
                            value={stateDiploma.issuedBy}
                            onChange={(issuedBy) => {
                                setStateDiploma({ ...stateDiploma, issuedBy });
                                setValidDiplomaErrors({ ...validDiplomaErrors, issuedBy: '' });
                            }}
                        />
                    </Col>
                </Form.Group>
                <Form.Group className={`row ${identificationStyles.align_center}`}>
                    <Col sm="2" className="p-0">
                        <Form.Label column>
                            ДАТА ВЫДАЧИ
                        </Form.Label>
                    </Col>
                    <Col sm="10" className={cn('p-0', identificationStyles.col)}>
                        <DatePicker
                            from={passportDateOfIssueDifference}
                            to={0}
                            onChange={(val, momentDate) => {
                                setStateDiploma({ ...stateDiploma, dateOfIssue: momentDate });
                                setValidDiplomaErrors({ ...validDiplomaErrors, dateOfIssue: '' });
                            }}
                            initialDate={stateDiploma.dateOfIssue}
                            isInvalid={validDiplomaErrors.dateOfIssue}
                        />
                    </Col>
                </Form.Group>
                
                

                <InputText
                    name="snils"
                    value={stateProfile.snils}
                    labelTitle="СНИЛС"
                    labelColSize={2}
                    inputColSize={10}
                    fieldFailures={validProfileErrors}
                    inputGroupClassNames="mb-4 mt-5"
                    labelClassNames={profileStyles.profile_data__title}
                    inputClassNames={profileStyles.profile_data__value}
                    onChange={(name, value) => {
                        setStateProfile({...stateProfile, snils: value});
                        setValidProfileErrors({...validProfileErrors, snils: ''});
                    }}
                    withDarkLabel
                    maxLength={11}
                />

                <Form.Group className="row">
                    <Col sm={{offset: 2}} className={identificationStyles.buttonsContainer}>
                        <LoaderBtn loading={processing} disabled={processing} className="d-block" type="submit">
                            Следующий шаг
                        </LoaderBtn>
                        <Button className="ml-5" onClick={goBack} variant="outline-secondary">Отмена</Button>
                    </Col>
                </Form.Group>
            </Form>
      </Card>
    );
};

export default IdentificationTab;

interface IProps {
    identification: IApplicantIdentityDoc;
    diploma?: IApplicantDiploma | null;
    countries: ICountry[] | null;
    profile: IApplicant;
    apiError?: IApiError | null;
    contentClassName?: string;
    goNext: () => void;
    goBack: () => void;
    setApplicantPassport: (data: IApplicantPassport) => Promise<boolean>;
    updateApplicantDiploma: (data: IApplicantDiploma) => Promise<boolean>;
    setApplicantOtherIdentity: (data: IApplicantOtherIdentity, type: IdentityDocType) => Promise<boolean>;
    onUpdateProfile: (data: IUpdateProfile) => Promise<boolean>;
}

interface IInitProfileErrors {
    firstName: string;
    lastName: string;
    middleName: string;
    sex: string;
    email: string;
    birthDate: string;
    citizenshipType: string;
    citizenshipCountryId: string;
    citizenshipCountryName: string;
    militaryRelationship: string;
    snils: string;

    [key: string]: string;
}

interface IInitApplicantPassport extends Omit<IApplicantPassport, 'dateOfIssue'> {
    dateOfIssue?: moment.Moment;
}

interface IInitProfile {
    lastName: string;
    firstName: string;
    middleName: string;
    email: string;
    birthDate: moment.Moment | undefined;
    sex: Sex;
    citizenshipType?: CitizenshipType;
    citizenshipCountryId?: number | undefined;
    citizenshipCountryName?: string | undefined;
    militaryRelationship?: MilitaryRelationshipType;
    snils: string;
}

export interface IInitApplicantDiploma {
    type: DiplomaType | undefined;
    serialNumber: string;
    issuedBy: string;
    dateOfIssue: moment.Moment | undefined;
}

export interface IApplicantDiplomaErrors {
    type: string
    serialNumber: string;
    issuedBy: string;
    dateOfIssue: string;

    [key: string]: string;
}


interface IInitIdentificationErrors {
    identityDocType: string;
    serialNumber: string;
    issuedBy: string;
    dateOfIssue: string;
    registrationAddress: string;
    title: string;
    data: string;

    [key: string]: string;
}
