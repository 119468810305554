import * as React from 'react';
import moment, { Moment } from 'moment';
import {
    IApiError,
    IBaseExperts, IBaseExpertsForDay, IExpert,
} from 'components/models';
import { useEffect } from 'react';
import { WarningModal } from 'components';
import { formatFIO } from '_helpers';
import { Button, Form } from 'react-bootstrap';
import { DayEditor } from '../../../../DayEditor';
import AssigningExpertsModal from './AssigningExpertsModal';

const CreateDayModals: React.FC<IProps> = (props) => {
    const generateTime = React.useCallback(
      (d: Moment) => d.clone().utc().minute(Math.round(moment().minute() / 5) * 5).add(1, 'day'),
      [],
    );
    const [selectedDate, setSelectedDate] = React.useState(generateTime(props.defaultDate || moment().local()).local());

    const [showAssigningExpertsModal, setShowAssigningExpertsModal] = React.useState(false);


    const [assignedExperts, setAssignedExperts] = React.useState<IExpert[]>([]);

    const handleChooseExperts = async (experts: IExpert[]) => {
        setAssignedExperts(experts);
        setShowAssigningExpertsModal(false);
    };

    const handleCreateDay = async () => {
        if (await props.onCreateDay(assignedExperts.map(e => e.id), selectedDate)) {
            props.onHide();
            setAssignedExperts([]);
        }
    };

    useEffect(() => {
        setSelectedDate(generateTime(props.defaultDate || moment()).local());
    }, [props.show]);
    return (
      <>
          <WarningModal
            withoutOverflow
            withoutTitlePadding
            title="Создание нового дня сдачи"
            show={props.show}
            onAccess={() => {
                if (assignedExperts.length) {
                    handleCreateDay();
                } else {
                    setShowAssigningExpertsModal(true);
                }
            }}
            accessBtnTittle={assignedExperts.length ? 'Создать' : 'Сохранить и продолжить'}
            onHide={() => {
                props.onHide();
                setAssignedExperts([]);
            }}
            closeBtnTittle="Отмена"
            loading={props.createDayLoading}
          >
              <p>
                  Пожалуйста, выберите дату, на которую необходимо назначить новый день сдачи.
              </p>
              <DayEditor
                date={selectedDate}
                onChangeDate={setSelectedDate}
                apiError={props.apiErrors && props.apiErrors.failures && props.apiErrors.failures.time && props.apiErrors.failures.time[0]}
              >
                  {!!assignedExperts.length && (
                    <>
                        <span className="align-self-start">Комиссия</span>
                        <p style={{ whiteSpace: 'pre' }} className="mb-0">
                            {assignedExperts.map(e => `${formatFIO(e)}\n`)}
                        </p>

                        <span />
                        <Button onClick={() => setShowAssigningExpertsModal(true)} variant="outline-secondary">
                            Редактировать список
                        </Button>

                        {props.apiErrors && props.apiErrors.failures && props.apiErrors.failures.expertIds && (
                          <Form.Control.Feedback
                            className="d-block"
                            type="invalid"
                            style={{ gridColumn: '1/3', width: 400 }}
                          >
                              <p>
                                  {props.apiErrors.failures.expertIds[0]}
                              </p>
                          </Form.Control.Feedback>
                        )}
                    </>
                  )}
              </DayEditor>
          </WarningModal>

          <AssigningExpertsModal
            show={showAssigningExpertsModal}
            onHide={() => setShowAssigningExpertsModal(false)}
            time={selectedDate}
            onUpdateExperts={handleChooseExperts}
            minAssigningExperts={props.minAssigningExperts}
            expertsForDayList={props.expertsForDayList}
            experts={props.experts}
            apiErrors={props.apiErrors}
          />
      </>
    );
};

interface IProps {
    defaultDate?: Moment;
    show: boolean;
    onHide: () => void;

    onCreateDay: (expertsIds: string[], time: Moment) => Promise<unknown>;
    createDayLoading?: boolean;
    minAssigningExperts: number;

    experts: IBaseExperts;
    expertsForDayList: IBaseExpertsForDay;
    apiErrors: IApiError | null;
}

export default CreateDayModals;
