import React, { FunctionComponent } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';

interface IProps {
    value: number;
    dark?: boolean;
    className?: string;
}

const NotificationBundle: FunctionComponent<IProps> = props => (
        <span
          className={cn(styles.notification, props.className, { [styles.dark_style]: props.dark, [styles.light_style]: !props.dark })}
        >
            {props.value}
        </span>
    );

export default NotificationBundle;
