import React, { useEffect } from 'react';
import {
    ToastProvider,
    ToastProps,
    ToastProviderProps,
    DefaultToast,
    useToasts,
} from 'react-toast-notifications';

const NotificationSystem = ({ notifications, cleanNotifications }: IProps) => {
    const { addToast } = useToasts();

    useEffect(() => {
        if (notifications.length > 0) {
            notifications.map(sendNotification);
        }
        return () => {
            cleanNotifications();
        };
    }, [notifications.length]);

    const sendNotification = (notification: INotification) => {
        if (notification.message || notification.children) {
            addToast(notification.children || notification.message, {
                appearance: notification.appearance || 'info',
                autoDismiss: notification.autoDismiss !== undefined ? notification.autoDismiss : true,
            });
        }
    };

    return null;
};

const Toast = (props: IToast) => (
    <div className={props.className}>
        <DefaultToast {...props} />
    </div>
);

const NotificationSystemProvider = (props: IProps) => (
    <ToastProvider
      placement={props.placement || 'top-right'}
      components={{ Toast: toastProps => <Toast {...toastProps} className={props.className} /> }}
      {...props}
    >
        <NotificationSystem {...props} />
    </ToastProvider>
);

export default NotificationSystemProvider;

interface IProps extends Omit<ToastProviderProps, 'children'> {
    notifications: INotification[];
    className?: string;
    cleanNotifications: () => void;
}

interface IToast extends ToastProps {
    className?: string;
}

export interface INotification {
    message: string | undefined;
    appearance: ToastProps['appearance'];
    children?: ToastProps['children'];
    autoDismiss?: boolean;
}
