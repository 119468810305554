import React from 'react';
import { ExtendedSelect } from 'components';
import { IMatchingTheoreticalAnswer, IFieldsError } from './types';
import { serialNumberToChar } from '_helpers';
import styles from './TheoreticalQuestionRedactor.module.scss';
import cn from 'classnames';

const QuestionSelect = ({
    correct, answer, num, withoutNum, selectContainerClassName, right, formFieldErrors, setFormFieldErrors, onChangeSelect,
}: IProps) => {
    const getSelectedMatching = (questionNumber: number) => {
        const selectedMatching = Object.keys(correct).find(key => correct[key] === questionNumber);
        return selectedMatching;
    };

    const getNotSelectedAnswers = () => {
        const notSelectedAnswers = right.filter(ans => !getSelectedMatching(ans.number));

        return notSelectedAnswers;
    };

    const getCurrentAnswer = (): string => {
        const matchingNumber = correct[answer.number];

        if (!matchingNumber) return '0';
        return matchingNumber.toString();
    };

    const onChange = (value: string) => {
        setFormFieldErrors({ ...formFieldErrors, [`correct${answer.number}`]: '' });
        onChangeSelect({ questionNumber: answer.number, matchingNumber: parseInt(value) });
    };

    const options = [
        { value: '0', label: '-' },
        ...right.map(x => ({ value: `${x.number}`, label: serialNumberToChar(x.number) })),
    ];

    const notSelectedOptions = [
        { value: '0', label: '-' },
        ...getNotSelectedAnswers().map(x => ({ value: `${x.number}`, label: serialNumberToChar(x.number) })),
    ];

    return (
        <div className={cn(styles.answer, selectContainerClassName)}>
            {!withoutNum ? <div>{num + 1}.</div> : <span>→</span>}
            <ExtendedSelect
              className={cn(styles.answer_select, 'ml-3')}
              name="answer"
              value={getCurrentAnswer()}
              options={options}
              notSelectedOptions={notSelectedOptions}
              onChange={e => onChange(e.value)}
              isInvalid={getCurrentAnswer() === '0'}
            />
        </div>
    );
};

export default QuestionSelect;

interface IProps {
    correct: { [key: string]: number };
    answer: IMatchingTheoreticalAnswer;
    num: number;
    formFieldErrors: IFieldsError;
    right: IMatchingTheoreticalAnswer[];
    withoutNum?: boolean;
    selectContainerClassName?: string;
    setFormFieldErrors: (error: IFieldsError) => void;
    onChangeSelect: (data: IChangeSelect) => void;
}

export interface IChangeSelect {
    questionNumber: number;
    matchingNumber: number;
}
