import React from 'react';
import { IApiError } from '../../_helpers/ApiConnector/types';
import {
    Card,
    InputPassword,
    LoaderBtn,
    Captcha,
} from 'components';
import {
    Col,
    FormGroup,
    FormLabel,
    Form,
} from 'react-bootstrap';

const ResetPasswordThirdStep = ({ apiError, authProcessing, resetPassword }: IProps) => {
    const [password, setPassword] = React.useState('');
    const [passwordValid, setPasswordValid] = React.useState(true);
    const [captcha, setCaptcha] = React.useState('');
    const [captchaValid, setCaptchaValid] = React.useState(true);

    const doValidate = (): boolean => {
        const isCaptchaValid = apiError && apiError.failures && apiError.failures.captcha ? captcha.length === 4 : true;
        const isPasswordValid = password.length >= 6;

        setCaptchaValid(isCaptchaValid);
        setPasswordValid(isPasswordValid);

        return isCaptchaValid && isPasswordValid;
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!doValidate()) return;

        await resetPassword(password, captcha);
    };

    return (
        <>
            <Col className="auth-forms--container">
                <Form onSubmit={onSubmit}>
                    <Card
                      title="Сброс пароля"
                      ftTextCenter
                      legend={(
                            <>
                                <Form.Control.Feedback className="d-block" type="invalid">
                                    {apiError && apiError.message}
                                </Form.Control.Feedback>
                                <LoaderBtn
                                  variant="primary"
                                  type="submit"
                                  disabled={authProcessing}
                                  loading={authProcessing}
                                >
                                    Установить пароль
                                </LoaderBtn>
                            </>
                        )}
                    >
                        <div className="sub-title">
                            Придумайте новый пароль длиной не менее 6 символов.
                        </div>

                        <FormGroup data-test-new-password-group>
                            <FormLabel>Пароль</FormLabel>
                            <InputPassword
                              autoFocus
                              name="newPassword"
                              onChange={(e: React.FormEvent<any>) => setPassword((e.target as HTMLInputElement).value)}
                              isInvalid={!passwordValid}
                            />
                            {!passwordValid && (
                                <Form.Control.Feedback className="d-block" type="invalid">
                                    Минимальная длина пароля должна быть не менее 6-ти символов
                                </Form.Control.Feedback>
                            )}
                        </FormGroup>
                        <Captcha captcha={captcha} captchaValid={captchaValid} apiError={apiError} setCaptcha={setCaptcha} />
                    </Card>
                </Form>
            </Col>
        </>
    );
};

export default ResetPasswordThirdStep;

interface IProps {
    apiError?: IApiError | null;
    authProcessing: boolean;
    resetPassword: (password: string, captcha?: string) => void;
}
