import { Color } from './const/Colors';

export class ColorStatus implements ColorStatus {
    public color: Color;

    public text: string;

    public subStatus?: {
        color: Color;
        text: string;
    };

    public constructor(color: Color, text: string, subText?: ColorStatus['subStatus']) {
        this.color = color;
        this.text = text;
        this.subStatus = subText;
    }
}
