import React from 'react';
import {
    Card,
    Loader,
    AlertText,
    StatementTab,
    SmsSignModal,
    ExamApplicationStatus,
} from 'components';
import  { IApplicantPassport, IApplicantOtherIdentity, ICountry, IApplicantDiploma } from 'components/models';
import { IdentityDocType } from 'components/enums';
import { ISubmitExamApplication, VerificationCodeSendingStatus } from '../../api/ApiClient';
import { IUpdateProfile, IExamApplicationDetails } from 'components/models';
import { IdentificationTab } from './IdentificationTab';
import { DocumentsTab, IDocumentsTabProps } from './DocumentsTab';
import { IApiError } from '_helpers/ApiConnector/types';
import identificationIcon from 'components/assets/svg/identification_icon.svg';
import docsIcon from 'components/assets/svg/documents_icon.svg';
import sendIcon from './icons/send_icon.svg';
import styles from './RegistrationForExam.module.scss';
import cn from 'classnames';


const RegistrationForExam = ({
    apiError,
    processing,
    documentsProps,
    goBack,
    sendingProps,
    setApplicantOtherIdentity,
    setApplicantPassport,
    onUpdateProfile,
    examApplication,
    countries,
    updateApplicantDiploma,
}: IProps) => {
    const { sendCodeToSignApplication, createExamApplication, waitingSeconds } = sendingProps;

    const profile = React.useMemo(() => (examApplication ? examApplication.applicant : null), [examApplication]);
    const applicantIdentityDoc = React.useMemo(() => (examApplication ? examApplication.applicantIdentityDoc : null), [examApplication]);
    const applicantDiploma = React.useMemo(() => (examApplication ? examApplication.applicantDiploma : null), [examApplication]);
    const correctionsRequired = React.useMemo(() => (examApplication ? examApplication.status === ExamApplicationStatus.CorrectionsRequired : false), [examApplication]);
    const reviewComment = React.useMemo(() => (examApplication ? examApplication.reviewComment : undefined), [examApplication]);
    const exam = React.useMemo(() => (examApplication ? examApplication.exam : null), [examApplication]);
    const title = exam ? `: «${exam.qualificationTitle}»` : '';

    const [showModal, setShowModal] = React.useState(false);
    
    const [professionalRetraining, setProfessionalRetraining] = React.useState(examApplication ? examApplication.professionalRetraining : undefined);
    const [startStage, setStartStage] = React.useState(examApplication ? examApplication.startStage : undefined);
    
    const [activeTab, setActiveTab] = React.useState(correctionsRequired ? TabKey.Documents : TabKey.Identification);
    //const [activeTab, setActiveTab] = React.useState(TabKey.Sending);
    
    const onSubmitSmsModal = async (signCode: string, captcha: string) => {
        const res = await sendCodeToSignApplication(captcha);
        
        if (res && startStage && professionalRetraining !== undefined) {
            const created = await createExamApplication({
                signCode,
                captcha,
                startStage,
                professionalRetraining,
            });

            if (created) {
                goBack();
            }
        }
    };

    const handleOpenSignApplication = () => {
        setShowModal(true);
        if (!waitingSeconds) {
            sendCodeToSignApplication('');
        }
    };

    return (
        <Card
          title={`Подача заявки${title}`}
          showLoader={processing}
          withoutPadding
          smallTitle
        >
            <div className={styles.tabContainer}>
                <div className={cn(styles.tabContainer_tab, styles.tabContainer_tab_complete)}>
                    <img src={identificationIcon} alt="identificationIcon" />
                    <span className="pl-2">Информация по документам</span>
                </div>
                <div className={cn(styles.tabContainer_tab, (activeTab === TabKey.Documents || activeTab === TabKey.Sending) && styles.tabContainer_tab_complete)}>
                    <img src={docsIcon} alt="docsIcon" />
                    <span className="pl-2">Сканы документов</span>
                </div>
                <div className={cn(styles.tabContainer_tab, activeTab === TabKey.Sending && styles.tabContainer_tab_complete)}>
                    <img src={sendIcon} alt="sendIcon" />
                    <span className="pl-2">Заявление на аккредитацию</span>
                </div>
            </div>
            {reviewComment && correctionsRequired
                ? <AlertText className="mt-4" title="В текущую заявку требуется внести следующие исправления:" text={reviewComment} />
                : null
            }
            {activeTab === TabKey.Identification ? (
                <div>
                    {applicantIdentityDoc && profile ? (
                        <>
                            <IdentificationTab
                              identification={applicantIdentityDoc}
                              diploma={applicantDiploma}
                              countries={countries}
                              profile={profile}
                              apiError={apiError}
                              setApplicantOtherIdentity={setApplicantOtherIdentity}
                              setApplicantPassport={setApplicantPassport}
                              updateApplicantDiploma={updateApplicantDiploma}
                              contentClassName={styles.content}
                              goBack={goBack}
                              goNext={() => setActiveTab(TabKey.Documents)}
                              onUpdateProfile={onUpdateProfile}
                            />
                        </>
                    ) : <Loader fullHeight />}
                </div>
            ) : null}

            {activeTab === TabKey.Documents ? (
                <DocumentsTab
                  {...documentsProps}
                  goToIdentificationTab={() => setActiveTab(TabKey.Identification)}
                  goToSendingTab={() => setActiveTab(TabKey.Sending)}
                  documentTemplateGroups={documentsProps.documentTemplateGroups || []}
                  correctionsRequired={correctionsRequired}
                  apiError={apiError}
                />
            ) : null}

            {activeTab === TabKey.Sending ? (
                <div>
                    {examApplication ? (
                        <StatementTab
                          examApplication={examApplication}
                          onCancel={() => setActiveTab(TabKey.Documents)}
                          onSubmit={handleOpenSignApplication}
                          contentClassName={styles.content}
                          professionalRetraining={professionalRetraining}
                          setProfessionalRetraining={setProfessionalRetraining}
                          startStage={startStage}
                          setStartStage={setStartStage}
                          correctionsRequired={correctionsRequired}
                        />
                    ) : <Loader fullHeight />}
                </div>
            ) : null}

            {profile ? (
                <SmsSignModal
                  title="Подписание заявления"
                  phoneText="Для завершения подачи заявки на аккредитацию в электронной форме, введите код из СМС-сообщения, которое было отправлено на номер"
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  onSendPasswordAgain={sendCodeToSignApplication}
                  onSubmit={onSubmitSmsModal}
                  phone={profile.phone}
                  waitingSeconds={waitingSeconds}
                  processing={!!processing}
                  apiError={apiError}
                />
            ) : null}
        </Card>
    );
};

export default RegistrationForExam;

interface IProps {
    examApplication: IExamApplicationDetails | null;
    documentsProps: IDocumentsProps;
    countries: ICountry[] | null;
    sendingProps: IStatementTabSendingProps;
    apiError: IApiError | null;
    processing?: boolean;
    setApplicantPassport: (data: IApplicantPassport) => Promise<boolean>;
    updateApplicantDiploma: (data: IApplicantDiploma) => Promise<boolean>;
    setApplicantOtherIdentity: (data: IApplicantOtherIdentity, type: IdentityDocType) => Promise<boolean>;
    onUpdateProfile: (data: IUpdateProfile) => Promise<boolean>;
    goBack: () => void;
}

interface IStatementTabSendingProps {
    waitingSeconds?: number | null;
    createExamApplication: (data?: ISubmitExamApplication) => Promise<boolean>;
    sendCodeToSignApplication: (captcha: string | undefined) => Promise<VerificationCodeSendingStatus | null>;
}

interface IDocumentsProps {
    documentTemplateGroups: IDocumentsTabProps['documentTemplateGroups'];
    uploadedDocuments: IDocumentsTabProps['uploadedDocuments'];
    deleteDocument: IDocumentsTabProps['deleteDocument'];
    uploadDocument: IDocumentsTabProps['uploadDocument'];
    deleteDocumentSource: IDocumentsTabProps['deleteDocumentSource'];
}

enum TabKey {
    Identification = 'identification',
    Documents = 'documents',
    Sending = 'sending',
}
