import * as React from 'react';
import { WarningModal } from 'components';
import cn from 'classnames';

import WebCamIcon from 'components/assets/svg/exam/webcam_icon.svg';
import ScreenIcon from 'components/assets/svg/exam/screen_icon.svg';
import MicrophoneIcon from 'components/assets/svg/microphone.svg';

import styles from './OnlineModal.module.scss';
import VideoTest from './VideoTest';
import AudioTest from './AudioTest';
import ScreenTest from './ScreenTest';

const OnlineModal: React.FC<IProps> = (
  {
      show, onClose, title, onSuccess,
  },
) => {
    const [activeTab, setActiveTab] = React.useState(TabKey.WebCam);

    const isActive = React.useCallback((tab: TabKey) => {
        const tabsOrder = [TabKey.WebCam, TabKey.Micro, TabKey.Screen];
        const activeTabIndex = tabsOrder.indexOf(activeTab);
        const currentTabIndex = tabsOrder.indexOf(tab);

        return currentTabIndex <= activeTabIndex;
    }, [activeTab]);

    const handleClose = React.useCallback(() => {
        setActiveTab(TabKey.WebCam);
        onClose();
    }, []);

    return (
      <WarningModal
        title={title}
        show={show}
        onHide={handleClose}
        titleClassNames={styles.modalTitle}
        bodyClassNames="pb-0 pt-0"
        withoutCancelButton
        large
      >
          <article>
              <header className={styles.tabsContainer}>
                <span className={cn(styles.tab, { [styles.active]: isActive(TabKey.WebCam) })}>
                    <img className={styles.icon} src={WebCamIcon} alt="WebCamIcon" />Веб-камера
                </span>
                <span className={cn(styles.tab, { [styles.active]: isActive(TabKey.Micro) })}>
                    <img className={styles.icon} src={MicrophoneIcon} alt="ExpertChatIcon" />Микрофон
                </span>
                <span className={cn(styles.tab, { [styles.active]: isActive(TabKey.Screen) })}>
                    <img className={styles.icon} src={ScreenIcon} alt="ScreenIcon" />Рабочий стол
                </span>
              </header>

              <main className="d-flex">
                  <div className={cn({ [styles.fullWidth]: !isActive(TabKey.Micro) })}>
                      {activeTab === TabKey.WebCam && (
                        <VideoTest
                          onNextTest={() => setActiveTab(TabKey.Micro)}
                        />
                      )}
                      {activeTab === TabKey.Micro && (
                        <AudioTest
                          onNextTest={() => setActiveTab(TabKey.Screen)}
                        />
                      )}
                      {activeTab === TabKey.Screen && (
                        <ScreenTest
                          onNextTest={onClose}
                          onSuccess={onSuccess}
                        />
                      )}
                  </div>
              </main>
          </article>
      </WarningModal>
    );
};

export default OnlineModal;

interface IProps {
    show: boolean;
    title: string;
    onClose: () => void;
    onSuccess: () => void;
}

enum TabKey {
    WebCam = 'webCam',
    Micro = 'micro',
    Screen = 'screen'
}
