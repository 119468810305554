import React from 'react';
import { InputText, DragDrop } from 'components';
import { withDragDropContext } from 'components/DragDrop';
import { IFieldsError, ITheoreticalQuestion, TheoreticalQuestionType } from './types';
import QuestionVariant from './QuestionVariant';
import MatchingRightVariant from './MatchingRightVariant';
import QuestionSelect, { IChangeSelect } from './QuestionSelect';
import styles from './TheoreticalQuestionRedactor.module.scss';
import cn from 'classnames';

const MatchingOrOrderingTheoreticalQuestion = ({
    onChangeLeftHeader,
    onChangeRightHeader,
    formFieldErrors,
    handleChangeMatchingAnswerText,
    handleChangeOrderingAnswerText,
    question,
    onAddQuestionVariant,
    onDeleteQuestionVariant,
    setFormFieldErrors,
    onChangeSelect,
    onDropAnswer,
}: IProps) => {
    const [changeLeftHeader, setChangeLeftHeader] = React.useState(false);
    const [changeRightHeader, setChangeRightHeader] = React.useState(false);

    if (!question.matchingOrOrderingAnswers) return null;

    const {
        leftAnswersHeader, rightAnswersHeader, left, right, correct,
    } = question.matchingOrOrderingAnswers;

    const leftHeader = leftAnswersHeader
        || ((question.type === TheoreticalQuestionType.OrderingTheoretical && 'Варианты:')
            || (question.type === TheoreticalQuestionType.MatchingTheoretical && 'Утверждения:')) || '';

    const rightHeader = rightAnswersHeader
        || ((question.type === TheoreticalQuestionType.OrderingTheoretical && 'Укажите последовательность:')
            || (question.type === TheoreticalQuestionType.MatchingTheoretical && 'Варианты для соотнесения:')) || '';

    const answerChar = [...left];
    answerChar.sort((a, b) => a.number - b.number);

    return (
        <section>
            <div>
                {!changeLeftHeader && <span>{leftHeader}</span>}
                {changeLeftHeader && (
                    <InputText
                      name="leftHeader"
                      value={leftHeader}
                      onChange={(name, value) => onChangeLeftHeader(value)}
                      inputGroupClassNames="m-0"
                      inputClassNames="pl-0"
                      inputColSize={4}
                      withoutLabel
                    />
                )}
                <a
                  href="#"
                  className={cn(styles['font-extra-small'], styles.changeLink, 'd-block')}
                  onClick={(e) => { e.preventDefault(); setChangeLeftHeader(!changeLeftHeader); }}
                >
                    {!changeLeftHeader ? 'Изменить заголовок' : 'Сохранить заголовок'}
                </a>
                <div className="mt-4">
                    {left.map((answer, i) => (
                        <DragDrop key={`questionVariant_${i}`} rowIndex={i} onRowDrop={onDropAnswer} dndClassName={styles.dndButton}>
                            <QuestionVariant
                              handleChangeMatchingAnswerText={handleChangeMatchingAnswerText}
                              answerChar={answerChar[i].number}
                              formFieldErrors={formFieldErrors}
                              questionLeftLength={left.length}
                              onDelete={onDeleteQuestionVariant}
                              onAdd={onAddQuestionVariant}
                              containerClassName="pr-5"
                              answer={answer}
                              num={i}
                            />
                        </DragDrop>
                    ))}
                </div>
            </div>
            <div>
                {!changeRightHeader && <span>{rightHeader}</span>}
                {changeRightHeader && (
                    <InputText
                      name="rightHeader"
                      value={rightHeader}
                      onChange={(name, value) => onChangeRightHeader(value)}
                      inputGroupClassNames="m-0"
                      inputClassNames="pl-0"
                      inputColSize={4}
                      withoutLabel
                    />
                )}
                <a
                  href="#"
                  className={cn(styles['font-extra-small'], styles.changeLink, 'd-block')}
                  onClick={(e) => { e.preventDefault(); setChangeRightHeader(!changeRightHeader); }}
                >
                    {!changeRightHeader ? 'Изменить заголовок' : 'Сохранить заголовок'}
                </a>
                <div className="mt-4">
                    {question.type === TheoreticalQuestionType.OrderingTheoretical && right.map((answer, i) => (
                        <QuestionSelect
                          key={`questionSelect_${i}`}
                          selectContainerClassName="mb-3"
                          formFieldErrors={formFieldErrors}
                          setFormFieldErrors={setFormFieldErrors}
                          onChangeSelect={onChangeSelect}
                          correct={correct}
                          answer={answer}
                          right={right}
                          num={i}
                        />
                    ))}
                    {question.type === TheoreticalQuestionType.MatchingTheoretical && right.map((answer, i) => (
                        <MatchingRightVariant
                          key={`questionVariant_${i}`}
                          handleChangeOrderingAnswerText={handleChangeOrderingAnswerText}
                          setFormFieldErrors={setFormFieldErrors}
                          formFieldErrors={formFieldErrors}
                          onChangeSelect={onChangeSelect}
                          correct={correct}
                          answer={answer}
                          right={right}
                          num={i}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default withDragDropContext(MatchingOrOrderingTheoreticalQuestion);

interface IProps {
    question: ITheoreticalQuestion;
    formFieldErrors: IFieldsError;
    handleChangeMatchingAnswerText: (answerNumber: number) => (name: string, value: string) => void;
    handleChangeOrderingAnswerText: (answerNumber: number) => (name: string, value: string) => void;
    onAddQuestionVariant: (e: React.FormEvent<HTMLAnchorElement>) => void;
    onDeleteQuestionVariant: (answerNumber: number) => (e: React.FormEvent<HTMLAnchorElement>) => void;
    onChangeLeftHeader: (text: string) => void;
    onChangeRightHeader: (text: string) => void;
    setFormFieldErrors: (error: IFieldsError) => void;
    onChangeSelect: (data: IChangeSelect) => void;
    onDropAnswer: (from: number, to: number) => void;
}
