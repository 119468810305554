import React, { FormEvent } from 'react';
import cn from 'classnames';
import styles from './CustomSwitch.module.scss';
import { Loader } from 'components';

const CustomSwitch = ({
    value, name, onClick, changed, status, containerClassName, loading, disabled, pinClassName, iconClassName,
}: Props) => {
    const handleToggle = (event: FormEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (isFixPosition()) return;

        const newState = !value;
        onClick({ name, value: newState });
    };

    const isFixPosition = () => loading || disabled || (!changed && (status === 'accept' || status === 'reject'));

    const containerOptionalClasses = {
        [styles.switchOn]: value,
        [styles.switchOff]: !value,
        'bg-success': status === 'accept',
        'bg-danger': status === 'reject',
    };

    return (
        <div
          className={cn(
                styles.container,
                containerOptionalClasses, containerClassName || '',
                status === 'default' && !loading && styles.hoverContainer,
            )}
          onClick={handleToggle}
          data-test-switch={status || true}
        >
            <div
              className={cn(styles.pin, {
                    [styles.pinOn]: value,
                    [styles.pinOff]: !value,
                }, pinClassName)}
            >
                {loading ? (
                    <Loader className={styles.loader} />
                ) : null}
            </div>
            <div
              className={cn(styles.iconContainer, {
                    [styles.iconOn]: value,
                    [styles.iconOff]: !value,
                }, iconClassName)}
            />
        </div>
    );
};

export default CustomSwitch;

interface Props {
    name: string;
    value: boolean;
    changed?: boolean;
    loading?: boolean;
    disabled?: boolean;
    status?: CustomSwitchStatus;
    containerClassName?: string;
    pinClassName?: string;
    iconClassName?: string;
    onClick: (event: CustomSwitchEvent) => void;
}

export interface CustomSwitchEvent {
    value: boolean;
    name: string;
}

export enum CustomSwitchStatusEnum {
    Accept = 'accept',
    Reject = 'reject',
    Default = 'default',
}

export type CustomSwitchStatus = 'accept' | 'reject' | 'default';
