/* eslint-disable arrow-parens */
import React from 'react';

const useMultiSearch = <T>(fetch: (payload: T) => void, initialState: T) => {
    const [state, setState] = React.useState<T>(initialState);

    const filterSetter = (fieldKey: keyof T) => (value: T[keyof T]) => {
        let newState = { ...state };
        setState((prevState) => {
            newState = {
                ...prevState,
                [fieldKey]: value,
            };
            return newState;
        });
        return newState;
    };

    const changeFilterField = (fieldKey: keyof T) => (value: T[keyof T]) => {
        const newForm = filterSetter(fieldKey)(value);
        fetch(newForm);
    };

    const changeFilter = (form: Partial<T>) => {
        let newForm = {
            ...state,
            ...form,
        };
        setState((prevState) => {
            newForm = {
                ...prevState,
                ...form,
            };
            return newForm;
        });
        fetch(newForm);
    };


    return {
        filters: state, changeFilterField, filterSetter, changeFilter,
    };
};

export default useMultiSearch;
