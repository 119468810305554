import React from 'react';
import {
    ExamType,
    MappedExamTypes,
    EmptySection,
    Status, DatePeriodColumn,
} from 'components';
import { Table } from 'react-bootstrap';
import { ColorStatus } from '_helpers';
import newsIcon from 'components/assets/svg/news_icon.svg';
import Pagination, { IPaginationData } from '../Pagination/Pagination';
import moment from 'moment';
import styles from './ExamTable.module.scss';

const ExamTable = ({
    onChooseExam,
    header,
    tableRows,
    onChangePagination,
    onClickPlaceholderButton,
    pagination,
    placeholderTitle,
    placeholderText,
    placeholderButtonText,
    placeholderIcon,
}: ExamTableProps) => {
    const handleChooseExam = (id?: string) => {
        if (!id) return;
        onChooseExam(id);
    };

    const renderQualification = (name?: string, attestationCommissionName?: string, address?: string) => (
        <div className={styles.qualification}>
            <div className={styles.qualification__name}>
                {name}
            </div>
            {/*<div className={styles.qualification__centerName}>
                {attestationCommissionName}
            </div>
            <div className={styles.qualification__address}>
                {address}
            </div>*/}
        </div>
    );

    const hasApplicationsNumber = header.indexOf('Участники') !== -1;

    const renderTableRow = (rowData: Row, i: number) => {
        if (!rowData) return null;

        return (
            <tr key={`${rowData.id}`} onClick={() => handleChooseExam(rowData.id)} data-test-exam-number={i} data-test-exam-id={rowData.id}>
                <td>
                    {rowData.date && (<DatePeriodColumn dateFrom={rowData.date}  dateTo={rowData.lastDate} />)}
                </td>
                <td>
                    {renderQualification(
                        rowData.name,
                        rowData.attestationSubCommissionName,
                        rowData.address,
                    )}
                </td>
                {(hasApplicationsNumber && (<td>{rowData.applicationsNumber}</td>))}
                {rowData.examType && (
                    <td>
                        <Status status={MappedExamTypes[rowData.examType]} />
                    </td>
                )}
                {rowData.status && (
                    <td>
                        <Status status={rowData.status} />
                    </td>
                )}
            </tr>
        );
    };
    const getPaginationObject = () => {
        const DEFAULT_PAGINATION = {
            page: 1,
            perPage: 10,
            pages: 1,
        };

        if (!pagination) return DEFAULT_PAGINATION;

        const { total, pageSize, page } = pagination;

        return {
            page,
            perPage: pageSize,
            pages: Math.ceil(total / pageSize),
        };
    };


    if (!tableRows.length) {
        return (
            <EmptySection
              placeholderTitle={placeholderTitle || 'Нет данных'}
              placeholderText={placeholderText || 'Здесь будут отображаться все Ваши заявки на аккредитации'}
              placeholderButtonText={placeholderButtonText}
              placeholderIcon={placeholderIcon || newsIcon}
              onButtonClick={onClickPlaceholderButton}
            />
        );
    }

    return (
        <div className={styles.container}>
            <Table responsive hover className={styles.table}>
                <thead className={styles.table__header}>
                    <tr>
                        {header.map((columnHeader: string, key: number) => <th key={key}>{columnHeader}</th>)}
                    </tr>
                </thead>
                <tbody className={styles.table__body}>
                    {tableRows.map(renderTableRow)}
                </tbody>
            </Table>
            {onChangePagination && pagination && (
                <Pagination onChange={onChangePagination} pagination={getPaginationObject()} />
            )}
        </div>
    );
};

export default ExamTable;

interface ExamTableProps {
    header: string[];
    tableRows: Row[];
    pagination?: IExamPagination | null;
    placeholderTitle?: string;
    placeholderText?: string;
    placeholderIcon?: string;
    placeholderButtonText?: string;
    onChooseExam: (id: string) => void;
    onChangePagination?: (data: IPaginationData) => void;
    onClickPlaceholderButton?: () => void;
}

interface IExamPagination {
    page: number;
    pageSize: number;
    total: number;
}

interface Row {
    id?: string;
    date?: moment.Moment;
    lastDate?: moment.Moment;
    currentStageLastDayDate?: moment.Moment;
    name?: string;
    attestationSubCommissionName?: string;
    attestationCommissionName?: string;
    address?: string;
    applicationsNumber?: number;
    examType?: ExamType;
    city?: string;
    status?: ColorStatus;
}
