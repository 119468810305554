export function formatFIO(applicant: HasFIO) {
    return `${applicant.lastName} ${applicant.firstName} ${applicant.middleName}`.trim();
}

interface HasFIO {
    /** Имя */
    firstName: string;
    /** Фамилия */
    lastName: string;
    /** Отчество */
    middleName?: string | undefined;
}
