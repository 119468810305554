import React from 'react';
import { IUpdateProfile, IChangePassword } from '../../api/ApiClient';
import { connect } from 'react-redux';
import { Card, Identification, AnimatedTabs } from 'components';
import { Tab } from 'react-bootstrap';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { push } from 'connected-react-router';
import { useLocalErrorHandler } from 'hooks';
import styles from './ProfileContainer.module.scss';
import ProfileInfoBlock from 'components/ProfileInfoBlock/ProfileInfoBlock';

const ProfileContainer = ({
    profile,
    onPush,
    updateProfile,
    sendUpdatePhoneVerificationCode,
    checkUpdatePhoneVerificationCode,
    sendConfirmationEmail,
    updatePhone,
    apiError,
    authProcessing,
    getIdentityDoc,
    setApplicantPassport,
    setApplicantOtherIdentity,
    applicantIdentityDoc,
    setErrorHandler,
    changePassword,
}: Props) => {
    useLocalErrorHandler(setErrorHandler);

    const [processing, setProcessing] = React.useState(false);

    React.useEffect(() => {
        const fetchDoc = async () => {
            setProcessing(true);
            await getIdentityDoc();
            setProcessing(false);
        };

        fetchDoc();
    }, []);

    const [tab, setTab] = React.useState('personal-data');

    const onSubmit = async (data: IUpdateProfile) => {
        const newData = data as IUpdateProfile;
        await updateProfile({ ...newData });
    };

    const changePass = async (data: IChangePass) => {
        let res: boolean | null = false;
        if (data.oldPassword) {
            const nData = data as IChangePassword;
            res = await changePassword(nData);
        }

        return res;
    };

    const title = profile ? `${profile.lastName} ${profile.firstName} ${profile.middleName}` : '';

    return (
        <Card
          title={title}
          contentClassName={styles.profile__container}
          apiError={apiError}
          showLoader={processing}
          withoutBorder
          withoutPadding
        >
            <AnimatedTabs
              id="profileContainerTabs"
              activeKey={tab}
              onSelect={(key: string) => setTab(key)}
            >
                <Tab eventKey="personal-data" title="Личные данные" className="tab">
                    <Card
                      title="Ваши личные данные"
                      category="Информация должна соответствовать документам, предоставляемым на аккредитации"
                      show404Error={!profile}
                      withoutBorder
                      withoutPadding
                      withoutPaddingTitle
                    >
                        {profile ? (
                            <ProfileInfoBlock
                              profile={profile}
                              apiError={apiError}
                              processing={authProcessing}
                              onCancel={() => onPush('/exam-timetables')}
                              onSubmitPhone={sendUpdatePhoneVerificationCode}
                              onSubmitCode={checkUpdatePhoneVerificationCode}
                              sendConfirmationEmail={sendConfirmationEmail}
                              onChangePassword={changePass}
                              onUpdatePhone={updatePhone}
                              onSubmit={onSubmit}
                              canChange
                            />
                        ) : null}
                    </Card>
                </Tab>
                <Tab eventKey="identification" title="Удостоверение личности" className="tab">
                    <Card
                      title="Документ, удостоверяющий личность"
                      category="Укажите информацию о документе, который необходимо будет предъявить в качестве удостоверения личности"
                      withoutBorder
                      withoutPadding
                      withoutPaddingTitle
                    >
                        <Identification
                          apiError={apiError}
                          onSubmitPassport={setApplicantPassport}
                          onSubmitOther={async (data, type) => await setApplicantOtherIdentity({data: data && data.data, type})}
                          stateData={applicantIdentityDoc}
                        />
                    </Card>
                </Tab>
            </AnimatedTabs>
        </Card>
    );
};

const mapState = (state: IRootState) => ({
    profile: state.auth.profile,
    apiError: state.errors.error,
    authProcessing: Boolean(state.loading.effects.auth.updateProfile),
    applicantIdentityDoc: state.applicant.applicantIdentityDoc,
});

const mapDispatch = (dispatch: Dispatch) => ({
    onPush: (key: string) => dispatch(push(key)),
    updateProfile: dispatch.auth.updateProfile,
    sendUpdatePhoneVerificationCode: dispatch.auth.sendUpdatePhoneVerificationCodeAsync,
    checkUpdatePhoneVerificationCode: dispatch.auth.checkUpdatePhoneVerificationCodeAsync,
    updatePhone: dispatch.auth.updatePhoneAsync,
    getIdentityDoc: dispatch.applicant.getIdentityDocAsync,
    setApplicantPassport: dispatch.applicant.setApplicantPassportAsync,
    setApplicantOtherIdentity: dispatch.applicant.setApplicantOtherIdentityAsync,
    setErrorHandler: dispatch.errors.setErrorHandler,
    sendConfirmationEmail: dispatch.auth.sendConfirmationEmailAsync,
    changePassword: dispatch.auth.changePasswordAsync,
});

interface IChangePass {
    newPassword: string;
    oldPassword?: string;
    captcha?: string;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
type Props = connectedProps;

export default connect(mapState, mapDispatch)(ProfileContainer);
