import * as React from 'react';
import { WarningModal } from '../../../../Common/Modals';
import { CustomRadioGroup } from '../../../../CustomRadioGroup';
import cn from 'classnames';
import styles from '../StageContainer.module.scss';
import {
    EmptyInfo, IApiError, IAssignApplicationsForDay, IAssignForDayInput, IBaseExperts,
    IBaseUnratedExamApplications,
    IExamApplication, IExamStage,
    IFinalizeResultPayload,
} from 'components/models';
import moment, { Moment } from 'moment';
import { FinalizedResult, MappedFinalizedResult } from 'components/enums';
import { useLoading } from 'hooks';
import CreateDayModals from './CreateDayModals';
import ConfirmDateModal from './ConfirmDateModal';

const FinalizeResultModals: React.FC<IProps> = (props) => {
    const [showRetakeModal, setShowRetakeModal] = React.useState(false);

    const [showCreateNewDay, setShowCreateNewDay] = React.useState(false);
    const [selectedDayId, selectDayId] = React.useState<string | null>(null);

    React.useEffect(() => {
        props.unratedExamApplications.fetch({ examDayId: props.examDayId });
        props.setApplicantsForRate([]);
    }, [props.examDayId]);

    const radios = [
        { label: MappedFinalizedResult[FinalizedResult.Passed], option: FinalizedResult.Passed },
        { label: MappedFinalizedResult[FinalizedResult.NotPassed], option: FinalizedResult.NotPassed },
        { label: MappedFinalizedResult[FinalizedResult.Absent], option: FinalizedResult.Absent },
    ];
    const [chooseResult, setChooseResult] = React.useState<FinalizedResult>(radios[0].option);

    const handleFinalizeResult = async () => {
        if (chooseResult === FinalizedResult.NotPassed) {
            setShowRetakeModal(true);
        } else {
            await props.finalizeResult({
                examId: props.examId,
                stageNumber: props.stageNumber,
                examDayId: props.examDayId,
                examApplicationIds: props.applicantsForRate.map(a => a.examApplicationId),
                body: chooseResult,
            });
            await props.unratedExamApplications.fetch({ examDayId: props.examDayId });
            props.hideFinalizingResultModal();
        }
    };

    const [finalizeProcessing, finalizeStart] = useLoading(handleFinalizeResult);

    const handleConfirmDate = async () => {
        if (selectedDayId && await props.finalizeResult({
            examId: props.examId,
            stageNumber: props.stageNumber,
            examDayId: props.examDayId,
            examApplicationIds: props.applicantsForRate.map(a => a.examApplicationId),
            body: chooseResult,
        })) {
            await props.assignApplicationsForDay({
                examId: props.examId,
                stage: props.stageNumber,
                examDayId: selectedDayId,
                body: {
                    examApplicationIds: props.applicantsForRate.map(a => a.examApplicationId),
                },
            });
            await props.unratedExamApplications.fetch({ examDayId: props.examDayId });
            setShowRetakeModal(false);
            props.hideFinalizingResultModal();
            props.setApplicantsForRate([]);
        }
    };
    const [, confirmDate] = useLoading(handleConfirmDate);

    const handleCreateDay = async (expertsIds: string[], time: moment.Moment) => props.onCreateRetakeDay(
      time,
      expertsIds,
    );
    const [createDayLoading, createDay] = useLoading(handleCreateDay);

    const retakeDays = props.stage.examDays.filter(d => d.id !== props.examDayId).filter(d => moment().isBefore(d.time));

    const lastDay = [...props.stage.examDays].pop();
    return (
      <div>
          <WarningModal
            title="Финализировать результат"
            show={props.showFinalizingResultModal}
            accessBtnTittle="Сохранить результат"
            onHide={() => {
                props.hideFinalizingResultModal();
                setChooseResult(radios[0].option);
            }}
            loading={finalizeProcessing}
            onAccess={finalizeStart}
          >
              <p>
                  Укажите результат прохождения аккредитации:
              </p>

              <CustomRadioGroup
                radios={radios}
                radiosGroupName="finalizing-results-radio"
                onChangeRadio={o => setChooseResult(o)}
                containerClassName={cn('d-flex flex-column justify-content-between align-items-start', styles.height80)}
              />
          </WarningModal>

          <ConfirmDateModal
            show={showRetakeModal}
            onHide={() => setShowRetakeModal(false)}
            onAccess={confirmDate}
            days={retakeDays}
            stage={props.stage}
            selectedDayNumber={retakeDays.findIndex(d => d.id === selectedDayId)}
            chooseDay={i => selectDayId(retakeDays[i].id)}
            onCreateNewDay={() => setShowCreateNewDay(true)}
            examApplication={props.applicantsForRate}
          />

          <CreateDayModals
            show={showCreateNewDay}
            onHide={() => setShowCreateNewDay(false)}
            defaultDate={lastDay ? lastDay.time.clone().add(1, 'd') : props.stage.lastDayDateTime}
            onCreateDay={createDay}
            createDayLoading={createDayLoading}
            minAssigningExperts={props.minAssigningExperts}
            experts={props.experts}
            expertsForDayList={EmptyInfo}
            apiErrors={props.error}
          />
      </div>
    );
};

export interface IFinalizeResultModalsProps {
    finalizeResult: (payload: IFinalizeResultPayload) => Promise<boolean>;
    unratedExamApplications: IBaseUnratedExamApplications;
    experts: IBaseExperts;
    examId: string;
    stageNumber: number;
    examDayId: string;
    onCreateRetakeDay: (time: Moment, expertIds: string[]) => Promise<unknown>;
    assignApplicationsForDay: (data: IAssignForDayInput<IAssignApplicationsForDay>) => Promise<unknown>;
    minAssigningExperts: number;
    error: IApiError | null;
}

interface IProps extends IFinalizeResultModalsProps {
    applicantsForRate: IExamApplication[];
    setApplicantsForRate: (applicantsForRate: IExamApplication[]) => void;
    showFinalizingResultModal: boolean;
    hideFinalizingResultModal: () => void;
    stage: IExamStage;
}

export default FinalizeResultModals;
