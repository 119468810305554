import * as React from 'react';
import { IExamStage } from '../../../models';
import { Card } from '../../../Card';
import { Details, ReactNodeValue, StringValue } from '../../../Details';
import { formatPhoneNumber, getMoscowTime } from '_helpers';
import { StepStatus } from '../../../StepStatus';

const AwaitingStage: React.FC<IProps> = ({ stageInfo }) => {
    const startDate = stageInfo.firstDayDateTime;
    const { moscowHours, moscowMinutes } = getMoscowTime(stageInfo.firstDayDateTime);

    const infoRows = React.useMemo(() => [
        new StringValue('Период проведения', startDate.format('DD.MM.YYYY')),
        new StringValue('Время начала', `${startDate.format('HH:mm')} (${moscowHours}:${moscowMinutes}) МСК)`),
        new StringValue('Площадка', stageInfo.accreditationCenterName),
        new StringValue('Почта', stageInfo.secretaryEmail),
        new StringValue('Телефон', formatPhoneNumber(stageInfo.secretaryPhone || '') || ''),
        new ReactNodeValue(
          'Подтверждение',
          <StepStatus
            step={{
                label: 'Администратор площадки:',
                value: stageInfo.secretaryFullName || 'Ожидает подтверждение',
                status: stageInfo.secretaryFullName ? 'success' : 'loading',
            }}
          />,
        ),
    ], [stageInfo]);

    return (
      <Card
        withoutBorder
        title={`Этап ${stageInfo.stageNumber}: подтверждение`}
        category="Подтверждение со стороны аккредитационной площадки"
        contentClassName="pt-0"
      >
          <Details
            centeredRow
            withDarkRowLabel
            infoRows={infoRows}
          />
      </Card>
    );
};

interface IProps {
    stageInfo: IExamStage;
}

export default AwaitingStage;
