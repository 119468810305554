import * as React from 'react';
import styles from '../StageContainer.module.scss';
import { Tab } from 'react-bootstrap';
import { DayEditor } from 'components/DayEditor';
import ExpertsAssignTable from '../Components/ExpertsAssignTable';
import moment, { Moment } from 'moment';
import { useLoading } from 'hooks';
import {
    IAssignApplicationsForDay,
    IAssignForDayInput,
    IExamDay,
    IExamDetails,
    IUpdateExamDay,
    IFinalizeResultPayload,
    ICreateExamDayInput,
    IAdmitToExamDay,
    IBaseExamApplicationsForDayAdmit,
    IBaseApplicantDetails,
    IBaseUnratedExamApplications, IBaseExperts, IBaseExpertsForDay, IBaseExamApplicationsForDay, IExpert,
} from 'components/models';
import { WarningModal } from 'components/Common/Modals';
import { AnimatedTabs } from 'components/AnimatedTabs';
import {
    ExamAssigningApplicationsColumnHeader,
} from 'components/ExamAssigningApplicationsTable';
import { IApiError } from '_helpers/ApiConnector/types';
import FinalizingResultsTab from './EditModalTabs/FinalizingResultsTab';
import AdmitApplicantsTab from './EditModalTabs/AdmitApplicantsTab';
import FinalizedExamApplicationsTab from './EditModalTabs/FinalizedExamApplicationsTab';
import ExamApplicationsTab from './EditModalTabs/ExamApplicationsTab';
import { formatDayTitle } from '_helpers/formatHelpers';

const EditDayModal: React.FC<IProps> = (props) => {
    const availableDays = props.days.filter(d => d.dayNumber !== props.day.dayNumber);
    const [selectedNewDate, setSelectedNewDate] = React.useState(() => moment().utc().clone().minute(Math.round(moment().minute() / 5) * 5));
    const [showEditDate, setShowEditDate] = React.useState(false);

    const [selectedDayForReplace, setSelectedDayForReplace] = React.useState(availableDays[0] ? availableDays[0].dayNumber : 0);

    const handleFetchExamApplicationsForDay = (day: IExamDay) => props.examApplicationsForDay.fetch({
        examDayId: day.id,
        examId: day.examId,
        stage: day.stageNumber,
    });

    const handleFetchExamApplicationsForDayAdmit = (day: IExamDay) => props.examApplicationsForDayAdmit.fetch({
        examDayId: day.id,
        examId: day.examId,
        stage: day.stageNumber,
    });


    const handleUpdateDayDate = () => props.updateDay({
        examId: props.examDetails.id,
        stage: props.stageNumber,
        examDayId: props.day.id,
        body: {
            time: selectedNewDate,
        },
    });

    const [updateDayLoading, updateDay] = useLoading(handleUpdateDayDate);

    const handleAssignExpert = React.useCallback(
      (experts: IExpert[]) => props.onUpdateExperts(experts.map(e => e.id))
      .then((r) => {
          if (props.day.id && r) {
              return props.expertsForDayList.fetch({ examDayId: props.day.id });
          }

          return Promise.resolve(r);
      }),
      [props.onUpdateExperts],
    );

    React.useEffect(() => {
        if (availableDays[0] && (!selectedDayForReplace || props.day.dayNumber === selectedDayForReplace)) {
            setSelectedDayForReplace(availableDays[0].dayNumber);
        }
    }, [props.day]);

    React.useEffect(() => {
        if (props.show) {
            props.expertsForDayList.fetch({
                examDayId: props.day.id,
            });
        }
    }, [props.day.id, props.show]);

    React.useEffect(() => {
        if (props.show) {
            handleFetchExamApplicationsForDay(props.day);
            handleFetchExamApplicationsForDayAdmit(props.day);
        }
    }, [props.day.id, props.show]);

    React.useEffect(() => {
        if (props.day.time) {
            setSelectedNewDate(props.day.time.clone().utc());
        }
        if (props.day) {
            handleFetchExamApplicationsForDay(props.day);
            handleFetchExamApplicationsForDayAdmit(props.day);
        }
    }, [props.day]);

    React.useEffect(() => {
        if (!props.show) {
            props.examApplicationsForDay.clear();
            props.examApplicationsForDayAdmit.clear();
            props.expertsForDayList.clear();
        }

        return () => {
            props.examApplicationsForDay.clear();
            props.examApplicationsForDayAdmit.clear();
            props.expertsForDayList.clear();
        };
    }, [props.show]);

    const applicantHeaders: ExamAssigningApplicationsColumnHeader[] = [
        { value: 'Имя, фамилия', key: 'applicantFullName' },
        { value: 'Телефон', key: 'applicantPhone' },
    ];

    if (props.day.readyForFinalize) {
        applicantHeaders.push(
          { value: 'Статус', key: 'lastFinalizedStageResult', isStatus: true },
        );
    }

    const handleCreateRetakeDay = (
      time: Moment,
      expertIds: string[],
    ) => props.createExamDay({
        examId: props.examDetails.id,
        stage: props.stageNumber,
        body: {
            time,
            expertIds,
        },
    });

    const admitApplicants = (
      <Tab title="Допуск аккредитуемых" eventKey="admitApplicants" key="admitApplicants">
          <AdmitApplicantsTab
            readonly={props.readonly}
            day={props.day}
            examApplicationsForDayAdmit={props.examApplicationsForDayAdmit}
            applicantDetails={props.applicantDetails}
            admitToExamDay={props.admitToExamDay}
          />
      </Tab>
    );

    const finalizeTab = (
      <Tab title="Финализация результатов" eventKey="finalizeExamApplications" key="finalizeExamApplications">
          <FinalizingResultsTab
            assignApplicationsForDay={props.assignApplicationsForDay}
            readonly={props.readonly}
            onCreateRetakeDay={handleCreateRetakeDay}
            examId={props.day.examId}
            stageNumber={props.day.stageNumber}
            examDayId={props.day.id}
            unratedExamApplications={props.unratedExamApplications}
            finalizeResult={props.finalizeResult}
            experts={props.experts}
            minAssigningExperts={props.minAssigningExperts}
            error={props.apiErrors}
            stage={props.examDetails.examStages[props.stageNumber - 1]}
          />
      </Tab>
    );

    const expertsTab = React.useMemo(() => (
      <Tab title="Члены комиссии" eventKey="experts" key="experts">
          <div className="mt-3">
              <ExpertsAssignTable
                readonly={props.readonly}
                disabled={props.day.readyForFinalize}
                expertsForDayList={props.expertsForDayList}
                experts={props.experts}
                onUpdateExperts={handleAssignExpert}
                onHide={() => true}
                apiError={props.apiErrors && props.apiErrors.failures && props.apiErrors.failures.expertIds ? props.apiErrors.failures.expertIds[0] : undefined}
                minAssigningExperts={props.minAssigningExperts}
              />
          </div>
      </Tab>
    ), [props.expertsForDayList, props.experts, handleAssignExpert, props.apiErrors, props.minAssigningExperts, props.day]);


    const applicantsTab = (
      <Tab title="Список аккредитуемых" eventKey="examApplications" key="examApplications">
          {props.day.readyForFinalize ? (
            <FinalizedExamApplicationsTab
              assignApplicationsForDay={props.assignApplicationsForDay}
              stageInfo={props.examDetails.examStages[props.stageNumber - 1]}
              readonly={props.readonly}
              finalizeResult={props.finalizeResult}
              unratedExamApplications={props.unratedExamApplications}
              experts={props.experts}
              examId={props.day.examId}
              stageNumber={props.stageNumber}
              examDayId={props.day.id}
              onCreateRetakeDay={handleCreateRetakeDay}
              minAssigningExperts={props.minAssigningExperts}
              error={props.apiErrors}
              examApplicationsForDay={props.examApplicationsForDay}
            />
          ) : (
            <ExamApplicationsTab
              apiError={props.apiErrors}
              readonly={props.readonly}
              day={props.day}
              days={props.days}
              examDetails={props.examDetails}
              stageNumber={props.stageNumber}
              examApplicationsForDay={props.examApplicationsForDay}
              assignApplicationsForDay={props.assignApplicationsForDay}
            />
          )}
      </Tab>
    );

    let tabs = [expertsTab, applicantsTab];
    if (props.day.readyForFinalize && (!props.readonly || props.day.unFinalizedApplications > 0)) {
        tabs = tabs.reverse();
        tabs.unshift(finalizeTab);
    }
    if (!(props.day.readyForFinalize && !props.day.unFinalizedApplications) && props.stageNumber === 1) {
        tabs.unshift(admitApplicants);
    }

    const activeTab = React.useMemo(() => {
        if (props.day.readyForFinalize && !props.day.unFinalizedApplications) {
            return 'examApplications';
        }

        if (props.day.readyForFinalize) {
            return 'finalizeExamApplications';
        }

        return 'experts';
    }, [props.day]);

    return (
      <WarningModal
        large
        withoutOverflow
        withoutCancelButton
        centered={false}
        withoutTitlePadding
        show={props.show}
        title={formatDayTitle(props.day)}
        onHide={props.onHide}
        accessBtnTittle="Продолжить"
        bodyClassNames={styles.modalBodyWithoutPadding}
        closeBtnTittle="Добавить члена комиссии"
        dialogClassName={styles.modalWithoutPadding}
        footerClassNames="py-0"
        dotsButtons={!props.readonly && !props.day.readyForFinalize ? [
            { onClick: () => setShowEditDate(true), buttonText: 'Изменить дату и время', iconType: 'edit' },
        ] : undefined}
      >
          <AnimatedTabs activeKey={activeTab} defaultActiveKey={activeTab}>
              {tabs}
          </AnimatedTabs>

          <WarningModal
            title="Редактирование даты и времени"
            withoutTitleMargin
            withoutOverflow
            show={showEditDate}
            onHide={() => {
                setShowEditDate(false);
                setSelectedNewDate(props.day.time);
            }}
            accessBtnTittle="Сохранить изменения"
            closeBtnTittle="Отмена"
            onAccess={async () => {
                if (await updateDay()) {
                    setShowEditDate(false);
                }
            }}
            loading={updateDayLoading}
          >
              <DayEditor
                disabled={props.day.readyForFinalize || props.readonly}
                date={selectedNewDate}
                onChangeDate={setSelectedNewDate}
                className="mt-0"
                apiError={props.apiErrors && props.apiErrors.failures && props.apiErrors.failures.time && props.apiErrors.failures.time[0]}
              />
          </WarningModal>
      </WarningModal>
    );
};

interface IProps {
    day: IExamDay;
    show: boolean;
    readonly?: boolean;
    onHide: () => void;
    onUpdateExperts: (ids: string[]) => Promise<unknown>;
    minAssigningExperts: number;
    days: IExamDay[];
    examDetails: IExamDetails;
    stageNumber: number;

    // DispatchProps
    updateDay: (data: IAssignForDayInput<IUpdateExamDay>) => Promise<IExamDetails | null>;
    createExamDay: (data: ICreateExamDayInput) => Promise<IExamDetails | null>;
    assignApplicationsForDay: (data: IAssignForDayInput<IAssignApplicationsForDay>) => Promise<unknown>;

    // StateProps
    apiErrors: IApiError | null;

    unratedExamApplications: IBaseUnratedExamApplications;
    examApplicationsForDayAdmit: IBaseExamApplicationsForDayAdmit;
    applicantDetails: IBaseApplicantDetails;
    experts: IBaseExperts;
    expertsForDayList: IBaseExpertsForDay;
    examApplicationsForDay: IBaseExamApplicationsForDay;

    finalizeResult: (payload: IFinalizeResultPayload) => Promise<boolean>;
    admitToExamDay: (props: IAssignForDayInput<IAdmitToExamDay>) => void;
}

export default EditDayModal;
