import { useHistory, useLocation } from 'react-router';

const useGoBack = (): [() => void, string | null] => {
    const { search } = useLocation();
    const { push } = useHistory();
    const from = new URLSearchParams(search).get('from');

    return [() => push(`/${from}`), from];
};

export default useGoBack;
