import React from 'react';
import { WarningModal, InputText, Captcha } from 'components';
import { Form } from 'react-bootstrap';
import { IApiError } from '_helpers/ApiConnector/types';
import styles from './ResetPasswordModal.module.scss';
import cn from 'classnames';

const ResetPasswordModal = ({
    show,
    onHide,
    onChangePassword,
    apiError,
    withoutOldPass,
    withoutRepeatPass,
    title,
    children,
}: IProps) => {
    const [oldPass, setOldPass] = React.useState('');
    const [newPass, setNewPass] = React.useState('');
    const [repeatPass, setRepeatPass] = React.useState('');
    const [validOldPass, setValidOldPass] = React.useState(true);
    const [validNewPass, setValidNewPass] = React.useState(true);
    const [validRepeatPass, setValidRepeatPass] = React.useState(true);
    const [captcha, setCaptcha] = React.useState('');
    const [captchaValid, setCaptchaValid] = React.useState(true);
    const [processing, setProcessing] = React.useState(false);
    const captchaError = apiError && apiError.failures && apiError.failures.captcha;
    const newPassError = apiError && apiError.failures && apiError.failures.newPassword && apiError.failures.newPassword[0];
    const oldPassError = apiError && apiError.failures && apiError.failures.oldPassword && apiError.failures.oldPassword[0];

    React.useEffect(() => {
        clearState();
    }, [show]);

    const clearState = () => {
        setOldPass('');
        setNewPass('');
        setRepeatPass('');
        setCaptcha('');
        setValidOldPass(true);
        setValidNewPass(true);
        setValidRepeatPass(true);
        setCaptchaValid(true);
        setProcessing(false);
    };

    const doValidate = () => {
        const oldPassValid = withoutOldPass ? true : oldPass.length >= 6;
        const newPassValid = newPass.length >= 6;
        const repeatPassValid = withoutRepeatPass ? true : repeatPass.length >= 6 && repeatPass === newPass;
        const isCaptchaValid = captchaError ? captcha.length === 4 : true;

        setValidOldPass(oldPassValid);
        setValidNewPass(newPassValid);
        setValidRepeatPass(repeatPassValid);
        setCaptchaValid(isCaptchaValid);

        return oldPassValid && newPassValid && repeatPassValid && isCaptchaValid;
    };

    const submit = async () => {
        if (!doValidate()) return;

        setProcessing(true);
        setCaptcha('');

        const res = await onChangePassword({
            newPassword: newPass,
            oldPassword: oldPass,
            captcha,
        });

        setProcessing(false);

        if (res) {
            onHide();
        }
    };

    return (
        <WarningModal
          title={title || 'Изменение пароля'}
          show={show}
          onHide={onHide}
          onAccess={submit}
          loading={processing}
          accessBtnTittle="Сохранить"
          closeBtnTittle="Отмена"
          withoutTitlePadding
        >
            {children}
            {!withoutOldPass ? (
                <InputText
                  name="oldPass"
                  value={oldPass}
                  type="password"
                  onChange={(name, value) => {
                      setOldPass(value);
                      setValidOldPass(true);
                  }}
                  labelTitle="СТАРЫЙ ПАРОЛЬ"
                  labelColSize={3}
                  inputColSize={6}
                  minLength={6}
                  fieldFailures={!validOldPass ? { oldPass: 'Минимальная длина пароля 6 символов' } : { oldPass: oldPassError || '' }}
                  inputGroupClassNames={styles.row_input}
                />
            ) : null}
            <InputText
              name="newPass"
              value={newPass}
              type="password"
              onChange={(name, value) => {
                    setNewPass(value);
                    setValidNewPass(true);
                }}
              labelTitle={withoutRepeatPass ? '' : 'НОВЫЙ ПАРОЛЬ'}
              labelColSize={withoutRepeatPass ? 0 : 3}
              inputColSize={6}
              minLength={6}
              fieldFailures={!validNewPass ? { newPass: 'Минимальная длина пароля 6 символов' } : { newPass: newPassError || '' }}
              inputGroupClassNames={styles.row_input}
              inputClassNames={cn(withoutRepeatPass && 'p-0')}
            />
            {!withoutRepeatPass ? (
                <InputText
                  name="repeatPass"
                  value={repeatPass}
                  type="password"
                  onChange={(name, value) => {
                      setRepeatPass(value);
                      setValidRepeatPass(true);
                  }}
                  labelTitle="ПОВТОРИТЕ ПАРОЛЬ"
                  labelColSize={3}
                  inputColSize={6}
                  minLength={6}
                  fieldFailures={!validRepeatPass ? { repeatPass: repeatPass.length < 6 ? 'Минимальная длина пароля 6 символов' : 'Пароли не совпадают' } : {}}
                  inputGroupClassNames={styles.row_input}
                />
            ) : null}
            <Captcha
              captcha={captcha}
              captchaValid={captchaValid}
              apiError={apiError}
              setCaptcha={setCaptcha}
            />
            <Form.Control.Feedback className="d-block" type="invalid">
                {apiError && apiError.failures && apiError.failures.captcha && apiError.message}
            </Form.Control.Feedback>
        </WarningModal>
    );
};

export default ResetPasswordModal;

interface IProps {
    show: boolean;
    apiError?: IApiError | null;
    withoutOldPass?: boolean;
    withoutRepeatPass?: boolean;
    title?: string;
    children?: React.ReactNode;
    onChangePassword: (data: IChangePassword) => Promise<boolean | null>;
    onHide: () => void;
}

export interface IChangePassword {
    newPassword: string;
    oldPassword?: string;
    captcha?: string;
}
