import React from 'react';
import { ChatMessage, ChatMessageType } from '_helpers';
import { useAutoScroll } from '../../hooks';
import OnlineChatAlert from './OnlineChatAlert';
import OnlineChatMessage from './OnlineChatMessage';
import LoadingPlaceholder from './LoadingPlaceholder';
import styles from './OnlineChat.module.scss';
import cn from 'classnames';

const OnlineChat = ({
    messages,
    onGetMessages,
    onSendMessage,
    ...props
}: IProps) => {
    const [tab, setTab] = React.useState(ChatTabs.Messages);
    const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);
    const chatAreaRef = useAutoScroll<HTMLDivElement>(null, messages, props.onReadMessages);
    const [messageText, setMessageText] = React.useState('');

    React.useEffect(() => {
        onGetMessages();
    }, []);

    React.useEffect(() => {
        const message = messages[messages.length - 1];
        if (message && message.type === ChatMessageType.Message && tab === ChatTabs.Alerts) {
            setTab(ChatTabs.Messages);
        }
    }, [messages]);

    React.useLayoutEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    }, [messageText]);

    const handleSendMessage = React.useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            if (messageText.trim()) {
                onSendMessage(messageText.trim());
            }
            setMessageText('');
        }
    }, [messageText]);

    const handleChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessageText(event.target.value.replace(/\n/g, ''));
    }, []);

    const messagesTitle = props.isApplicant ? 'Чат с членом комиссии' : 'Чат с аккредитуемым';

    const chatMessages = React.useMemo(() => {
        if (props.isApplicant) {
            return [...messages].reverse();
        }

        if (tab === ChatTabs.Alerts) {
            return messages.filter(m => m.type !== ChatMessageType.Message).reverse();
        }

        return messages.filter(m => m.type === ChatMessageType.Message).reverse();
    }, [messages, tab]);

    return (
        <div className={cn(styles.chatContainer, props.containerClassName)}>
            {props.withTabs && (
                <div className={styles.tabsContainer}>
                    <button
                      type="button"
                      onClick={() => setTab(ChatTabs.Messages)}
                      className={cn(styles.tab, { [styles.active]: tab === ChatTabs.Messages })}
                    >
                        {messagesTitle}
                    </button>
                    <button
                      type="button"
                      onClick={() => setTab(ChatTabs.Alerts)}
                      className={cn(styles.tab, { [styles.active]: tab === ChatTabs.Alerts })}
                    >
                        Лог событий
                    </button>
                </div>
            )}

            <div className={cn(styles.chat, { [styles.fixed]: props.loading })} ref={chatAreaRef}>
                <LoadingPlaceholder show={props.loading} />
                {chatMessages.map((chatMessage: ChatMessage, i: number, allMessages: ChatMessage[]) => (
                    chatMessage.type === ChatMessageType.Message ? (
                        <OnlineChatMessage
                          key={`chatMessage_${allMessages.length - i}`}
                          message={chatMessage}
                          expertId={props.expertId}
                          isApplicant={props.isApplicant}
                        />
                    ) : (
                            <OnlineChatAlert
                              key={`chatAlert_${allMessages.length - i}`}
                              message={chatMessage}
                            />
                        )))}
            </div>

            <div className={cn(styles.textAreaContainer, { [styles.hideInput]: props.hideInput })}>
                <textarea
                  rows={1}
                  ref={textAreaRef}
                  autoComplete="off"
                  className={styles.textArea}
                  name="message"
                  value={messageText}
                  onChange={handleChange}
                  onKeyPress={handleSendMessage}
                  placeholder="Напишите сообщение …"
                />
            </div>
        </div>
    );
};

export default OnlineChat;

enum ChatTabs {
    Alerts = 'Alerts',
    Messages = 'Messages',
}

interface IProps {
    loading?: boolean;
    messages: ChatMessage[];
    onGetMessages: () => void;
    onReadMessages: () => void;
    onSendMessage: (message: string) => void;
    containerClassName?: string;
    isApplicant?: boolean;
    expertId?: string;
    withTabs?: boolean;
    hideInput?: boolean;
}
