import * as React from 'react';
import { ExamAssigningApplicationsTable } from 'components/ExamAssigningApplicationsTable';
import {
    IExamApplication, IExamStage,
} from 'components/models';
import FinalizeResultModals, { IFinalizeResultModalsProps } from '../FinalizeResultModals';

const FinalizingResultsTab: React.FC<IProps> = (props) => {
    const [applicantsForRate, setApplicantsForRate] = React.useState<IExamApplication[]>([]);
    const [showFinalizingResultModal, setShowFinalizingResultModal] = React.useState(false);


    React.useEffect(() => {
        props.unratedExamApplications.fetch({ examDayId: props.examDayId });
        setApplicantsForRate([]);
    }, [props.examDayId]);

    const handleAssignedApplicants = (applicants: IExamApplication[]) => {
        setApplicantsForRate(applicants);
        setShowFinalizingResultModal(true);
    };

    return (
      <>
          <ExamAssigningApplicationsTable
            readonly={props.readonly}
            withCheckbox
            withConfirmButton
            onAssignedApplicants={handleAssignedApplicants}
            confirmButtonText="Финализировать результат для выбранных"
            headers={[
                { value: 'Имя, фамилия', key: 'applicantFullName' },
                { value: 'Телефон', key: 'applicantPhone' },
            ]}
            examApplications={(props.unratedExamApplications.info && props.unratedExamApplications.info.items.map(i => ({ examApplication: i }))) || []}
            placeholderTitle="Список финализации пуст"
            placeholderText="Здесь будут отображаться все аккредитуемые, требующие финализации результатов"
          />

          <FinalizeResultModals
            assignApplicationsForDay={props.assignApplicationsForDay}
            stage={props.stage}
            finalizeResult={props.finalizeResult}
            unratedExamApplications={props.unratedExamApplications}
            experts={props.experts}
            examId={props.examId}
            stageNumber={props.stageNumber}
            examDayId={props.examDayId}
            onCreateRetakeDay={props.onCreateRetakeDay}
            minAssigningExperts={props.minAssigningExperts}
            error={props.error}
            applicantsForRate={applicantsForRate}
            setApplicantsForRate={setApplicantsForRate}
            showFinalizingResultModal={showFinalizingResultModal}
            hideFinalizingResultModal={() => setShowFinalizingResultModal(false)}
          />
      </>
    );
};

interface IProps extends IFinalizeResultModalsProps {
    readonly?: boolean;
    stage: IExamStage;
}

export default FinalizingResultsTab;
