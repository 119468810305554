import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Card, Captcha, LoaderBtn } from 'components';
import { IApiError } from '../../../applicant/src/api/ApiClient';

const EmailConfirmation = ({
    children, auth, onPush, apiError, onSubmit,
}: IProps) => {
    const [captcha, setCaptcha] = React.useState('');
    const [processing, setProcessing] = React.useState(false);
    const [captchaValid, setCaptchaValid] = React.useState(true);
    const captchaError = Boolean(apiError && apiError.failures && apiError.failures.captcha && apiError.type === 'captcha');
    const tokenError = Boolean(apiError && apiError.failures && apiError.failures.token);

    const doValidate = () => {
        const isCaptchaValid = captchaError ? captcha.length === 4 : true;

        setCaptchaValid(isCaptchaValid);
        return isCaptchaValid;
    };

    const sendCode = async () => {
        if (!doValidate()) return;

        setProcessing(true);
        await onSubmit(captcha);
        setProcessing(false);
        setCaptcha('');
    };

    return (
        <Card
          title={(captchaError || tokenError) ? 'Подтверждение почты' : 'Вы успешно подтвердили почту'}
          apiError={apiError}
        >
            <Captcha
              captcha={captcha}
              captchaValid={captchaValid}
              apiError={apiError}
              setCaptcha={setCaptcha}
            />
            <Form.Control.Feedback className="d-block" type="invalid">
                {(captchaError || tokenError) && apiError && apiError.message}
            </Form.Control.Feedback>
            {captchaError && <LoaderBtn className="mt-4" loading={processing} onClick={sendCode}>Отправить</LoaderBtn>}
            {!(captchaError || tokenError) && <Button className="mb-2" onClick={() => onPush('/')}>{auth ? 'Продолжить' : 'Войти'}</Button>}
            {children}
        </Card>
    );
};

export default EmailConfirmation;

interface IProps {
    auth: boolean;
    apiError?: IApiError | null;
    children?: React.ReactNode;
    onSubmit: (captcha: string) => void;
    onPush: (key: string) => void;
}
