import * as React from 'react';
import styles from '../StageContainer.module.scss';
import deleteIcon from 'components/assets/svg/delete_icon.svg';
import { useLoading } from 'hooks';
import AssigningOneExpertModal from '../Modals/AssigningOneExpertModal';
import { Button, Form } from 'react-bootstrap';
import { formatPhoneNumber, wordDeclination } from '_helpers';
import { IBaseExperts, IBaseExpertsForDay, IExpert } from 'components/models';
import AdvancedTable, { AdvancedTableHeader, AdvancedTableData } from 'components/Common/AdvancedTable/AdvancedTable';
import { LoaderBtn } from 'components/Common/LoaderBtn';

const ExpertsAssignTable: React.FC<IProps> = (props) => {
    const [showAssignOneExpertModal, setShowAssignOneExpertModal] = React.useState(false);
    const [experts, setExperts] = React.useState<IExpert[]>([]);

    React.useEffect(() => {
        if (props.expertsForDayList.info) {
            setExperts(props.expertsForDayList.info.items);
        }
    }, [props.expertsForDayList.info]);

    React.useEffect(() => {
        if (props.autoOpen && !experts.length && (!props.expertsForDayList.info || !props.expertsForDayList.info.items.length)) {
            setShowAssignOneExpertModal(true);
        }
    }, []);


    const handleAdd = React.useCallback(async (expert: IExpert) => {
        if (!experts.filter(e => e.id === expert.id).length) {
            setExperts(experts.concat([expert]));
        }
        setShowAssignOneExpertModal(false);
    }, [experts]);

    const handleRemove = React.useCallback(async (id: string) => {
        setExperts(experts.filter(i => i.id !== id));
    }, [experts]);

    const handleUpdate = React.useCallback(async () => {
       await props.onUpdateExperts(experts);
    }, [experts, props.onUpdateExperts]);

    const [updateExpertsLoading, updateExperts] = useLoading(handleUpdate, [handleUpdate]);

    const listTableHeaders: AdvancedTableHeader[] = [
        { fieldName: '', title: 'ФАМИЛИЯ, ИМЯ, ОТЧЕСТВО', columnStyles: { width: '65%' } },
        { fieldName: '', title: 'ТЕЛЕФОН' },
    ];

    if (!props.readonly) {
        listTableHeaders.push({ fieldName: '', title: '', columnStyles: { minWidth: '1rem' } });
    }

    const tableData = (): AdvancedTableData[] => experts.map((
      {
          id,
          phone,
          firstName,
          middleName,
          lastName,
      },
    ) => {
        const row = {
            id,
            columns: [
                `${lastName} ${firstName} ${middleName}`,
                String(formatPhoneNumber(phone, '')),
            ] as (string | JSX.Element)[],
        };

        if (!props.readonly) {
            row.columns.push(
              !props.disabled ? (
                <div
                  onClick={() => handleRemove(id)}
                  className={styles.removeIcon}
                >
                    <img
                      src={deleteIcon}
                      alt="Удалить члена комиссии"
                    />
                </div>
              ) : '',
            );
        }

        return row;
    });
    const noExpertsCount = React.useMemo(
      () => `${props.minAssigningExperts} член${wordDeclination(props.minAssigningExperts, ['а', 'ов', 'ов'])} комиссии`,
      [props.minAssigningExperts],
    );
    return (
     <>
         {!experts.length && !props.readonly ? <p className="ml-2-0">Вам необходимо добавить, как минимум {noExpertsCount}</p> : undefined}

         <AdvancedTable
           data={tableData()}
           tableHeaders={listTableHeaders}
           handleRowClick={() => {
           }}
           placeholderTitle={!props.readonly ? `Вам необходимо добавить, как минимум ${noExpertsCount}` : ''}
           placeholderClassName={styles.emptyExpertsTable}
         />

         {!props.disabled && !props.readonly && (
           <>
               <Form.Control.Feedback className="d-block ml-2-0 mt-5" type="invalid">
                   <p>
                       {props.apiError}
                   </p>
               </Form.Control.Feedback>
               <div className="d-flex ml-2-0">
                   <Button
                     onClick={() => setShowAssignOneExpertModal(true)}
                     variant="outline-secondary"
                   >
                       Добавить члена комиссии
                   </Button>
                   <LoaderBtn
                     onClick={updateExperts}
                     variant="primary"
                     loading={updateExpertsLoading}
                     className="ml-4"
                   >
                       Завершить назначение
                   </LoaderBtn>
               </div>
           </>
         )}


         <AssigningOneExpertModal
           existedIds={experts.map(e => e.id)}
           examDayId={props.examDayId}
           show={showAssignOneExpertModal}
           onHide={() => setShowAssignOneExpertModal(false)}
           onAccess={handleAdd}
           experts={props.experts}
         />
     </>
    );
};

interface IProps {
    readonly?: boolean;
    disabled?: boolean;
    onHide: () => void;
    examDayId?: string;
    expertsForDayList: IBaseExpertsForDay;
    experts: IBaseExperts;
    onUpdateExperts: (experts: IExpert[]) => void;
    showAssignExpertRow?: boolean;
    minAssigningExperts: number;
    apiError?: string;
    autoOpen?: boolean;
}

export default ExpertsAssignTable;
