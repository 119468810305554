import * as React from 'react';
import logo from 'components/assets/png/logo_unauth.png';
import { Loader } from 'components';

interface AuthorizedLayoutProps {
    children: JSX.Element[] | JSX.Element;
    processing: boolean[];
    location?: Location;
}

const UnAuthorizedLayout: React.FunctionComponent<AuthorizedLayoutProps> = (props) => {
    const { children } = props;

    return (
        <div className="NotAuthorizedLayout">
            <div className="NotAuthorizedLayout--header" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="d-flex align-items-center">
                    <img src={logo} alt="main-logo" height={55} />
                </div>
            </div>
            <div className="NotAuthorizedLayout__content">
                {props.processing.some(p => p) && <Loader />}
                {children}
            </div>
        </div>
    );
};

export default UnAuthorizedLayout;
