import React, { useState, useEffect } from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Location } from 'history';
import { saveToLocalStorage, removeFromLocalStorage, getHeaderTitle } from '_helpers';
import {
    CustomSwitch, Dropdown, IDropdownItem, SidebarTab,
} from 'components';
import styles from './Header.module.scss';
import cx from 'classnames';
import { ExpertStatus } from 'expert/src/api/ApiClient';

const Header = (
  {
      location,
      adaptive,
      sidebarToggled,
      dropdownItems,
      tabs,
      role,
  }: HeaderProps,
) => {
    const [emulateRequestError, toggleEmulateError] = useState(false);
    const headerTitle = getHeaderTitle();
    const hasDropdownItems = dropdownItems && dropdownItems.length > 1;

    const tabsRoutes = React.useMemo(() => tabs
      .filter(t => (t.role ? !!role && t.role.includes(role) : true))
      .map(t => ({
          title: t.name,
          pathname: t.list
            ? t.list
              .filter(i => (i.role ? !!role && i.role.includes(role) : true))
              .reduce<string[]>((all, tl) => [...all, tl.path, ...(tl.routes || [])], [])
            : [],
      })), [tabs, role]);

    useEffect(() => {
        if (emulateRequestError) {
            saveToLocalStorage('statusCode', '500');
        } else {
            removeFromLocalStorage('statusCode');
        }
        return () => {
            removeFromLocalStorage('statusCode');
        };
    }, [emulateRequestError]);

    const getNameOfActivePage = () => {
        const activeRoute = tabsRoutes.find((route) => {
            for (let i = 0; i < route.pathname.length; i += 1) {
                if (isSameLinks(route.pathname[i])) return true;
            }
            return false;
        });
        return activeRoute && activeRoute.title;
    };

    const isSameLinks = (pathname: string) => {
        let locPathName = pathname;
        if (locPathName.includes('?from=')) [locPathName] = locPathName.split('?from=');
        if (locPathName.endsWith('/*')) return location.pathname.startsWith(locPathName.split('/*')[0]);
        return locPathName === location.pathname;
    };

    const renderEmulateErrorSwitch = () => (
        <div className="d-flex align-items-center">
            <div className="mr-3">ON API ERROR:</div>
            <CustomSwitch
              containerClassName="mr-5"
              value={emulateRequestError}
              name="emulateRequestError"
              onClick={() => toggleEmulateError(!emulateRequestError)}
            />
        </div>
    );

    const onToggleSidebar = () => {
        const body = document.querySelector('body');

        if (body && body.classList.contains('hide_sidebar')) {
            body.classList.remove('hide_sidebar');
        } else if (body) {
            body.classList.add('hide_sidebar');
        }
    };

    return (
        <Navbar bg="light" variant="light" className={styles.header}>
            <button
              id="minimizeSidebar"
              className={cx(styles.collapseButton, { [styles.adaptive]: adaptive })}
              onClick={onToggleSidebar}
              type="button"
            >
                <i className={cx('fa', sidebarToggled ? styles.showMenuIcon : styles.hideMenuIcon)} />
            </button>
            <Link to="#" className={cx(styles.header__link)} href="#">
                {getNameOfActivePage()}
            </Link>
            {headerTitle ? (
                <div className={cx(styles.dropdownContainer, !hasDropdownItems && styles.dropdownContainer_withoutDropdown)}>
                    {dropdownItems && hasDropdownItems ? (
                        <Dropdown items={dropdownItems} title={headerTitle} />
                    ) : <label className={styles.headerTitle}>{headerTitle}</label>}
                </div>
            ) : null}
            {process.env.REACT_APP_LOCAL_ENV === 'dev' && renderEmulateErrorSwitch()}
        </Navbar>
    );
};

export default Header;

interface HeaderProps {
    location: Location;
    role?: ExpertStatus;
    tabs: SidebarTab[];
    sidebarToggled?: boolean;
    adaptive?: boolean;
    dropdownItems?: IDropdownItem[] | null;
}
