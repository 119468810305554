import React from 'react';
import {
    ExamApplicationStatus,
    AddressLink,
    renderRow,
    DatePeriodColumn,
} from 'components';

import {
    createExamDate,
} from '_helpers';
import { Alert } from 'react-bootstrap';
import { IApiError } from '_helpers/ApiConnector/types';
import { IExamApplicationDetails } from 'components/models';
import getExamApplicationStatus from './getExamApplicationStatus';

const ExamApplicationDetails = (
  {
      examApplicationDetails,
      apiError,
  }: IProps,
) => {
    if (apiError) {
        return (
          <Alert variant="danger">
              {apiError.message}
          </Alert>
        );
    }

    const { exam } = examApplicationDetails;
    const isResult = examApplicationDetails.status === ExamApplicationStatus.NotPassed || examApplicationDetails.status === ExamApplicationStatus.Passed;
    const colorStatus = getExamApplicationStatus(examApplicationDetails);

    return (
      <section>
          <>
              {renderRow('специальность', exam.qualificationTitle)}

              {renderRow('регион', exam.region)}

              {/*{renderRow('место проведения', <AddressLink {...exam} withoutLink />)}*/}

              {renderRow('Период проведения', <DatePeriodColumn dateFrom={exam.date}  dateTo={exam.lastDate} />)}


              {examApplicationDetails.applicationSubmitDate ? (
                renderRow('Подача заявки', createExamDate(examApplicationDetails.applicationSubmitDate, examApplicationDetails.applicationSubmitDate))
              ) : null}

          </>


          {!!colorStatus && (
            renderRow(isResult ? 'результат' : 'статус', colorStatus.text, undefined, colorStatus.color)
          )}

          
      </section>
    );
};

export default ExamApplicationDetails;


interface IProps {
    examApplicationDetails: IExamApplicationDetails;
    apiError?: IApiError | null;
}
