import * as React from 'react';
import AuthorizedLayout from '../layouts/auth/AuthorizedLayout';
import { Location } from 'history';
import { Switch, Route } from 'react-router';
import {
    ProfilePage,
    NotFoundPage,
    ExamTimeTablesPage,
    ExamDetailsPage,
    ExamApplicationsPage,
    ApplicantExamsPage,
    EmailConfirmationPage,
    PaymentResultPage,
    QualificationsListPage, ExamApplicationDetailsPage,
} from '../pages';
import { ErrorBoundary } from 'components';

const AuthorizedHandler = ({ location }: IProps) => (
    <AuthorizedLayout location={location} adaptive>
        <React.Suspense fallback={<div>Loading...</div>}>
            <ErrorBoundary>
                <Switch location={location}>
                    <Route path="/my-profile" component={ProfilePage} />
                    <Route path="/exam-timetables" component={ExamTimeTablesPage} />
                    <Route path="/exam/:id/:tab?" component={ExamDetailsPage} />
                    <Route path="/exam-application/:id" component={ExamApplicationDetailsPage} />
                    <Route path="/exam-applications" component={ExamApplicationsPage} />
                    <Route path="/applicant-exams" component={ApplicantExamsPage} />
                    <Route path="/applicant-qualifications" component={QualificationsListPage} />
                    <Route path="/confirm-email/:token/" component={EmailConfirmationPage} />
                    <Route path="/payment" component={PaymentResultPage} />
                    <Route component={NotFoundPage} />
                </Switch>
            </ErrorBoundary>
        </React.Suspense>
    </AuthorizedLayout>
);

export default AuthorizedHandler;


interface IProps {
    location: Location;
}
