import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
} from 'react-bootstrap';
import { Card, Captcha, LoaderBtn } from 'components';
import { connect } from 'react-redux';
import { mapAPIError, formatSecretPhoneNumber } from '_helpers';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import styles from './RegistrationStep.module.scss';

const defaultState = {
    verificationCode: '',
};

const RegistrationStep2 = ({
    verificationStatus,
    phoneForVerify,
    sendRegistrationVerificationCode,
    checkRegistrationVerificationCode,
    setRegisterForm,
    nextStep,
    prevStep,
    apiError,
}: Props) => {
    const [form, setForm] = useState(defaultState);
    const [errors, setErrors] = useState(defaultState);
    const [captcha, setCaptcha] = React.useState('');
    const [captchaValid, setCaptchaValid] = React.useState(true);
    const [resendTimer, setResendTimer] = useState(0);
    const [processing, setProcessing] = useState(false);

    const number = formatSecretPhoneNumber(phoneForVerify);

    const calcTimer = () => {
        if (resendTimer) {
            setResendTimer(resendTimer - 1);
        }
    };

    useEffect(() => {
        const id = setTimeout(calcTimer, 1000);

        return () => clearTimeout(id);
    }, [resendTimer]);

    useEffect(() => {
        if (verificationStatus && verificationStatus.waitingSeconds) {
            setResendTimer(verificationStatus.waitingSeconds);
        }
    }, [verificationStatus]);

    useEffect(() => {
        setErrors({
            ...errors,
            ...mapAPIError(apiError),
        });
    }, [apiError]);

    const clearState = () => {
        setForm(defaultState);
        setErrors(defaultState);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (typeof value !== 'string' || !name) return;

        const newForm = {
            ...form,
            [name]: value,
        };
        setForm(newForm);
    };

    const onClose = () => {
        clearState();
        prevStep();
    };

    const doValidate = () => {
        const isCaptchaValid = apiError && apiError.failures && apiError.failures.captcha ? captcha.length === 4 : true;

        setCaptchaValid(isCaptchaValid);
        return isCaptchaValid;
    };

    const resendVerificationCode = async () => {
        if (!doValidate()) return;

        clearState();

        setProcessing(true);
        await sendRegistrationVerificationCode({
            phone: phoneForVerify,
            captcha,
        });
        setProcessing(false);
        setCaptcha('');
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!doValidate()) return;

        setProcessing(true);
        const result = await checkRegistrationVerificationCode({
            phone: phoneForVerify,
            verificationCode: form.verificationCode,
            captcha,
        });

        setProcessing(false);
        setCaptcha('');

        if (result) {
            setRegisterForm(form);
            nextStep();
        }
    };

    return (
        <React.Fragment>
            <Col className="auth-forms--container">
                <form onSubmit={handleSubmit}>
                    <Card
                      title="Регистрация"
                      category={`На номер ${number} был отправлен код для подтверждения.`}
                      apiError={apiError}
                      contentClassName={styles.content}
                      legend={(
                            <div>
                                <LoaderBtn
                                  variant="primary"
                                  type="submit"
                                  disabled={!form.verificationCode || processing}
                                  loading={processing}
                                >
                                    Продолжить
                                </LoaderBtn>
                                <Button
                                  variant="outline-secondary"
                                  onClick={onClose}
                                >
                                    Назад
                                </Button>
                            </div>
                        )}
                      ftTextCenter
                    >
                        <FormGroup data-test-verification-code-group>
                            <FormLabel>Введите код из СМС</FormLabel>
                            <FormControl
                              autoFocus
                              name="verificationCode"
                              onChange={handleChange}
                              isInvalid={Boolean(errors.verificationCode)}
                              value={form.verificationCode}
                              minLength={4}
                              maxLength={4}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.verificationCode}
                            </Form.Control.Feedback>

                            {resendTimer ? (
                                <div className={styles.resend_interval_text}>
                                    Отправить повторно
                                    через {resendTimer} секунд
                                </div>
                            ) : (
                                    <button className={styles.resend_button} onClick={resendVerificationCode} type="button">
                                        Отправить код повторно
                                    </button>
                                )}
                        </FormGroup>
                        <Captcha captcha={captcha} captchaValid={captchaValid} apiError={apiError} setCaptcha={setCaptcha} />
                        <Form.Control.Feedback className="d-block" type="invalid">
                            {apiError && (!(apiError.failures && apiError.failures.verificationCode) || apiError.type === 'captcha') && apiError.message}
                        </Form.Control.Feedback>
                    </Card>
                </form>
            </Col>
        </React.Fragment>
    );
};

const mapState = (state: IRootState) => ({
    phoneForVerify: state.auth.registerForm.phone,
    verificationStatus: state.auth.verificationStatus,
    apiError: state.errors.error,
});
const mapDispatch = (dispatch: Dispatch) => ({
    setRegisterForm: dispatch.auth.setRegisterForm,
    sendRegistrationVerificationCode: dispatch.auth.sendRegistrationVerificationCode,
    checkRegistrationVerificationCode: dispatch.auth.checkRegistrationVerificationCode,
});

interface OwnProps {
    nextStep: () => void;
    prevStep: () => void;
}

type connectedProps = ReturnType<typeof mapDispatch> & ReturnType<typeof mapState>;
type Props = connectedProps & OwnProps;

export default connect(mapState, mapDispatch)(RegistrationStep2);
