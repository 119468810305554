import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { ExamApplicationStatus } from '../enums';
import { renderRow } from '../Row';
import { IExamApplicationDetails } from '../models';
import { IApiError } from '../../_helpers/ApiConnector/types';
import getExamApplicationStatus from './getExamApplicationStatus';

const StagingExamApplicationDetails: React.FC<IProps> = (
  {
      examApplicationDetails,
      apiError,
  }: IProps,
) => {
    if (apiError) {
        return (
          <Alert variant="danger">
              {apiError.message}
          </Alert>
        );
    }

    const isResult = examApplicationDetails.status === ExamApplicationStatus.NotPassed || examApplicationDetails.status === ExamApplicationStatus.Passed;
    const colorStatus = getExamApplicationStatus(examApplicationDetails);

    return (
      <section>
          {examApplicationDetails.examDayApplications.map(ea => (
            renderRow(`ЭТАП ${ea.stageNumber} ${ea.attemptNumber > 0 ? `(ПЕРЕСДАЧА ${ea.attemptNumber})` : ''}`, ea.examDayDateTime.local().format('DD.MM.YYYY в HH:mm'))
          ))}

          {!!colorStatus && (
            renderRow(isResult ? 'результат' : 'статус', colorStatus.text, undefined, colorStatus.color)
          )}
          
      </section>
    );
};

interface IProps {
    examApplicationDetails: IExamApplicationDetails;
    apiError?: IApiError | null;
}

export default StagingExamApplicationDetails;
