import React from 'react';
import { Card, CKText } from 'components';
import styles from './RulesTab.module.scss';

const RulesTab = ({ rules }: IProps) => (
    <Card
      withoutBorder
      title="Правила проведения"
      category="Общие правила проведения аккредитации"
      contentClassName={styles.RulesTab}
    >
        <CKText text={rules} />
    </Card>
);

export default RulesTab;

interface IProps {
    rules?: string;
}
