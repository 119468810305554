import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { Loader } from 'components';
import styles from './LoaderBtn.module.scss';
import cn from 'classnames';

const LoaderBtn = ({
    type, loading, disabled, className, onClick, variant, children,
}: IProps) => (
        <Button
          type={type}
          disabled={loading || disabled}
          className={cn(styles.loaderBtn, className)}
          onClick={onClick}
          variant={variant || 'primary'}
          data-test-loader-btn
        >
            <div className={cn(styles.btn_content, loading && styles.btn_content_offset)}>
                {children}
            </div>

            {loading ? <Loader className={styles.loader} /> : null}
        </Button>
    );


export default LoaderBtn;

interface IProps {
    disabled?: boolean;
    className?: string;
    variant?: ButtonProps['variant'];
    type?: ButtonProps['type'];
    loading?: boolean;
    children?: React.ReactNode;
    onClick?: () => void;
}
