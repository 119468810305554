/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import {
    ErrorPlaceholder,
    Loader,
    UnsavedFormModal,
    NotFound,
    DotsSettings,
    IDotsButtonProps,
} from 'components';
import { Alert } from 'react-bootstrap';
import { IApiError } from '_helpers/ApiConnector/types';
import { formDetector } from '_helpers';
import cx from 'classnames';
import backArrow from 'components/assets/svg/back_arrow.svg';
import styles from './Card.module.scss';

const Card = ({
    showLoader,
    apiError,
    cardClassName,
    fullHeight,
    hidden,
    calendar,
    plain,
    wizard,
    withoutBorder,
    title,
    category,
    textCenter,
    borderedTitle,
    withoutPaddingTitle,
    withBackButton,
    onClickBack,
    categoryClassName,
    withoutPadding,
    ctAllIcons,
    ctFullWidth,
    ctTextCenter,
    tableFullWidth,
    contentClassName,
    children,
    stats,
    legend,
    ftTextCenter,
    detectFormChanges,
    footerElement,
    saveAndCloseDetectFunc,
    smallTitle,
    headerChild,
    titleClassName,
    containerRef,
    show404Error,
    headerClassName,
    withoutTitleMargin,
    dotsButtons,
    without404Error,
    withoutBottomMargin,
                  extHeader,
}: CardProps) => {
    React.useEffect(() => {
        if (detectFormChanges) {
            formDetector.setDetectChanges(true);

            const forms = document.querySelectorAll('form');
            const submitButtons = document.querySelectorAll('button[type=submit]');
            const onFormSubmit = () => formDetector.setFormSubmit(true);

            forms.forEach(form => form.addEventListener('submit', onFormSubmit));
            submitButtons.forEach(form => form.addEventListener('click', onFormSubmit));

            return () => {
                forms.forEach(form => form.removeEventListener('submit', onFormSubmit));
                submitButtons.forEach(form => form.removeEventListener('click', onFormSubmit));
                formDetector.resetDetectState();
            };
        }

        return () => true;
    }, []);

    if (showLoader) return <Loader fullHeight />;

    if (!without404Error && (apiError && (apiError.code === 404 || show404Error))) return <NotFound />;

    return (
        <>
            {apiError && apiError.code === 500 ? <ErrorPlaceholder withCard apiError={apiError} /> : (
                <div
                  className={cx('card', styles.card, cardClassName,
                        {
                            [styles.fullHeight]: fullHeight,
                            'card-hidden': hidden,
                            'card-calendar': calendar,
                            'card-plain': plain,
                            'card-wizard': wizard,
                            'border-0': withoutBorder,
                            'mb-0': withoutBottomMargin,
                        })
                    }
                  ref={containerRef}
                >
                    {title !== undefined || category !== undefined ? (
                        <div
                          className={cx(
                                styles.card__header,
                                'header',
                                textCenter && 'text-center',
                                borderedTitle && styles.titleBorder,
                                withoutPaddingTitle && 'pl-0',
                                headerClassName,
                            )}
                        >
                            <div className={cx(withBackButton && styles.title__withButton)}>
                                {withBackButton && (
                                    <img src={backArrow} alt="goBack" onClick={onClickBack /* onBackClick */} />
                                )}
                                <div>
                                    <h4
                                      className={cx(styles.card_title, smallTitle && styles.card_title_small, withoutTitleMargin && styles.card_title_withoutMargin, titleClassName)}
                                    >
                                        {title}
                                        {dotsButtons ? <DotsSettings buttons={dotsButtons} /> : null}
                                    </h4>
                                    <p
                                      className={cx('category', styles.category, categoryClassName)}
                                    >
                                        {category}
                                    </p>
                                </div>
                            </div>
                            <div className={styles.headerChildContainer}>
                                {headerChild}
                            </div>
                        </div>
                    ) : (
                            ''
                        )}
                    <div
                      className={cx(
                            'content',
                            { [styles.content__withoutPadding]: withoutPadding },
                            { [styles.fullHeight]: fullHeight },
                            { 'all-icons': ctAllIcons },
                            { 'content-full-width': ctFullWidth },
                            { 'text-center': ctTextCenter },
                            { 'table-full-width': tableFullWidth },
                            contentClassName && contentClassName,
                        )}
                    >
                        {extHeader && (
                          <div className={cx(styles.admitted, { 'ml-30': withoutPadding })}>
                              {extHeader.map(h => ([
                                  <span key={h.label}>{h.label}</span>,
                                  <span key={h.value}> {h.value}</span>,
                              ]))}
                          </div>
                        )}
                        {children}
                    </div>
                    {footerElement}
                    {stats !== undefined || legend !== undefined ? (
                        <div className={`footer${ftTextCenter ? ' text-center' : ''}`}>
                            {legend !== undefined ? (
                                <div className="legend">{legend}</div>
                            ) : null}
                            {stats !== undefined ? <hr /> : null}
                            {stats !== undefined ? (
                                <div className="stats">{stats}</div>
                            ) : null}
                        </div>
                    ) : null}

                    {apiError && apiError.message !== 'Unauthorized' && apiError.code !== 500 && (!apiError.failures || !Object.keys(apiError.failures).length) && (
                        <Alert autoFocus className={styles.errorPlaceholder} variant="danger">
                            {apiError.message}
                        </Alert>
                    )}
                    {detectFormChanges ? <UnsavedFormModal saveAndCloseDetectFunc={saveAndCloseDetectFunc} /> : null}
                </div>
            )}
        </>
    );
};

export interface CardProps {
    plain?: string;
    title?: string;
    category?: string;
    legend?: React.ReactNode;
    containerRef?: React.MutableRefObject<HTMLDivElement | null>;
    extHeader?: ExtHeader[];

    hidden?: boolean;
    textCenter?: boolean;
    ftTextCenter?: boolean;
    withoutPadding?: boolean;
    withoutPaddingTitle?: boolean;
    borderedTitle?: boolean;
    fullHeight?: boolean;
    withBackButton?: boolean;
    smallTitle?: boolean;
    titleClassName?: string;
    headerChild?: React.ReactNode;
    show404Error?: boolean;
    without404Error?: boolean;
    withoutBottomMargin?: boolean;

    ctAllIcons?: string;
    ctFullWidth?: string;
    ctTextCenter?: string;
    tableFullWidth?: string;
    stats?: {};

    calendar?: string;
    wizard?: string;

    withoutBorder?: boolean;
    withoutTitleMargin?: boolean;
    headerClassName?: string;
    categoryClassName?: string;
    cardClassName?: string;
    contentClassName?: string;
    children?: React.ReactNode;
    footerElement?: React.ReactNode;
    apiError?: IApiError | null;
    showLoader?: boolean;
    detectFormChanges?: boolean;
    dotsButtons?: IDotsButtonProps[];
    onClickBack?: () => void;
    saveAndCloseDetectFunc?: () => Promise<boolean>;
}

interface ExtHeader {
    label: string;
    value: string;
}

export default Card;
