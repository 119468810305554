import React, {useEffect} from 'react';
import {AnimatedTabs, ExamStatus,} from 'components';
import {
    IAdmitToExamDay,
    IAssignApplicationsForDay,
    IAssignForDayInput,
    IBaseApplicantDetails,
    IBaseExamApplicationsForDay,
    IBaseExamApplicationsForDayAdmit,
    IBaseExamUnAssignedApplicationsList,
    IBaseExperts,
    IBaseExpertsForDay,
    IBaseUnratedExamApplications,
    ICreateExamDayInput,
    IExamApplication,
    IExamDetails,
    IExamStage,
    IFinalizeResultPayload,
    IUpdateAssignedExpertsForDay,
    IUpdateExamDay,
} from 'components/models';
import {Tab} from 'react-bootstrap';
import {ApplicantsTab, IApplicantsTabProps} from './ApplicantsTab';
import {ExamInfoTab} from './ExamInfoTab';
import StageContainer from './ExamStagesTabs/AssigningStage/StageContainer';
import {IApiError} from '_helpers/ApiConnector/types';
import AwaitingStage from './ExamStagesTabs/AwaitingStage/AwaitingStage';
import {PaginationImpl} from '../defaultValues';

const ExamDetailsTabs = ({
                             tab,
                             applicantsTabProps,
                             examDetails,
                             examApplicationsList,
                             withoutApplicantsTab,
                             withoutExpertsTab,
                             ...props
                         }: IProps) => {
    const getAssigningStage = (s: IExamStage) => (
      <StageContainer
        readonly={props.readonly}
        createExamDay={props.createExamDay}
        assignApplicationsForDay={props.assignApplicationsForDay}
        updateExpertsForDay={props.updateExpertsForDay}
        error={props.error}
        stageNumber={s.stageNumber}
        examDetails={examDetails}
        examUnAssignedApplicationsList={props.examUnAssignedApplicationsList}
        updateDay={props.updateDay}
        examApplicationsForDay={props.examApplicationsForDay}
        experts={props.experts}
        expertsForDayList={props.expertsForDayList}
        finalizeResult={props.finalizeResult}
        applicantDetails={props.applicantDetails}
        examApplicationsForDayAdmit={props.examApplicationsForDayAdmit}
        admitToExamDay={props.admitToExamDay}
        unratedExamApplications={props.unratedExamApplications}
      />
    );

    const getAwaitingStage = (s: IExamStage) => (
      <AwaitingStage stageInfo={s} />
    );

    const stagesMap: Record<string, (s: IExamStage) => JSX.Element> = {
        defaultReturn: getAssigningStage,
        [ExamStatus.AwaitingExpertConfirmation]: getAwaitingStage,
    };

    const renderStage = React.useMemo(() => {
        if (examDetails.status in stagesMap) {
            return stagesMap[examDetails.status];
        }

        return stagesMap.defaultReturn;
    }, [examDetails.status, stagesMap]);

    const checkPropsTab = () => {
        if (tab && (tab === TabKey.Rules
          || (tab === TabKey.Applicants && !withoutApplicantsTab)
          || tab === TabKey.Info
          || (tab === TabKey.Experts && !withoutExpertsTab))) {
            return tab;
        }

        return null;
    };

    const getDefaultTab = () => {
        if (checkPropsTab()) {
            return checkPropsTab();
        }

        if (examStatus === ExamStatus.IsApplying) return TabKey.Info;
        if (!withoutApplicantsTab) return TabKey.Applicants;
        if (!withoutExpertsTab) return TabKey.Experts;

        return TabKey.Info;
    };

    React.useEffect(() => {
        if (!activeTab) {
            setActiveTab(getDefaultTab());
        }
    }, [examDetails]);

    const [activeTab, setActiveTab] = React.useState(getDefaultTab());
    const examStatus = examDetails.status;
    const examInCreateStatus = examStatus === ExamStatus.AwaitingExpertConfirmation;

    useEffect(() => {
        if (String(activeTab).startsWith('stage')) {
            props.examUnAssignedApplicationsList.fetch({
                examId: examDetails.id,
                stage: +String(activeTab).split('/')[1],
            });
        }
    }, [activeTab]);


    return (
      <>
          <AnimatedTabs
            id="ExamDetailsTabs"
            activeKey={activeTab}
            inModal={props.inModal}
            onSelect={(key: string) => setActiveTab(key as TabKey)}
          >

              <Tab eventKey={TabKey.Info} title="Информация" className="tab p-0">
                  <ExamInfoTab examDetails={examDetails} />
                  {props.renderAppendForInfo && props.renderAppendForInfo(examDetails)}
              </Tab>

              {!!examDetails && (!props.readonly || examStatus !== ExamStatus.IsApplying) && examDetails.examStages.map((s, i) => (
                <Tab eventKey={`stage/${String(i + 1)}`} title={`Этап ${i + 1}`} key={i}>
                    {s.stageNumber === +String(activeTab).split('/')[1] && (
                      <>
                          {renderStage(s)}
                      </>
                    )}
                </Tab>
              ))}

              {!withoutApplicantsTab && applicantsTabProps && examApplicationsList && !examInCreateStatus ? (
                <Tab
                  eventKey={TabKey.Applicants}
                  title="Список аккредитуемых"
                  className="tab p-0"
                >
                    <ApplicantsTab
                      examDetails={examDetails}
                      examApplications={examApplicationsList}
                      examStatus={examStatus}
                      handleApplicantClick={applicantsTabProps.handleApplicantClick}
                      handleApplicantsListChange={applicantsTabProps.handleApplicantsListChange}
                      pagination={new PaginationImpl(examApplicationsList).getPaginationData()}
                    />
                </Tab>
              ) : null}
          </AnimatedTabs>
      </>
    );
};

export default ExamDetailsTabs;

export interface IProps {
    inModal?: boolean;
    readonly?: boolean;
    examDetails: IExamDetails;
    examApplicationsList?: IExamApplicationList;
    applicantsTabProps?: IApplicantsTabProps;
    withoutApplicantsTab?: boolean;
    withoutExpertsTab?: boolean;
    tab?: TabType;
    renderAppendForInfo?: (ed: IExamDetails) => JSX.Element | undefined | null | boolean;

    stageNumber?: string;
    error: IApiError | null;

    examUnAssignedApplicationsList: IBaseExamUnAssignedApplicationsList;
    expertsForDayList: IBaseExpertsForDay;
    examApplicationsForDay: IBaseExamApplicationsForDay;
    experts: IBaseExperts;
    unratedExamApplications: IBaseUnratedExamApplications;
    applicantDetails: IBaseApplicantDetails;
    examApplicationsForDayAdmit: IBaseExamApplicationsForDayAdmit;

    admitToExamDay: (props: IAssignForDayInput<IAdmitToExamDay>) => void;
    finalizeResult: (payload: IFinalizeResultPayload) => Promise<boolean>;
    updateDay: (data: IAssignForDayInput<IUpdateExamDay>) => Promise<IExamDetails | null>;
    createExamDay: (data: ICreateExamDayInput) => Promise<IExamDetails | null>;
    assignApplicationsForDay: (data: IAssignForDayInput<IAssignApplicationsForDay>) => Promise<unknown>;
    updateExpertsForDay: (data: IAssignForDayInput<IUpdateAssignedExpertsForDay>) => Promise<IExamDetails | null>;
}

ExamDetailsTabs.defaultProps = {
    admitToExamDay: () => true,
    finalizeResult: () => true,
    updateDay: () => true,
    createExamDay: () => true,
    assignApplicationsForDay: () => true,
    updateExpertsForDay: () => true,
};

interface IExamApplicationList {
    admittedCount: number;
    items: IExamApplication[];
    page: number;
    pageSize: number;
    total: number;
}

enum TabKey {
    Applicants = 'applicants',
    Experts = 'experts',
    Info = 'info',
    Rules = 'rules',
}

type TabType = 'applicants' | 'experts' | 'info' | 'rules'
