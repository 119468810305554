/* eslint-disable no-useless-escape */
export const RegExpConst = {
    PHONE: /^[0-9]{10}$/,
    SNILS: /(^[0-9]{11}$)|(^\d{3})-(\d{3})-(\d{3}) (\d{2}$)/,
    EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NAME: /^([а-яёА-ЯЁ][а-яёА-ЯЁ\- ]{0,28}[а-яёА-ЯЁ]|-)$/,
    PASSWORD: /^[\\#@$-/:-?{-~!"^_`\[\]a-zA-Z0-9]{6,15}$/,
    NUMBER: /^[0-9]{1,15}$/,
    DOUBLE: /^[0-9]*([,.][0-9]+)?$/,
};
