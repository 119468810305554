import React from 'react';
import { IDropZoneUploadedDocument, IUploadedDocumentProps, UploadedDocument } from '../UploadedDocument';
import { Form } from 'react-bootstrap';
import { IApiError } from '_helpers/ApiConnector/types';
import ReactDropZone, { FileRejection } from 'react-dropzone';
import styles from './DropZone.module.scss';
import dropIcon from './drop_icon.svg';
import cn from 'classnames';
import { DocumentType, DocumentTypeTitles } from "components/enums";

const DropZone = ({
    template,
    onDrop,
    templateNum,
    uploadedDocuments,
    apiError,
    onDeleteFile,
    onDeleteSource,
    loadingFile,
    lastDropTemplateId,
}: IProps) => {
    const [showErrorUploadFile, setShowErrorUploadFile] = React.useState(false);

    const isUploadedDropZone = (documentTemplateId: string) => uploadedDocuments.length && uploadedDocuments.find(file => file.documentTemplateId === documentTemplateId);

    const getDocFromTemplate = (templateId: string) => uploadedDocuments.find(file => file.documentTemplateId === templateId);

    const getDocSourceFiles = (templateId: string): IFile[] => {
        const doc = getDocFromTemplate(templateId);
        let sourceFiles: IFile[] = [];

        if (doc) {
            sourceFiles = [...doc.sourceFiles.map(file => ({
                ...file,
                documentTemplateId: templateId,
                isSource: true,
            }))];
        }

        return sourceFiles;
    };

    const onDropFile = (file: File[], fileRejection: FileRejection[]) => {
        setShowErrorUploadFile(false);

        if (!fileRejection.length) {
            onDrop(file, template.id);
        } else {
            setShowErrorUploadFile(true);
        }
    };

    const uploadProps = {
        onDeleteFile,
        getDocFromTemplate,
        onDeleteSource,
    };

    return (
        <section
          className={cn(
                styles.document,
                styles.document_dropZone,
                isUploadedDropZone(template.id) && styles.document_dropZone_uploaded,
            )}
        >
            <div className={styles.document_dropZone_uploadedTitle}>ЗАГРУЖЕНО</div>
            {templateNum ? <span className={styles.document_title}>{DocumentTypeTitles[template.type]}</span> : null}
            {template.description ? <span className={styles.document_text}>{template.description}</span> : null}
            <ReactDropZone
              accept="image/jpeg, image/png, image/jpg, application/pdf"
              onDrop={onDropFile}
              disabled={!!loadingFile}
            >
                {({ getRootProps, getInputProps }) => (
                    <section className={styles.drop}>
                        <div className={styles.drop_fieldContainer} {...getRootProps()}>
                            <img className={styles.drop_icon} src={dropIcon} alt="dropIcon" />
                            <input {...getInputProps()} />
                            <span className={styles.drop_text}>Перетащите файлы или <span className={styles.drop_link}>выберите с компьютера</span>.</span>
                        </div>
                    </section>
                )}
            </ReactDropZone>

            <div>
                {getDocSourceFiles(template.id).map((file, i) => <UploadedDocument key={`doc_${i}`} file={file} {...uploadProps} />)}
                {loadingFile && lastDropTemplateId === template.id ? (
                    <UploadedDocument
                      file={{
                            ...loadingFile,
                            fileExtension: loadingFile.file.name,
                            fileSize: loadingFile.file.size,
                            fileId: '',
                        }}
                      {...uploadProps}
                    />
                ) : null}

                {apiError && lastDropTemplateId === template.id ? (
                    <Form.Control.Feedback className="d-block" type="invalid">
                        {apiError.message}
                    </Form.Control.Feedback>
                ) : null}

                {showErrorUploadFile ? (
                    <Form.Control.Feedback className="d-block" type="invalid">
                        Неверный формат файла
                    </Form.Control.Feedback>
                ) : null}
            </div>
        </section>
    );
};

export default DropZone;

export interface IProps {
    template: ITemplate;
    uploadedDocuments: IDropZoneUploadedDocument[];
    apiError: IApiError | null | undefined;
    loadingFile: ILoadingFile | null;
    lastDropTemplateId: string | null;
    templateNum?: number;
    onDeleteFile: IUploadedDocumentProps['onDeleteFile'];
    onDeleteSource: IUploadedDocumentProps['onDeleteSource'];
    onDrop: (file: File[], templateId: string) => void;
}

interface ITemplate {
    id: string;
    order: number;
    variant: number;
    description: string;
    type: DocumentType;
}

interface IFile {
    fileId: string;
    fileExtension: string;
    fileName?: string;
    fileSize: number;
    documentTemplateId?: string;
    isSource?: boolean;
    progress?: number;
}

interface ILoadingFile {
    file: File;
    progress: number;
}
