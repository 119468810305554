class VersionReader {
    public static getVersion() {
        try {
            const scripts: HTMLScriptElement[] = Array.prototype.slice.call(document.querySelectorAll('script'));
            const links: HTMLLinkElement[] = Array.prototype.slice.call(document.querySelectorAll('link'));

            const chunkCss = links.filter(link => link.href.includes('chunk.css'));
            const chunkJs = scripts.filter(script => script.src.includes('chunk.js'));

            if (!chunkJs.length || !chunkCss.length) return 'local';

            const files = chunkCss.map((f) => {
                const sp = f.href.split('/');
                return sp[sp.length - 1];
            }).sort().concat(chunkJs.map((f) => {
                const sp = f.src.split('/');
                return sp[sp.length - 1];
            }).sort());

            return files.map(n => n.split('.')[1]).join('.');
        } catch (e) {
            console.error(e);
            return 'local';
        }
    }
}

export const appVersion = VersionReader.getVersion();
