import React from 'react';
import {renderRow} from 'components';
import {IApplicant, IApplicantIdentityDoc, IApplicantDiploma} from 'components/models';
import { Sex,
    IdentityDocTypeTitles,
    CitizenshipTypeTitles,
    MilitaryRelationshipTypeTitles,
    DiplomaTypeTitles,
} from 'components/enums';
import { formatSnils } from '_helpers';

const ProfileInfoTab = ({ applicant, applicantIdentityDoc, applicantDiploma }: IProps) => {
    const { identityDocType, passport, otherIdentity } = applicantIdentityDoc;

    return (
        <section>
            {renderRow('Фамилия', applicant.lastName)}
            {renderRow('Имя', applicant.firstName)}
            {renderRow('Отчество', applicant.middleName || '')}
            
            {renderRow('Пол', applicant.sex == Sex.Female ? 'Женский' : 'Мужской', 'mt-5' )}
            {renderRow('Дата рождения', applicant.birthDate.format('DD.MM.YYYY'))}
        
            {renderRow('УДОСТОВЕРЕНИЕ ЛИЧНОСТИ', IdentityDocTypeTitles[identityDocType], 'mt-5')}

            {passport ? (
                <>
                    {renderRow('Серия/номер', passport.serialNumber)}
                    {renderRow('Кем выдан', passport.issuedBy)}
                    {renderRow('Дата выдачи', passport.dateOfIssue.format('DD.MM.YYYY'))}
                    {renderRow('Адрес регистрации', passport.registrationAddress)}
                </>
            ) : otherIdentity ? (
                <>
                    {renderRow('Данные документа', otherIdentity.data)}
                </>
            ) : null}

            {renderRow('Тип Гражданства', applicant.citizenshipType ? CitizenshipTypeTitles[applicant.citizenshipType] : '-', 'mt-5')}
            {renderRow('Гражданство', applicant.citizenshipCountryName || '-')}
            {renderRow('Воинская служба', applicant.militaryRelationship ? MilitaryRelationshipTypeTitles[applicant.militaryRelationship] : '-', 'mt-5')}
            
            {applicantDiploma && (
                <>
                    {renderRow('ДОКУМЕНТ ОБ ОБРАЗОВАНИИ', DiplomaTypeTitles[applicantDiploma.type], 'mt-5')}
                    {renderRow('НОМЕР ДОКУМЕНТА', applicantDiploma.serialNumber)}
                    {renderRow('Кем выдан', applicantDiploma.issuedBy)}
                    {renderRow('Дата выдачи', applicantDiploma.dateOfIssue.format('DD.MM.YYYY'))}
                </>
            )}

            {renderRow('СНИЛС', formatSnils(applicant.snils, ''), 'mt-5')}
        </section>
    );
};

export default ProfileInfoTab;

interface IProps {
    applicant: IApplicant;
    applicantIdentityDoc: IApplicantIdentityDoc;
    applicantDiploma?: IApplicantDiploma;
}
