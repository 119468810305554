import React from 'react';
import {
    InputText,
    ExtendedSelect,
    DatePicker,
    LoaderBtn,
    Textarea,
} from 'components';
import  { IApplicantPassport, IApplicantOtherIdentity } from 'components/models';
import { IdentityDocType, IdentityDocTypeTitles } from 'components/enums';
import { IApiError } from '_helpers/ApiConnector/types';
import { Form, Col, Button } from 'react-bootstrap';
import styles from './Identification.module.scss';
import moment from 'moment';
import cn from 'classnames';
import _ from 'lodash';

const Identification = ({
    apiError,
    onlyWatch,
    stateData,
    buttonText,
    onSubmitPassport,
    onSubmitOther,
    onCancel,
    loading,
    withoutSubmitButton,
}: IProps) => {
    const initErrors: IInitErrors = {
        identityDocType: '',
        serialNumber: '',
        issuedBy: '',
        dateOfIssue: '',
        registrationAddress: '',
        title: '',
        data: '',
    };
    const [validErrors, setValidErrors] = React.useState(initErrors);
    const [processing, setProcessing] = React.useState(false);

    // passport
    const [identityDocType, setIdentityDocType] = React.useState(IdentityDocType.Passport);
    const [serialNumber, setSerialNumber] = React.useState('');
    const [issuedBy, setIssuedBy] = React.useState('');
    const [dateOfIssue, setDateOfIssue] = React.useState<moment.Moment | undefined>(undefined);
    const [registrationAddress, setRegistrationAddress] = React.useState('');

    // other
    const [data, setData] = React.useState('');

    const docTypeOptions = Object.keys(IdentityDocTypeTitles).map(k => ({
        value: k,
        label: IdentityDocTypeTitles[k as keyof typeof IdentityDocTypeTitles],
    }));

    React.useEffect(() => {
        if (stateData && stateData.passport) {
            if (stateData.passport.serialNumber) {
                setIdentityDocType(IdentityDocType.Passport);
                setSerialNumber(stateData.passport.serialNumber);
                setIssuedBy(stateData.passport.issuedBy);
                setDateOfIssue(stateData.passport.dateOfIssue);
                setRegistrationAddress(stateData.passport.registrationAddress);
                setData('');
            }
        }

        if (stateData && stateData.otherIdentity) {
            setIdentityDocType(IdentityDocType.Residence);
            setData(stateData.otherIdentity.data);
            setSerialNumber('');
            setIssuedBy('');
            setDateOfIssue(undefined);
            setRegistrationAddress('');
        }
    }, [stateData]);

    React.useEffect(() => {
        if (apiError && apiError.failures) {
            const errors = apiError.failures;

            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key) && errors[key]) {
                    const error = errors[key].join(',');
                    const newErrors = validErrors;
                    newErrors[key] = error;

                    setValidErrors({ ...newErrors });
                }
            }
        }
    }, [apiError, validErrors]);

    const handleDateChange = (val: string, momentDate: moment.Moment) => {
        setDateOfIssue(momentDate);
        setValidErrors({ ...validErrors, dateOfIssue: '' });
    };

    const checkFieldsErrors = (submitData: IApplicantPassport | IApplicantOtherIdentity) => _.mapValues(submitData, (val, key) => {
        if (key === 'dateOfIssue' && !val) return 'Обязательное поле для заполнения';
        if (val === '') return 'Обязательное поле для заполнения';
        return '';
    });

    const isValidFormData = (fieldsError: IInitErrors): boolean => _.values(fieldsError).every(e => e.length === 0);

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let subData: IApplicantPassport | IApplicantOtherIdentity;

        if (identityDocType === IdentityDocType.Passport && onSubmitPassport && dateOfIssue !== undefined) {
            subData = {
                serialNumber,
                issuedBy,
                dateOfIssue,
                registrationAddress,
            };

            const errors = checkFieldsErrors(subData) as IInitErrors;
            setValidErrors(errors);

            if (isValidFormData(errors)) {
                setProcessing(true);
                await onSubmitPassport(subData);
                setProcessing(false);
            }
        } else if (identityDocType !== IdentityDocType.Passport && onSubmitOther) {
            subData = {
                data,
            };

            const errors = checkFieldsErrors(subData) as IInitErrors;
            setValidErrors(errors);

            if (isValidFormData(errors)) {
                setProcessing(true);
                await onSubmitOther(subData, identityDocType);
                setProcessing(false);
            }
        }
    };

    return (
        <Form onSubmit={submit}>
            <Form.Group className={cn('row', styles.align_center)}>
                <Col sm="2" className="p-0">
                    <Form.Label column>
                        ТИП ДОКУМЕНТА
                    </Form.Label>
                </Col>
                <Col sm="6" className={cn('p-0', styles.col)}>
                    <ExtendedSelect
                        name="region"
                        value={identityDocType}
                        options={docTypeOptions}
                        onChange={(e) => {
                            setIdentityDocType(e.value as IdentityDocType);
                            setValidErrors(initErrors);
                        }}
                        disabled={onlyWatch}
                    />
                    <Form.Control.Feedback type="invalid">{validErrors.identityDocType}</Form.Control.Feedback>
                </Col>
            </Form.Group>
            {identityDocType === IdentityDocType.Passport ? (
                <>
                    <InputText
                        name="serialNumber"
                        value={serialNumber}
                        onChange={(name, value) => {
                            setSerialNumber(value);
                            setValidErrors({ ...validErrors, serialNumber: '' });
                        }}
                        labelTitle="СЕРИЯ/НОМЕР"
                        labelColSize={2}
                        inputColSize={4}
                        disabled={onlyWatch}
                        fieldFailures={validErrors}
                        inputClassNames="p-0"
                    />
                    <Form.Group className="row">
                        <Col sm="2" className="p-0">
                            <Form.Label column className="pt-2">
                                КЕМ ВЫДАН
                            </Form.Label>
                        </Col>
                        <Col sm="6" className={cn('p-0', styles.col)}>
                            <Textarea
                                name='issuedBy'
                                value={issuedBy}
                                onChange={value => {
                                    setIssuedBy(value);
                                    setValidErrors({ ...validErrors, issuedBy: '' });
                                }}
                                validErrors={validErrors}
                                disabled={onlyWatch}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group className={`row ${styles.align_center}`}>
                        <Col sm="2" className="p-0">
                            <Form.Label column>
                                ДАТА ВЫДАЧИ
                            </Form.Label>
                        </Col>
                        <Col sm="6" className={cn('p-0', styles.col)}>
                            <DatePicker from={-30} to={0} disabled={onlyWatch} onChange={handleDateChange} initialDate={dateOfIssue} />
                            <Form.Control.Feedback className="d-block" type="invalid">{validErrors.dateOfIssue}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group className="row">
                        <Col sm="2" className="p-0">
                            <Form.Label column className="pt-2">
                                АДРЕС РЕГИСТРАЦИИ
                            </Form.Label>
                        </Col>
                        <Col sm="6" className={cn('p-0', styles.col)}>
                            <Textarea
                                name='registrationAddress'
                                value={registrationAddress}
                                onChange={(value) => {
                                    setRegistrationAddress(value);
                                    setValidErrors({ ...validErrors, registrationAddress: '' });
                                }}
                                validErrors={validErrors}
                                disabled={onlyWatch}
                            />
                        </Col>
                    </Form.Group>
                </>
            ) : (
                    <>
                        <Form.Group className="row">
                            <Col sm="2" className="p-0">
                                <Form.Label column className="pt-2">
                                    ДАННЫЕ ДОКУМЕНТА
                                </Form.Label>
                            </Col>
                            <Col sm="6" className={cn('p-0', styles.col)}>
                                <Textarea
                                    name='data'
                                    value={data}
                                    onChange={value => {
                                        setData(value);
                                        setValidErrors({ ...validErrors, data: '' });
                                    }}
                                    validErrors={validErrors}
                                    placeholder="Укажите полную информацию о документе, включая серию/номер, дату выдачи, кем выдан итп."
                                    disabled={onlyWatch}
                                />
                            </Col>
                        </Form.Group>
                    </>
                )}
            {!onlyWatch && !withoutSubmitButton && (
                <Form.Group className="row mb-0 mt-5">
                    <Col sm={{ span: 3, offset: 2 }} className="p-0">
                        <LoaderBtn loading={processing || loading} disabled={processing} className="d-block" type="submit">{buttonText || 'Сохранить'}</LoaderBtn>
                    </Col>
                    {onCancel && (
                        <Col sm={{ span: 3, offset: 1 }}>
                            <Button className="d-block" variant="outline-secondary" onClick={onCancel}>Отмена</Button>
                        </Col>
                    )}
                </Form.Group>
            )}
        </Form>
    );
};

export default Identification;

interface IProps {
    apiError?: IApiError | null;
    stateData: IStateData | null;
    onlyWatch?: boolean;
    buttonText?: string;
    loading?: boolean;
    withoutSubmitButton?: boolean;
    onSubmitPassport?: (data: IApplicantPassport) => void;
    onSubmitOther?: (data: IApplicantOtherIdentity, type: IdentityDocType) => void;
    onCancel?: () => void;
}

interface IStateData {
    passport?: IApplicantPassport;
    otherIdentity?: IApplicantOtherIdentity;
}

interface IInitErrors {
    identityDocType: string;
    serialNumber: string;
    issuedBy: string;
    dateOfIssue: string;
    registrationAddress: string;
    title: string;
    data: string;
    [key: string]: string;
}