import React from 'react';
import { Card, WarningModal } from 'components';
import { Button } from 'react-bootstrap';
import { IApiError } from '_helpers/ApiConnector/types';
import { IDropZoneUploadedDocument } from './UploadedDocument';
import { DropZone, IDropZoneProps } from './DropZone';
import styles from './DocumentUpLoader.module.scss';
import cn from 'classnames';

const DocumentUpLoader = ({
    onGoBack,
    onSubmit,
    documentTemplateGroups,
    deleteDocument,
    uploadDocument,
    deleteDocumentSource,
    uploadedDocuments: nUploadedDocuments,
    apiError,
    goBackButtonText,
    submitButtonText,
    withoutPadding,
}: IProps) => {
    const [uploadedDocuments, setUploadedDocuments] = React.useState<IDropZoneUploadedDocument[]>([]);
    const [loadingFile, setLoadingFile] = React.useState<ILoadingFile | null>(null);
    const [lastDropTemplateId, setLastDropTemplateId] = React.useState<string | null>(null);

    const dropZoneDocuments =  [...documentTemplateGroups[0].templates, ...documentTemplateGroups[1].templates];

    React.useEffect(() => {
        if (!apiError) {
            setUploadedDocuments(nUploadedDocuments);
        }
        setLoadingFile(null);
    }, [nUploadedDocuments]);

    React.useEffect(() => {
        if (apiError) {
            setLoadingFile(null);
        }
    }, [apiError]);

    React.useEffect(() => {
        if (loadingFile && loadingFile.progress === 0) {
            const timer = setTimeout(() => {
                setLoadingFile({ ...loadingFile, progress: 50 });
            }, 1000);
            return () => clearTimeout(timer);
        }

        return undefined;
    }, [loadingFile]);
    

    const onDeleteFile = (documentId: string) => {
        deleteDocument(documentId);
    };

    const onUploadFile = (templateId: string, file: File) => {
        uploadDocument(
            templateId,
            file,
        );
    };

    const onDeleteSource = (documentId: string, sourceFileId: string) => {
        deleteDocumentSource(
            documentId,
            sourceFileId,
        );
    };

    const onDrop = (acceptedFiles: File[], templateId: string) => {
        setLastDropTemplateId(templateId);
        setLoadingFile({ file: acceptedFiles[0], progress: 0 });
        onUploadFile(templateId, acceptedFiles[0]);
    };

    const isUploadedDropZone = (documentTemplateId: string) => uploadedDocuments.length && uploadedDocuments.find(file => file.documentTemplateId === documentTemplateId);

    return (
        <Card
          title="Загрузите документы"
          category="Оригиналы всех загруженных документов потребуется предъявить перед прохождением аккредитации. Несоответствие оригинала документов какой-либо из загруженных копий может стать причиной для отказа в допуске к прохождению аккредитации."
          withoutPadding={withoutPadding}
          withoutPaddingTitle={withoutPadding}
          contentClassName={styles.content}
          withoutBorder
        >
            <>
                {dropZoneDocuments.map((template, i) => (template ? (
                    <DropZone
                      key={`dropZone_${i}`}
                      template={template}
                      templateNum={i + 1}
                      uploadedDocuments={uploadedDocuments}
                      apiError={apiError}
                      onDrop={onDrop}
                      onDeleteFile={onDeleteFile}
                      onDeleteSource={onDeleteSource}
                      loadingFile={loadingFile}
                      lastDropTemplateId={lastDropTemplateId}
                    />
                ) : null))}
                {onGoBack ? (
                    <Button variant="outline-secondary" className={styles.button} onClick={onGoBack}>
                        {goBackButtonText || 'На предыдущий шаг'}
                    </Button>
                ) : null}
                {dropZoneDocuments.every(template => template && isUploadedDropZone(template.id)) ? (
                    <Button
                      variant="primary"
                      className={cn('ml-4', 'pl-4', 'pr-4', styles.button)}
                      onClick={onSubmit}
                    >
                        {submitButtonText || 'Следующий шаг'}
                    </Button>
                ) : null}
            </>
        </Card>
    );
};

export default DocumentUpLoader;

export interface IProps {
    documentTemplateGroups: IDocumentTemplateGroup[];
    uploadedDocuments: IDropZoneProps['uploadedDocuments'];
    apiError: IApiError | null | undefined;
    correctionsRequired?: boolean;
    goBackButtonText?: string;
    submitButtonText?: string;
    withoutPadding?: boolean;
    onSubmit: () => void;
    deleteDocument: (documentId: string) => void;
    uploadDocument: (templateId: string, file: File) => void;
    deleteDocumentSource: (documentId: string, sourceFileId: string) => void;
    onGoBack?: () => void;
}

interface IDocumentTemplateGroup {
    templates: ITemplate[];
}

type ITemplate = IDropZoneProps['template'];

interface ILoadingFile {
    file: File;
    progress: number;
}
