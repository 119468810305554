import React from 'react';
import moment from 'moment';
import statusIcon from 'components/assets/svg/status_icon.svg';
import { Color, ColorStatus } from '_helpers';
import { AdvancedTable, AdvancedTableData, ExamApplicationStatus } from 'components';

const ExamRequirement = ({ examApplicationsList, getList, onRowClick }: IProps) => {
    const initialState: IState = {
        sortOrder: true,
    };

    const [sortOrder, setSortOrder] = React.useState(initialState.sortOrder);

    const tableHeaders = [
        { fieldName: 'applicantFullName', title: 'ФАМИЛИЯ, ИМЯ, ОТЧЕСТВО' },
        { fieldName: 'applicantEmail', title: 'Почта' },
        { fieldName: 'applicantPhone', title: 'Телефон' },
        { fieldName: 'status', title: 'Результат' },
    ];

    const createStatus = (status: ExamApplicationStatus, finalized: boolean) => {
        let text = '';
        let color = Color.Primary;
        if (status === ExamApplicationStatus.Passed) {
            text = finalized ? 'Аккредитация пройдена' : 'Теория пройдена';
            color = finalized ? Color.Primary : Color.Success;
        } else {
            text = 'Аккредитация не пройдена';
            color = finalized ? Color.Primary : Color.Red;
        }

        const styledStatus = new ColorStatus(color, text);

        return (
            <span style={{ color: `var(${styledStatus.color})` }}>
                {styledStatus.text} {finalized && status !== ExamApplicationStatus.Passed && <img src={statusIcon} alt="address_icon" />}
            </span>
        );
    };

    const createTableData = (
        examApplications: IApplication[],
    ): AdvancedTableData[] => examApplications.map(
            ({
                examApplicationId,
                applicantFullName,
                applicantEmail,
                applicantPhone,
                status,
                finalized,
            }) => ({
                id: examApplicationId,
                columns: [
                    applicantFullName,
                    applicantEmail,
                    applicantPhone,
                    createStatus(status, finalized),
                ],
            }),
        );

    const handleOrder = async (fieldName: string) => {
        setSortOrder(!sortOrder);
        await getList({ sortField: fieldName, sortOrder });
    };

    const handleSearch = (fieldName: string, value: string) => {
        getList({ searchField: fieldName, searchTerm: value });
    };

    return (
        <AdvancedTable
          data={createTableData(examApplicationsList.items)}
          tableHeaders={tableHeaders}
          handlePageChange={getList}
          handleOrder={handleOrder}
          handleRowClick={onRowClick}
          handleSearch={handleSearch}
          pageSize={examApplicationsList.pageSize}
          total={examApplicationsList.total}
          page={examApplicationsList.page}
        />
    );
};

interface IProps {
    getList: (data: IGetList) => void;
    onRowClick: (applicantId: string) => void;
    examApplicationsList: IApplicationList;
}

interface IState {
    sortOrder: boolean;
}

interface IGetList {
    examId?: string;
    searchField?: string;
    searchTerm?: string;
    page?: number;
    pageSize?: number;
    sortOrder?: boolean;
    sortField?: string;
}

interface IApplicationList {
    items: IApplication[];
    page: number;
    pageSize: number;
    total: number;
    admittedCount: number;
}

interface IApplication {
    examApplicationId: string;
    applicantId: string;
    applicantFullName: string;
    applicantPhone: string;
    applicationSubmitDate: moment.Moment;
    applicantEmail: string;
    status: ExamApplicationStatus;
    statusChangeTime: moment.Moment;
    examSessionStatus?: ExamSessionStatus | undefined;
    recommendations?: string;
    finalized: boolean;
}

enum ExamSessionStatus {
    Active = 'active',
    Completed = 'completed'
}

export default ExamRequirement;
