import React from 'react';
import { EmailConfirmation, Loader } from 'components';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { useLocalErrorHandler } from 'hooks';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

const EmailConfirmationContainer = ({
    token, confirmEmailByToken, apiError, setErrorHandler, auth, onPush,
}: Props) => {
    useLocalErrorHandler(setErrorHandler);

    const [processing, setProcessing] = React.useState(false);

    React.useEffect(() => {
        const checkToken = async () => {
            setProcessing(true);
            await confirmEmailByToken({ token });
            setProcessing(false);
        };

        checkToken();
    }, []);

    const sendCode = (captcha: string) => {
        confirmEmailByToken({ token, captcha });
    };

    if (processing) return <Loader />;

    return (
        <EmailConfirmation
          apiError={apiError}
          auth={Boolean(auth && auth.profile)}
          onPush={onPush}
          onSubmit={sendCode}
        />
    );
};

const mapState = (state: IRootState) => ({
    apiError: state.errors.error,
    auth: state.auth,
});

const mapDispatch = (dispatch: Dispatch) => ({
    confirmEmailByToken: dispatch.auth.confirmEmailByTokenAsync,
    setErrorHandler: dispatch.errors.setErrorHandler,
    onPush: (key: string) => dispatch(push(key)),
});

export default connect(mapState, mapDispatch)(EmailConfirmationContainer);

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
type Props = connectedProps & IProps;

interface IProps {
    token: string;
}
