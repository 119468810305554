import { createModel } from '@rematch/core';
import { INotification } from 'components';

const initialState: IState = [];

const notifications = createModel({
    state: initialState,
    reducers: {
        addNotification(state: IState, notificationArr: INotification[]): IState {
            return [...notificationArr];
        },
        clean(): IState {
            return [];
        },
    },
});

export default notifications;

type IState = INotification[];
