import * as React from 'react';
import cn from 'classnames';
import styles from './DayCell.module.scss';
import { Moment } from 'moment';
import { Badge } from 'react-bootstrap';
import { OverlayTrigger } from '../../../OverlayTrigger';
import ExpertsIcon from '../../../Common/icons/ExpertsIcon';
import StatusIcon from '../../../Common/icons/StatusIcon';
import { formatDayTitle } from '_helpers';

const DayCell: React.FC<IProps> = (
  {
      selected,
      disabled,
      dayNumber,
      group,
      date,
      readyForFinalize,
      count = 0,
      badgeCount = 0,
      expertsCount = 0,
      audienceFilledPercents = 0,
      onChoose = () => {},
      onEdit = () => {},
      orange,
      readonly,
  },
) => (
  <div
    className={cn(styles.day,
      {
          [styles.selected]: !!selected && !readonly,
          [styles.disabled]: !!disabled,
          [styles.finalizing]: !!readyForFinalize,
          [styles.orange]: !!orange,
          [styles.readonly]: !!readonly,
      })}
  >
      <div className={styles.number} onClick={() => !(disabled || readonly) && onChoose(dayNumber)}>{formatDayTitle({ dayNumber, group })}</div>
      <div className={styles.info} onClick={() => !disabled && onEdit(dayNumber)}>
         <div className={styles.subInfo}>
             <span>{date ? date.format('DD MMM') : '- -'}</span>
             <span>{date ? date.format('HH:mm') : '- -'}</span>
         </div>

          <OverlayTrigger triggerText="Количество аккредитуемых">
              <div className={styles.count}>
                  {count}

                  {!!badgeCount && (
                    <Badge variant="danger" className={styles.badge}>
                        {badgeCount}
                    </Badge>
                  )}
              </div>
          </OverlayTrigger>

          {readyForFinalize ? (
            <div className={styles.finalizingTitle}>
                {badgeCount ? 'Финализация' : 'Завершен'}
            </div>
          ) : (
            <div className={styles.subInfo}>
                <OverlayTrigger triggerText="Члены комиссии">
                    <span>
                        <ExpertsIcon /> {expertsCount}
                    </span>
                </OverlayTrigger>

                <OverlayTrigger triggerText="Заполненность аудитории">
                    <span>
                        <StatusIcon />
                        {audienceFilledPercents}%
                    </span>
                </OverlayTrigger>
            </div>
          )}
      </div>
  </div>
);

interface IProps {
    readonly?: boolean;
    selected?: boolean;
    disabled?: boolean;
    readyForFinalize?: boolean;
    orange?: boolean;
    retake?: number;
    dayNumber: number;
    group?: number;
    date?: Moment;
    count?: number;
    badgeCount?: number;
    expertsCount?: number;
    audienceFilledPercents?: number;
    onChoose?: (day: number) => void;
    onEdit?: (day: number) => void;
}

export default DayCell;
