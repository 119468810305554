import React from 'react';
import styles from './EmptySection.module.scss';
import { Button } from 'react-bootstrap';
import cn from 'classnames';

const EmptySection: React.FC<IProps> = (
  {
      placeholderTitle, placeholderText, placeholderButtonText, onButtonClick, placeholderIcon,
      className,
  },
) => (
  <div className={cn(styles.placeholder, className)}>
      {placeholderIcon ? <img src={placeholderIcon} alt="placeholder" /> : null}
      <div className={styles.placeholder__title}>
          {placeholderTitle}
      </div>
      <div className={styles.placeholder__text}>
          {placeholderText}
      </div>
      {placeholderButtonText && (
        <Button className={styles.placeholder__button} onClick={onButtonClick}>
            {placeholderButtonText}
        </Button>
      )}
  </div>
);

export default EmptySection;

export interface IProps {
    onButtonClick?: () => void;
    placeholderTitle: string;
    placeholderText: string;
    placeholderButtonText?: string;
    placeholderIcon?: string;
    className?: string;
}
