import React, { DependencyList, EffectCallback } from 'react';

const useEffectProcessing = (effect: () => Promise<ReturnType<EffectCallback>>, deps?: DependencyList, returnFunction?: () => void): boolean => {
    const [processing, setProcessing] = React.useState(false);

    React.useEffect(() => {
        setProcessing(true);
        effect()
        .then(() => {
            setProcessing(false);
        })
        .catch(() => setProcessing(false));
        return returnFunction;
    }, deps);

    return processing;
};

export default useEffectProcessing;
