import React from 'react';
import cx from 'classnames';
import styles from './Pagination.module.scss';

const Pagination = ({ pagination, onChange, className }: ComponentProps) => {
    const onChangePerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const newPagination = { ...pagination };
        newPagination.perPage = value;
        newPagination.page = 1;

        onChange(newPagination);
    };

    const onChangePage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);

        if (!value || value > pagination.pages) return;

        const newPagination = { ...pagination };
        newPagination.page = value;

        onChange(newPagination);
    };

    const onNextPage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const disabled = (e.target as HTMLDivElement).getAttribute('data-disabled');
        if (disabled === 'true') return;

        const newPagination = { ...pagination };
        newPagination.page += 1;

        onChange(newPagination);
    };

    const onPrevPage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const disabled = (e.target as HTMLDivElement).getAttribute('data-disabled');
        if (disabled === 'true') return;

        const newPagination = { ...pagination };
        newPagination.page -= 1;

        onChange(newPagination);
    };

    const prevPageStyleName = [styles.page_button];
    if (pagination.page <= 1) prevPageStyleName.push(styles.disabled);

    const nextPageStyleName = [styles.page_button];
    if (pagination.page >= pagination.pages) nextPageStyleName.push(styles.disabled);

    return (
        <div className={cx(styles.pagination, className)}>
            <div
              className={prevPageStyleName.join(' ')}
              data-disabled={(pagination.page <= 1)}
              onClick={onPrevPage}
            >
                Предыдущая
            </div>
            <div className={styles.page_info}>
                <span>Страница</span>
                <span><input value={pagination.page} type="number" min="1" max={Number.isNaN(pagination.pages) ? String(pagination.pages) : pagination.pages} onChange={onChangePage} /></span>
                <span>
                    из
                        {' '}
                    {pagination.pages}
                </span>
            </div>
            <div className={styles.per_page_info} data-test-pagination-per-page>
                <select value={pagination.perPage.toString() || '10'} onChange={onChangePerPage}>
                    <option value="10">10 строк</option>
                    <option value="50">50 строк</option>
                    <option value="100">100 строк</option>
                </select>
            </div>
            <div
              className={nextPageStyleName.join(' ')}
              data-disabled={(pagination.page >= pagination.pages)}
              onClick={onNextPage}
            >
                Следующая
            </div>
        </div>
    );
};

export default Pagination;

export interface IPaginationData {
    page: number;
    perPage: number;
    pages: number;
}

interface ComponentProps {
    onChange: (data: IPaginationData) => void;
    pagination: IPaginationData;
    className?: string;
}
