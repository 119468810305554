import React from 'react';
import { InputText, InputTextProps, OverlayTrigger } from 'components';
import convertIcon from './convert_icon.svg';
import arrowsIcon from './arrows_icon.svg';
import styles from './VerifyEmailInput.module.scss';
import cn from 'classnames';

const VerifyEmailInput = ({
    sendConfirmationEmail, lowRightIconPosition, triggerText, ...inputProps
}: IProps) => {
    const [rotate, setRotate] = React.useState(false);

    React.useEffect(() => {
        if (sendConfirmationEmail && rotate) {
            const timeout = setTimeout(() => {
                setRotate(false);
                sendConfirmationEmail();
            }, 1000);

            return () => clearTimeout(timeout);
        }

        return () => undefined;
    }, [rotate]);

    const verifyEmailIcon = React.useMemo(() => (
        <>
            {!inputProps.disabled && sendConfirmationEmail && (
                <OverlayTrigger triggerText={triggerText || 'Отправить приглашение еще раз'}>
                    <div
                      className={cn(styles.verifyContainer, lowRightIconPosition && styles.verifyContainer_low_right, rotate && styles.verifyContainer_rotate)}
                      onClick={() => !rotate && setRotate(true)}
                    >
                        <img className={styles.convertIcon} src={convertIcon} alt="convertIcon" />
                        <img className={cn(rotate && styles.arrowsIcon_rotate)} src={arrowsIcon} alt="arrowsIcon" />
                    </div>
                </OverlayTrigger>
            )}
        </>
    ), [rotate, inputProps.disabled, sendConfirmationEmail]);

    return (
        <InputText
          {...inputProps}
          inputClassNames={cn(styles.inputGroup, inputProps.inputClassNames)}
          name="email"
          type="text"
          labelTitle="ПОЧТА"
          placeholder={inputProps.placeholder || 'Укажите адрес электронной почты'}
        >
            {verifyEmailIcon}
            {inputProps.children}
        </InputText>
    );
};

export default VerifyEmailInput;

interface IProps extends Omit<InputTextProps, 'name' | 'type' | 'labelTitle'> {
    lowRightIconPosition?: boolean;
    triggerText?: string;
    sendConfirmationEmail?: () => void;
}
