import * as React from 'react';
import cn from 'classnames';
import { Form } from 'react-bootstrap';
import styles from '../../MultiSelectSection/MultiSelectSection.module.scss';
import { ExtendedSelect, IExtendedSelectEvent, IExtendedSelectOption } from '../../index';

const DefaultSelectSearch: React.FC<IProps> = ({
                                                   isClearable = true,
                                                   ...props
                                               }) => (
  <Form.Group className={cn(styles.one_select_group, props.className)}>
      <Form.Text className={cn(styles.select_text, 'text-muted mt-0', props.labelClassName)}>
          {props.label}
      </Form.Text>
      <ExtendedSelect
        isClearable={isClearable}
        withoutDetectChanges
        name={props.name || 'changeSelect'}
        options={props.options}
        onChange={props.onChange}
        className={cn(styles.field_value, props.inputClassName)}
        placeholder={props.placeholder}
        value={props.value}
      />
  </Form.Group>
);

interface IProps {
    label: string;
    name?: string;
    isClearable?: boolean;
    options: IExtendedSelectOption[];
    placeholder?: string;
    value?: string;
    className?: string;
    labelClassName?: string;
    inputClassName?: string;
    onChange: (e: IExtendedSelectEvent) => void;
}

export default DefaultSelectSearch;
