import React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

const HOC = (component: React.FunctionComponent<any>) => 
    // eslint-disable-next-line
     class ClassComponent extends React.Component<any> {
        render() {
            const { ...rest } = this.props;
            const Component = component;

            return (
                <Component {...rest} />
            );
        }
    }
;

export const withDragDropContext = (component: React.FunctionComponent<any>) => DragDropContext(HTML5Backend)(HOC(component));
