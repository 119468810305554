import React from 'react';
import {
    Switch,
    Route,
    Redirect,
    withRouter,
    RouteComponentProps,
} from 'react-router';
import { hasAccessTokenInLocalStorage, createReturnUrl, getReturnUrl } from '_helpers';
import { connect } from 'react-redux';
import { IRootState } from '../redux/store/configureStore';
import UnAuthorizedHandler from './UnAuthorizedHandler';
import AuthorizedHandler from './AuthorizedHandler';

const Routes = ({ processing }: Props) => {
    if (hasAccessTokenInLocalStorage()) {
        const returnUrl = getReturnUrl();

        return (
            <Switch>
                {returnUrl && <Redirect to={returnUrl} />}
                <Redirect path="/login" to="/" />
                <Redirect path="/reset-password" to="/" />
                <Redirect path="/register" to="/" />

                <Redirect exact path="/" to="/exam-timetables" />

                <Route
                  path="/"
                  render={({ location }) => (
                        <AuthorizedHandler location={location} />
                    )}
                />
            </Switch>
        );
    }

    createReturnUrl();

    return (
        <Switch>
            <Route
              path="/"
              render={({ location }) => (
                    <UnAuthorizedHandler location={location} processing={processing} />
                )}
            />
            <Redirect to="/login" />
        </Switch>
    );
};


const mapState = (state: IRootState) => ({
    processing: [
        Boolean(!state.loading.effects.auth.sendRegistrationVerificationCode && state.loading.models.auth),
        Boolean(state.loading.models.examApplications),
    ],
});

type connectedProps = ReturnType<typeof mapState>;
type Props = connectedProps & RouteComponentProps;

export default withRouter(connect(mapState)(Routes));
