import React from 'react';
import { CustomSwitch } from 'components';

const DocumentSwitch = ({ num, onSwitchClick }: IProps) => {
    const [active, setActive] = React.useState(false);

    const onClick = () => {
        const newActive = !active;
        setActive(newActive);

        onSwitchClick(num, newActive);
    };

    return (
        <CustomSwitch
          name={`documentSwitch_${num}`}
          value={active}
          onClick={onClick}
          containerClassName="ml-3"
          status="default"
        />
    );
};

export default DocumentSwitch;

interface IProps {
    num: number;
    onSwitchClick: (num: number, active: boolean) => void;
}
