import React from 'react';
import { OverlayTrigger as BootstrapTrigger, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import styles from './OverlayTrigger.module.scss';

const OverlayTrigger = ({ triggerText, children, placement = 'top' }: IProps) => (
    <BootstrapTrigger
      placement={placement}
      delay={{ show: 100, hide: 100 }}
      overlay={(
            <Popover id="button-tooltip" className={styles.tooltip}>
                {triggerText}
            </Popover>
        )}
    >
        {children}
    </BootstrapTrigger>
);

const copyToClipboard = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const text = e.currentTarget.textContent;

    if (text) {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        textArea.remove();
    }
};

export const renderCopyTrigger = (text: string) => (
    <OverlayTrigger triggerText="Клик, чтобы скопировать в буфер">
        <span className={styles.copy} onClick={copyToClipboard}>{text}</span>
    </OverlayTrigger>
);

export default OverlayTrigger;

interface IProps {
    triggerText: string;
    children: React.ReactElement;
    placement?: Placement;
}
