import { useHistory, useLocation } from 'react-router';

const usePushFrom = (newPathName: string) => {
    const { push } = useHistory();
    const { pathname } = useLocation();
    const search = new URLSearchParams();
    search.set('from', pathname.replace('/', ''));

    return [(id: string) => push(`/${newPathName}/${id}?${search.toString()}`)];
};

export default usePushFrom;
