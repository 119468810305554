import React from 'react';
import { RouteComponentProps } from 'react-router';
import { EmailConfirmationContainer } from '../../containers';

const EmailConfirmationPage = ({ match }: Props) => <EmailConfirmationContainer token={match.params.token} />;

export default EmailConfirmationPage;

interface OwnProps {
    token: string;
}

type Props = RouteComponentProps<OwnProps>;
