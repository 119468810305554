import React from 'react';
import ReactDOM from 'react-dom';

import 'components/assets/sass/index.scss';

import Root from './Root';
import { store, browserHistory } from './redux/store/configureStore';


ReactDOM.render(
  <Root history={browserHistory} store={store} />,
  document.getElementById('root'),
);
