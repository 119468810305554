import React from 'react';
import cx from 'classnames';
import styles from './CustomRadio.module.scss';
import { formDetector } from '_helpers';

const CustomRadio = ({
    checked,
    number,
    label,
    option,
    name,
    onClick,
    blue,
    className,
    labelClassName,
    withoutFormDetect,
    ...rest
}: CustomRadioProps) => {
    React.useEffect(() => {
        if (checked && option && !withoutFormDetect) {
            formDetector.setChangedInput(name, option);
        }
    }, [checked, option]);

    const click = () => {
        formDetector.setChangedInput(name, option);
        if (onClick) {
            onClick();
        }
    };

    return (
        <div
          className={cx('radio mb-0', className || '', { [styles.radio__blue]: blue })}
          onClick={click}
        >
            <input
              style={{ display: 'none' }}
              id={number}
              name={name}
              type="radio"
              value={option}
              checked={checked}
              {...rest}
              onChange={() => true}
            />
            <label
              className={cx(styles.radio_label, labelClassName || '')}
              htmlFor={number}
            >
                {label}
            </label>
        </div>
    );
};

export default CustomRadio;

interface CustomRadioProps {
    name: string;
    option: string;
    number?: string;
    checked?: boolean;
    label?: string;
    blue?: boolean;
    className?: string;
    labelClassName?: string;
    disabled?: boolean;
    withoutFormDetect?: boolean;
    onClick?: () => void;
}
