import React from 'react';
import {
    Card,
    ExamApplicationStatus,
    ExamApplicationTabs, ExamStatus,
} from 'components';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { RegistrationForExamContainer } from '../RegistrationForExamContainer';
import { useEffectProcessing, useGoBack, useLocalErrorHandler } from 'hooks';

const ExamApplicationDetailsContainer = (props: Props) => {
    useLocalErrorHandler(props.setErrorHandler);
    const [goBack, from] = useGoBack();

    const [showRegisterInfo, setShowRegisterInfo] = React.useState(false);

    const redirectionLink = React.useRef<HTMLAnchorElement | null>(null);
    const examStatus = React.useMemo(() => props.examApplicationDetails && props.examApplicationDetails.exam.status, [props.examApplicationDetails]);
    const examApplicationStatus = React.useMemo(() => props.examApplicationDetails && props.examApplicationDetails.status, [props.examApplicationDetails]);
    const draft = React.useMemo(() => examApplicationStatus === ExamApplicationStatus.Draft, [examApplicationStatus]);
    const changeStatus = React.useMemo(() => !examApplicationStatus || draft || examApplicationStatus === ExamApplicationStatus.CorrectionsRequired, [examApplicationStatus, draft]);

    const processing = useEffectProcessing(
      async () => {
          await props.getExamApplication(props.id);
      },
      [],
      () => {
          props.clearExamApplication();
      },
    );

    React.useEffect(() => {
        if (redirectionLink.current && props.onlineExamRedirectionUrl) {
            redirectionLink.current.click();
        }
    }, [redirectionLink, props.onlineExamRedirectionUrl]);

    const onRegisterButtonClick = async () => {
        if (!draft && examStatus === ExamStatus.IsApplying) {
            await props.createExamApplication(props.id);
        }
        setShowRegisterInfo(true);
    };

    const cardHeaderBtn = () => (
      changeStatus && !(from === 'exam-timetables' && draft) && (
        <Button onClick={onRegisterButtonClick}>
            {examApplicationStatus === ExamApplicationStatus.CorrectionsRequired
              ? 'Перейти к исправлению'
              : `${draft ? 'Редактировать' : 'Подать'} заявку`
            }
        </Button>
      )
    );


    if (showRegisterInfo && props.examApplicationDetails) {
        return (
          <RegistrationForExamContainer
            examId={props.examApplicationDetails.exam.id}
            onGoBack={() => setShowRegisterInfo(false)}
          />
        );
    }

    return (
      <Card
        onClickBack={goBack}
        title={props.examApplicationDetails ? props.examApplicationDetails.exam.qualificationTitle : ''}
        apiError={props.apiError}
        showLoader={processing}
        headerChild={cardHeaderBtn()}
        withBackButton
        withoutTitleMargin
        withoutPadding
        smallTitle
      >
          {props.examApplicationDetails ? (
            <ExamApplicationTabs
              examApplicationDetails={props.examApplicationDetails}
            />
          ) : null}
      </Card>
    );
};

const mapState = (state: IRootState) => ({
    examApplicationDetails: state.examApplications.examApplicationDetails,
    apiError: state.errors.error,
    onlineExamRedirectionUrl: state.exam.onlineExamRedirectionUrl,
});

const mapDispatch = (dispatch: Dispatch) => ({
    getExamApplication: dispatch.examApplications.getExamApplication,
    clearExamApplication: () => dispatch.examApplications.setExamApplicationDetails(null),
    createExamApplication: dispatch.exam.createExamApplication,
    setErrorHandler: dispatch.errors.setErrorHandler,
});

export default connect(mapState, mapDispatch)(ExamApplicationDetailsContainer);

interface ComponentOwnProps {
    id: string;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
type Props = connectedProps & ComponentOwnProps;
