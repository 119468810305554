import { createModel } from '@rematch/core';
import { IApplicantExamList, MyExamStatus, ExamReviewStatus } from '../../api/ApiClient';
import apiConnector from '../../api/ApiConnector';
import { IExamApplicationDetails } from 'components/models';

const initialState: IState = {
    examApplicationsList: null,
    myExamApplicationsList: null,
    examApplicationDetails: null,
};

export default createModel({
    state: initialState,
    reducers: {
        setExamApplicationList: (state, examApplicationsList: IState['examApplicationsList']): IState => ({ ...state, examApplicationsList }),
        setExamApplicationDetails: (state, examApplicationDetails: IState['examApplicationDetails']): IState => ({ ...state, examApplicationDetails }),
        setMyExamApplicationList: (state, myExamApplicationsList: IState['myExamApplicationsList']): IState => ({ ...state, myExamApplicationsList }),
    },
    effects: (dispatch: any) => ({
        async getExamApplication(id: string) {
            const examApplicationDetails = await apiConnector.execApi(
              dispatch,
              apiClient => apiClient.getExamApplication(id),
            );

            this.setExamApplicationDetails(examApplicationDetails);
        },
        async fetchExamApplicationsAsync(data: IGetExamApplications) {
            const examApplicationsList = await apiConnector.execApi(dispatch, apiClient => apiClient.getExamApplications(data.examReviewStatus, data.page, data.pageSize, data.sortOrder, data.sortField));

            this.setExamApplicationList(examApplicationsList);
        },
        async getMyExamApplications(data: IGetMyExamApplications) {
            const myExamApplicationsList = await apiConnector.execApi(dispatch, apiClient => apiClient.getMyExamApplicationsList(data.myExamStatus, data.page, data.pageSize, data.sortOrder, data.sortField));

            this.setMyExamApplicationList(myExamApplicationsList);
        },
    }),
});

interface IGetExamApplications {
    examReviewStatus: ExamReviewStatus;
    page?: number;
    pageSize?: number;
    sortOrder?: boolean | undefined;
    sortField?: string;
}

interface IGetMyExamApplications {
    myExamStatus: MyExamStatus;
    page?: number;
    pageSize?: number;
    sortOrder?: boolean | undefined;
    sortField?: string;
}

interface IState {
    examApplicationsList: IApplicantExamList | null;
    examApplicationDetails: IExamApplicationDetails | null;
    myExamApplicationsList: IApplicantExamList | null;
}
