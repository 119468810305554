import { createModel } from '@rematch/core';
import { Region } from '../../api/ApiClient';
import apiConnector from '../../api/ApiConnector';

const initialState: IState = {
    list: null,
};

export default createModel({
    state: initialState,
    reducers: {
        setList(state, list: IState['list']) {
            return { ...state, list };
        },
        clearList(state) {
            return { ...state, list: null };
        },
    },
    effects: (dispatch: any) => ({
        async getRegionsAsync() {
            const regions = await apiConnector.execApi(dispatch, apiClient => apiClient.getRegions());

            this.setList(regions);
        },
    }),
});


interface IState {
    list: Region[] | null;
}
