import { createModel } from '@rematch/core';
import { IApiError } from '../../api/ApiClient';

const initState: ErrorsState = {
    error: null,
    handler: 'global',
};

const errors = createModel({
    state: initState,
    reducers: {
        handle(state: ErrorsState, error: IApiError): ErrorsState {
            return { ...state, error };
        },
        setErrorHandler(state: ErrorsState, handler: ErrorHandlerType): ErrorsState {
            return { ...state, handler };
        },
        clean(state: ErrorsState): ErrorsState {
            return { ...state, error: null };
        },
    },
});

export default errors;

type ErrorHandlerType = 'local' | 'global';

interface ErrorsState {
    error: IApiError | null;
    handler: ErrorHandlerType;
}
