import React from 'react';
import { IExamApplication, IExamDayApplication } from 'components/models';
import { Button, FormControl, Table } from 'react-bootstrap';
import { formatPhoneNumber } from '_helpers';
import Pagination, { IPaginationData } from '../Pagination/Pagination';
import styles from './ExamAssigningApplicationsTable.module.scss';
//import sortIcon from '../assets/svg/sort_icon.svg';
import groupIcon from '../assets/svg/group.svg';
import cn from 'classnames';
import CustomCheckbox from '../Common/CustomCheckbox/CustomCheckbox';
import { WarningModal } from '../Common/Modals';
import { ExtendedSelect } from '../index';
import { ISelectEvent } from '../Common/ExtendedSelect/ExtendedSelect';
import { FinalizedResult, MappedFinalizedResult, MappedFinalizedResultColor } from '../enums';

const ExamAssigningApplicationsTable = (props: ExamApplicationsTableProps) => {
    const initialFilter: IFilter = {
        fieldName: '',
        fieldValue: '',
    };

    const [examApplications, setExamApplications] = React.useState(props.examApplications);
    const [filter, setFilter] = React.useState(initialFilter);
    const [checkedApplications, setCheckedApplications] = React.useState<Record<string, boolean>>({});
    const [checkedAll, setCheckedAll] = React.useState(false);
    const [showEmptyListModal, setShowEmptyListModal] = React.useState(false);

    React.useEffect(() => {
        setCheckedAll(false);
        setCheckedApplications({});
        setExamApplications(props.examApplications);
    }, [props.examApplications]);

    const withStatus = props.headers.length === 3 && !!props.headers.find(h => h.isStatus);

    const statusOptions = props.examApplications.map(c => c.finalizedResult || FinalizedResult.None)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map(v => ({ label: MappedFinalizedResult[v], value: v.toString() }));
    statusOptions.unshift({label: 'Все', value: ''})


    const handleCheckApplication = React.useCallback((id: string) => {
        const newChecked = {
            ...checkedApplications,
            [id]: !checkedApplications[id],
        };
        const countOfChecked = examApplications.filter(i => newChecked[i.examApplication.examApplicationId]).length;
        if (countOfChecked === examApplications.length) {
            setCheckedAll(true);
        } else if (checkedAll) {
            setCheckedAll(false);
        }

        setCheckedApplications(newChecked);
    }, [checkedApplications, checkedAll, examApplications]);

    const handleCheckAll = React.useCallback(() => {
        const newChecked: Record<string, boolean> = {};
        examApplications.forEach((a) => {
            if (!props.finalizing || a.canFinalize) {
                newChecked[a.examApplication.examApplicationId] = !checkedAll;
            }
        });
        setCheckedApplications(newChecked);
        setCheckedAll(!checkedAll);
    }, [checkedAll, examApplications, props.finalizing]);

    const handleAssignedApplicants = React.useCallback(() => {
        const assignedApplicants = examApplications.filter(i => checkedApplications[i.examApplication.examApplicationId]).map(i => i.examApplication);
        if (!assignedApplicants.length) {
            setShowEmptyListModal(true);
        } else if (props.onAssignedApplicants) {
            props.onAssignedApplicants(assignedApplicants);
        }
    }, [examApplications, checkedApplications, props.onAssignedApplicants]);

    const triggerParentChange = (data?: IPaginationData, nFilter?: IFilter) => {
        const { fieldName, fieldValue } = nFilter || filter;

        if (data) {
            const { page, perPage } = data;

            props.onChange && props.onChange(fieldName, fieldValue, page, perPage);
        }
        
        if(props.onChange) {
            props.onChange(fieldName, fieldValue, undefined, undefined);
        }
        else {
            console.log({ fieldName, fieldValue, a: props.examApplications });
            if (fieldName && fieldValue) {
                if(fieldName === 'finalizedResult') {
                    setExamApplications(props.examApplications.filter(a => a.finalizedResult === fieldValue));
                } else {
                    const val = fieldValue.toLowerCase();
                    setExamApplications(props.examApplications.filter(a => ('' + (a.examApplication as any)[fieldName]).toLowerCase().indexOf(fieldValue) !== -1));
                }
            }
            else {
                setExamApplications(props.examApplications);
            }
        }
    };

    const onFilterFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | { target: ISelectEvent }) => {
        const { name, value } = event.target;
        
        const newFilter = {
            ...filter,
            fieldName: name,
            fieldValue: value,
        };

        setFilter(newFilter);
        triggerParentChange(props.pagination, newFilter);
    };

    const renderStatus = (status: FinalizedResult = FinalizedResult.None) => (
      <td className="border-0" style={{ color: `var(${MappedFinalizedResultColor[status].color})` }}>
          {MappedFinalizedResultColor[status].text}
      </td>
    );

    const renderTableRow = ({
       examApplication, finalizedResult, canFinalize,
    }: IExamDayApplication, i: number) => (
      <tr
        key={i}
        onClick={(e) => {
            if (props.readonly || (props.finalizing && !canFinalize)) return;
            if (props.onItemClick) props.onItemClick(examApplication.examApplicationId);
            handleCheckApplication(examApplication.examApplicationId);
            e.stopPropagation();
            e.preventDefault();
        }}
      >
          {props.withCheckbox && !props.readonly && (
            <td className="border-0">
                <CustomCheckbox
                  blue
                  disabled={props.disableCheckbox || (props.finalizing && !canFinalize)}
                  checked={checkedApplications[examApplication.examApplicationId] || false}
                  number={examApplication.examApplicationId}
                  onCheck={() => true}
                />
            </td>
          )}
          <td className="border-0">
              {examApplication.applicantFullName}
          </td>
          <td className="border-0">
              {formatPhoneNumber(examApplication.applicantPhone)}
          </td>
          {withStatus && (
            renderStatus(finalizedResult)
          )}
      </tr>
    );

    return (
        <div className={props.className}>
            <Table className="mb-3 w-100 advancedTable">
                <thead>
                    <tr>
                        {props.withCheckbox && !props.readonly && (
                          <th className={cn(styles.th, 'border-0', styles.checkbox)}>
                              <CustomCheckbox
                                blue
                                disabled={props.disableCheckbox}
                                checked={checkedAll}
                                number={String(+Date.now())}
                                onCheck={handleCheckAll}
                              />
                          </th>
                        )}
                        {props.headers.map(({ value, key, isStatus }, i: number) => {
                            let textValue = undefined;
                            if (key === filter.fieldName) {
                                textValue = filter.fieldValue;
                            }

                            return (
                                <th className={cn(styles.th, 'border-0')} key={`th_${i}_${key}`}>
                                    <div className={styles.table_header_text}>
                                        {value}
                                        {/*<img src={sortIcon} alt="sortIcon" />*/}
                                    </div>
                                    {isStatus ? (
                                      <ExtendedSelect
                                        name={key}
                                        options={statusOptions}
                                        placeholder="ВЫБРАТЬ"
                                        onChange={e => onFilterFieldChange({ target: e })}
                                        value={textValue}
                                        noUppercase
                                      />
                                    ) : (
                                      <FormControl
                                        name={key}
                                        placeholder="ИСКАТЬ"
                                        value={textValue}
                                        onChange={onFilterFieldChange}
                                      />
                                    )}
                                </th>
                            );
                        })}
                    </tr>
                    {props.withConfirmButton && !!examApplications.length && !props.readonly && (
                      <tr>
                          <th className={cn(styles.th, 'border-0', styles.confirmButton)} colSpan={2}>
                              <Button onClick={handleAssignedApplicants}>
                                  {props.confirmButtonText}
                              </Button>
                          </th>
                          <th className={cn(styles.th, 'border-0')} />
                      </tr>
                    )}
                </thead>
                <tbody className={styles.table__body}>
                    {examApplications.map(renderTableRow)}
                </tbody>
            </Table>
            {!examApplications.length && (
              <div className={styles.notFoundContainer}>
                  <img src={groupIcon} alt="Список пуст" />
                  <span className={styles.notFoundTitle}>
                      {props.placeholderTitle || 'Список распределения пуст'}
                  </span>
                  <span className={styles.notFoundText}>
                     {props.placeholderText || 'Здесь будут отображаться все аккредитуемые, требующие распределения'}
                  </span>
              </div>
            )}
            {props.pagination && !!examApplications.length && (
              <Pagination
                className={props.paginationClassName}
                pagination={props.pagination}
                onChange={triggerParentChange}
              />
            )}
            <WarningModal title="Требуется выбрать аккредитуемых" show={showEmptyListModal} onHide={() => setShowEmptyListModal(false)}>
                Пожалуйста, выберите несколько аккредитуемых из списка для того, чтобы распределить их.
            </WarningModal>
        </div>
    );
};

interface ExamApplicationsTableProps {
    finalizing?: boolean;
    placeholderTitle?: string;
    placeholderText?: string;
    readonly?: boolean;
    withCheckbox?: boolean;
    withConfirmButton?: boolean;
    confirmButtonText?: string;
    disableCheckbox?: boolean;
    onAssignedApplicants?: (applicants: IExamApplication[]) => void;
    headers: ExamAssigningApplicationsColumnHeader[];
    pagination?: IPaginationData;
    examApplications: IApplicationRow[];
    className?: string;
    paginationClassName?: string;
    onChange?: (searchField: string | undefined, searchTerm: string | undefined, pageNumber: number | undefined, pageSize: number | undefined) => void;
    onItemClick?: (id: string) => void;
}

interface IApplicationRow extends Partial<IExamDayApplication> {
    examApplication: IExamApplication;
}

interface IFilter {
    fieldName: string;
    fieldValue: string;
}

interface ColumnHeader {
    value: string;
    key: string;
}

export class ExamAssigningApplicationsColumnHeader implements ColumnHeader {
    value: string;
    key: string;
    isStatus?: boolean;

    constructor(key: string, value: string, isStatus?: boolean) {
        this.key = key;
        this.value = value;
        this.isStatus = isStatus;
    }
}

export default ExamAssigningApplicationsTable;
