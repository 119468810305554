import React from 'react';
import {
    Card,
} from 'components';
import { IExamDetails } from 'components/models';
import ExamInfoStates from '../ExamInfoStates/ExamInfoStates';

const ExamInfoTab: React.FC<IProps> = (
  {
      examDetails, withoutPadding,
  },
) => {
    const examInfoState = React.useMemo(() => new ExamInfoStates(examDetails).getState(), [examDetails]);

    return (
        <Card
          withoutBorder
          title={examInfoState.getTitle()}
          category={examInfoState.getSubTitle()}
          contentClassName="pt-0"
          withoutPadding={withoutPadding}
        >
            {examInfoState.getInfoElement()}
        </Card>
    );
};

export default ExamInfoTab;

export interface IProps {
    examDetails: IExamDetails;
    withoutPadding?: boolean;
}
