import React from 'react';
import styles from './MultiSelectSearch.module.scss';

const MultiSelectSearch: React.FC<IProps> = props => (!props.hide ? (
  <div className={styles.container} data-test-multi-select-section>
      {props.children}
  </div>
) : null);

interface IProps {
  hide?: boolean;
}


export default MultiSelectSearch;
