import { useEffect, useState } from 'react';

interface Props {
    handler: 'local' | 'global';
}

const useShowGlobalErrorModal = (props: Props) => {
    useEffect(() => {
        if (props.handler && props.handler === 'global') {
            toggleModal(true);
        }
    }, [props]);

    const [showModal, toggleModal] = useState(false);

    return { showModal, toggleModal };
};

export default useShowGlobalErrorModal;
