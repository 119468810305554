import _ from 'lodash';

export enum AppExceptionType {
    Unspecified = 'unspecified',
    Validation = 'validation',
    NotFound = 'notFound',
    AlreadyExists = 'alreadyExists',
    WrongVerificationCode = 'wrongVerificationCode',
    TooManyRequests = 'tooManyRequests',
    WrongCredentials = 'wrongCredentials',
    AuthUserNotFound = 'authUserNotFound',
    Forbidden = 'forbidden',
    Captcha = 'captcha',
    WrongIp = 'wrongIp',
}

export interface ApiError {
    code: number;
    type: AppExceptionType;
    message: string;
    failures?: { [key: string]: string[] };
}

export interface ApiErrorFailures { [key: string]: string }

export const isGlobalError = (apiError: ApiError) => _.isEmpty(apiError.failures);
