import { Moment } from 'moment';
import { Color } from '../const/Colors';
import { ColorStatus } from '../ColorStatus';

export class Unread {
    messages!: number;
    logs!: number;
}

export class ChatMessage {
    id!: string;
    applicantId!: string;
    applicantName!: string;
    examApplicationId!: string;
    message!: string;
    created!: string;
    dateTime?: Moment;
    fromApplicant!: boolean;
    type!: ChatMessageType;
    expertId?: string;
    expertName?: string;
}

export enum ChatMessageType
{
    Message = 'message',
    Violation = 'violation',
    Info = 'info',
    Error = 'error',
}

export enum VideoType
{
    Cam = 'cam',
    Screen = 'screen'
}


export class KurentoResponse {
    id!: KurentoResponseIds;
    response?: string;
    message?: string;
    sdpAnswer?: string;
    candidate?: RTCIceCandidate;
    requestId?: KurentoRequestIds;
    presenterId?: string;
    videoType?: VideoType;
}

export enum KurentoResponseIds {
    Error= 'error',
    IceCandidate = 'iceCandidate',
    Response = 'response',
}

export class KurentoRequest {
    id!: string;
    presenterId?: string;
    videoType?: string;
    sdpOffer?: string;
    candidate?: RTCIceCandidate;
}

export enum KurentoRequestIds {
    Start = 'start',
    OnIceCandidate = 'onIceCandidate',
    Stop = 'stop',
    Switch = 'switch',
}

export const AlertsTypes = {
    [ChatMessageType.Violation]: new ColorStatus(Color.LightRed, 'Нарушение'),
    [ChatMessageType.Info]: new ColorStatus(Color.LightPurple, 'Событие'),
    [ChatMessageType.Error]: new ColorStatus(Color.Orange, 'Технический сбой'),
    [ChatMessageType.Message]: new ColorStatus(Color.Default, 'Сообщение'),
};
