import React from 'react';
import { Modal, Form, Col } from 'react-bootstrap';
import { LoaderBtn, Captcha, PhoneInput } from 'components';
import { IApiError } from '_helpers/ApiConnector/types';
import { formatSecretPhoneNumber } from '_helpers';
import styles from './PhoneModal.module.scss';
import cn from 'classnames';

const PhoneModal = ({
    show, onHide, title, onSubmitPhone, error, onSubmitCode, waitingSeconds,
}: IProps) => {
    const [phone, setPhone] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordValid, setPasswordValid] = React.useState(true);
    const [verificationCode, setVerificationCode] = React.useState('');
    const [verificationCodeValid, setVerificationCodeValid] = React.useState(true);
    const [showCode, setShowCode] = React.useState(false);
    const [timer, setTimer] = React.useState(0);
    const [processingCode, setProcessingCode] = React.useState(false);
    const [processingPhone, setProcessingPhone] = React.useState(false);
    const [phoneValid, setPhoneValid] = React.useState(true);
    const [captcha, setCaptcha] = React.useState('');
    const [captchaValid, setCaptchaValid] = React.useState(true);
    const [step, setStep] = React.useState(1);

    const doValidate = () => {
        const isCaptchaValid = error && error.failures && error.failures.captcha ? captcha.length === 4 : true;
        const isPasswordValid = password.length >= 6;
        const isPhoneValid = /^[0-9]{10}$/.test(phone);

        setPhoneValid(isPhoneValid);
        setCaptchaValid(isCaptchaValid);
        setPasswordValid(isPasswordValid);

        return isCaptchaValid && isPhoneValid && isPasswordValid;
    };

    const doValidateCodeForm = () => {
        const isCaptchaValid = error && error.failures && error.failures.captcha ? captcha.length === 4 : true;
        const isVerificationCodeValid = verificationCode.length === 4;

        setVerificationCodeValid(isVerificationCodeValid);
        setCaptchaValid(isCaptchaValid);

        return isCaptchaValid && isVerificationCodeValid;
    };

    const submitPhone = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!doValidate()) return;
        setShowCode(true);
        setProcessingPhone(true);
        await onSubmitPhone(phone, password, captcha);
        setProcessingPhone(false);
        setCaptcha('');
    };

    const submitCode = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!doValidateCodeForm()) return;
        setProcessingCode(true);
        const res = await onSubmitCode({ phone, verificationCode, captcha });
        setProcessingCode(false);
        setCaptcha('');
        setVerificationCode('');

        if (res) {
            clearState();
        }
    };

    const getCodeAgain = () => {
        setVerificationCode('');
        onSubmitPhone(phone, password, captcha);
    };

    const checkError = (field: string) => {
        if (error && error.failures && error.failures[field]) {
            return error.failures[field][0];
        }

        return '';
    };

    const clearState = () => {
        setPhone('');
        setPassword('');
        setVerificationCode('');
        setShowCode(false);
        setProcessingCode(false);
        setProcessingPhone(false);
    };

    const onClose = () => {
        clearState();
        onHide();
    };

    const calcTimer = () => {
        if (timer) {
            setTimer(timer - 1);
        }
    };

    const formatPhone = (nPhone: string) => {
        if (!nPhone) return '';
        return formatSecretPhoneNumber(nPhone);
    };

    const checkFirstStep = () => {
        if ((showCode && step === 1 && (checkError('phone') || checkError('password') || checkError('captcha')))
            || !showCode
            || processingPhone) {
            if (step === 2) setStep(1);
            return true;
        }

        if (step === 1) setStep(2);
        return false;
    };

    const usePrevious = (value?: number) => {
        const ref = React.useRef(0);

        React.useEffect(() => {
            ref.current = value || 0;
        });
        return ref.current;
    };

    const prevTime = usePrevious(waitingSeconds);

    React.useEffect(() => {
        if (waitingSeconds && prevTime !== waitingSeconds) {
            setTimer(waitingSeconds);
        }

        const id = setTimeout(calcTimer, 1000);

        return () => clearTimeout(id);
    }, [timer, waitingSeconds]);

    return (
        <>
            {checkFirstStep() ? (
                <Modal
                  show={show}
                  onHide={onClose}
                  dialogClassName={styles.signModal}
                  centered
                >
                    <Modal.Header
                      className={styles.signModal__header}
                      closeButton
                    >
                        <Modal.Title>
                            {title}
                        </Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={submitPhone}>
                        <Modal.Body className={styles.signModal__body}>
                            <div className={styles.body__text}>
                                Для изменения номера мобильного телефона необходимо указать
                                новый номер телефона и в целях безопасности ввести текущий
                                пароль от вашей учетной записи.
                            </div>

                            <PhoneInput
                              labelTitle="НОВЫЙ НОМЕР ТЕЛЕФОНА"
                              labelColSize={4}
                              inputColSize={5}
                              inputClassNames={cn('p-0', styles.body__input)}
                              value={phone}
                              onChange={(name, value) => setPhone(value)}
                              fieldFailures={{ phone: !phoneValid ? 'Неверный формат телефона' : checkError('phone') }}
                              phoneHelperClassName={styles.phone_input__helper}
                            />

                            <Form.Group className={cn(styles.align_center, 'row', 'mt-4')}>
                                <Col sm="4" className="p-0">
                                    <Form.Label className="mr-4 mb-0">ПОДТВЕРЖДЕНИЕ ПАРОЛЯ</Form.Label>
                                </Col>
                                <Col sm="5" className="p-0">
                                    <Form.Control
                                      as="input"
                                      type="password"
                                      name="password"
                                      value={password}
                                      className={styles.body__input}
                                      onChange={(e: any) => setPassword(e.target.value)}
                                      isInvalid={!!checkError('password') || !passwordValid}
                                    />
                                    <Form.Control.Feedback className={styles.invalid} type="invalid">
                                        {checkError('password') || (!passwordValid && 'Минимальная длина пароля 6 символов')}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Captcha captcha={captcha} captchaValid={captchaValid} apiError={error} setCaptcha={setCaptcha} />
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {error && ((error.failures && !(error.failures.phone || error.failures.password)) || (error.type === 'captcha')) && error.message}
                            </Form.Control.Feedback>
                        </Modal.Body>
                        <Modal.Footer className={styles.signModal__footer}>
                            <LoaderBtn disabled={processingPhone} loading={processingPhone} type="submit">Далее</LoaderBtn>
                            <LoaderBtn onClick={onClose} variant="outline-secondary">Отмена</LoaderBtn>
                        </Modal.Footer>
                    </Form>
                </Modal>
            ) : (
                    <Modal
                      show={show}
                      onHide={onClose}
                      dialogClassName={styles.signModal}
                      centered
                    >
                        <Modal.Header
                          className={styles.signModal__header}
                          closeButton
                        >
                            <Modal.Title>
                                {title}
                            </Modal.Title>
                        </Modal.Header>
                        <Form onSubmit={submitCode}>
                            <Modal.Body className={styles.signModal__body}>
                                <div className={styles.body__text}>
                                    Для завершения изменения номера мобильного телефона, введите
                                    код из СМС-сообщения, которое было отправлено на номер <br />
                                    {formatPhone(phone)}
                                </div>
                                <Form.Group className={cn(styles.phone_container, styles.align_center, 'row', 'mb-4')}>
                                    <Form.Control
                                      as="input"
                                      value={verificationCode}
                                      name="verificationCode"
                                      className={styles.body__input}
                                      onChange={(e: any) => setVerificationCode(e.target.value)}
                                      isInvalid={!!checkError('verificationCode') || !verificationCodeValid}
                                      placeholder="Код верификации"
                                      maxLength={4}
                                      disabled={(!!checkError('phone') || !!checkError('password')) || false}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {checkError('verificationCode') || (!verificationCodeValid && 'Длина цифр с картинки должны быть 4 символа')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    {timer ? (<span>До повторной отправки осталось {timer}с.</span>) : (<button onClick={getCodeAgain} disabled={!!timer || processingCode} className={styles.body__sendAgain} type="button">Отправить код повторно</button>)}
                                </Form.Group>
                                <Captcha captcha={captcha} captchaValid={captchaValid} apiError={error} setCaptcha={setCaptcha} />
                                <Form.Control.Feedback className="d-block" type="invalid">
                                    {error && ((error.failures && !(error.failures.phone || error.failures.password || error.failures.verificationCode)) || (error.type === 'captcha')) && error.message}
                                </Form.Control.Feedback>
                            </Modal.Body>
                            <Modal.Footer className={styles.signModal__footer}>
                                <LoaderBtn disabled={processingCode} loading={processingCode} type="submit">Подтвердить</LoaderBtn>
                                <LoaderBtn onClick={onClose} variant="outline-secondary">Отмена</LoaderBtn>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
        </>
    );
};

interface IProps {
    show: boolean;
    title: string;
    error?: IApiError | null;
    waitingSeconds: number;
    onHide: () => void;
    onSubmitPhone: (phone: string, password: string, captcha?: string) => void;
    onSubmitCode: (body: ICheckCode) => Promise<boolean | null>;
}

interface ICheckCode {
    phone: string;
    verificationCode: string;
    captcha?: string;
}

export default PhoneModal;
