import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import {
 Container, Row, Col, Form,
} from 'react-bootstrap';
import { ExtendedSelect, IExtendedSelectEvent } from 'components';
import { formDetector } from '_helpers';

const monthsList: IMonth[] = [
    { value: '01', text: 'Январь' },
    { value: '02', text: 'Февраль' },
    { value: '03', text: 'Март' },
    { value: '04', text: 'Апрель' },
    { value: '05', text: 'Май' },
    { value: '06', text: 'Июнь' },
    { value: '07', text: 'Июль' },
    { value: '08', text: 'Август' },
    { value: '09', text: 'Сентябрь' },
    { value: '10', text: 'Октябрь' },
    { value: '11', text: 'Ноябрь' },
    { value: '12', text: 'Декабрь' },
];

const days: string[] = [];
for (let i = 1; i <= 31; i += 1) {
    const day = i.toString().padStart(2, '0');

    days.push(day);
}

const DatePicker = ({
    initialDate: initDate, onChange, from, to, disabled, withoutDetectChanges, datePickerSizeSm, isInvalid, ...props
}: IProps) => {
    const initialDate = React.useMemo(() => {
        if (initDate) {
            return initDate.clone();
        }

        return undefined;
    }, [initDate]);
    const initialBirthDate: IDatePickerDate = {
        year: '',
        day: '',
        month: '',
    };

    const [datePickerDate, setDatePickerDate] = React.useState(initialBirthDate);

    React.useEffect(() => {
        if (initialDate) {
            const date = initialDate.clone().utc();
            const year = date.format('YYYY');
            const day = date.format('DD');
            const month = date.format('MM');

            setDatePickerDate({ year, day, month });

            if (!withoutDetectChanges) {
                formDetector.setChangedInput('year', year);
                formDetector.setChangedInput('day', day);
                formDetector.setChangedInput('month', month);
            }
        } else {
            setDatePickerDate(initialBirthDate);
        }
    }, [initialDate]);

    const handleBirthDayChange = (event: IExtendedSelectEvent) => {
        const { name } = event;
        let { value } = event;
        const formData = datePickerDate;

        formData[name as keyof IDatePickerDate] = value;
        const isDateFilled = formData.year && formData.month && formData.day;
        if (name === 'month' && isDateFilled) {
            const daysInMonth = moment(`${formData.year}-${formData.month}`, 'YYYY-MM').daysInMonth();
            if (parseInt(formData.day) > daysInMonth) {
                formData.day = daysInMonth.toString();
            }
        }

        if (name === 'day' && isDateFilled) {
            const daysInMonth = moment(`${formData.year}-${formData.month}`, 'YYYY-MM').daysInMonth();
            if (parseInt(value) > daysInMonth) {
                value = daysInMonth.toString();
                formData[name as keyof IDatePickerDate] = value;
            }
        }

        if (isDateFilled) {
            const ymd = `${formData.year}-${formData.month}-${formData.day}`;
            const momentDate = moment.utc(ymd);

            if (onChange) onChange(ymd, momentDate);
        }

        if (!withoutDetectChanges) {
            formDetector.setChangedInput(name, value);
        }

        setDatePickerDate(formData);
    };

    const getYears = () => {
        const years: string[] = [];
        const currentYear = new Date().getFullYear();
        const f = from !== undefined ? from : -100;
        const t = to !== undefined ? to : -16;

        for (let i = currentYear + f; i <= currentYear + t; i += 1) {
            years.push(i.toString());
        }

        return years;
    };

    const yearsList = getYears();

    return (
        <Container className={cn('p-0', props.groupClassName)}>
            <Row>
                <Col xs="4" className={cn('pl-0', props.dayClass)}>
                    <ExtendedSelect
                      name="day"
                      value={datePickerDate.day}
                      options={days.map(d => ({ value: d, label: d }))}
                      onChange={handleBirthDayChange}
                      disabled={disabled}
                      placeholder="День"
                      withoutDetectChanges={withoutDetectChanges}
                      smSize={datePickerSizeSm}
                      isInvalid={Boolean(isInvalid)}
                      withoutStrictValue
                    />
                </Col>
                <Col xs="4" className={cn('px-0', props.monthClass)}>
                    <ExtendedSelect
                      name="month"
                      value={datePickerDate.month}
                      options={monthsList.map(m => ({ value: m.value, label: m.text }))}
                      onChange={handleBirthDayChange}
                      disabled={disabled}
                      placeholder="Месяц"
                      withoutDetectChanges={withoutDetectChanges}
                      smSize={datePickerSizeSm}
                      isInvalid={Boolean(isInvalid)}
                      withoutStrictValue
                    />
                </Col>
                <Col xs="4" className={cn('pr-0', props.yearClass)}>
                    <ExtendedSelect
                      name="year"
                      value={datePickerDate.year}
                      options={yearsList.map(y => ({ value: y, label: y }))}
                      onChange={handleBirthDayChange}
                      disabled={disabled}
                      placeholder="Год"
                      withoutDetectChanges={withoutDetectChanges}
                      smSize={datePickerSizeSm}
                      isInvalid={Boolean(isInvalid)}
                      withoutStrictValue
                    />
                </Col>
            </Row>
            <Form.Control.Feedback className="d-block" type="invalid">{isInvalid}</Form.Control.Feedback>
        </Container>
    );
};

interface IProps {
    initialDate?: moment.Moment;
    disabled?: boolean;
    from?: number;
    to?: number;
    withoutDetectChanges?: boolean;
    datePickerSizeSm?: boolean;
    isInvalid?: string;
    onChange?: (val: string, moment: moment.Moment) => void;
    groupClassName?: string;
    monthClass?: string;
    dayClass?: string;
    yearClass?: string;
}

interface IDatePickerDate {
    year: string;
    day: string;
    month: string;
}

interface IMonth {
    value: string;
    text: string;
}

export default DatePicker;
