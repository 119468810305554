import React from 'react';
import { Button } from 'react-bootstrap';
import { Card } from 'components';
import { ApiError } from '_helpers/errors/ApiError';
import errorIcon from 'components/assets/svg/error_icon.svg';
import styles from './ErrorPlaceholder.module.scss';
import _ from 'lodash';

const ErrorPlaceholder = ({ placeholderIcon, apiError, withCard }: IProps) => {
    const renderBody = () => (
        <div className={styles.placeholder}>
            <img src={placeholderIcon || errorIcon} alt="placeholder" />
            <div className={styles.placeholder__title}>
                {`Статус код: ${apiError.code}`}
            </div>
            <div className={styles.placeholder__text}>
                {apiError.message}
            </div>
            {apiError.failures && (
                _.keys(apiError.failures).map((k, i) => (
                    <div key={i} className={styles.placeholder__text}>
                        {`${i + 1}. ${k}: ${apiError.failures ? apiError.failures[k].join(', ') : ''}!`}
                    </div>
                ))
            )}
            <Button className={styles.placeholder__button} onClick={() => window.location.reload()}>
                Повторить запрос
            </Button>
        </div>
    );

    return withCard ? (
            <Card title="Ошибка запроса" category="Ошибка работы приложения">
                {renderBody()}
            </Card>
        )
        : renderBody();
};

export default ErrorPlaceholder;

export interface IProps {
    apiError: ApiError;
    placeholderIcon?: string;
    withCard?: boolean;
}
