import {
    ILoginApplicant,
    IApplicant,
    IRegisterApplicant,
    IResetPassword,
    ISendVerificationCode,
    IUpdateProfile,
    LoginApplicant,
    RegisterApplicant,
    ResetPassword, SendVerificationCode,
    UpdateProfile,
    IVerificationCodeSendingStatus,
    SendVerificationCodeForUpdatePhone,
    ISendVerificationCodeForUpdatePhone,
    CheckVerificationCode,
    UpdatePhone,
    IUpdatePhone, ICheckVerificationCode,
    SendConfirmationEmailAgain,
    ISendConfirmationEmailAgain,
    ConfirmEmailByToken,
    IConfirmEmailByToken,
    ChangePassword,
    IChangePassword,
    IAuthResult,
    QualificationInfoList,
    Region,
} from '../../api/ApiClient';
import { INotification } from 'components';
import { createModel } from '@rematch/core';
import { clearAuthToken, setAuthToken } from '_helpers';
import apiConnector from '../../api/ApiConnector';

const initialState: AuthState = {
    profile: null,
    token: '',
    registerForm: new RegisterApplicant().toJSON(),
    verificationStatus: null,
    qualificationList: null,
    regions: null,
};

export default createModel({
    state: initialState,
    reducers: {
        setAuth(state, data: IAuthResult): AuthState {
            if (data && data.token) {
                setAuthToken(data.token);
            }
            return { ...state, ...data };
        },
        setRegisterForm<S extends AuthState>(state: S, registerForm: Partial<IRegisterApplicant>): AuthState {
            return { ...state, registerForm: { ...state.registerForm, ...registerForm } };
        },
        logout(): AuthState {
            clearAuthToken();

            return initialState;
        },
        setQualificationList(state, qualificationList: QualificationInfoList): AuthState {
            return { ...state, qualificationList };
        },
    },
    effects: (dispatch: any) => ({
        async login(data: ILoginApplicant) {
            const auth = await apiConnector.execApi(dispatch, apiClient => apiClient.login(new LoginApplicant(data)));

            if (!auth) return;

            this.setAuth({ ...auth });
        },
        async fetchProfile() {
            this.setAuth({
                profile: await apiConnector.execApi(dispatch, apiClient => apiClient.profile()),
            });
        },
        async register(data: IRegisterApplicant) {
            const auth = await apiConnector.execApi(dispatch, apiClient => apiClient.register(new RegisterApplicant(data)));

            if (!auth) return;

            this.setAuth({ ...auth });
        },
        async sendResetPasswordVerificationCodeAsync(data: ISendVerificationCode) {
            return await apiConnector.execApi(dispatch, apiClient => apiClient.sendResetPasswordVerificationCode(new SendVerificationCode(data)));
        },
        async checkResetPasswordVerificationCodeAsync(data: ICheckVerificationCode) {
            return await apiConnector.execApi(dispatch, apiClient => apiClient.checkResetPasswordVerificationCode(new CheckVerificationCode(data)));
        },
        async resetPassword(data: IResetPassword) {
            const auth = await apiConnector.execApi(dispatch, apiClient => apiClient.resetPassword(new ResetPassword(data)));

            if (auth) {
                this.setAuth({ ...auth });
            }

            return auth;
        },
        async updateProfile(data: IUpdateProfile) {
            const profile = await apiConnector.execApi(dispatch, apiClient => apiClient.updateProfile(new UpdateProfile(data)));
            if (profile) {
                this.setAuth({ profile });
                dispatch.notifications.addNotification([{ message: 'Данные профиля успешно обновлены!', appearance: 'success' }] as INotification[]);
            }
            return profile;
        },
        async sendRegistrationVerificationCode(payload: ISendVerificationCode) {
            const verificationStatus = await apiConnector.execApi(
                dispatch,
                apiClient => apiClient.sendRegistrationVerificationCode(new SendVerificationCode(payload)),
            );
            if (!verificationStatus) return false;
            this.setAuth({ verificationStatus });
            return true;
        },
        async checkRegistrationVerificationCode(data: ICheckVerificationCode) {
            const result = await apiConnector.execApi(
                dispatch,
                apiClient => apiClient.checkRegistrationVerificationCode(new CheckVerificationCode(data)),
            );
            return Boolean(result);
        },
        async sendUpdatePhoneVerificationCodeAsync(body: ISendVerificationCodeForUpdatePhone) {
            const res = await apiConnector.execApi(dispatch, apiClient => apiClient.sendUpdatePhoneVerificationCode(new SendVerificationCodeForUpdatePhone(body)));

            return res;
        },
        async checkUpdatePhoneVerificationCodeAsync(data: ICheckVerificationCode) {
            const res = await apiConnector.execApi(dispatch, apiClient => apiClient.checkUpdatePhoneVerificationCode(new CheckVerificationCode(data)));

            return res;
        },
        async updatePhoneAsync(body: IUpdatePhone) {
            const profile = await apiConnector.execApi(dispatch, apiClient => apiClient.updatePhone(new UpdatePhone(body)));

            if (profile) {
                this.setAuth({ profile });
                dispatch.notifications.addNotification([{ message: 'Телефон успешно обновлен!', appearance: 'success' }] as INotification[]);
            }
        },
        async sendConfirmationEmailAsync(body: ISendConfirmationEmailAgain) {
            const res = await apiConnector.execApi(dispatch, apiClient => apiClient.sendConfirmationEmailAgain(new SendConfirmationEmailAgain(body)));
            if (res) {
                dispatch.notifications.addNotification([{ message: 'Ссылка для подтверждения электронной почты успешно отправлена!', appearance: 'success' }] as INotification[]);
            }
            return res;
        },
        async confirmEmailByTokenAsync(body: IConfirmEmailByToken) {
            const res = await apiConnector.execApi(dispatch, apiClient => apiClient.confirmEmailByToken(new ConfirmEmailByToken(body)));
            if (res) {
                dispatch.notifications.addNotification([{ message: 'Email успешно подтвержден!', appearance: 'success' }] as INotification[]);
            }
            return res;
        },
        async changePasswordAsync(body: IChangePassword) {
            const res = await apiConnector.execApi(dispatch, apiClient => apiClient.changePassword(new ChangePassword(body)));

            if (res) {
                dispatch.notifications.addNotification([{ message: 'Пароль успешно изменен!', appearance: 'success' }] as INotification[]);
            }
            return res;
        },
        async getQualificationsAsync(payload: { residency: boolean }) {
            const result = await apiConnector.execApi(
                dispatch,
                apiClient => apiClient.getQualifications(
                    undefined,
                    undefined,
                    payload.residency,
                    undefined,
                    undefined,
                    undefined,
                    300,
                    undefined,
                    undefined,
                ),
            );
            if (!result) return;
            this.setQualificationList(result);
        },
    }),
});

export interface AuthState {
    profile: IApplicant | null;
    token: string;
    registerForm: IRegisterApplicant;
    verificationStatus: IVerificationCodeSendingStatus | null;
    qualificationList: QualificationInfoList | null;
    regions: Region[] | null;
}
