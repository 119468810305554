import React from 'react';
import {
    ExamStatus,
    AppType,
    AnimatedTabs,
} from 'components';
import {
    IExamApplication,
    IExamStage,
} from 'components/models';
import { Tab } from 'react-bootstrap';
import { ApplicantsTab, IApplicantsTabProps } from './ApplicantsTab';
import { ExamInfoTab, ExamInfoTabProps } from './ExamInfoTab';
import { IApiError } from '../../_helpers/ApiConnector/types';
import AwaitingStage from './ExamStagesTabs/AwaitingStage/AwaitingStage';

const ExamDetailsOnlyWaitingStatusTabs = ({
    tab,
    applicantsTabProps,
    examDetails,
    examApplicationsList,
    withoutApplicantsTab,
    withoutExpertsTab,
    appType,
  ...props
}: IProps) => {
    const isExpert = appType === AppType.Expert;
    const isMain = appType === AppType.Main;
    const isApplicant = appType === AppType.Applicant;
    const isAc = appType === AppType.Ac;

    const getAwaitingStage = (s: IExamStage) => (
      <AwaitingStage stageInfo={s} />
    );

    const stagesMap: Record<string, (s: IExamStage) => JSX.Element | null> = {
        defaultReturn: () => null,
        [ExamStatus.AwaitingExpertConfirmation]: getAwaitingStage,
    };

    const renderStage = React.useMemo(() => {
        if (examDetails.status in stagesMap) {
            return stagesMap[examDetails.status];
        }

        return stagesMap.defaultReturn;
    }, [examDetails.status, stagesMap]);


    const checkPropsTab = () => {
        if (tab && (tab === TabKey.Rules
            || (tab === TabKey.Applicants && !withoutApplicantsTab)
            || tab === TabKey.Info
            || (tab === TabKey.Experts && !withoutExpertsTab))) {
            return tab;
        }

        return null;
    };

    const getDefaultTab = () => {
        if (checkPropsTab()) {
            return checkPropsTab();
        }

        if (!isExpert) return TabKey.Info;
        if (examStatus === ExamStatus.IsApplying) return TabKey.Info;
        if (!withoutApplicantsTab) return TabKey.Applicants;
        if (!withoutExpertsTab) return TabKey.Experts;

        return TabKey.Info;
    };

    React.useEffect(() => {
        if (!activeTab) {
            setActiveTab(getDefaultTab());
        }
    }, [examDetails]);

    const [activeTab, setActiveTab] = React.useState(getDefaultTab());
    const examStatus = examDetails.status;
    const examInCreateStatus = examStatus === ExamStatus.AwaitingExpertConfirmation;



    return (
        <>
            <AnimatedTabs
              id="ExamDetailsTabs"
              activeKey={activeTab}
              onSelect={(key: string) => setActiveTab(key as TabKey)}
            >

                <Tab eventKey={TabKey.Info} title="Информация" className="tab mt-4 p-0">
                    <ExamInfoTab examDetails={examDetails} />
                    {props.renderAppendForInfo && props.renderAppendForInfo()}
                </Tab>

                {!!examDetails && examDetails.examStages.map((s, i) => (
                  <Tab eventKey={`stage/${String(i + 1)}`} title={`Этап ${i + 1}`} key={i}>
                      {s.stageNumber === +String(activeTab).split('/')[1] && (
                        renderStage(s)
                      )}
                  </Tab>
                ))}

                {!withoutApplicantsTab && applicantsTabProps && examApplicationsList && !examInCreateStatus ? (
                    <Tab
                      eventKey={TabKey.Applicants}
                      title="Список аккредитуемых"
                      className="tab mt-4 p-0"
                    >
                        <ApplicantsTab
                          examDetails={examDetails}
                          examApplications={examApplicationsList}
                          examStatus={examStatus}
                          handleApplicantClick={applicantsTabProps.handleApplicantClick}
                          handleApplicantsListChange={applicantsTabProps.handleApplicantsListChange}
                        />
                    </Tab>
                ) : null}
            </AnimatedTabs>
        </>
    );
};

export default ExamDetailsOnlyWaitingStatusTabs;

export interface IProps {
    examDetails: ExamInfoTabProps['examDetails'];
    appType: AppType;
    examApplicationsList?: IExamApplicationList;
    applicantsTabProps?: IApplicantsTabProps;
    withoutApplicantsTab?: boolean;
    withoutExpertsTab?: boolean;
    tab?: TabType;

    stageNumber?: string;
    error: IApiError | null;
    renderAppendForInfo?: () => JSX.Element;
}

interface IExamApplicationList {
    admittedCount: number;
    items: IExamApplication[];
    page: number;
    pageSize: number;
    total: number;
}

enum TabKey {
    Applicants = 'applicants',
    Experts = 'experts',
    Info = 'info',
    Rules = 'rules',
}

type TabType = 'applicants' | 'experts' | 'info' | 'rules'
