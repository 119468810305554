import React from 'react';
import {
    Card,
    Checkbox,
    PhoneInput,
    InputPassword,
    LoaderBtn,
    Captcha,
    InputText,
} from 'components';
import {
    Col,
    FormGroup,
    FormLabel,
    Form,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RegExpConst } from '_helpers';
import { IApiError } from '../../_helpers/ApiConnector/types';
import _ from 'lodash';
import styles from './AuthenticationForm.module.scss';

const InitFormErrors: IFieldsError = {
    phone: '',
    password: '',
    captcha: '',
};

const InitFormData: IFormData = {
    phone: '',
    password: '',
    captcha: '',
    rememberMe: false,
};

const AuthenticationForm = ({
    apiError,
    onLogin,
    title,
    subTitle,
    resetPasswordLink,
    registerLink,
    withRegister,
    withEmail,
    withPhone,
    withoutForgotPassword,
}: IProps) => {
    const [formData, setFormData] = React.useState(InitFormData);
    const [formFieldErrors, setFormFieldErrors] = React.useState(InitFormErrors);
    const [processing, setProcessing] = React.useState(false);
    const fieldFailures = formFieldErrors as object as { [key: string]: string };

    const getFieldErrorFromApiError = (fieldName: string, errors: IApiError | null) => {
        if (!errors || !errors.failures) return '';
        if (_.has(errors.failures, fieldName)) return errors.failures[fieldName].join(', ');
        return '';
    };

    React.useEffect(() => {
        const phoneError = getFieldErrorFromApiError('email', apiError);
        const emailError = getFieldErrorFromApiError('email', apiError);
        const passwordError = getFieldErrorFromApiError('password', apiError);

        setProcessing(false);
        setFormFieldErrors({
            phone: phoneError,
            password: passwordError,
            email: emailError,
        });

        return () => setProcessing(false);
    }, [apiError]);

    const checkValidationErrors = (data: IFormData): IFieldsError => {
        const phoneError = ((data.phone && /^[0-9]{10}$/.test(data.phone)) || !withPhone) ? '' : 'Неверный формат телефона';
        const emailError = ((data.email && RegExpConst.EMAIL.test(data.email)) || !withEmail) ? '' : 'Неверный формат email';
        const isCaptchaValid = apiError && apiError.failures && apiError.failures.captcha ? data.captcha && data.captcha.length === 4 : true;
        let captchaError = '';
        let passwordError = '';

        if (data.password.length === 0) {
            passwordError = 'Заполните поле';
        } else if (data.password.length < 6) {
            passwordError = 'Минимальная длина пароля 6 символов';
        }

        if (!isCaptchaValid) {
            captchaError = 'Длина цифр с картинки должна быть 4 символа';
        }

        return {
            phone: phoneError,
            email: emailError,
            password: passwordError,
            captcha: captchaError,
        };
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const validResult = checkValidationErrors(formData);

        if ((validResult.phone && validResult.phone.length > 0) || validResult.password.length > 0 || (validResult.captcha && validResult.captcha.length > 0)) {
            setFormFieldErrors({ ...validResult });
            return;
        }

        setFormData({ ...formData, captcha: '' });
        setProcessing(true);
        onLogin && await onLogin(formData);
    };

    const handleInputChange = (name: string, value: string) => {
        setFormFieldErrors({ ...formFieldErrors, [name]: '' });
        setFormData({ ...formData, [name]: value });
    };

    return (
        <>
            <Col className="auth-forms--container">
                <Form onSubmit={handleSubmit} autoComplete="new-password">
                    <Card
                      title={title || 'Вход'}
                      category={subTitle || 'в личный кабинет'}
                      apiError={apiError}
                      contentClassName={styles.content}
                      ftTextCenter
                      legend={(
                            <div>
                                <LoaderBtn
                                  variant="primary"
                                  type="submit"
                                  disabled={processing || !onLogin}
                                  loading={processing}
                                >
                                    Войти
                                </LoaderBtn>
                                <FormGroup className="remember-me-block">
                                    <Checkbox
                                      blue
                                      number="1"
                                      label="Запомнить меня"
                                      checked={formData.rememberMe}
                                      onCheck={() => setFormData({ ...formData, rememberMe: !formData.rememberMe })}
                                    />
                                </FormGroup>
                            </div>
                        )}
                    >
                        {withEmail && (
                            <InputText
                              labelTitle="Email"
                              labelColSize={12}
                              inputColSize={12}
                              inputClassNames="p-0"
                              value={formData.email || ''}
                              onChange={handleInputChange}
                              name="email"
                              type="email"
                              fieldFailures={fieldFailures}
                            />
                        )}
                        {withPhone && (
                            <PhoneInput
                              labelTitle="Мобильный телефон"
                              labelColSize={12}
                              inputColSize={12}
                              inputClassNames="p-0"
                              value={formData.phone || ''}
                              onChange={handleInputChange}
                              fieldFailures={fieldFailures}
                            />
                        )}
                        <FormGroup className="password" data-test-password-group>
                            <FormLabel>Пароль</FormLabel>
                            <InputPassword
                              name="password"
                              onChange={e => handleInputChange(e.target.name || '', e.target.value ? e.target.value.toString() : '')}
                              value={formData.password}
                              isInvalid={!!formFieldErrors.password}
                            />
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {formFieldErrors.password}
                            </Form.Control.Feedback>
                        </FormGroup>
                        {!withoutForgotPassword && (
                            <div className="forgot-password--container align-right">
                                <Link to={resetPasswordLink || '/reset-password'}>Забыли пароль?</Link>
                            </div>
                        )}
                        <Captcha
                          captcha={formData.captcha || ''}
                          captchaValid={!formFieldErrors.captcha}
                          apiError={apiError}
                          setCaptcha={captcha => setFormData({ ...formData, captcha })}
                        />
                        <Form.Control.Feedback className="d-block" type="invalid">
                            {apiError && apiError.failures && apiError.failures.captcha && apiError.message}
                        </Form.Control.Feedback>
                    </Card>
                </Form>
            </Col>
            {withRegister && (
                <div className="register-link--container">
                    Еще нет аккаунта? <Link to={registerLink || '/register'} className="register-link">Регистрация</Link>
                </div>
            )}
        </>
    );
};

interface IProps {
    apiError: IApiError | null;
    onLogin?: (data: IFormData) => void;
    title?: string;
    subTitle?: string;
    resetPasswordLink?: string;
    withEmail?: boolean;
    withPhone?: boolean;
    withRegister?: boolean;
    withoutForgotPassword?: boolean;
    registerLink?: string;
}

interface IFieldsError {
    password: string;
    captcha?: string;
    phone?: string;
    email?: string;
}

interface IFormData {
    password: string;
    rememberMe: boolean;
    captcha?: string;
    phone?: string;
    email?: string;
}

export default AuthenticationForm;
