import React from 'react';
import { Card, EmptySection } from 'components';
import { DocumentSwitch } from './DocumentSwitch';
import docsIcon from 'components/assets/svg/documents_icon.svg';
import styles from './ApplicantDocuments.module.scss';
import {DocumentType, DocumentTypeTitles} from 'components/enums';

const ApplicantDocuments = ({ documents, onChangeSwitch, withoutPadding }: IProps) => {
    const [activeValues, setActiveValues] = React.useState<boolean[]>(documents.map(() => false));

    const onSwitchClick = (num: number, active: boolean) => {
        if (onChangeSwitch) {
            const newActive = activeValues;
            newActive[num] = active;

            setActiveValues(newActive);
            onChangeSwitch(newActive);
        }
    };

    const renderSwitch = (num: number) => (onChangeSwitch ? (
        <DocumentSwitch num={num} onSwitchClick={onSwitchClick} />
    ) : null);

    return (
        <Card
          withoutPadding={withoutPadding}
          withoutBorder
          withoutBottomMargin
        >
            {documents.length ? documents.map((document, i) => (
                <div className={styles.document} key={`document_${i}`}>
                    <a className={styles.document_image} href={document.fileUrl} target="__blank">
                        <img src={`${document.fileUrl}/preview`} alt="documentImage" />
                    </a>
                    <div className={styles.document_content}>
                        <span className={styles.document_title}>{DocumentTypeTitles[document.type]}
                        </span>
                        <span className={styles.document_text}>{document.documentDescription}</span>
                    </div>
                </div>
            )) : (
                    <EmptySection
                      placeholderTitle="Документы отсутствуют"
                      placeholderText="Пока не загружено ни одного документа"
                      placeholderIcon={docsIcon}
                    />
                )}
        </Card>
    );
};

export default ApplicantDocuments;

export interface IProps {
    documents: IDocument[];
    withoutPadding?: boolean;
    onChangeSwitch?: (change: boolean[]) => void;
}

interface IDocument {
    applicantId: string;
    documentDescription: string;
    examApplicationId: string;
    fileExtension: string;
    fileId: string;
    fileSize: number;
    fileUrl: string;
    id: string;
    documentTemplateId?: string;
    type: DocumentType;
}
