import React, { CSSProperties, ReactElement } from 'react';
import {
    ExtendedSelect,
    IExtendedSelectEvent,
    DateTimePicker,
    EmptySection,
    IEmptySectionProps,
} from 'components';
import { Table, Form } from 'react-bootstrap';
import Pagination, { IPaginationData } from '../../Pagination/Pagination';
import moment from 'moment';
import styles from './AdvancedTable.module.scss';
import cn from 'classnames';
import './DatePickerFixStyles.scss';
import _ from 'lodash';
import EmptyUsersListIcon from '../../assets/svg/group.svg';
import EmptyListIcon from '../../assets/svg/news_icon.svg';

const AdvancedTable: React.FC<TableProps> = (
  {
      handleSearch,
      tableHeaders,
      data,
      handleRowClick,
      handleOrder,
      placeholderIcon,
      placeholderText,
      placeholderTitle,
      withoutPagination,
      handlePageChange,
      page,
      pageSize,
      total,
      filters,
      handleSelectChange,
      tdClassName,
      onButtonClick,
      placeholderButtonText,
      renderAdditionalHeaderRow = () => undefined,
      placeholderClassName,
    ...props
  },
) => {
    const initialFormData: { [key: string]: string } = tableHeaders.reduce((acc, x) => ({
        ...acc,
        [x.fieldName]: '',
    }), {});
    const savedFilters = filters ? { [filters.searchField]: filters.searchTerm } : {};

    const [firstRun, setFirstRun] = React.useState(true);
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({ ...initialFormData, ...savedFilters });
    const [typingTimer, setTypingTimer] = React.useState<NodeJS.Timeout | null>(null);
    const [inputTarget, setInputTarget] = React.useState<{ fieldName: string; fieldValue: string } | null>(null);
    const [selectedOption, setSelectedOption] = React.useState<IExtendedSelectEvent>({ name: '', value: '' });
    const [from, setFrom] = React.useState<moment.Moment | undefined>(undefined);
    const [to, setTo] = React.useState<moment.Moment | undefined>(undefined);
    const doneTypingInterval = 500;


    const onInputKeyup = () => {
        if (typingTimer) {
            clearTimeout(typingTimer);
        }

        setTypingTimer(setTimeout(() => {
            handleInputChange();
        }, doneTypingInterval));
    };

    const onInputKeydown = () => {
        if (typingTimer) {
            clearTimeout(typingTimer);
        }
    };

    const onInputChange = (event: any) => setInputTarget({
        fieldName: event.target.name as string,
        fieldValue: event.target.value as string,
    });

    const handleInputChange = () => {
        if (inputTarget && handleSearch) {
            const { fieldName, fieldValue } = inputTarget;
            const newFormData = _.mapValues(formData, (v, key) => (key === fieldName ? fieldValue : ''));

            setFormData(newFormData);
            setFirstRun(false);
            handleSearch(fieldName, fieldValue);
        }
    };

    const onHandleSelectChange = (e: IExtendedSelectEvent) => {
        setSelectedOption(e);
        const searchField = e.value !== 'undefined' ? e.name : '';
        const searchTerm = e.value !== 'undefined' ? e.value : '';

        if (handleSelectChange) {
            handleSelectChange(searchField, searchTerm);
        } else if (handleSearch) {
            handleSearch(searchField, searchTerm);
        }

        setFirstRun(false);
    };

    const onHandleDateChange = (searchField: string, date: IDateValue) => {
        if (handleSearch) {
            const oldDateFrom = from ? moment.utc(from).format() : '';
            const oldDateTo = to ? moment.utc(to).format() : '';
            const dateFrom = date.from ? moment.utc(date.from).format() : oldDateFrom;
            const dateTo = date.to ? moment.utc(date.to).format() : oldDateTo;

            handleSearch(searchField, `${dateFrom};${dateTo}`);

            setFirstRun(false);
        }
    };

    const renderTableItem = () => data.map((item, rowNum) => (
        <tr key={item.id} onClick={() => handleRowClick(item.id)} data-test-row-id={item.id} data-test-row-num={rowNum}>
            {item.columns.map((x, i) => (
                <td key={i} className={cn('border-0 position-relative', tdClassName)} style={tableHeaders[i] && tableHeaders[i].columnStyles}>
                    {tableHeaders[i].isDate ? moment(x as string).format('DD.MM.YYYY в HH:mm') : x}
                </td>
            ))}
        </tr>
    ));

    const renderTableHeaders = () => tableHeaders.map((field, i) => (
        <th
          key={i}
          className={cn('border-0 mb-2', styles['custom-table-header'], field.isDate && styles.tableDate)}
          style={tableHeaders[i] && tableHeaders[i].columnStyles}
        >
            <span className="mb-2 d-inline-block">{field.title}</span>
            {field.fieldName !== ''
                ? (
                    <>
                        {handleOrder && <div className={`${styles.sortBtn} ml-2`} onClick={() => handleOrder(field.fieldName)} />}
                        {!field.selectOptions && !field.isDate ? (
                            <Form autoComplete="new-password" onSubmit={((e: React.FormEvent) => e.preventDefault())}>
                                <Form.Control
                                  type="text"
                                  placeholder="Искать"
                                  autoComplete="off"
                                  name={`${field.fieldName}`}
                                  defaultValue={formData[field.fieldName]}
                                  onKeyUp={onInputKeyup}
                                  onKeyDown={onInputKeydown}
                                  onChange={onInputChange}
                                />
                            </Form>
                        ) : null}
                        {field.selectOptions && (handleSelectChange || handleSearch) ? (
                            <ExtendedSelect
                              name={field.fieldName}
                              value={selectedOption.value || (filters && `${filters[field.fieldName]}`)}
                              options={field.selectOptions}
                              placeholder="Выбрать"
                              className={styles.selectOptions}
                              onChange={onHandleSelectChange}
                              withoutDetectChanges
                            />
                        ) : null}
                        {field.isDate ? (
                            <DateTimePicker
                              fetch={date => onHandleDateChange(field.fieldName, date)}
                              containerClassName={styles.date_container}
                              groupClassName={styles.date_group}
                              firstDateProps={{ readOnly: true, placeholder: 'Дата от' }}
                              secondDateProps={{ readOnly: true, placeholder: 'Дата по' }}
                              setFrom={setFrom}
                              setTo={setTo}
                              from={from}
                              to={to}
                              withoutLabels
                              withoutTime
                              withoutClear
                            />
                        ) : null}
                    </>
                )
                : null}
        </th>
    ));

    const renderNotFound = () => (
        <tr>
            <td colSpan={tableHeaders.length} className="not-found">Записи не найдены</td>
        </tr>
    );

    if (data.length === 0 && firstRun && !filters) {
        return (
            <EmptySection
              placeholderTitle={placeholderTitle || 'Нет ничего в списке'}
              placeholderText={placeholderText || 'Здесь будут отображаться данные'}
              placeholderIcon={placeholderIcon || (props.users ? EmptyUsersListIcon : EmptyListIcon)}
              onButtonClick={onButtonClick}
              placeholderButtonText={placeholderButtonText}
              className={placeholderClassName}
            />
        );
    }

    return (
        <>
            <Table className="mb-3 w-100 advancedTable" data-test-table>
                <thead>
                    <tr>
                        {renderTableHeaders()}
                    </tr>
                    {renderAdditionalHeaderRow()}
                </thead>
                <tbody>
                    {(!firstRun || filters) && data.length === 0
                        ? renderNotFound()
                        : renderTableItem()}
                </tbody>
            </Table>
            {!withoutPagination && handlePageChange && (
                <Pagination
                  onChange={handlePageChange}
                  pagination={{
                        page: page || 1,
                        perPage: pageSize || 10,
                        pages: Math.ceil((total || 1) / (pageSize || 10)),
                    }}
                  className={styles.pagination}
                />
            )}
        </>
    );
};

export default AdvancedTable;

export interface AdvancedTableHeader {
    fieldName: string;
    title: string;
    columnStyles?: CSSProperties;
    selectOptions?: ISelectOption[];
    isDate?: boolean;
}

export interface AdvancedTableData {
    id: string;
    columns: (string | number | JSX.Element)[];
}

interface TableProps extends Partial<IEmptySectionProps> {
    data: AdvancedTableData[];
    tableHeaders: AdvancedTableHeader[];
    page?: number;
    pageSize?: number;
    total?: number;
    withoutPagination?: boolean;
    users?: boolean;
    tdClassName?: string;
    filters?: { [key: string]: string } | null;
    handleRowClick: (itemId: string) => void;
    handlePageChange?: (paginationData: IPaginationData) => void;
    handleSearch?: (fieldName: string, searchTerm: string) => void;
    handleOrder?: (fieldName: string) => void;
    handleSelectChange?: (fieldName: string, value: string) => void;
    renderAdditionalHeaderRow?: () => ReactElement | boolean;
    placeholderClassName?: string;
}

interface ISelectOption {
    label: string;
    value: string;
}

interface IDateValue {
    from?: moment.Moment;
    to?: moment.Moment;
}
