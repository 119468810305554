export const formatSnils = (snils: string, defaultReturn: string = '') => {
    const cleaned = (`${snils}`).replace(/\D/g, '');

    const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);

    if (match) {
        return `${match[1]}-${match[2]}-${match[3]} ${match[4]}`;
    }

    return defaultReturn;
};
