import React, { useEffect } from 'react';
import {
    Card,
    AdvancedTable,
    AdvancedTableData,
    AdvancedTableHeader,
    PaginationData,
    WarningModal,
    IEmptySectionProps,
    AnimatedTabs,
} from 'components';
import { Button, Tab } from 'react-bootstrap';
import { ApiError } from '_helpers/errors/ApiError';
import { formatPhoneNumber } from '_helpers';
import AcUserForm, { IUserDetail } from './UserForm';
import userPlaceholderIcon from 'components/assets/svg/main/ac_admin_empty_table.svg';

const UsersPage = ({
    getList,
    getUserDetail,
    handleClickFormClose,
    submitUserForm,
    removeUser,
    repeatInvite,
    showForm,
    userList,
    apiError,
    userDetail,
    loading,
    withoutTabs,
    withoutBackIcon,
}: Props) => {
    useEffect(() => {
        const fetch = async () => {
            setProcessing(true);
            await getList({});
            setProcessing(false);
        };

        fetch();
    }, []);

    const initialState: State = {
        showForm: false,
        showWarning: false,
        deletedId: null,
        listSortOrder: true,
        firstRun: true,
    };

    const [activeTab, setActiveTab] = React.useState(TabKey.Info);
    const [processing, setProcessing] = React.useState(false);
    const [showWarning, setShowWarning] = React.useState(initialState.showWarning);
    const [deletedId, setDeletedId] = React.useState(initialState.deletedId);
    const [listSortOrder, setListSortOrder] = React.useState(initialState.listSortOrder);
    const [firstRun, setFirstRun] = React.useState(initialState.firstRun);


    const onOpenForm = async (id: string) => {
        await getUserDetail(id);
    };

    const onClose = () => {
        handleClickFormClose();
        setListSortOrder(true);
    };

    const formSubmit = (userDetails: IUserDetail) => {
        submitUserForm(userDetails);
    };

    const onRemoveUser = () => {
        if (userDetail) {
            setDeletedId(userDetail.id);
            setShowWarning(true);
        }
    };

    const onHideWarningModal = () => {
        setDeletedId(null);
        setShowWarning(false);
    };

    const onSubmitWarningModal = async () => {
        if (deletedId) {
            const res = await removeUser(deletedId);

            if (res) {
                handleClickFormClose();
            }
        }
        setShowWarning(false);
    };

    const handleListOrder = (fieldName: string) => {
        setListSortOrder(!listSortOrder);
        getList({ sortField: fieldName, sortOrder: listSortOrder });
    };

    const handleListSearch = (fieldName: string, value: string) => {
        setFirstRun(false);
        getList({ searchField: fieldName, searchTerm: value });
    };

    const warningModalProps = {
        title: 'Удалить администратора?',
        show: showWarning,
        onHide: onHideWarningModal,
        onAccess: onSubmitWarningModal,
    };

    const emptyDataProps: IEmptySectionProps = {
        placeholderTitle: 'Нет добавленных администраторов',
        placeholderText: 'Вам необходимо добавить, как минимум, одного администратора АК',
        placeholderButtonText: 'Добавить администратора',
        onButtonClick: () => onOpenForm('new'),
        placeholderIcon: userPlaceholderIcon,
    };

    const listTableHeaders: AdvancedTableHeader[] = [
        { fieldName: 'fullName', title: 'ФАМИЛИЯ, ИМЯ, ОТЧЕСТВО', columnStyles: { width: '60%' } },
        { fieldName: 'email', title: 'ПОЧТА', columnStyles: { width: '40%' } },
        { fieldName: 'phone', title: 'ТЕЛЕФОН', columnStyles: { minWidth: '10rem' } },
    ];

    const repeatInviteFunc = repeatInvite && userDetail && !userDetail.emailConfirmed ? () => repeatInvite(userDetail.id) : undefined;

    const createListTableData = (): AdvancedTableData[] => (userList ? userList.items.map(user => ({
        id: user.id,
        columns: [
            user.fullName,
            user.email,
            formatPhoneNumber(user.phone) || user.phone,
        ],
    })) : []);

    const renderUserDetails = () => (
        <AcUserForm
          initUserDetails={userDetail || undefined}
          onSubmit={formSubmit}
          onCancel={onClose}
          repeatInvite={repeatInviteFunc}
          getUserDetail={getUserDetail}
          loading={loading}
          apiError={apiError}
        />
    );

    const createHederButton = () => (userList && userList.items.length ? <Button onClick={() => onOpenForm('new')}>Добавить администратора</Button> : null);

    return (
        <section>
            {!showForm && firstRun ? (
                <Card
                  cardClassName="border-0"
                  title="Администрация"
                  category="Список администраторов"
                  showLoader={processing}
                  apiError={apiError}
                  headerChild={createHederButton()}
                  withoutPadding
                >
                    <AdvancedTable
                      data={createListTableData()}
                      tableHeaders={listTableHeaders}
                      handlePageChange={(event: PaginationData) => getList({ page: event.page, pageSize: event.perPage })}
                      handleOrder={handleListOrder}
                      handleRowClick={onOpenForm}
                      handleSearch={handleListSearch}
                      pageSize={(userList && userList.pageSize) || 10}
                      total={(userList && userList.total) || 1}
                      page={(userList && userList.page) || 1}
                      {...emptyDataProps}
                    />
                </Card>
            ) : null}

            {showForm ? (
                <Card
                  cardClassName="border-0"
                  title={userDetail ? `${userDetail.firstName} ${userDetail.lastName}` : 'Добавление администратора'}
                  withBackButton={!withoutBackIcon && !!userDetail}
                  apiError={apiError}
                  showLoader={processing}
                  onClickBack={onClose}
                  dotsButtons={userDetail ? [{
                        buttonText: 'Удалить администратора',
                        iconType: 'delete',
                        onClick: onRemoveUser,
                    }] : undefined}
                  smallTitle
                  withoutPadding
                  withoutTitleMargin
                  withoutBottomMargin
                >
                    {!withoutTabs ? (
                        <AnimatedTabs
                          id="usersPageTabs"
                          activeKey={activeTab}
                          onSelect={(key: string) => setActiveTab(key as TabKey)}
                        >
                            <Tab eventKey={TabKey.Info} title="Информация" className="tab mt-4 p-0">
                                {renderUserDetails()}
                            </Tab>
                        </AnimatedTabs>
                    ) : renderUserDetails()}
                    <WarningModal {...warningModalProps} />
                </Card>
            ) : null}
        </section>
    );
};

export default UsersPage;

export interface IList {
    items: User[];
    page: number;
    pageSize: number;
    total: number;
}

export interface IGetList {
    acIdQuery?: string;
    searchField?: string;
    searchTerm?: string;
    page?: number;
    pageSize?: number;
    sortOrder?: boolean;
    sortField?: string;
}

interface Props {
    showForm: boolean;
    userList: IList | null;
    userDetail: IUserDetail | null;
    apiError: ApiError | null;
    loading?: boolean;
    withoutTabs?: boolean;
    withoutBackIcon?: boolean;
    getList: (data: IGetList) => void;
    getUserDetail: (userId: string) => void;
    submitUserForm: (userDetail: IUserDetail) => void;
    handleClickFormClose: () => void;
    removeUser: (userId: string) => Promise<boolean | null>;
    repeatInvite?: (userId: string) => void;
}

interface State {
    showForm: boolean;
    showWarning: boolean;
    deletedId: string | null;
    listSortOrder: boolean;
    firstRun: boolean;
}

interface User {
    id: string;
    fullName: string;
    email: string;
    phone: string;
}

enum TabKey {
    Info = 'info',
}
