import React, { useState, useEffect, createRef } from 'react';
import {
    getTableHeader, createArray, IHeaderRows, IRowItem,
} from './helpers';
import { withDragDropContext } from 'components/DragDrop'; // Не прокидывается через components
import { AutoGrowTextArea } from 'components';
import cn from 'classnames';
import styles from './EditableTable.module.scss';
import RowRenderer from './RowRenderer';

const EditableTable = ({
    table, headersSeparator, onlyWatch, submitSolution, fromAnswerRows, hideTitle, tableId,
}: IProps) => {
    const totalText = 'ИТОГО:';
    const tableRef = createRef<HTMLTableElement>();
    const headersArr = table.columns.map(({ header }) => header);
    const multilineArr = table.columns.map(({ multiline }) => multiline);
    const readOnlyArr = table.columns.map(({ readonly }) => readonly);
    const headersWidth = table.columns.map(({ width }) => (width));
    const headersLength = headersArr.length;
    const disabledMoving = Boolean(table.forbidMoving || onlyWatch);

    const prepareTaskGrid = (tableRows: string[][]): GridElement[][] => tableRows.map((r, i) => r.map((c, j) => (
        {
            value: i === tableRows.length - 1 && j === 0 && c === 'total' ? totalText : c,
            readOnly: onlyWatch || readOnlyArr[j],
            colSpan: 1,
        })));

    const [grid, setGrid] = useState(prepareTaskGrid((fromAnswerRows && table.answerRows) || table.initialRows));
    const [tableWidth, setTableWidth] = useState(0);
    const gridLength = grid.length;

    useEffect(() => {
        setGrid(prepareTaskGrid((fromAnswerRows && table.answerRows) || table.initialRows));
    }, [table, onlyWatch]);

    useEffect(() => {
        if (tableRef.current) {
            setTableWidth(tableRef.current.clientWidth - 25);
        }
    }, [tableRef.current]);

    const onSubmit = (newGrid: GridElement[][]) => {
        if (submitSolution) {
            submitSolution(convertGridToSolution(newGrid));
        }
    };

    const handleRowDrop = (from: number, to: number) => {
        if (disabledMoving) return;

        const sourceRow = [...grid[from]];
        const nGrid = grid.slice();
        nGrid.splice(from > to ? to : to + 1, 0, sourceRow);
        const newGrid = nGrid.filter((x, i) => (i !== (from > to ? from + 1 : from)));
        setGrid(newGrid);
        onSubmit(newGrid);
    };

    const handleDeleteClick = (rowNumber: number) => {
        if (onlyWatch) return;

        const newGrid = grid.filter((r, i) => rowNumber !== i);
        setGrid(newGrid);
        onSubmit(newGrid);
    };

    const handleCLickRowAdd = () => {
        if (onlyWatch) return;

        const newGrid = [...grid];
        const emptyGrid = createArray<GridElement>(headersLength, () => ({ value: '', readOnly: false, colSpan: 1 }));
        const totalGrid = newGrid.find(r => r.find(c => c.value === totalText));

        if (totalGrid) {
            newGrid.push(totalGrid);
            newGrid.splice(gridLength - 1, 1, emptyGrid);
        } else {
            newGrid.push(emptyGrid);
        }

        setGrid(newGrid);
        onSubmit(newGrid);
    };

    const handleCellInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, row: number, col: number) => {
        if (onlyWatch) return;

        const { value } = e.currentTarget;
        const newGrid = [...grid];
        newGrid[row][col] = { ...newGrid[row][col], value };
        setGrid(newGrid);
        onSubmit(newGrid);
    };

    const handleSelectNextCell = (e: React.KeyboardEvent<HTMLInputElement>, row: number, col: number) => {
        if (e.key !== 'Enter') return;
        let nRow = row;
        let nCol = col + 1;

        if (nCol === grid[0].length) {
            nCol = 0;
            nRow += 1;
        }
        if (nRow === gridLength) nRow = 0;

        const cell: HTMLInputElement | null = document.querySelector(`#editableTableCell_${tableId}-${nRow}${nCol} input`);

        if (cell) {
            cell.focus();
        }
    };

    const renderTableHeaders = (headers: IHeaderRows) => headers.map((h, i, arr) => (
        <tr key={`${i}-r`}>
            {!disabledMoving && <th className={styles.empty_th} />}
            {h.map((header, j) => (
                <th
                  id={i === arr.length - 1 ? `editableTableHeader=${j}` : ''}
                  colSpan={header.colSpan}
                  style={getHeaderWidth(j, header)}
                  key={j}
                >
                    {header.text}
                </th>
            ))}
            {!disabledMoving && <th className={styles.empty_th} />}
        </tr>
    ));

    const renderFields = (row: number, col: number, { readOnly, value }: GridElement) => (
        onlyWatch || value === totalText || readOnly ? <div>{value}</div>
            : (
                <>
                    {(multilineArr[col] ? (
                        <AutoGrowTextArea
                          value={value}
                          onChange={e => handleCellInputChange(e, row, col)}
                        />
                    ) : (
                            <input
                              type="text"
                              className="text-left"
                              value={value || ''}
                              onKeyPress={e => handleSelectNextCell(e, row, col)}
                              onChange={e => handleCellInputChange(e, row, col)}
                            />
                        ))}
                </>
            ));

    const getHeaderWidth = (col: number, header: IRowItem) => {
        if (!tableWidth) return {};

        let width = 0;

        if (header.colSpan !== undefined && header.colSpan > 1) {
            for (let i = 0; i < header.colSpan; i += 1) {
                width += headersWidth[col + i];
            }
        } else {
            const el = table.columns.find(column => column.header === header.text);
            if (el) {
                const { width: nWidth } = el;
                width = nWidth;
            }
        }

        const res = {
            width: width !== 0 && `${Math.ceil(tableWidth * width / 100)}px`,
            maxWidth: width !== 0 && `${Math.ceil(tableWidth * width / 100)}px`,
        };

        return res;
    };

    return (
        <>
            {table.caption && !hideTitle && <span className={styles.caption}>{table.caption}</span>}
            <table
              ref={tableRef}
              id="editableExamTable"
              className={cn('table taskTable table-striped table-hover', styles.editable_table, !disabledMoving && styles.editable_table_moving)}
            >
                {headersLength > 0 ? (
                    <thead>
                        {renderTableHeaders(getTableHeader(headersArr, headersSeparator))}
                    </thead>
                ) : null}
                <tbody>
                    {grid.map((r, row) => (
                        <RowRenderer
                          key={`rowRenderer_${row}`}
                          rowIndex={row}
                          onRowDrop={handleRowDrop}
                          gridLength={gridLength}
                          headersLength={headersLength}
                          disabledMoving={disabledMoving}
                          handleDeleteClick={handleDeleteClick}
                          handleCLickRowAdd={handleCLickRowAdd}
                        >
                            {r.map((c, col) => (
                                <td
                                  className={cn(styles.cell)}
                                  data-test-answer-table-cell={`${row}:${col}`}
                                  data-not-move={col === 0 && c.value === totalText}
                                  id={`editableTableCell_${tableId}-${row}${col}`}
                                  key={`editableTableCell_${tableId}-${row}${col}`}
                                >
                                    {renderFields(row, col, c)}
                                </td>
                            ))}
                        </RowRenderer>
                    ))}
                </tbody>
            </table>
        </>
    );
};

const convertGridToSolution = (grid: GridElement[][]): string[][] => grid.map(r => r.map(c => c.value));

export default withDragDropContext(EditableTable);

interface IProps {
    headersSeparator: string;
    table: ITable;
    tableId: string;
    onlyWatch?: boolean;
    fromAnswerRows?: boolean;
    hideTitle?: boolean;
    submitSolution?: (solution: string[][]) => void;
}

interface ITable {
    caption: string;
    columns: IColumn[];
    forbidMoving: boolean;
    initialRows: string[][];
    exactEquality?: boolean;
    answerRows?: string[][];
}

interface IColumn {
    header: string;
    key: boolean;
    multiline: boolean;
    numeric: boolean;
    readonly: boolean;
    width: number;
}

interface GridElement {
    value: string;
    colSpan: number;
    readOnly: boolean;
}
