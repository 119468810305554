class UnsavedFormDetector {
    constructor() {
        this.changedInputs = {};
        this.formSubmitted = false;
        this.detectChanges = false;
    }

    private changedInputs: { [key: string]: IInput };
    private formSubmitted: boolean;
    private detectChanges: boolean;

    private getSubmittedInputs = () => {
        let submitted: { [key: string]: IInput } = {};

        Object.keys(this.changedInputs).forEach((key) => {
            const obj: IInput = {
                oldValue: this.changedInputs[key].value,
                value: this.changedInputs[key].value,
            };

            submitted = { ...submitted, [key]: obj };
        });

        return submitted;
    };

    public setChangedInput(key: string, value: string) {
        if (this.detectChanges) {
            this.formSubmitted = false;

            if (!this.changedInputs[key]) {
                this.changedInputs[key] = {
                    oldValue: value,
                    value,
                };
            } else {
                this.changedInputs[key] = {
                    ...this.changedInputs[key],
                    value,
                };
            }
        }
    }

    public setFormSubmit(isSubmit: boolean) {
        if (this.detectChanges) {
            this.changedInputs = this.getSubmittedInputs();
            this.formSubmitted = isSubmit;
        }
    }

    public setDetectChanges(detect: boolean) {
        this.detectChanges = detect;
    }

    public getShowModal() {
        if (this.detectChanges) {
            const inputsChanged = Object.keys(this.changedInputs).find(key => this.changedInputs[key].oldValue !== this.changedInputs[key].value);

            return !!inputsChanged && !this.formSubmitted;
        }

        return false;
    }

    public resetDetectState() {
        this.changedInputs = {};
        this.formSubmitted = false;
        this.detectChanges = false;
    }
}

export const formDetector = new UnsavedFormDetector();

interface IInput {
    oldValue: string;
    value: string;
}
