import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { AuthenticationForm } from 'components';

const LoginContainer = ({
    onLogin, apiError,
}: Props) => (
        <AuthenticationForm
            subTitle="в личный кабинет и запись на аккредитации"
            apiError={apiError}
            //onLogin={data => onLogin({ ...data, phone: data.phone || '' })}
            //withRegister
            withPhone
        />
    );

const mapState = (state: IRootState) => ({
    apiError: state.errors.error,
});

const mapDispatch = (dispatch: Dispatch) => ({
    onLogin: dispatch.auth.login,
});

export default connect(mapState, mapDispatch)(LoginContainer);

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
type Props = connectedProps;
