import React from 'react';
import styles from './ExtendedSelect.module.scss';
import { OptionProps, OptionTypeBase, Styles } from 'react-select';
// eslint-disable-next-line import/no-unresolved
import { isArray } from 'util';
import { Color, formDetector } from '_helpers';
import classNames from 'classnames';
import Creatable from 'react-select/creatable';
import AddIcon from '../../assets/svg/add_icon.svg';

const ExtendedSelect = ({
    value,
    name,
    withoutDetectChanges,
    withoutStrictValue,
    isInvalid,
    options,
    multi,
    onChangeMulti,
    onChange,
    className,
    noOptionsMessage,
    disabled,
    placeholder,
    notSelectedOptions,
    onMenuOpen,
    subText,
    invalidTitle,
    isClearable,
    smSize,
  ...props
}: Props) => {
    React.useEffect(() => {
        if (value && !isArray(value) && !withoutDetectChanges) {
            formDetector.setChangedInput(name, value);
        }
    }, [value]);

    const customStyle: Styles<OptionTypeBase, true> = {
        control: base => ({
            ...base,
            border: isInvalid ? '1px solid #dc3545 !important' : '',
        }),
        option: (base: React.CSSProperties, { data }: OptionProps<OptionTypeBase, true>): React.CSSProperties => ({
            ...base,
            color: data.color && String(data.color).startsWith('--') ? `var(${data.color}) !important` : data.color,
        }),
    };

    const multiOptions: string[] | null = value && isArray(value) ? value as string[] : null;
    const selectedOptions = options.filter(x => (multiOptions && multiOptions.length && multiOptions.find(option => x.value === option)));
    const findValue = multi && Array.isArray(value)
      ? options.filter(x => value.includes(x.value))
      : options.find(x => x.value === value);

    const onChangeSelect = (e: any) => {
        if (multi && onChangeMulti) {
            onChangeMulti(e);
        } else {
            onChange({
                name,
                value: e && e.value && e.value as string,
            });
            if (!withoutDetectChanges) {
                formDetector.setChangedInput(name, e.value as string);
            }
        }
    };

    return (
        <div className={classNames(styles.container, className)} data-test-extended-select style={{textTransform: props.noUppercase ? "none" : undefined}}>
            <Creatable
              isValidNewOption={() => !!props.creatable}
              formatCreateLabel={() => (
                <a className="d-flex align-items-center">
                    <img className="mr-2" src={AddIcon} alt="add_icon" />
                    <span ref={r => r && r.style.setProperty('color', 'var(--primary)', 'important')}>{props.createLabel}</span>
                </a>
              )}
              onCreateOption={props.onCreateOption}
              createOptionPosition={props.createOptionPosition || 'first'}
              noOptionsMessage={() => noOptionsMessage || 'Нет опций'}
              isDisabled={disabled}
              isMulti={multi}
              isClearable={isClearable}
              styles={customStyle}
              name={name}
              className={classNames('react-select-container', smSize && 'react-select-container_size_sm')}
              classNamePrefix="react-select"
              defaultValue={selectedOptions}
              value={withoutStrictValue ? findValue : (findValue || null)}
              onChange={onChangeSelect}
              options={notSelectedOptions || options}
              placeholder={placeholder || 'Выберите из списка'}
              onMenuOpen={onMenuOpen}
            />
            {subText ? (
                <div className={styles.feedbackText}>
                    {subText}
                </div>
            )
                : null}
            {isInvalid && invalidTitle ? (
                <div className={`${styles.feedbackText} mt-1 text-danger`}>
                    {invalidTitle || null}
                </div>
            )
                : null}
        </div>
    );
};

export default ExtendedSelect;

export interface IOption {
    value: string;
    label: string;
    color?: Color | string;
}

export interface ISelectEvent {
    name: string;
    value: string;
}

interface Props {
    creatable?: boolean;
    createLabel?: string;
    onCreateOption?: (inputValue: string) => void;
    createOptionPosition?: 'first' | 'last';
    name: string;
    options: IOption[];
    notSelectedOptions?: IOption[];
    value?: string | string[] | null;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    isInvalid?: boolean;
    invalidTitle?: string;
    subText?: string;
    noOptionsMessage?: string;
    multi?: boolean;
    isClearable?: boolean;
    withoutDetectChanges?: boolean;
    withoutStrictValue?: boolean;
    smSize?: boolean;
    onChange: (event: ISelectEvent) => void;
    onChangeMulti?: (event: ISelectEvent[]) => void;
    onMenuOpen?: () => void;
    noUppercase?: boolean;
}
