import * as React from 'react';
import { Details, ReactNodeValue } from '../../../Details';
import { IExamDetails } from '../../../models';
import { DetailsStringValue } from '../../../index';
import styles from './AwaitingExpertConfirmationComponent.module.scss';
import { StepStatus } from '../../../StepStatus';

const AwaitingExpertConfirmationComponent: React.FC<IProps> = ({ examDetails }) => {
    const infoRowsFromExamDetails = React.useMemo(() => [
        new DetailsStringValue('Регион', examDetails.regionName),
        new DetailsStringValue('Специальность', examDetails.qualificationTitle),
    ], [examDetails]);

    const progressStepsDetails = React.useMemo(() => examDetails.examCreationProgress.map((step, index) => {
        const prevStepCompletionDateTime = index > 0 ? examDetails.examCreationProgress[index - 1].completionDateTime : null;
        return  new ReactNodeValue(
            step.completionDateTime
                ? step.completionDateTime.format('DD.MM.YYYY - HH:mm')
                : prevStepCompletionDateTime ? 'Ожидание' : '',
            <StepStatus
                step={{
                    ...step,
                    status: step.completionDateTime ? 'success' : 'loading',
                }}
            />,
        )
    }), [examDetails]);

    return (
      <>
          <Details
            centeredRow
            withDarkRowLabel
            infoRows={infoRowsFromExamDetails}
          />

          <h4 className={styles.title}>Процесс создания</h4>
          <Details
            centeredRow
            withDarkRowLabel
            infoRows={progressStepsDetails}
          />
      </>
    );
};

interface IProps {
    examDetails: IExamDetails;
}

export default AwaitingExpertConfirmationComponent;
