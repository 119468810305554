import React, { useEffect, useState } from 'react';
import {
    DatePicker,
    Card,
    Checkbox,
    CustomRadio,
    LoaderBtn,
    Captcha,
    InputText, ExtendedSelect,
} from 'components';
import {
    Col,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
} from 'react-bootstrap';
import { mapAPIError, RegExpConst, RegExpErrorsText } from '_helpers';
import { Sex } from '../../api/ApiClient';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import styles from './RegistrationStep.module.scss';

const defaultState: FormState = {
    email: '',
    sex: '' as Sex,
    personalChecked: false,
    birthDate: null,
    snils: '',
    regionId: 0,
};

const defaultFormErrors = {
    email: '',
    sex: '',
    personalChecked: '',
    birthDate: '',
    captcha: '',
    snils: '',
    regionId: '',
};

const RegistrationStep4 = (
  {
      apiError, getRegionsAsync, register, registerForm, authProcessing, regions,
  }: Props,
) => {
    const [form, setForm] = useState(defaultState);
    const [formSubmit, setFormSubmit] = useState(false);
    const [errors, setErrors] = useState(defaultFormErrors);
    const [captcha, setCaptcha] = React.useState('');
    const [captchaValid, setCaptchaValid] = React.useState(true);
    const fieldFailures = errors as { [key: string]: string };
    const lkHost = window.location.hostname.includes('lk');

    useEffect(() => {
        setErrors({
            ...errors,
            ...mapAPIError(apiError),
        });
    }, [apiError]);

    useEffect(() => {
        getRegionsAsync();
    }, []);

    const doValidate = (formData: FormState) => {
        const validateErrors = { ...errors };

        const setE = (isNotValid: boolean, text?: string) => (isNotValid ? (text || 'Error') : '');
        const test = (field: string, regexp: RegExp, text?: string) => setE(!regexp.test(field), text);

        validateErrors.email = test(formData.email, RegExpConst.EMAIL);
        validateErrors.birthDate = setE(!formData.birthDate);
        validateErrors.snils = test(formData.snils, RegExpConst.SNILS, RegExpErrorsText.SNILS);
        validateErrors.sex = setE(!formData.sex);
        validateErrors.regionId = setE(!formData.regionId, 'Обязятельное поле');
        validateErrors.captcha = '';

        const isCaptchaValid = apiError && apiError.failures && apiError.failures.captcha ? captcha.length === 4 : true;
        const isFormValid = Object.values(validateErrors).every(err => !err);

        if (validateErrors.email) {
            validateErrors.email = 'Неверная электронная почта';
        }

        setCaptchaValid(isCaptchaValid);
        setErrors(validateErrors);

        return isFormValid && isCaptchaValid;
    };

    const handleToggleCheck = (targetNodeName: string, name: keyof FormState) => {
        if (targetNodeName !== 'DIV' && targetNodeName !== 'LABEL') return;
        setForm({
            ...form,
            [name]: !form[name],
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setFormSubmit(true);

        if (!doValidate(form)) return;

        await register({
            ...registerForm,
            snils: form.snils.replace(/[- ]/g, ''),
            email: form.email,
            regionId: form.regionId,
            birthDate: moment(form.birthDate || ''),
            sex: form.sex,
            captcha,
        });

        setCaptcha('');
    };

    const handleChangeInput = (name: keyof FormState, value: FormState[keyof FormState]) => {
        const newForm = {
            ...form,
            [name]: value,
        };

        setForm(newForm);
        if (formSubmit) {
            doValidate(newForm);
        }
    };

    const renderSexElement = (sex: Sex) => (
      <CustomRadio
        option={sex}
        name="sex"
        label={SexTranslation[sex]}
        checked={form.sex === sex}
        onClick={() => handleChangeInput('sex', sex)}
      />
    );

    return (
      <React.Fragment>
          <Col className="auth-forms--container">
              <form onSubmit={handleSubmit}>
                  <Card
                    title="Регистрация"
                    category="дополнительная информация"
                    apiError={apiError}
                    contentClassName={styles.content}
                    legend={(
                      <div>
                          <Form.Control.Feedback className="d-block" type="invalid">
                              {apiError && (apiError.failures && apiError.failures && !(apiError.failures.email)) && (!(apiError.failures && apiError.failures.length) || apiError.type === 'captcha') && apiError.message}
                          </Form.Control.Feedback>
                          <LoaderBtn
                            variant="primary"
                            type="submit"
                            disabled={!form.personalChecked || Boolean(authProcessing)}
                            loading={Boolean(authProcessing)}
                          >
                              Зарегистрироваться
                          </LoaderBtn>
                      </div>
                    )}
                    ftTextCenter
                  >
                      <InputText
                        labelTitle="Электронная почта"
                        labelColSize={12}
                        inputColSize={12}
                        inputClassNames="p-0"
                        value={form.email}
                        onChange={handleChangeInput}
                        name="email"
                        fieldFailures={fieldFailures}
                        autoFocus
                      />

                      <FormGroup data-test-date-group>
                          <FormLabel>Дата рождения</FormLabel>
                          <DatePicker
                            datePickerSizeSm
                            onChange={(_, time) => handleChangeInput('birthDate', time)}
                            isInvalid={errors.birthDate ? 'Заполните дату рождения' : undefined}
                          />
                      </FormGroup>

                      <InputText
                        labelTitle="Снилс"
                        labelColSize={12}
                        inputColSize={12}
                        inputClassNames="p-0"
                        value={form.snils}
                        onChange={handleChangeInput}
                        name="snils"
                        maxLength={11}
                        fieldFailures={fieldFailures}
                        autoFocus
                      />

                      <FormGroup data-is-residency-group>
                          <FormLabel>Регион</FormLabel>
                          <ExtendedSelect
                            name="regionId"
                            placeholder="Выберите..."
                            options={regions ? regions.map(r => ({
                                value: String(r.id),
                                label: r.name,
                            })) : []}
                            onChange={e => handleChangeInput('regionId', Number(e.value))}
                            invalidTitle={fieldFailures.regionId}
                            isInvalid={!!fieldFailures.regionId}
                            withoutStrictValue
                          />
                      </FormGroup>

                      <FormGroup className={styles.phone_form_container} data-test-sex-group>
                          <div className="sex-container">
                              {renderSexElement(Sex.Male)}
                              {renderSexElement(Sex.Female)}
                          </div>
                          <FormControl hidden isInvalid={Boolean(errors.sex)} />
                          <Form.Control.Feedback type="invalid">
                              Выберите пол
                          </Form.Control.Feedback>
                      </FormGroup>

                      <FormGroup>
                          <div className="required-checkboxes-container">
                              <div
                                onClick={(e: any) => handleToggleCheck(e.target.nodeName, 'personalChecked')}
                                data-test-first-checkbox
                              >
                                  <Checkbox checked={form.personalChecked} number="1" />
                                  <label>
                                      Я выражаю свое согласие на обработку моих персональных данных в
                                      соответствии с
                                      <a
                                        target={lkHost ? '__blank' : undefined}
                                        style={{ display: 'block' }}
                                        //href={lkHost ? personalDataProcessingPolicy : '#'}
                                      >
                                          Политикой обработки и защиты
                                          персональных данных
                                      </a>
                                  </label>
                              </div>
                          </div>
                      </FormGroup>
                      <Captcha
                        captcha={captcha}
                        captchaValid={captchaValid}
                        apiError={apiError}
                        setCaptcha={setCaptcha}
                      />
                  </Card>
              </form>
          </Col>
          <div className={styles.register_link_container}>
              Уже зарегистрированы? <Link to="/login" className={styles.register_link}>Войти</Link>
          </div>
      </React.Fragment>
    );
};

const mapState = (state: IRootState) => ({
    registerForm: state.auth.registerForm,
    regions: state.regions.list,
    apiError: state.errors.error,
    authProcessing: Boolean(state.loading.effects.auth.register),
});

const mapDispatch = (dispatch: Dispatch) => ({
    register: dispatch.auth.register,
    getRegionsAsync: dispatch.regions.getRegionsAsync,
});

export default connect(mapState, mapDispatch)(RegistrationStep4);

type connectedProps = ReturnType<typeof mapDispatch> & ReturnType<typeof mapState>;
type Props = connectedProps;

interface FormState {
    email: string;
    sex: Sex;
    personalChecked: boolean;
    birthDate: moment.Moment | null;
    snils: string;
    regionId: number;
}

enum SexTranslation {
    'male' = 'Мужской',
    'female' = 'Женский',
}
