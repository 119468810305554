import React from 'react';
import { IQualificationDocumentTemplateGroup, IExamApplicationDocument } from 'components/models';
import { DocumentUpLoader } from 'components';
import { IApiError } from '_helpers/ApiConnector/types';

const DocumentsTab = (props: IProps) => (
    <DocumentUpLoader {...props} onGoBack={props.goToIdentificationTab} onSubmit={props.goToSendingTab} />
);

export default DocumentsTab;

export interface IProps {
    documentTemplateGroups: IQualificationDocumentTemplateGroup[];
    uploadedDocuments: IExamApplicationDocument[];
    correctionsRequired?: boolean;
    apiError: IApiError | null | undefined;
    goToIdentificationTab: () => void;
    goToSendingTab: () => void;
    deleteDocument: (documentId: string) => void;
    uploadDocument: (templateId: string, file: File) => void;
    deleteDocumentSource: (documentId: string, sourceFileId: string) => void;
}
