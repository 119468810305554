export interface ITheoreticalQuestion {
    number: number;
    active: boolean;
    text: string;
    type: TheoreticalQuestionType;
    openAnswer?: string;
    id?: string;
    multipleChoiceAnswers?: ITheoreticalAnswer[];
    matchingOrOrderingAnswers?: IMatchingTheoreticalAnswers;
}

export interface IMatchingTheoreticalAnswer {
    number: number;
    text: string;
}

export interface IFieldsError {
    [key: string]: string;
}

export enum TheoreticalQuestionType {
    MultipleChoiceTheoretical = 'multipleChoiceTheoretical',
    MatchingTheoretical = 'matchingTheoretical',
    OpenAnswerTheoretical = 'openAnswerTheoretical',
    OrderingTheoretical = 'orderingTheoretical',
}

export interface ITheoreticalAnswer {
    number: number;
    text: string;
    correct: boolean;
}

interface IMatchingTheoreticalAnswers {
    left: IMatchingTheoreticalAnswer[];
    right: IMatchingTheoreticalAnswer[];
    correct: { [key: string]: number };
    leftAnswersHeader?: string;
    rightAnswersHeader?: string;
}
