import React from 'react';
import styles from './Row.module.scss';
import cn from 'classnames';

const Row = ({
    title, value, rowClassName, valueColor,
}: IProps) => (
        <div className={cn(styles.row, rowClassName)}>
            <label className={styles.row_title}>{title}</label>
            <div className={styles.row_value} style={{ color: valueColor }}>{value || '-'}</div>
        </div>
    );

export default Row;

export const renderRow = (title: IProps['title'], value: IProps['value'], rowClassName?: IProps['rowClassName'], valueColor?: IProps['valueColor'], extKey?: string | number) => (
    <Row title={title} value={value} rowClassName={rowClassName} valueColor={`var(${valueColor})`} key={title + value + extKey} />
);

interface IProps {
    title: string;
    value?: string | React.ReactNode;
    rowClassName?: string;
    valueColor?: string;
}
