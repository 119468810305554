import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Form,
    FormGroup,
    FormLabel,
} from 'react-bootstrap';
import { Card, ExtendedSelect } from 'components';
import { connect } from 'react-redux';
import { mapAPIError } from '_helpers';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import styles from './RegistrationStep.module.scss';
import { useMultiSearch } from 'hooks';
import { ResidencyOptions } from 'components/enums';

const defaultState = {
    qualificationId: '',
};

const RegistrationStep3 = (
  {
      getQualificationsAsync,
      setRegisterForm,
      nextStep,
      apiError,
      qualifications,
  }: Props,
) => {
    const [form, setForm] = useState(defaultState);
    const [errors, setErrors] = useState(defaultState);

    const { filters, changeFilterField } = useMultiSearch<{ residency?: boolean }>(getQualificationsAsync, {});

    useEffect(() => {
        setErrors({
            ...errors,
            ...mapAPIError(apiError),
        });
    }, [apiError]);

    const onNext = () => {
        setRegisterForm(form);
        nextStep();
    };

    return (
      <React.Fragment>
          <Col className="auth-forms--container">
              <form>
                  <Card
                    title="Регистрация"
                    category="информация об образовании"
                    apiError={apiError}
                    contentClassName={styles.content}
                    legend={(
                      <div>
                          <Button
                            disabled={!form.qualificationId}
                            variant="primary"
                            onClick={onNext}
                          >
                              Продолжить
                          </Button>
                      </div>
                    )}
                    ftTextCenter
                  >
                      <FormGroup>
                          <FormLabel>Уровень образования</FormLabel>
                          <ExtendedSelect
                            value={filters.residency ? String(filters.residency) : undefined}
                            name="residency"
                            placeholder="Выберите..."
                            options={ResidencyOptions}
                            onChange={(e) => {
                                changeFilterField('residency')(e.value === 'true');
                                setForm(defaultState);
                            }}
                            withoutStrictValue
                          />
                      </FormGroup>

                      <FormGroup>
                          <FormLabel>Специальность</FormLabel>
                          <ExtendedSelect
                            value={form.qualificationId || null}
                            disabled={filters.residency === undefined}
                            name="qualificationId"
                            placeholder="Выберите..."
                            options={qualifications ? qualifications.items.map(q => ({
                                value: q.id,
                                label: `${q.regNumber} - ${q.title}`,
                            })) : []}
                            onChange={e => setForm({ qualificationId: e.value })}
                          />
                      </FormGroup>
                      <Form.Control.Feedback className="d-block" type="invalid">
                          {apiError && (!(apiError.failures && apiError.failures.verificationCode) || apiError.type === 'captcha') && apiError.message}
                      </Form.Control.Feedback>
                  </Card>
              </form>
          </Col>
      </React.Fragment>
    );
};

const mapState = (state: IRootState) => ({
    qualifications: state.auth.qualificationList,
    apiError: state.errors.error,
});
const mapDispatch = (dispatch: Dispatch) => ({
    setRegisterForm: dispatch.auth.setRegisterForm,
    getQualificationsAsync: dispatch.auth.getQualificationsAsync,
});

interface OwnProps {
    nextStep: () => void;
}

type connectedProps = ReturnType<typeof mapDispatch> & ReturnType<typeof mapState>;
type Props = connectedProps & OwnProps;

export default connect(mapState, mapDispatch)(RegistrationStep3);
