/**
 * Функция для склонения слов
 *
 * Например, для слова "яблоко" нужно так передать значения: wordDeclination(1, ['яблоко', 'яблока', 'яблок']
 *
 *
 * @param number - число для склонения
 *
 * @param txt - варианты склонения: [одно, два, много]
 *
 * @param cases - кейсы для получения склонения
 */
export default (number: number, txt: string[], cases = [2, 0, 1, 1, 1, 2]) => txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
