import React from 'react';
import {
    AdvancedTable, Card, ExamStatus, MappedExamApplicationStatuses, StatusColumn,
} from 'components';
import { IExamApplicationList, IExamDetails } from 'components/models';
import { IExamApplicationsFilters } from 'components/reduxModels';
import cn from 'classnames';
import styles from './ApplicantsTab.module.scss';
import { IPaginationData } from '../../Pagination/Pagination';
import {
    Color, ColorStatus, formatPhoneNumber, formatSnils,
} from '_helpers';
import GroupIcon from 'components/assets/svg/group.svg';
import { useMultiSearch } from 'hooks';

const ApplicantsTab = ({
    examApplications, handleApplicantsListChange, handleApplicantClick, examStatus, pagination, examDetails,
}: IProps) => {
    const { changeFilter } = useMultiSearch<IExamApplicationsFilters>(handleApplicantsListChange, { examId: examDetails.id });
    const isExamFinished = React.useMemo(() => (
      [ExamStatus.Finishing, ExamStatus.Finished].includes(examStatus)
    ), [examStatus]);

    const applicationsHeader = isExamFinished ? [
        { fieldName: 'applicantFullName', title: 'ИМЯ, ФАМИЛИЯ', columnStyles: { width: '55%' } },
        { fieldName: 'applicantPhone', title: 'Телефон', columnStyles: { width: 165 } },
        { fieldName: 'status', title: 'Результат' },
    ] : [
        { fieldName: 'applicantFullName', title: 'ИМЯ, ФАМИЛИЯ' },
        { fieldName: 'applicantEmail', title: 'ПОЧТА' },
        { fieldName: 'applicantSnils', title: 'Снилс', columnStyles: { width: 150 } },
        { fieldName: 'applicantPhone', title: 'Телефон', columnStyles: { width: 165 } },
    ];

    const data = examApplications.items.map(ea => ({
        id: ea.examApplicationId,
        columns: isExamFinished ? [
            ea.applicantFullName,
            formatPhoneNumber(ea.applicantPhone) || ea.applicantPhone,
            <StatusColumn
              status={ea.finalized ? MappedExamApplicationStatuses[ea.status] : new ColorStatus(Color.Default, 'Финализация результата')}
            />,
        ] : [
            ea.applicantFullName,
            ea.applicantEmail,
            formatSnils(ea.applicantSnils),
            formatPhoneNumber(ea.applicantPhone) || ea.applicantPhone,
        ],
    }));

    const title = 'Список аккредитуемых';

    const category = 'Список всех аккредитуемых, зарегистрированных на аккредитационное мероприятие';

    return (
        <Card
          title={title}
          category={category}
          withoutPadding
          withoutBorder
        >
            <div className={cn(styles.admitted, 'ml-30')}>
                <span>Всего аккредитуемых</span>
                <span>{examDetails.applicationsNumber || 0} чел.</span>
            </div>

            <AdvancedTable
              data={data}
              tableHeaders={applicationsHeader}
              handleRowClick={handleApplicantClick || (() => true)}
              page={pagination ? pagination.page : undefined}
              pageSize={pagination ? pagination.perPage : undefined}
              total={examApplications.total}
              handlePageChange={p => changeFilter({
                  page: p.page,
                  pageSize: p.perPage,
              })}
              handleSearch={(f, t) => changeFilter({
                  searchField: f,
                  searchTerm: t,
              })}
              placeholderIcon={GroupIcon}
              placeholderTitle="Список аккредитуемых пуст"
              placeholderText="Здесь будут отображаться все аккредитуемые, зарегистрированные на аккредитацию"
            />
        </Card>
    );
};

export default ApplicantsTab;

interface IProps extends IAppProps {
    examApplications: IExamApplicationList;
    examStatus: ExamStatus;
    examDetails: IExamDetails;
}

export interface IAppProps {
    handleApplicantsListChange: (data: IExamApplicationsFilters) => void;
    handleApplicantClick?: (examApplicationId: string) => void;
    pagination?: IPaginationData;
}
