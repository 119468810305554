import React from 'react';
import { Container, Row, Form } from 'react-bootstrap';
import { InputText } from 'components';
import { IMatchingTheoreticalAnswer, IFieldsError } from './types';
import { serialNumberToChar } from '_helpers';
import styles from './TheoreticalQuestionRedactor.module.scss';
import cn from 'classnames';

const QuestionVariant = ({
    answer,
    answerChar,
    formFieldErrors,
    handleChangeMatchingAnswerText,
    num,
    onAdd,
    onDelete,
    questionLeftLength,
    containerClassName,
}: IProps) => (
        <Container className={cn('m-0 p-0', containerClassName)}>
            <Row className="m-0 p-0">
                <div className={cn('m-0 p-0 col-12', styles.answer_container)}>
                    <span>{serialNumberToChar(answerChar)}.</span>
                    <InputText
                      inputClassNames="pr-0"
                      inputGroupClassNames="mb-0 w-100"
                      inputColSize={12}
                      withoutLabel
                      name={`answer${answer.number}`}
                      value={answer.text}
                      onChange={handleChangeMatchingAnswerText(answer.number)}
                      fieldFailures={formFieldErrors}
                    />
                </div>
            </Row>
            <Form.Text className={cn('row mt-0', { 'mb-2': questionLeftLength > 2, 'mb-4': questionLeftLength <= 2 }, styles.matchingAdditionalBtn)}>
                {num + 1 === questionLeftLength ? (
                    <a href="#" className={cn('pt-0', styles['font-extra-small'], styles.matchingAddBtn)} onClick={onAdd}>
                        Добавить вариант
                    </a>
                ) : (<div />)}
                {questionLeftLength > 2 && (
                    <a
                      href="#"
                      className={cn('offset-1 pt-0 text-danger', styles['font-extra-small'])}
                      onClick={onDelete(answer.number)}
                    >
                        Удалить вариант
                    </a>
                )}
            </Form.Text>
        </Container>
    );

export default QuestionVariant;

interface IProps {
    answer: IMatchingTheoreticalAnswer;
    answerChar: number;
    formFieldErrors: IFieldsError;
    questionLeftLength: number;
    num: number;
    containerClassName?: string;
    handleChangeMatchingAnswerText: (answerNumber: number) => (name: string, value: string) => void;
    onAdd: (e: React.FormEvent<HTMLAnchorElement>) => void;
    onDelete: (answerNumber: number) => (e: React.FormEvent<HTMLAnchorElement>) => void;
}
