import moment from 'moment';
import { Color, wordDeclination } from '_helpers';
import { IExamStage } from 'components/models';

export const formatDayTitle = ({ dayNumber, group, time }: HasDayNumber) => {
    let result = `День ${dayNumber}`;

    if (group) {
        result = result.concat(`: группа ${group}`);
    }

    if (time) {
        result = result.concat(`: ${time.format('DD.MM.YYYY в HH:mm')}`);
    }

    return result;
};
export const formatDayOption = (
  {
      dayNumber, group, time, value, totalApplications,
  }: HasDayInfo,
  stageInfo: IExamStage,
) => {
    const availablePlaces = stageInfo.audienceCapacity - (totalApplications || 0);
    let result = `День ${dayNumber}`;

    if (group) {
        result = result.concat(`: группа ${group}`);
    }

    if (time) {
        result = result.concat(` - ${time.format('DD.MM.YYYY')}`);
    }

    result = availablePlaces
      ? result.concat(` (${availablePlaces} ${wordDeclination(availablePlaces, ['место', 'места', 'мест'])} доступно)`)
      : result.concat(' (Нет доступных мест)');

    return {
        label: result,
        isDisabled: !availablePlaces,
        color: availablePlaces ? Color.Default : Color.Red,
        value,
    };
};

interface HasDayNumber {
    dayNumber: number;
    group?: number | undefined;
    time?: moment.Moment;
}

interface HasDayInfo extends HasDayNumber{
    totalApplications?: number;
    value: string;
}
