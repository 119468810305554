import * as React from 'react';
import { Details } from '../../Details';
import { IExamDetails } from 'components/models';
import { ExamStatus, getExamStatusMapping } from 'components/enums';
import {
    DatePeriodColumn, DetailsArrayValue, DetailsReactNodeValue, DetailsStringValue,
} from 'components/index';

export default class ExamInfoState implements IExamInfoState {
    title = 'Информация об аккредитации';

    subTitle = 'Общая информация об аккредитационном мероприятии';

    examDetails: IExamDetails;

    constructor(examDetails: IExamDetails) {
        this.examDetails = examDetails;
    }

    getTitle = () => this.title;

    getSubTitle = () => this.subTitle;

    getInfoElement = () => {
        const { examDetails } = this;

        const getInfoRowsFromExamDetails = () => {
            const infoRows: (DetailsReactNodeValue | DetailsStringValue | DetailsArrayValue)[] = [
                new DetailsStringValue('СПЕЦИАЛЬНОСТЬ', examDetails.qualificationTitle),
                new DetailsStringValue('РЕГИОН', examDetails.regionName),
                new DetailsStringValue('', ''),
                new DetailsReactNodeValue(
                  'ПЕРИОД ПРОВЕДЕНИЯ',
                  <DatePeriodColumn
                    dateFrom={examDetails.date}
                    dateTo={examDetails.lastDate}
                  />,
                ),
                new DetailsStringValue('', ''),
                new DetailsStringValue('ТЕКУЩИЙ СТАТУС', getExamStatusMapping(examDetails.status, examDetails.currentStageNumber).text),
            ];

            if (examDetails.status === ExamStatus.IsApplying) {
                infoRows.push(new DetailsStringValue('ОКОНЧАНИЕ ЗАПИСИ', examDetails.applyingDeadline.local().format('DD.MM.YYYY в HH:mm')));
            } else if (examDetails.status !== ExamStatus.AwaitingExpertConfirmation) {
                infoRows.push(new DetailsStringValue('АККРЕДИТУЕМЫХ', `${examDetails.applicationsNumber} чел.`));
            }

            infoRows.push(
              new DetailsStringValue('', ''),
              new DetailsStringValue('', ''),
            );

            return infoRows;
        };

        return (
          <Details infoRows={getInfoRowsFromExamDetails()} withDarkRowLabel />
        );
    };
}


export interface IExamInfoState {
    getTitle: () => string;
    getSubTitle: () => string;
    getInfoElement: () => string | JSX.Element;
}
