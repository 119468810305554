import * as React from 'react';
import styles from '../StageContainer.module.scss';
import ExpertsAssignTable from '../Components/ExpertsAssignTable';
import { Moment } from 'moment';
import { WarningModal } from 'components/Common/Modals';
import { IBaseExperts, IBaseExpertsForDay, IExpert } from 'components/models';
import { IApiError } from '_helpers/ApiConnector/types';

const AssigningExpertsModal: React.FC<IProps> = (props) => {
    const handleAssignExpert = React.useCallback(
      (experts: IExpert[]) => props.onUpdateExperts(experts),
      [props.onUpdateExperts],
    );

    React.useEffect(() => {
        if (props.show && props.examDayId) {
            props.expertsForDayList.fetch({
                examDayId: props.examDayId,
            });
        }
        if (!props.show) {
            props.expertsForDayList.clear();
        }
    }, [props.show, props.examDayId]);

    return (
      <WarningModal
        large
        withoutCancelButton
        centered={false}
        withoutTitlePadding
        show={props.show}
        title={`Назначение членов комиссии ${props.time ? props.time.format('на DD.MM.YYYY') : ''}`}
        onHide={props.onHide}
        accessBtnTittle="Продолжить"
        bodyClassNames={styles.modalBodyWithoutPadding}
        closeBtnTittle="Добавить члена комиссии"
        footerClassNames="py-0"
        dialogClassName={styles.modalWithoutPadding}
      >
          <ExpertsAssignTable
            autoOpen
            expertsForDayList={props.expertsForDayList}
            experts={props.experts}
            onUpdateExperts={handleAssignExpert}
            minAssigningExperts={props.minAssigningExperts}
            onHide={props.onHide}
            apiError={props.apiErrors && props.apiErrors.failures && props.apiErrors.failures.expertIds ? props.apiErrors.failures.expertIds[0] : undefined}
          />
      </WarningModal>
    );
};

interface IProps {
    examDayId?: string;
    time?: Moment;
    show: boolean;
    onHide: () => void;
    onUpdateExperts: (experts: IExpert[]) => void;
    minAssigningExperts: number;

    experts: IBaseExperts;
    expertsForDayList: IBaseExpertsForDay;
    apiErrors: IApiError | null;
}


export default AssigningExpertsModal;
