export const createReturnUrl = () => {
    const url = window.location.pathname;
    const search = new URLSearchParams(window.location.search);
    const from = search.get('from');

    if (url !== '/login' && url !== '/') {
        localStorage.setItem('ReturnUrl', `${url}${from ? `?from=${from}` : ''}`);
    }
};

export const getReturnUrl = () => {
    const returnUrl = localStorage.getItem('ReturnUrl');
    localStorage.removeItem('ReturnUrl');

    return returnUrl;
};
