import React from 'react';
import { rowDragSource, rowDropTarget } from './drag-drop';
import dndIcon from 'components/assets/svg/dnd_icon.svg';
import styles from './DragDrop.module.scss';
import cn from 'classnames';

const DragDrop = rowDragSource(rowDropTarget(({
    children, connectDropTarget, connectDragPreview, connectDragSource, isOver, dndClassName,
}: IDropProps): JSX.Element => {
    const className = isOver ? styles.drop_target : styles.container;

    return connectDropTarget && connectDragPreview && connectDragSource && (
        connectDropTarget(connectDragPreview(
            <div className={className}>
                {connectDragSource(
                    <div className={cn(styles.dnd_btn, dndClassName)}>
                        <img src={dndIcon} alt="dnd-btn" />
                    </div>,
                )}
                {children}
            </div>,
        ))
    );
}));

export default DragDrop;

interface IProps {
    children: any;
    rowIndex: number;
    dndClassName?: string;
    onRowDrop: (from: number, to: number) => void;
}

export interface IDragProps {
    children: any;
    rowIndex: number;
    isOver?: boolean;
    onRowDrop: (from: number, to: number) => void;
    connectDropTarget?: (el: JSX.Element) => any;
    connectDragPreview?: (el: JSX.Element) => any;
    connectDragSource?: (el: JSX.Element) => any;
}

type IDropProps = IProps & IDragProps;
