import React from 'react';
import styles from './AlertText.module.scss';
import cn from 'classnames';

const AlertText = ({ title, text, className }: IProps) => (
    <div className={cn(styles.container, className && className)}>
        <span className={styles.container_title}>{title}</span>
        <span className={styles.container_text}>{text}</span>
    </div>
);

export default AlertText;

interface IProps {
    title: string;
    text: string;
    className?: string;
}
