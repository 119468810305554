import React from 'react';
import { ExamDetailsContainer } from '../../containers';
import { RouteComponentProps } from 'react-router';

const ExamDetailsPage = ({ match }: Props) => <ExamDetailsContainer examId={match.params.id} />;

export default ExamDetailsPage;

interface OwnProps {
    id: string;
}

type Props = RouteComponentProps<OwnProps>
