import React from 'react';
import cx from 'classnames';
import styles from './CustomCheckbox.module.scss';

const CustomCheckbox = ({
    checked, onCheck, number, label, inline, blue, className, ...rest
}: CustomCheckboxProps) => {
    const [isChecked, setIsChecked] = React.useState(checked);

    const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (checked === undefined) {
            setIsChecked(!isChecked);
        }

        if (onCheck) {
            onCheck(e);
        }
    };

    const classes = cx('checkbox', styles.blue, {
        'checkbox-inline': inline,
    }, className);

    return (
        <div className={classes}>
            <input
              id={number}
              type="checkbox"
              onChange={onCheck || handleClick}
              checked={checked !== undefined ? checked : isChecked}
              {...rest}
            />
            <label htmlFor={number}>{label}</label>
        </div>
    );
};

export default CustomCheckbox;

interface CustomCheckboxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>{
    checked?: boolean;
    disabled?: boolean;
    number: string;
    className?: string;
    label?: string;
    inline?: boolean;
    blue?: boolean;
    onCheck?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
