import React from 'react';
import styles from './CKText.module.scss';
import cn from 'classnames';
import './CKStyles.scss';

const CKText = ({ text }: IProps) => (
    <div className={cn('ck-content', styles.container)} dangerouslySetInnerHTML={{ __html: text || '' }} />
);

export default CKText;

interface IProps {
    text?: string;
}
