import React from 'react';
import { Card, LoaderBtn, Captcha } from 'components';
import { IApiError } from '../../_helpers/ApiConnector/types';
import {
    Col,
    FormGroup,
    FormLabel,
    FormControl,
    Form,
    Button,
} from 'react-bootstrap';

const ResetPasswordSecondStep = ({
    phone, checkCode, resendCode, goBack, goNext, waitingSeconds, apiError,
}: IProps) => {
    const [code, setCode] = React.useState('');
    const [codeValid, setCodeValid] = React.useState(true);
    const [captcha, setCaptcha] = React.useState('');
    const [captchaValid, setCaptchaValid] = React.useState(true);
    const [timer, setTimer] = React.useState(waitingSeconds);
    const [processing, setProcessing] = React.useState(false);

    const newPhone = phone.replace(/\D+/g, '');
    const phoneLetters = newPhone.split('');
    const number = `+7 ${phoneLetters.slice(0, 3).join('')}-***-**-${phoneLetters.slice(8).join('')}`;

    const doValidate = (): boolean => {
        const isCaptchaValid = apiError && apiError.failures && apiError.failures.captcha ? captcha.length === 4 : true;
        const isCodeValid = code.length === 4;

        setCaptchaValid(isCaptchaValid);
        setCodeValid(isCodeValid);

        return isCaptchaValid && isCodeValid;
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!doValidate()) return;

        setProcessing(true);
        const codeExists = await checkCode(phone, code, captcha);
        setProcessing(false);
        setCaptcha('');
        setCode('');

        if (codeExists) {
            goNext();
        }
    };

    const resendVerificationCode = async () => {
        setCodeValid(true);
        setCode('');

        const status = await resendCode();

        if (status && status.waitingSeconds) {
            setTimer(status.waitingSeconds);
        }
    };

    const calcTimer = () => {
        if (timer) {
            setTimer(timer - 1);
        }
    };

    React.useEffect(() => {
        const id = setTimeout(calcTimer, 1000);

        return () => clearTimeout(id);
    }, [timer, waitingSeconds]);

    return (
        <Col className="auth-forms--container">
            <form onSubmit={onSubmit}>
                <Card
                  title="Сброс пароля"
                  ftTextCenter
                  legend={(
                        <>
                            <LoaderBtn
                              variant="primary"
                              type="submit"
                              disabled={!code || processing}
                              loading={processing}
                            >
                                Продолжить
                            </LoaderBtn>
                            <Button
                              variant="outline-secondary"
                              onClick={goBack}
                            >
                                Назад
                            </Button>
                        </>
                    )}
                >
                    <div className="sub-title">
                        На номер {number} был отправлен код для подтверждения.
                    </div>

                    <FormGroup data-test-verify-code-group>
                        <FormLabel>Введите код из СМС</FormLabel>
                        <FormControl
                          autoFocus
                          name="verificationCode"
                          onChange={(e: React.FormEvent<any>) => setCode((e.target as HTMLInputElement).value)}
                          value={code}
                          isInvalid={!codeValid || !!(apiError && apiError.failures && apiError.failures.verificationCode)}
                          maxLength={4}
                          type="text"
                        />
                        <Form.Control.Feedback type="invalid">
                            {codeValid ? (apiError && apiError.failures && apiError.failures.verificationCode) : 'Длина кода должна составлять 4 символа'}
                        </Form.Control.Feedback>

                        {timer ? (
                            <div className="resend-interval-text">Отправить повторно через {timer} секунд</div>
                        ) : (
                                <div className="forgot-password--container">
                                    <span onClick={resendVerificationCode}>Отправить код повторно</span>
                                </div>
                            )
                        }
                    </FormGroup>
                    <Captcha captcha={captcha} captchaValid={captchaValid} apiError={apiError} setCaptcha={setCaptcha} />
                    <Form.Control.Feedback className="d-block" type="invalid">
                        {apiError && !(apiError.failures && apiError.failures.verificationCode) && apiError.message}
                    </Form.Control.Feedback>
                </Card>
            </form>
        </Col>
    );
};

export default ResetPasswordSecondStep;

interface IProps {
    apiError?: IApiError | null;
    phone: string;
    waitingSeconds: number;
    checkCode: (phone: string, code: string, captcha?: string) => Promise<boolean>;
    resendCode: () => Promise<IStatus | null>;
    goNext: () => void;
    goBack: () => void;
}

interface IStatus {
    wasSent?: boolean;
    waitingSeconds?: number;
}
