import React from 'react';
import {
    IExamApplication, IExamDayApplication, IFilterPart, ISearchPart,
} from 'components/models';
import { Button, FormControl, Table } from 'react-bootstrap';
import Pagination, { IPaginationData } from '../Pagination/Pagination';
import styles from './ExamAdmittingApplicationsTable.module.scss';
import sortIcon from '../assets/svg/sort_icon.svg';
import groupIcon from '../assets/svg/group.svg';
import cn from 'classnames';
import CustomCheckbox from '../Common/CustomCheckbox/CustomCheckbox';
import { WarningModal } from '../Common/Modals';
import { ExtendedSelect } from '../index';
import { ISelectEvent } from '../Common/ExtendedSelect/ExtendedSelect';
import { MappedAdmittedResultColor } from '../enums';

const ExamAdmittingApplicationsTable = (props: ExamApplicationsTableProps) => {
    const initialFilter: IFilter = {
        fieldName: '',
        fieldValue: '',
    };

    const { examApplications } = props;
    const [filter, setFilter] = React.useState(initialFilter);
    const [checkedApplications, setCheckedApplications] = React.useState<Record<string, boolean>>({});
    const [checkedAll, setCheckedAll] = React.useState(false);
    const [showEmptyListModal, setShowEmptyListModal] = React.useState(false);

    React.useEffect(() => {
        setCheckedAll(false);
        setCheckedApplications({});
    }, [examApplications]);

    const statusOptions = [
        { label: MappedAdmittedResultColor.false.text, value: 'false' },
        { label: MappedAdmittedResultColor.true.text, value: 'true' },
    ];


    const handleCheckApplication = React.useCallback((id: string) => {
        const newChecked = {
            ...checkedApplications,
            [id]: !checkedApplications[id],
        };
        const countOfChecked = examApplications.filter(i => newChecked[i.examApplication.examApplicationId]).length;
        if (countOfChecked === examApplications.length) {
            setCheckedAll(true);
        } else if (checkedAll) {
            setCheckedAll(false);
        }

        setCheckedApplications(newChecked);
    }, [checkedApplications, checkedAll, examApplications]);

    const handleCheckAll = React.useCallback(() => {
        const newChecked: Record<string, boolean> = {};
        examApplications.forEach((a) => {
            newChecked[a.examApplication.examApplicationId] = !checkedAll;
        });
        setCheckedApplications(newChecked);
        setCheckedAll(!checkedAll);
    }, [checkedAll, examApplications]);

    const handleAssignedApplicants = React.useCallback(() => {
        const assignedApplicants = examApplications.filter(i => checkedApplications[i.examApplication.examApplicationId]);
        if (!assignedApplicants.length) {
            setShowEmptyListModal(true);
        } else if (props.onAssignedApplicants) {
            props.onAssignedApplicants(assignedApplicants);
        }
    }, [examApplications, checkedApplications, props.onAssignedApplicants]);

    const triggerParentChange = (data?: IPaginationData, nFilter?: IFilter) => {
        const { fieldName: searchField, fieldValue: searchTerm } = nFilter || filter;

        if (data) {
            const { page, perPage: pageSize } = data;

            props.onChange({
                searchField, searchTerm, page, pageSize,
            });
        }

        props.onChange({ searchField, searchTerm });
    };

    const onFilterFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | { target: ISelectEvent }) => {
        const { name, value } = event.target;

        const newFilter = {
            ...filter,
            fieldName: name,
            fieldValue: value,
        };

        setFilter(newFilter);
        triggerParentChange(props.pagination, newFilter);
    };

    const renderTableRow = (examDayApplication: IExamDayApplication) => {
        const { examApplication } = examDayApplication;
        return (
          <tr
            key={examApplication.examApplicationId}
            onClick={(e) => {
                if (props.readonly) return;
                if (props.onItemClick) props.onItemClick(examApplication.examApplicationId);
                handleCheckApplication(examApplication.examApplicationId);
                e.stopPropagation();
                e.preventDefault();
            }}
          >
              {props.withCheckbox && !props.readonly && (
                <td className="border-0">
                    <CustomCheckbox
                      blue
                      disabled={props.disableCheckbox}
                      checked={checkedApplications[examApplication.examApplicationId] || false}
                      number={examApplication.examApplicationId}
                      onCheck={() => true}
                    />
                </td>
              )}
              {props.headers.map(({ key, render }) => (
                <td className="border-0" key={key}>
                    {render ? render(examDayApplication) : examApplication[key]}
                </td>
              ))}
          </tr>
        );
    };

    return (
        <div className={props.className}>
            <Table className="mb-3 w-100 advancedTable">
                <thead>
                    <tr>
                        {props.withCheckbox && !props.readonly && (
                          <th className={cn(styles.th, 'border-0', styles.checkbox)}>
                              <CustomCheckbox
                                blue
                                disabled={props.disableCheckbox}
                                checked={checkedAll}
                                number={String(+Date.now())}
                                onCheck={handleCheckAll}
                              />
                          </th>
                        )}
                        {props.headers.map(({ value, key, isStatus }, i: number) => {
                            let textValue = '';
                            if (key === filter.fieldName) {
                                textValue = filter.fieldValue;
                            }

                            return (
                                <th className={cn(styles.th, 'border-0')} key={`th_${i}_${key}`}>
                                    <div className={styles.table_header_text}>
                                        {value}
                                        <img src={sortIcon} alt="sortIcon" />
                                    </div>
                                    {isStatus ? (
                                      <ExtendedSelect
                                        name={key}
                                        options={statusOptions}
                                        placeholder="Выбрать"
                                        onChange={e => onFilterFieldChange({ target: e })}
                                      />
                                    ) : (
                                      <FormControl
                                        name={key}
                                        placeholder="Искать"
                                        value={textValue}
                                        onChange={onFilterFieldChange}
                                      />
                                    )}
                                </th>
                            );
                        })}
                    </tr>
                    {props.withConfirmButton && !!examApplications.length && !props.readonly && (
                      <tr>
                          <th className={cn(styles.th, 'border-0', styles.confirmButton)} colSpan={2}>
                              <Button onClick={handleAssignedApplicants}>
                                  {props.confirmButtonText}
                              </Button>
                          </th>
                          <th className={cn(styles.th, 'border-0')} />
                      </tr>
                    )}
                </thead>
                <tbody className={styles.table__body}>
                    {examApplications.map(renderTableRow)}
                </tbody>
            </Table>
            {!examApplications.length && (
              <div className={styles.notFoundContainer}>
                  <img src={groupIcon} alt="Список пуст" />
                  <span className={styles.notFoundTitle}>
                      Список распределения пуст
                  </span>
                  <span className={styles.notFoundText}>
                      Здесь будут отображаться все аккредитуемые, требующие распределения
                  </span>
              </div>
            )}
            {props.pagination && !!examApplications.length && (
              <Pagination
                className={props.paginationClassName}
                pagination={props.pagination}
                onChange={triggerParentChange}
              />
            )}
            <WarningModal title="Требуется выбрать аккредитуемых" show={showEmptyListModal} onHide={() => setShowEmptyListModal(false)}>
                Пожалуйста, выберите несколько аккредитуемых из списка для того, чтобы распределить их.
            </WarningModal>
        </div>
    );
};

interface ExamApplicationsTableProps {
    withCheckbox?: boolean;
    readonly?: boolean;
    withConfirmButton?: boolean;
    confirmButtonText?: string;
    disableCheckbox?: boolean;
    onAssignedApplicants?: (applicants: IExamDayApplication[]) => void;
    headers: ExamAdmittingApplicationsColumnHeader[];
    pagination?: IPaginationData;
    examApplications: IExamDayApplication[];
    className?: string;
    paginationClassName?: string;
    onChange: (filter: IFilterPart & ISearchPart) => void;
    onItemClick?: (id: string) => void;
}

interface IFilter {
    fieldName: string;
    fieldValue: string;
}

interface ColumnHeader {
    value: string;
    key: string;
}

export class ExamAdmittingApplicationsColumnHeader implements ColumnHeader {
    value: string;
    key: keyof IExamApplication;
    render?: (applicant: IExamDayApplication) => string | JSX.Element;
    isStatus?: boolean;

    constructor(key: keyof IExamApplication, value: string, isStatus?: boolean, render?: (applicant: IExamDayApplication) => string) {
        this.key = key;
        this.value = value;
        this.render = render;
        this.isStatus = isStatus;
    }
}

export default ExamAdmittingApplicationsTable;
