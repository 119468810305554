import { SidebarTab } from 'components';
import ExamIcon from 'components/assets/svg/sidebar/exams.svg';
import QualificationIcon from 'components/assets/svg/sidebar/qualif.svg';

const sidebarTabs: SidebarTab[] = [
    {
        name: 'Аккредитации',
        icon: ExamIcon,
        state: 'exams',
        list: [
            {
                name: 'Доступные аккредитации',
                path: '/exam-timetables',
                routes: ['/exam/*?from=exam-timetables'],
            },
            {
                name: 'Мои заявки',
                path: '/exam-applications',
                routes: ['/exam-application/*?from=exam-applications'],
            },
            {
                name: 'Мои аккредитации',
                path: '/applicant-exams',
                routes: ['/exam/*?from=applicant-exams'],
            },
        ],
    },
    {
        name: 'Специальности',
        icon: QualificationIcon,
        state: 'qualifications',
        list: [
            {
                name: 'Мои специальности',
                path: '/applicant-qualifications',
                routes: ['/applicant-qualifications'],
            },
        ],
    },
];

export default sidebarTabs;
