import * as React from 'react';
import { useLoading } from 'hooks';
import { ExamAdmittingApplicationsTable } from 'components/ExamAdmittingApplicationsTable';
import { formatPhoneNumber, formatSnils } from '_helpers/formatHelpers';
import {
    IAdmitToExamDay,
    IAssignForDayInput, IBaseApplicantDetails, IBaseExamApplicationsForDayAdmit, IExamDay,
    IExamDayApplication,
} from 'components/models';
import { DocumentTypeTitles, MappedAdmittedResultColor } from 'components/enums';
import { WarningModal } from 'components/Common/Modals';
import { Loader } from 'components/Common/Loader';
import styles from 'components/ApplicantDocuments/ApplicantDocuments.module.scss';
import { DocumentSwitch } from 'components/ApplicantDocuments/DocumentSwitch';
import { formatFIO } from '_helpers';

const AdmitApplicantsTab: React.FC<IProps> = (props) => {
    const [showAdmitApplicantModal, setShowAdmitApplicantModal] = React.useState(false);
    const [fetchDetailsProcessing, fetchDetails] = useLoading((id: string) => props.applicantDetails.fetch(id));
    const [admitToExamDayProcessing, admitToExamDay] = useLoading(async (id: string) => props.admitToExamDay({
        examDayId: props.day.id,
        examId: props.day.examId,
        stage: props.day.stageNumber,
        body: {
            examApplicationId: id,
        },
    }), [props.day]);

    const [activeValues, setActiveValues] = React.useState<boolean[]>([]);

    const handleSwitchClick = (num: number, active: boolean) => {
        const newActive = [...activeValues];
        newActive[num] = active;
        setActiveValues(newActive);
    };

    React.useEffect(() => {
        if (props.applicantDetails.info) {
            setActiveValues(props.applicantDetails.info.documents.map(() => false));
        }
    }, [props.applicantDetails.info]);

    const handleAdmittingApplicant = (applicantId: string) => {
        fetchDetails(applicantId);
        setShowAdmitApplicantModal(true);
    };

    const handleAccessApplicant = async () => {
        if (props.applicantDetails.info) {
            await admitToExamDay(props.applicantDetails.info.examApplicationId);
        }

        await props.examApplicationsForDayAdmit.fetch({
            examId: props.day.examId,
            examDayId: props.day.id,
            stage: props.day.stageNumber,
        });
        setShowAdmitApplicantModal(false);
    };

    const renderStatus = (applicant: IExamDayApplication) => (
      <span style={{ color: `var(${MappedAdmittedResultColor[String(applicant.admitted) as 'true' | 'false'].color})` }}>
          {MappedAdmittedResultColor[String(applicant.admitted) as 'true' | 'false'].text}
      </span>
    );

    return (
      <>
          <ExamAdmittingApplicationsTable
            readonly={props.readonly}
            onItemClick={handleAdmittingApplicant}
            headers={[
                { value: 'Имя, фамилия', key: 'applicantFullName' },
                { value: 'Телефон', key: 'applicantPhone', render: a => String(formatPhoneNumber(a.examApplication.applicantPhone, '')) },
                { value: 'Снилс', key: 'applicantSnils', render: a => formatSnils(a.examApplication.applicantSnils) },
                {
                    value: 'Статус', key: 'status', render: renderStatus, isStatus: true,
                },
            ]}
            examApplications={(props.examApplicationsForDayAdmit.info && props.examApplicationsForDayAdmit.info.items) || []}
            onChange={() => {}}
          />

          {!!props.applicantDetails.info && (
            <WarningModal
              title={formatFIO(props.applicantDetails.info.applicant)}
              show={showAdmitApplicantModal}
              onHide={() => {
                  props.applicantDetails.clear();
                  setShowAdmitApplicantModal(false);
              }}
              disableAccessButton={activeValues.some(v => !v)}
              loading={admitToExamDayProcessing}
              onAccess={handleAccessApplicant}
            >
                {fetchDetailsProcessing ? <Loader /> : props.applicantDetails.info.documents.map((document, i) => (
                  <div className={styles.document} key={`document_${i}`}>
                      <a className={styles.document_image} href={document.fileUrl} target="__blank">
                          <img src={`${document.fileUrl}/preview`} alt="documentImage" />
                      </a>
                      <div className={styles.document_content}>
                        <span className={styles.document_title}>{DocumentTypeTitles[document.type]}
                        <DocumentSwitch
                          num={i}
                          onSwitchClick={handleSwitchClick}
                        />
                        </span>
                          <span className={styles.document_text}>{document.documentDescription}</span>
                      </div>
                  </div>
                ))}
            </WarningModal>
          )}
      </>
    );
};

interface IProps {
    day: IExamDay;
    readonly?: boolean;
    examApplicationsForDayAdmit: IBaseExamApplicationsForDayAdmit;
    applicantDetails: IBaseApplicantDetails;
    admitToExamDay: (props: IAssignForDayInput<IAdmitToExamDay>) => void;
}

export default AdmitApplicantsTab;
