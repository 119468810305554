import React from 'react';
import {
    AnimatedTabs,
    ApplicantDocuments,
    AppType,
    ExamApplicationDetails,
    ExamApplicationStatus,
    LoaderBtn,
    ProfileInfoTab,
    StatementTab,
} from 'components';
import { IExamApplicationDetails } from 'components/models';
import { Button, Tab } from 'react-bootstrap';
import cn from 'classnames';
import { StagingExamApplicationDetails } from '../ExamApplicationDetails';

const ExamApplicationTabs = (
  {
      tab,
      examApplicationDetails,
      appType,
      examTabChild,
      docTabChild,
      identificationTabChild,
      withoutTabPadding,
      onChangeSwitch,
      disableSelectTab,
      inModal,
      onStatementCancel,
      onStatementSubmit,
      processing,
  }: IProps,
) => {

    const renderStatementButtons = () => (
      <>
          {onStatementSubmit ? (
            <LoaderBtn
              variant="primary"
              className={cn('pl-4 pr-4')}
              onClick={onStatementSubmit}
              loading={processing}
              disabled={processing}
            >
                Одобрить заявку на аккредитацию
            </LoaderBtn>
          ) : null}

          {onStatementCancel ? (
            <Button
              className={cn('ml-4')}
              variant="outline-secondary"
              onClick={onStatementCancel}
              disabled={processing}
            >
                Отклонить заявку на аккредитацию
            </Button>
          ) : null}
      </>
    );
    
    const [activeTab, setActiveTab] = React.useState(tab ? TabKey[tab] : TabKey.ExamTab);

    const tabClassName = cn('tab', withoutTabPadding && 'p-0', inModal && 'mt-4');
    
    return (
      <AnimatedTabs
        id="examApplicationTabs"
        activeKey={activeTab}
        onSelect={(key: string) => !disableSelectTab && setActiveTab(key as TabKey)}
        inModal={inModal}
      >
          {examApplicationDetails.status === ExamApplicationStatus.Staging || examApplicationDetails.status === ExamApplicationStatus.NotPassed || examApplicationDetails.status === ExamApplicationStatus.Passed ? (
              <Tab eventKey={TabKey.ExamTab} title="Этапы и результат" className={tabClassName}>
                  <StagingExamApplicationDetails examApplicationDetails={examApplicationDetails} />
                  {examTabChild && examTabChild(() => setActiveTab(TabKey.IdentificationTab))}
              </Tab>
          ) : (
              <Tab eventKey={TabKey.ExamTab} title="Общая информаци" className={tabClassName}>
                  <ExamApplicationDetails examApplicationDetails={examApplicationDetails} />
                  {examTabChild && examTabChild(() => setActiveTab(TabKey.IdentificationTab))}
              </Tab>
          )}
          <Tab
            eventKey={TabKey.ProfileTab}
            title="Информация по документам"
            className={tabClassName}
          >
              <ProfileInfoTab
                applicant={examApplicationDetails.applicant}
                applicantIdentityDoc={examApplicationDetails.applicantIdentityDoc}
                applicantDiploma={examApplicationDetails.applicantDiploma}
              />
          </Tab>

          <Tab eventKey={TabKey.DocumentsTab} title="Документы" className={tabClassName}>
              <ApplicantDocuments documents={examApplicationDetails.documents} onChangeSwitch={onChangeSwitch} withoutPadding />
              {docTabChild}
              {appType !== AppType.Applicant && !examApplicationDetails.signCode ? renderStatementButtons() : null}
          </Tab>

          {appType !== AppType.Applicant && examApplicationDetails.signCode ? (
            <Tab
              eventKey={TabKey.StatementInfoTab}
              title="Заявление"
              className={cn('tab', 'mt-5', withoutTabPadding && 'p-0')}
            >
                <StatementTab
                  examApplication={examApplicationDetails}
                  submitButtonText="Одобрить заявку на аккредитацию"
                  cancelButtonText="Отклонить заявку на аккредитацию"
                  onCancel={onStatementCancel}
                  onSubmit={onStatementSubmit}
                  processing={processing}
                  withoutPadding
                />
            </Tab>
          ) : null}
      </AnimatedTabs>
    );
};

export default ExamApplicationTabs;

interface IProps {
    examApplicationDetails: IExamApplicationDetails;
    tab?: TabType;
    docTabChild?: React.ReactNode;
    appType?: AppType;
    withoutTabPadding?: boolean;
    disableSelectTab?: boolean;
    inModal?: boolean;
    processing?: boolean;
    examTabChild?: (goToIdentificationTab: () => void) => React.ReactNode;
    identificationTabChild?: (goToDocTab: () => void) => React.ReactNode;
    onChangeSwitch?: (active: boolean[]) => void;
    onStatementSubmit?: () => void;
    onStatementCancel?: () => void;
}

enum TabKey {
    ExamTab = 'exam-tab',
    ProfileTab = 'profile-tab',
    IdentificationTab = 'identification-tab',
    DocumentsTab = 'documents-tab',
    StatementInfoTab = 'statement-tab'
}

type TabType = keyof typeof TabKey;
