import React from 'react';
import {
    InputText,
    CustomSwitch,
    CustomRadio,
    CKEditor,
    LoaderBtn,
} from 'components';
import {
    ITheoreticalQuestion,
    IMatchingTheoreticalAnswer,
    IFieldsError,
    TheoreticalQuestionType,
    ITheoreticalAnswer,
} from './types';
import { Form, Col, Button } from 'react-bootstrap';
import { mapAPIError, RegExpConst } from '_helpers';
import { IApiError } from '_helpers/ApiConnector/types';
import MatchingOrOrderingTheoreticalQuestion from './MatchingOrOrderingTheoreticalQuestion';
import MultipleChoiceTheoreticalQuestion from './MultipleChoiceTheoreticalQuestion';
import _ from 'lodash';
import styles from './TheoreticalQuestionRedactor.module.scss';
import cn from 'classnames';

const TheoreticalQuestionRedactor = ({
    theoreticalQuestion,
    errors,
    loading,
    onSubmit,
    onExit,
}: IProps) => {
    const errorRef = React.useRef(errors);

    const initialState: IState = {
        questionType: TheoreticalQuestionType.MultipleChoiceTheoretical,
        question: theoreticalQuestion || emptyQuestion,
        formFieldErrors: {},
    };
    const [question, setQuestion] = React.useState(initialState.question);
    const [questionType, setQuestionType] = React.useState(initialState.questionType);
    const [formFieldErrors, setFormFieldErrors] = React.useState(initialState.formFieldErrors);

    React.useEffect(() => {
        errorRef.current = errors;
        const errorsMap = mapAPIError(errors);
        if (errorsMap) {
            setFormFieldErrors({ ...formFieldErrors, ...errorsMap });
        }
    }, [errors]);

    React.useEffect(() => {
        if (theoreticalQuestion && !loading) {
            setQuestion(theoreticalQuestion);
            setQuestionType(theoreticalQuestion.type);
        }
    }, [theoreticalQuestion]);

    const setQuestionNumber = (name: string, number: string) => {
        const newNumber = number === '' ? '0' : number;
        if (RegExpConst.NUMBER.test(newNumber)) {
            setQuestion({ ...question, number: parseInt(newNumber, 10) });
            setFormFieldErrors({ ...formFieldErrors, number: '' });
        }
    };

    const setQuestionText = (text: string) => {
        setQuestion({ ...question, text });
        setFormFieldErrors({ ...formFieldErrors, text: '' });
    };

    const changeCorrectAnswer = (answerNumber: number) => {
        const newAnswers = (question.multipleChoiceAnswers || []).map(a => (a.number === answerNumber
            ? { ...a, correct: !a.correct }
            : { ...a }));
        setQuestion({ ...question, multipleChoiceAnswers: newAnswers });
        setFormFieldErrors({ ...formFieldErrors, general: '' });
    };

    const handleChangeAnswerText = (answerNumber: number) => (name: string, value: string) => {
        const newAnswers = (question.multipleChoiceAnswers || []).map((a) => {
            if (a.number === answerNumber) {
                setFormFieldErrors({ ...formFieldErrors, [`answer${answerNumber}`]: '' });
                return { ...a, text: value };
            }
            return { ...a };
        });
        setQuestion({ ...question, multipleChoiceAnswers: newAnswers });
    };

    const handleChangeMatchingAnswerText = (answerNumber: number) => (name: string, value: string) => {
        if (!question.matchingOrOrderingAnswers) return;

        const newAnswers: IMatchingTheoreticalAnswer[] = (question.matchingOrOrderingAnswers.left).map((a) => {
            if (a.number === answerNumber) {
                setFormFieldErrors({ ...formFieldErrors, [`answer${answerNumber}`]: '' });
                return { ...a, text: value };
            }
            return { ...a };
        });
        setQuestion({ ...question, matchingOrOrderingAnswers: { ...question.matchingOrOrderingAnswers, left: newAnswers } });
    };

    const handleChangeOrderingAnswerText = (answerNumber: number) => (name: string, value: string) => {
        if (!question.matchingOrOrderingAnswers) return;

        const newAnswers: IMatchingTheoreticalAnswer[] = (question.matchingOrOrderingAnswers.right).map((a) => {
            if (a.number === answerNumber) {
                setFormFieldErrors({ ...formFieldErrors, [`answer_right_${answerNumber}`]: '' });
                return { ...a, text: value };
            }
            return { ...a };
        });
        setQuestion({ ...question, matchingOrOrderingAnswers: { ...question.matchingOrOrderingAnswers, right: newAnswers } });
    };

    const handleAddAnswer = (event: React.FormEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        const lastAnswer = _.maxBy(question.multipleChoiceAnswers, 'number');
        if (!lastAnswer) return;
        setQuestion({ ...question, multipleChoiceAnswers: [...(question.multipleChoiceAnswers || []), createEmptyAnswer(lastAnswer.number + 1)] });
    };

    const onDropAnswer = (from: number, to: number) => {
        if (!question.matchingOrOrderingAnswers || !question.matchingOrOrderingAnswers.left) return;
        const left = [...question.matchingOrOrderingAnswers.left];
        const fromEl = left[from];
        const toEl = left[to];

        left[to] = fromEl;
        left[from] = toEl;
        setQuestion({ ...question, matchingOrOrderingAnswers: { ...question.matchingOrOrderingAnswers, left } });
    };

    const handleDeleteAnswer = (answerNumber: number) => (event: React.FormEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        const newAnswers = (question.multipleChoiceAnswers || []).filter(a => a.number !== answerNumber);
        setFormFieldErrors({ ...formFieldErrors, [`answer${answerNumber}`]: '' });
        setQuestion({ ...question, multipleChoiceAnswers: newAnswers });
    };

    const handleAddMatchingAnswer = (event: React.FormEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        if (question.matchingOrOrderingAnswers) {
            const lastAnswer = _.maxBy(question.matchingOrOrderingAnswers.left, 'number');
            if (!lastAnswer) return;
            setQuestion({
                ...question,
                matchingOrOrderingAnswers: {
                    ...question.matchingOrOrderingAnswers,
                    left: [...question.matchingOrOrderingAnswers.left, createEmptyMatchingAnswer(lastAnswer.number + 1)],
                    right: [...question.matchingOrOrderingAnswers.right, createEmptyMatchingAnswer(lastAnswer.number + 1)],
                },
            });
        }
    };

    const handleDeleteMatchingAnswer = (questionNumber: number) => (event: React.FormEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        if (question.matchingOrOrderingAnswers) {
            const { left, right } = question.matchingOrOrderingAnswers;
            const newLeftAnswers = left.filter(a => a.number !== questionNumber);
            const newRightAnswers = right.filter(a => a.number !== questionNumber);
            const newCorrect = {};

            setFormFieldErrors({ ...formFieldErrors, [`answer${questionNumber}`]: '' });
            setQuestion({
                ...question,
                matchingOrOrderingAnswers: {
                    ...question.matchingOrOrderingAnswers,
                    left: newLeftAnswers,
                    right: newRightAnswers,
                    correct: newCorrect,
                },
            });
        }
    };

    const handleClickSwitch = () => {
        const newQuestion = { ...question, active: !question.active };
        setQuestion(newQuestion);
    };

    const handleChangeTextInput = (name: string, value: string) => {
        setQuestion({ ...question, openAnswer: value });
        setFormFieldErrors({ ...formFieldErrors, openAnswer: '' });
    };

    const onChangeSelect = ({ questionNumber, matchingNumber }: { questionNumber: number; matchingNumber: number }) => {
        if (!question.matchingOrOrderingAnswers) return;

        const newCorrect = question.matchingOrOrderingAnswers.correct;
        newCorrect[questionNumber] = matchingNumber;

        setQuestion({
            ...question,
            matchingOrOrderingAnswers: {
                ...question.matchingOrOrderingAnswers,
                correct: newCorrect,
            },
        });
    };

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const validationErrors = getValidationErrors(question, questionType);

        if (isValidFormData(validationErrors)) {
            const res = await onSubmit(question);

            if (!errorRef.current && res) {
                onExit();
            }
        } else {
            setFormFieldErrors(validationErrors);
        }
    };

    const onChangeLeftHeader = (text: string) => {
        if (!question.matchingOrOrderingAnswers) return;

        setQuestion({
            ...question,
            matchingOrOrderingAnswers: {
                ...question.matchingOrOrderingAnswers,
                leftAnswersHeader: text,
            },
        });
    };

    const onChangeRightHeader = (text: string) => {
        if (!question.matchingOrOrderingAnswers) return;

        setQuestion({
            ...question,
            matchingOrOrderingAnswers: {
                ...question.matchingOrOrderingAnswers,
                rightAnswersHeader: text,
            },
        });
    };

    const onChangeRadio = (type: TheoreticalQuestionType) => {
        if (!theoreticalQuestion && emptyQuestion.matchingOrOrderingAnswers) {
            setQuestionType(type);
            setFormFieldErrors({});
            setQuestion({
                ...emptyQuestion,
                matchingOrOrderingAnswers: {
                    ...emptyQuestion.matchingOrOrderingAnswers,
                    correct: {},
                },
                text: question.text,
                number: question.number,
                active: question.active,
                type,
            });
        }
    };

    return (
        <Form onSubmit={submit} autoComplete="off">
            <div className={styles.radio_section}>
                <Form.Group className="mr-4 mb-0">
                    <CustomRadio
                      className={styles.radio_block}
                      label="Мультивыбор"
                      name="questionType"
                      onClick={() => onChangeRadio(TheoreticalQuestionType.MultipleChoiceTheoretical)}
                      option={TheoreticalQuestionType.MultipleChoiceTheoretical}
                      checked={questionType === TheoreticalQuestionType.MultipleChoiceTheoretical}
                      disabled={!!theoreticalQuestion}
                    />
                    <CustomRadio
                      className={styles.radio_block}
                      label="Открытый ответ"
                      name="questionType"
                      onClick={() => onChangeRadio(TheoreticalQuestionType.OpenAnswerTheoretical)}
                      option={TheoreticalQuestionType.OpenAnswerTheoretical}
                      checked={questionType === TheoreticalQuestionType.OpenAnswerTheoretical}
                      disabled={!!theoreticalQuestion}
                    />
                </Form.Group>
                <Form.Group>
                    <CustomRadio
                      className={styles.radio_block}
                      label="Соответствие"
                      name="questionType"
                      onClick={() => onChangeRadio(TheoreticalQuestionType.MatchingTheoretical)}
                      option={TheoreticalQuestionType.MatchingTheoretical}
                      checked={questionType === TheoreticalQuestionType.MatchingTheoretical}
                      disabled={!!theoreticalQuestion}
                    />
                    <CustomRadio
                      className={styles.radio_block}
                      label="Последовательность"
                      name="questionType"
                      onClick={() => onChangeRadio(TheoreticalQuestionType.OrderingTheoretical)}
                      option={TheoreticalQuestionType.OrderingTheoretical}
                      checked={questionType === TheoreticalQuestionType.OrderingTheoretical}
                      disabled={!!theoreticalQuestion}
                    />
                </Form.Group>
            </div>
            <InputText
              inputGroupClassNames={cn('mb-4 mt-4', styles.id_input)}
              inputClassNames="d-flex align-items-center"
              name="number"
              value={`${question.number}`}
              labelTitle="№"
              onChange={setQuestionNumber}
              fieldFailures={formFieldErrors}
            >
                <CustomSwitch containerClassName="ml-4" name="setActive" value={question.active} onClick={handleClickSwitch} />
            </InputText>
            <Col sm="12" className="p-0 mb-5">
                <CKEditor
                  data={question.text}
                  onChange={setQuestionText}
                >
                    <Form.Control.Feedback type="invalid" className="mt-0 d-block">
                        {_.has(formFieldErrors, 'text') && formFieldErrors.text.length > 0
                            ? formFieldErrors.text : null}
                    </Form.Control.Feedback>
                </CKEditor>
            </Col>

            {questionType === TheoreticalQuestionType.MultipleChoiceTheoretical && question.multipleChoiceAnswers && (
                <>
                    <p>Варианты ответов:</p>
                    {question.multipleChoiceAnswers.map((answer, i) => (
                        <MultipleChoiceTheoreticalQuestion
                          key={`multipleChoiceQuestion_${i}`}
                          answer={answer}
                          formFieldErrors={formFieldErrors}
                          multipleChoiceAnswersLength={question.multipleChoiceAnswers ? question.multipleChoiceAnswers.length : 0}
                          changeCorrectAnswer={changeCorrectAnswer}
                          handleChangeAnswerText={handleChangeAnswerText}
                          onAdd={handleAddAnswer}
                          onDelete={handleDeleteAnswer}
                          num={i}
                        />
                    ))}
                </>
            )}

            {(questionType === TheoreticalQuestionType.MatchingTheoretical || questionType === TheoreticalQuestionType.OrderingTheoretical) && question.matchingOrOrderingAnswers && (
                <MatchingOrOrderingTheoreticalQuestion
                  question={question}
                  onChangeLeftHeader={onChangeLeftHeader}
                  onChangeRightHeader={onChangeRightHeader}
                  formFieldErrors={formFieldErrors}
                  handleChangeMatchingAnswerText={handleChangeMatchingAnswerText}
                  handleChangeOrderingAnswerText={handleChangeOrderingAnswerText}
                  onAddQuestionVariant={handleAddMatchingAnswer}
                  onDeleteQuestionVariant={handleDeleteMatchingAnswer}
                  setFormFieldErrors={setFormFieldErrors}
                  onChangeSelect={onChangeSelect}
                  onDropAnswer={onDropAnswer}
                />
            )}

            {questionType === TheoreticalQuestionType.OpenAnswerTheoretical ? (
                <InputText
                  inputGroupClassNames={cn('mb-4', styles.id_input)}
                  inputClassNames="d-flex align-items-center"
                  name="answer"
                  value={question.openAnswer || ''}
                  labelTitle="Ответ"
                  onChange={handleChangeTextInput}
                  fieldFailures={formFieldErrors}
                />
            ) : null}

            {formFieldErrors && formFieldErrors.general ? (
                <div className="row">
                    <Form.Text className="col p-0 m-0">
                        <p className="nav-link m-0 p-0 text-danger">
                            {formFieldErrors.general}
                        </p>
                    </Form.Text>
                </div>
            ) : null}
            <div className="mt-4">
                <LoaderBtn
                  type="submit"
                  variant="primary"
                  loading={loading}
                >
                    Сохранить
                </LoaderBtn>
                <Button onClick={onExit} variant="outline-secondary" className="ml-4">Отмена</Button>
            </div>
        </Form>
    );
};

export default TheoreticalQuestionRedactor;

interface IProps {
    theoreticalQuestion: ITheoreticalQuestion | null;
    errors: IApiError | null;
    loading?: boolean;
    onSubmit: (question: ITheoreticalQuestion) => Promise<boolean>;
    onExit: () => void;
}

interface IState {
    question: ITheoreticalQuestion;
    questionType: TheoreticalQuestionType;
    formFieldErrors: IFieldsError;
}

const isValidFormData = (fieldsError: IFieldsError): boolean => _.values(fieldsError).every(e => e.length === 0);

const getValidationErrors = (data: ITheoreticalQuestion, questionType: TheoreticalQuestionType): IFieldsError => {
    const requiredErrorText = 'Обязательное поле для заполнения';
    const res = Object.keys(data).reduce((acc, key) => {
        switch (key) {
            case 'number':
                return { ...acc, number: data.number === 0 ? 'Введите номер отличный от 0' : '' };
            case 'text':
                return { ...acc, text: data.text === '' ? requiredErrorText : '' };
            default:
                return acc;
        }
    }, {});

    if (questionType === TheoreticalQuestionType.MultipleChoiceTheoretical) {
        const answers = Object.keys(data).reduce((acc, key) => {
            switch (key) {
                case 'multipleChoiceAnswers':
                    return {
                        ...acc,
                        ...(data.multipleChoiceAnswers || []).reduce((y, x) => (
                            { ...y, [`multipleChoiceAnswer_${x.number}`]: (x.text === '' ? requiredErrorText : '') }),
                            {}),
                    };
                default:
                    return acc;
            }
        }, {});

        const correctAnswerSelected = (data.multipleChoiceAnswers || []).some(({ correct }) => correct);
        return correctAnswerSelected ? { ...res, ...answers } : { ...res, ...answers, general: 'Не выбран правильный ответ' };
    }

    if (questionType === TheoreticalQuestionType.MatchingTheoretical && data.matchingOrOrderingAnswers) {
        const { correct, left, right } = data.matchingOrOrderingAnswers;
        const allCorrectSelected = Object.keys(correct).length === left.length;
        const matchingOrOrderingAnswers = Object.keys(data).reduce((acc, key) => {
            switch (key) {
                case 'matchingOrOrderingAnswers':
                    return {
                        ...acc,
                        ...(left || []).reduce((y, x) => (
                            { ...y, [`answer${x.number}`]: (x.text === '' ? requiredErrorText : '') }),
                            {}),
                        ...(right || []).reduce((y, x) => (
                                { ...y, [`answer_right_${x.number}`]: (x.text === '' ? requiredErrorText : '') }),
                            {}),
                    };
                default:
                    return acc;
            }
        }, {});

        return allCorrectSelected ? { ...res, ...matchingOrOrderingAnswers } : { ...res, ...matchingOrOrderingAnswers, general: 'Заполните все последовательности' };
    }

    if (questionType === TheoreticalQuestionType.OrderingTheoretical && data.matchingOrOrderingAnswers) {
        const { correct, left } = data.matchingOrOrderingAnswers;
        const allCorrectSelected = Object.keys(correct).length === left.length;
        const matchingOrOrderingAnswers = Object.keys(data).reduce((acc, key) => {
            switch (key) {
                case 'matchingOrOrderingAnswers':
                    return {
                        ...acc,
                        ...(left || []).reduce((y, x) => (
                            { ...y, [`answer${x.number}`]: (x.text === '' ? requiredErrorText : '') }),
                            {}),
                    };
                default:
                    return acc;
            }
        }, {});

        return allCorrectSelected ? { ...res, ...matchingOrOrderingAnswers } : { ...res, ...matchingOrOrderingAnswers, general: 'Заполните все последовательности' };
    }

    return res;
};

const createEmptyAnswer = (number: number): ITheoreticalAnswer => ({
    number,
    text: '',
    correct: false,
});

const createEmptyMatchingAnswer = (number: number): IMatchingTheoreticalAnswer => ({
    number,
    text: '',
});

const emptyQuestion: ITheoreticalQuestion = {
    number: 0,
    text: '',
    multipleChoiceAnswers: [1, 2].map(createEmptyAnswer),
    active: true,
    type: TheoreticalQuestionType.MultipleChoiceTheoretical,
    matchingOrOrderingAnswers: {
        left: [1, 2].map(createEmptyMatchingAnswer),
        right: [1, 2].map(createEmptyMatchingAnswer),
        leftAnswersHeader: undefined,
        correct: {},
    },
};
