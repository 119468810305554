import { ApiError, ApiErrorFailures } from './ApiError';
import _ from 'lodash';

export const mapAPIError = (apiError: ApiError | null): ApiErrorFailures | null => {
    if (!apiError) return null;
    if (_.isEmpty(apiError.failures)) {
        return { general: apiError.message };
    }
    return _.mapValues(
        apiError.failures,
        errors => (errors.length > 2 ? errors.join(', ') : errors[0]),
    );
};
