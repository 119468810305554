import React from 'react';
import { Tabs as BootstrapTab, TabsProps } from 'react-bootstrap';
import styles from './AnimatedTabs.module.scss';
import cn from 'classnames';

const AnimatedTabs = ({
    inModal, children, className, activeKey, ...props
}: IProps) => {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const [tabBorderStyle, setTabBorderStyle] = React.useState<React.CSSProperties>({});
    const [key, setKey] = React.useState<string | undefined>(undefined);

    const moveTabBorder = () => {
        if (ref.current) {
            const tabs = ref.current.querySelectorAll('a');
            const tabsLength = tabs.length;
            const firstTab: HTMLAnchorElement | null = tabs[0];
            const firstTabPaddingLeft = Math.ceil(parseFloat(window.getComputedStyle(firstTab, null).paddingLeft));
            const leftStartPosition = -firstTab.getBoundingClientRect().x - firstTabPaddingLeft + (inModal ? -30 : 2);
            let selectedTab: HTMLAnchorElement | null = null;
            let left = 0;
            let top = 0;

            for (let i = 0; i < tabsLength; i += 1) {
                const tab = tabs[i];
                const ariaSelected = tab.getAttribute('aria-selected');
                const tabBound = tab.getBoundingClientRect();

                left = Math.ceil(leftStartPosition + tabBound.x);

                if (ariaSelected === 'true') {
                    selectedTab = tab;
                    top = tabBound.height + (inModal ? 5 : 7);
                    break;
                }
            }

            if (selectedTab) {
                const tabStyle = window.getComputedStyle(selectedTab, null);
                const paddingLeft = parseFloat(tabStyle.paddingLeft);
                const paddingRight = parseFloat(tabStyle.paddingRight);
                const width = selectedTab.clientWidth - (paddingLeft + paddingRight);
                const firstTabY = firstTab.getBoundingClientRect().y;
                const selectedTabY = selectedTab.getBoundingClientRect().y;

                if (firstTabY < selectedTabY) {
                    top += selectedTabY - firstTabY;
                }

                left += paddingLeft;

                setTabBorderStyle({ width, left, top });
            }
        }
    };

    React.useEffect(() => {
        setKey(activeKey as string);
    }, [activeKey]);

    React.useEffect(() => {
        moveTabBorder();
    }, [key]);

    const onSelectKey = (newKey: string, e: any) => {
        if (props.onSelect) {
            props.onSelect(newKey, e);
        } else {
            setKey(newKey);
        }
    };

    return (
        <section ref={ref}>
            <div className={styles.TabBorder} style={tabBorderStyle} />
            <BootstrapTab
              {...props}
              onSelect={onSelectKey}
              activeKey={key}
              className={cn('tabsNav', 'animatedTabsNav', styles.Tabs, inModal && styles.Tabs_inModal, !inModal && 'pl-3', className)}
            >
                {children}
            </BootstrapTab>
        </section>
    );
};

export default AnimatedTabs;

interface IProps extends TabsProps {
    children?: React.ReactNode;
    className?: string;
    inModal?: boolean;
}
