import React from 'react';
import {
    FormGroup,
    FormLabel,
    FormControl,
    Form,
} from 'react-bootstrap';
import { IApiError } from '../../_helpers/ApiConnector/types';

const Captcha = ({
    apiError, captchaValid, captcha, setCaptcha,
}: IProps) => {
    if (apiError && apiError.failures && apiError.failures.captcha) {
        return (
            <FormGroup className="mb-0" data-test-captcha-group>
                <FormLabel>Введите цифры с картинки</FormLabel>
                <div className="captcha_group">
                    <FormControl
                      name="captcha"
                      type="text"
                      onChange={(e: React.FormEvent<any>) => setCaptcha((e.target as HTMLInputElement).value.replace(/[^\d]/, ''))}
                      value={captcha}
                      isInvalid={!captchaValid}
                      maxLength={4}
                      className="captcha_field"
                    />
                    <div className="captcha_image_group">
                        <img src={apiError.failures.captcha[0]} alt="captcha_image" />
                    </div>
                </div>
                <Form.Control.Feedback className="d-block" type="invalid">
                    {!captchaValid && 'Длина цифр с картинки должна быть 4 символа'}
                </Form.Control.Feedback>
            </FormGroup>
        );
    }
    return null;
};

export default Captcha;

interface IProps {
    captcha: string;
    captchaValid: boolean;
    apiError?: IApiError | null;
    setCaptcha: (captcha: string) => void;
}
