import * as React from 'react';
import styles from './StepStatus.module.scss';
import cn from 'classnames';
import LikeIcon from '../Common/icons/LikeIcon';
import WaitTimeIcon from '../assets/svg/wait_time_icon.svg';
import RejectedIcon from '../assets/svg/cross_cancel_icon.svg';

const icons = {
    error: () => <img src={RejectedIcon} alt="await" />,
    loading: () => <img src={WaitTimeIcon} alt="await" />,
    success: () => <LikeIcon />,
    default: () => <img src={WaitTimeIcon} alt="await" />,
};

const StepStatus: React.FC<IProps> = ({ step }) => (
  <div className={cn(styles.step, styles[step.status])}>
      <div className={styles.label}>{step.label}: <b>{step.value}</b></div>
      <div className={styles.icon}>
          {icons[step.status]()}
      </div>
  </div>
);

interface IProps {
    step: {
        value?: string;
        label: string;
        status: 'success' | 'error' | 'loading';
    };
}

export default StepStatus;
