import * as React from 'react';
import cn from 'classnames';

import { Moment } from 'moment';
import { getMoscowTime } from '_helpers/formatHelpers';

import styles from './TimePicker.module.scss';

const TimePicker: React.FC<IProps> = (props) => {
    const preparedTime = props.time.clone().local();
    const handleHoursChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const hour = parseInt(e.target.value);
        const newTime = props.time.clone().local();
        newTime.hour(hour);

        props.onChange(newTime);
    };

    const handleMinutesChange = (e: React.FormEvent<HTMLSelectElement>) => {
        const minute = parseInt(e.currentTarget.value);
        const newTime = props.time.clone().local();
        newTime.minute(minute);

        props.onChange(newTime);
    };

    const { moscowHours, moscowMinutes } = getMoscowTime(preparedTime);

    return (
      <div className={styles.timePickerContainer}>
          <div className={cn(styles.timePicker, { [styles.disabled]: !!props.disabled })}>
              <select value={preparedTime.hour()} onChange={handleHoursChange} disabled={props.disabled}>
                  {([1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4]).map((m, i) => <option key={i} value={i}>{(`0${i}`).slice(-2)}</option>)}
              </select>
              <span>:</span>
              <select value={preparedTime.minute()} onChange={handleMinutesChange} disabled={props.disabled}>
                  {([0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]).map(m => (
                    <option key={m} value={m}>{(`0${m}`).slice(-2)}</option>
                  ))}
              </select>
          </div>
          <span className="ml-4">По МСК {`${moscowHours}:${moscowMinutes}`}</span>
      </div>
    );
};

interface IProps {
    disabled?: boolean;
    time: Moment;
    onChange: (newTime: Moment) => void;
}

export default TimePicker;
