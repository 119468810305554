import React from 'react';
import {
    IApplicantExam,
    ExamStatus,
} from '../../api/ApiClient';
import { connect } from 'react-redux';
import { ExamTable, Card, MappedExamApplicationStatuses } from 'components';
import { push } from 'connected-react-router';
import { Color, ColorStatus } from '_helpers';
import { IPaginationData } from 'components/Pagination/Pagination';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { useLocalErrorHandler } from 'hooks';
import query from '../../helpers/const/query';

const ExamRecognitionContainer = ({
    setErrorHandler,
    fetchExams,
    onPush,
    examsList,
    apiError,
}: Props) => {
    useLocalErrorHandler(setErrorHandler);

    const [processing, setProcessing] = React.useState(false);

    React.useEffect(() => {
        const fetch = async () => {
            setProcessing(true);
            await fetchExams({});
            setProcessing(false);
        };

        fetch();
    }, []);

    const handleChooseExam = (id?: string) => {
        if (!id) return;
        onPush(`/exam/${id}?from=${query.FROM.RECOGNITION}`);
    };

    const getExamData = (exam: IApplicantExam) => ({
        ...exam,
        id: exam.examId,
        status: statuses[exam.examApplicationStatus || exam.examStatus] || new ColorStatus(Color.Default, (exam.examApplicationStatus || exam.examStatus).toString()),
        name: exam.qualificationTitle,
    });

    const handleChangePagination = (newPagination: IPaginationData) => {
        fetchExams({
            page: newPagination.page,
            pageSize: newPagination.perPage,
        });
    };

    const exams = (examsList && examsList.items) || [];
    const tableRows = exams.map(getExamData);

    return (
        <Card
          withoutPadding
          title="Признание аттестата"
          category="Список всех аккредитаций по признанию аттестатов, открытых для записи, а также тех, на которые вы записаны"
          apiError={apiError}
          showLoader={processing}
        >
            <ExamTable
              header={HEADER}
              tableRows={tableRows}
              onChooseExam={handleChooseExam}
              pagination={examsList}
              onChangePagination={handleChangePagination}
              placeholderTitle="Нет аккредитаций в списке"
              placeholderText="Здесь будут отображаться все доступные аккредитации"
            />
        </Card>
    );
};

const HEADER = [
    'ПЕРИОД ПРОВЕДЕНИЯ',
    'Специальность',
    'Статус',
];

const statuses = {
    ...MappedExamApplicationStatuses,
    [ExamStatus.IsApplying as string]: new ColorStatus(Color.Success, 'Идет запись'),
    [ExamStatus.ApplyingFinished as string]: new ColorStatus(Color.Orange, 'Запись окончена'),
};


const mapState = (state: IRootState) => ({
    examsList: state.exam.examsList,
    apiError: state.errors.error,
});

const mapDispatch = (dispatch: Dispatch) => ({
    fetchExams: dispatch.exam.fetchExams,
    setErrorHandler: dispatch.errors.setErrorHandler,
    onPush: (key: string) => dispatch(push(key)),
});

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
type Props = connectedProps;

export default connect(mapState, mapDispatch)(ExamRecognitionContainer);
