import React from 'react';
import {connect} from 'react-redux';
import {Card, DefaultMultiSelectSearch, ExamTable, MultiSelectSearch,} from 'components';
import {IApplicantExam, QualificationInfoList} from '../../api/ApiClient';
import {Color, ColorStatus} from '_helpers';
import {IPaginationData} from 'components/Pagination/Pagination';
import {Dispatch, IRootState} from '../../redux/store/configureStore';
import {useLocalErrorHandler, useMultiSearch} from 'hooks';
import query from '../../helpers/const/query';
import {useHistory} from 'react-router';
import {IExamsFilters, IFilterPart, IFromToDatePart} from 'components/models';
import DefaultSelectSearch from 'components/MultiSelectSearch/SearchElements/DefaultSelectSearch';
import {ResidencyOptions} from 'components/enums';

const ExamTimeTablesContainer = (
    {
        setErrorHandler,
        fetchExams,
        examsList,
        apiError,
        ...props
    }: Props,
) => {
    useLocalErrorHandler(setErrorHandler);

    const { push } = useHistory();

    const {
        filters, changeFilterField, changeFilter, filterSetter,
    } = useMultiSearch<{ residency?: boolean } & IExamsFilters & IFromToDatePart & IFilterPart>(fetchExams, {
        qualificationIds: props.filterState && props.filterState.qualificationIds,
        regionIds: props.filterState && props.filterState.regionIds || undefined,
    });

    const [processing, setProcessing] = React.useState(false);
    
    const initFilterState = async (qualifications?: QualificationInfoList | null) => {
        if(!props.profile) return null;
        const qualificationId = props.profile.qualificationId;
        const qualification = qualifications ? qualifications.items.find(i => i.id === qualificationId) : null;
        return props.filterState || await props.initFilterState({
            residency: qualification && qualification.residency,
            qualificationIds: [props.profile.qualificationId],
            regionIds: [props.profile.regionId],
        });
    };

    const fetch = async () => {
        setProcessing(true);
        const qualifications = props.qualifications || await props.fetchQualifications({ pageSize: 500 });
        props.regions || await props.getRegions({});
        const filterState = await initFilterState(qualifications);
        if(filterState) {
            changeFilter({
                qualificationIds: filterState.qualificationIds,
                regionIds: filterState.regionIds,
                residency: filterState.residency,
            });
        }
        setProcessing(false);
    };

    React.useEffect(() => {
        props.profile && !props.filterState && fetch();
    }, [props.profile]);

    React.useEffect(() => {
        props.profile && fetch();
    }, []);

    const handleChooseExam = (id?: string) => {
        if (!id) return;
        push(`/exam/${id}?from=${query.FROM.TIMETABLES}`);
    };

    const getExamData = (exam: IApplicantExam) => ({
        ...exam,
        id: exam.examId,
        status: new ColorStatus(Color.Default, exam.city),
        name: exam.qualificationTitle,
    });

    const handleChangePagination = (newPagination: IPaginationData) => {
        fetchExams({
            page: newPagination.page,
            pageSize: newPagination.perPage,
        });
    };

    const exams = (examsList && examsList.items) || [];
    const tableRows = exams.map(getExamData);

    return (
        <Card
            withoutPadding
            title="Доступные аккредитации"
            category="Список всех доступных для записи аккредитаций"
            apiError={apiError}
            showLoader={processing}
        >
            <MultiSelectSearch>
                <DefaultMultiSelectSearch
                    label="РЕГИОН"
                    options={props.regions ? props.regions.map(r => ({
                        value: String(r.id),
                        label: r.name,
                    })) : []}
                    placeholder="Выберите один или несколько регионов"
                    value={filters.regionIds}
                    onChange={changeFilterField('regionIds')}
                />
                <DefaultSelectSearch
                    isClearable={false}
                    label="ОБРАЗОВАНИЕ"
                    value={filters.residency !== undefined ? String(filters.residency) : undefined}
                    options={ResidencyOptions}
                    placeholder="Выберите ваш уровень образования"
                    onChange={(e) => {
                        changeFilter({
                            residency: e.value ? e.value === 'true' : undefined,
                            qualificationIds: e.value !== String(filters.residency) ? [] : filters.qualificationIds,
                        });
                    }}
                />
                <DefaultMultiSelectSearch
                    withoutStrictValue={false}
                    label="СПЕЦИАЛЬНОСТЬ"
                    value={filters.qualificationIds ? filters.qualificationIds : undefined}
                    options={props.qualifications ? props.qualifications.items.filter(q => q.residency === filters.residency).map(q => ({
                        value: String(q.id),
                        label: `${q.regNumber} - ${q.title}`,
                    })) : []}
                    placeholder="Выберите одну или несколько специальностей"
                    onChange={changeFilterField('qualificationIds')}
                />
            </MultiSelectSearch>

            <ExamTable
                header={HEADER}
                tableRows={tableRows}
                onChooseExam={handleChooseExam}
                pagination={examsList}
                onChangePagination={handleChangePagination}
                placeholderTitle="Нет аккредитаций в списке"
                placeholderText="Здесь будут отображаться все доступные аккредитации"
            />
        </Card>
    );
};

const HEADER = [
    'ПЕРИОД ПРОВЕДЕНИЯ',
    'Специальность',
    'Регион',
];

const mapState = (state: IRootState) => ({
    examsList: state.exam.examsList,
    filterState: state.exam.filterState,
    profile: state.auth.profile,
    regions: state.regions.list,
    qualifications: state.qualifications.list,
    apiError: state.errors.error,
});

const mapDispatch = (dispatch: Dispatch) => ({
    fetchExams: dispatch.exam.fetchExamsWithStateSafe,
    fetchProfile: dispatch.auth.fetchProfile,
    initFilterState: dispatch.exam.initFilterState,
    fetchQualifications: dispatch.qualifications.getQualificationsAsync,
    getRegions: dispatch.regions.getRegionsAsync,
    setErrorHandler: dispatch.errors.setErrorHandler,
});

export default connect(mapState, mapDispatch)(ExamTimeTablesContainer);

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
type Props = connectedProps;
