import * as React from 'react';

const ExpertsIcon: React.FC = () => (
  <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.09033 3.29677C7.09033 5.12581 8.55807 6.59355 10.3871 6.59355C12.1936 6.59355 13.6839 5.12581 13.6839 3.29677C13.6839 1.46774 12.1936 0 10.3871 0C8.55807 0 7.09033 1.46774 7.09033 3.29677ZM7.99356 3.29677C7.99356 1.9871 9.07743 0.903226 10.3871 0.903226C11.6968 0.903226 12.7807 1.9871 12.7807 3.29677C12.7807 4.60645 11.6968 5.69032 10.3871 5.69032C9.07743 5.69032 7.99356 4.60645 7.99356 3.29677Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M14.6096 4.51617C14.6096 5.80327 15.6709 6.86456 16.958 6.86456C18.2451 6.86456 19.3064 5.80327 19.3064 4.51617C19.3064 3.22908 18.2451 2.16779 16.958 2.16779C15.6709 2.16779 14.6096 3.22908 14.6096 4.51617ZM15.5128 4.51617C15.5128 3.72585 16.1677 3.07101 16.958 3.07101C17.7483 3.07101 18.4032 3.72585 18.4032 4.51617C18.4032 5.3065 17.7483 5.96133 16.958 5.96133C16.1677 5.96133 15.5128 5.3065 15.5128 4.51617Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M1.46777 4.51617C1.46777 5.80327 2.52906 6.86456 3.81616 6.86456C5.10326 6.86456 6.16455 5.80327 6.16455 4.51617C6.16455 3.22908 5.10326 2.16779 3.81616 2.16779C2.52906 2.16779 1.46777 3.22908 1.46777 4.51617ZM2.371 4.51617C2.371 3.72585 3.02584 3.07101 3.81616 3.07101C4.60648 3.07101 5.26132 3.72585 5.26132 4.51617C5.26132 5.3065 4.60648 5.96133 3.81616 5.96133C3.02584 5.96133 2.371 5.3065 2.371 4.51617Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M15.0161 11.742C15.4903 11.8097 16.0774 11.8549 16.9806 11.8549C18.6742 11.8549 20.5935 11.8549 20.5709 9.8226C20.5483 7.74518 18.8774 5.98389 16.9354 5.98389C15.6258 5.98389 14.3838 6.79679 13.7516 8.06131L13.6387 8.28711L13.7967 8.51292C14.3613 9.3484 14.6548 10.2742 14.6548 11.2V11.6742L15.0161 11.742ZM16.9806 10.9516C16.3709 10.9516 15.9193 10.9516 15.558 10.9065C15.4903 9.98066 15.2193 9.07744 14.7 8.24195C15.2193 7.40647 16.0774 6.88711 16.958 6.88711C18.4032 6.88711 19.6677 8.26453 19.6903 9.8226C19.6903 10.7484 19.2161 10.9516 16.9806 10.9516Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M0.203125 9.8226C0.203125 11.8549 2.12248 11.8549 3.81603 11.8549C4.71925 11.8549 5.30635 11.8097 5.78054 11.742L6.11925 11.6516V11.1774C6.09667 10.0258 6.57087 9.10002 6.95474 8.51292L7.1128 8.28711L6.9999 8.06131C6.36764 6.79679 5.12571 5.98389 3.81603 5.98389C1.85151 5.98389 0.203125 7.74518 0.203125 9.8226ZM1.10635 9.8226C1.10635 8.26453 2.37087 6.88711 3.81603 6.88711C4.69667 6.88711 5.55474 7.40647 6.07409 8.26453C5.55474 9.07744 5.28377 10.0032 5.23861 10.9065C4.87732 10.929 4.42571 10.9516 3.81603 10.9516C1.58054 10.9516 1.10635 10.7484 1.10635 9.8226Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M5.21606 11.2C5.21606 14 7.83542 14 10.387 14C12.9386 14 15.558 14 15.558 11.2C15.558 8.2645 13.1419 5.69031 10.387 5.69031C7.63219 5.69031 5.21606 8.2645 5.21606 11.2ZM6.11929 11.2C6.11929 8.73869 8.10639 6.59353 10.387 6.59353C12.6677 6.59353 14.6548 8.73869 14.6548 11.2C14.6548 12.8258 13.6386 13.0968 10.387 13.0968C7.13542 13.0968 6.11929 12.8258 6.11929 11.2Z" />
      </g>
  </svg>

);

export default ExpertsIcon;
