/* eslint-disable arrow-parens */
/* eslint-disable no-underscore-dangle */
import {
    appVersion,
    loadFromLocalStorage,
    timingSynchronizer,
    hasAccessTokenInLocalStorage,
} from '_helpers';
import { AppExceptionType, ExecAPiOptions, IApiError } from './types';
import { RematchDispatch } from '@rematch/core';
import { push } from 'connected-react-router';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

import unfetch from 'unfetch';
import _ from 'lodash';

interface IApiConfig {
    API_BASE_URL: string;
}

export interface IOnlineEvent {
    key: string;
    method: (...args: any[]) => void;
}

export interface IOnlineMethods {
    init?: () => void;
}


export class ApiConnector<T, M> {
    private readonly _apiClient: T;
    private readonly _onlineEventsSubscriber?: (dispatch: RematchDispatch) => IOnlineEvent[];
    private readonly _url: string;
    private _startConnectionPromise?: Promise<void>;
    private _handleStartConnection?: () => void;
    private _onlineMethodsClient?: { new(hubConnection: HubConnection): M };
    private _onlineMethods?: M & IOnlineMethods;
    private _connection?: HubConnection;

    constructor(
        ApiClient: { new(baseUrl?: string, http?: { fetch: (url: RequestInfo, init?: RequestInit | undefined) => Promise<Response> }): T },
        apiConfig: IApiConfig,
        onlineEventsSubscriber?: (dispatch: RematchDispatch) => IOnlineEvent[],
        onlineMethods?: { new(hubConnection: HubConnection): M },
    ) {
        if (window.location.href.startsWith('https:')) {
            this._url = apiConfig.API_BASE_URL.replace('http:', 'https:');
        } else this._url = apiConfig.API_BASE_URL;
        this._apiClient = new ApiClient(this._url, {
            fetch: fetchWithSetHeaderToken,
        });
        this._startConnectionPromise = new Promise<void>(resolve => {
            this._handleStartConnection = () => {
                resolve();
                delete this._startConnectionPromise;
            };
        });
        this._onlineEventsSubscriber = onlineEventsSubscriber;
        this._onlineMethodsClient = onlineMethods;
    }

    get apiClient(): T {
        return this._apiClient;
    }

    get baseUrl() {
        return this._url;
    }

    private initOnline = (dispatch: RematchDispatch) => {
        if (!this._onlineEventsSubscriber || this._connection) return;

        const token = loadFromLocalStorage('token');
        if (!token) return;

        setTimeout(async () => {
            if (this._connection) return;
            this._connection = new HubConnectionBuilder()
                .withUrl(`${this._url}/api/online`, {
                    accessTokenFactory: () => token,
                })
                .withAutomaticReconnect([0, 2, 10, 10, 20, 20, 30, 30, 60, 60, 120, 120, 240])
                .build();


            try {
                await this._connection.start();

                if (this._handleStartConnection) {
                    this._handleStartConnection();
                }

                this._connection.onreconnecting(() => console.log('WebSocket Reconnecting'));
                this._connection.onreconnected(() => console.log('WebSocket Reconnected'));
                this._connection.onclose(() => console.log('WebSocket Closed'));

                if (this._onlineMethodsClient) {
                    this._onlineMethods = new this._onlineMethodsClient(this._connection);
                    try {
                        if (this._onlineMethods && this._onlineMethods.init) await this._onlineMethods.init();
                    } catch (e) {
                        console.error(e);
                    }
                    if (this._onlineEventsSubscriber) {
                        this._onlineEventsSubscriber(dispatch)
                            .forEach(ev => this._connection && this._connection.on(ev.key, ev.method));
                    }
                }
            } catch (err) {
                console.error(err);
            }

            (window as any).connection = this._connection;
        });
    };

    public sendSocketMessage = async <U>(socketMethod: (socket: M & IOnlineMethods) => Promise<U>): Promise<U | null> => {
        if (this._startConnectionPromise) {
            return this._startConnectionPromise.then(() => this._sendSocketMessage(socketMethod));
        }

        return this._sendSocketMessage(socketMethod);
    };

    private async _sendSocketMessage<U>(socketMethod: (socket: M & IOnlineMethods) => Promise<U>): Promise<U | null> {
        if (this._connection && this._onlineMethods && this._connection.state === HubConnectionState.Connected) {
            try {
                return await socketMethod(this._onlineMethods);
            } catch (e) {
                console.error(e);
                return null;
            }
        }

        return null;
    }

    public execApi = async <U>(dispatch: any, apiMethod: (api: T) => Promise<U>, options?: ExecAPiOptions): Promise<U | null> => {
        try {
            if (options && options.suppressErrorAndReturnNull) {
                try {
                    const result = await apiMethod(this.apiClient);
                    return result;
                } catch (e) {
                    console.error(e);

                    if (e.code === 401 || e.status === 401) {
                        handleUnAuthorizeError(e, dispatch);
                    }

                    return null;
                }
            }
            if (options && options.withoutErrorsHandle) {
                const result = await apiMethod(this.apiClient);
                return result;
            }
            try {
                if (!(options && options.eventStateRequest)) dispatch.errors.clean();
                const result = await apiMethod(this._apiClient);
                if (result && typeof (result) === 'object' && (result as any).eventsState && dispatch.eventsState && dispatch.eventsState.updateEventState) {
                    dispatch.eventsState.updateEventState((result as any).eventsState);
                }
                return result;
            } catch (e) {
                if (e.status === 204) return null;
                errorHandler(e, dispatch, options);
                return null;
            }
        } finally {
            this.initOnline(dispatch);
        }
    };
}

const fetchWithSetHeaderToken = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
    const customOptions = init || {};
    const token = loadFromLocalStorage('token');
    customOptions.headers = (customOptions.headers as {}) || {};

    if (!_.get(init, 'headers.Authorization') && token) {
        customOptions.headers.Authorization = `Bearer ${token}`;
        const statusCode = loadFromLocalStorage('statusCode');
        if (statusCode) customOptions.headers['X-Status-Code'] = statusCode;
    }

    customOptions.headers['X-App-Version'] = appVersion;

    const response = await unfetch(url, init);

    const serverVersion = response.headers.get('X-App-Version');
    if (serverVersion && serverVersion.indexOf(appVersion) !== 0) {
        document.location.reload();
    }

    const serverTime = response.headers.get('X-Server-Time');
    if (serverTime) timingSynchronizer.setDelta(serverTime);

    return response;
};

const createApiError = (code: number, message: string, type: AppExceptionType): IApiError => ({
    code,
    message,
    type,
});

const errorHandler = (e: any, dispatch: any, options?: ExecAPiOptions) => {
    if (options && options.eventStateRequest && (e.code || e.status !== 401)) {
        console.error(e);
        return;
    }

    if (process.env.NODE_ENV !== 'production') {
        console.error(e);
    }

    switch (e.code || e.status) {
        case 401:
            handleUnAuthorizeError(e, dispatch);
            dispatch.errors.handle(e);
            return;
        case 400:
        case 404:
            dispatch.errors.handle(e.response ? JSON.parse(e.response) : e);
            return;
        case 500:
            dispatch.errors.handle(createApiError(e.code || e.status, 'Ошибка работы сервера', AppExceptionType.Unspecified));
            return;
        default:
            dispatch.errors.handle(createApiError(0, 'Ошибка подключения к серверу', AppExceptionType.Unspecified));
    }
};


const handleUnAuthorizeError = (e: any, dispatch: any) => {
    if (hasAccessTokenInLocalStorage()) {
        dispatch.auth.logout();
        dispatch(push('/login'));
        window.location.reload();
    }
};

export default ApiConnector;
