import React from 'react';
import { SubmitExamApplication, ISubmitExamApplication } from '../../api/ApiClient';
import { Dispatch, IRootState } from '../../redux/store/configureStore';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { RegistrationForExam } from '../../components';

const RegistrationForExamContainer = ({
    exam,
    apiError,
    examId,
    countries,
    fetchExamDetails,
    documentTemplateGroups,
    examApplication,
    getDocumentTemplateGroups,
    getCountries,
    deleteExamApplicationDocument,
    deleteExamApplicationDocumentSource,
    uploadExamApplicationDocument,
    onPush,
    onGoBack,
    submitExamApplication,
    sendCodeToSignApplication,
    setApplicantOtherIdentity,
    setApplicantPassport,
    updateApplicantDiploma,
    updateProfile,
}: Props) => {
    const [processing, setProcessing] = React.useState(false);
    const [waitingSeconds, setWaitingSecond] = React.useState<number | undefined | null>(null);

    React.useEffect(() => {
        const fetch = async () => {
            setProcessing(true);
            if (examId) {
                const res = await fetchExamDetails(examId);

                if (res) {
                    await getDocumentTemplateGroups(res.exam.qualificationId);
                    await getCountries();
                }
            }
        };

        fetch();

        setProcessing(false);

        return () => setProcessing(false);
    }, [examId]);

    const goBack = () => {
        if (onGoBack) {
            onGoBack();
        }

        onPush('/exam-applications');
    };

    const deleteDocumentSource = (documentId: string, sourceFileId: string) => {
        if (exam && exam.examApplicationId) {
            deleteExamApplicationDocumentSource({ examApplicationId: exam.examApplicationId, documentId, sourceFileId });
        }
    };

    const deleteDocument = (documentId: string) => {
        if (exam && exam.examApplicationId) {
            deleteExamApplicationDocument({ examApplicationId: exam.examApplicationId, documentId });
        }
    };

    const uploadDocument = (templateId: string, file: File) => {
        if (exam && exam.examApplicationId) {
            uploadExamApplicationDocument({ examApplicationId: exam.examApplicationId, templateId, file });
        }
    };

    const documentsProps = {
        documentTemplateGroups: documentTemplateGroups || [],
        deleteDocumentSource,
        deleteDocument,
        uploadDocument,
        uploadedDocuments: examApplication ? examApplication.documents : [],
    };

    const sendingProps = {
        certificateDeliveryAddress: undefined,//examApplication ? examApplication.certificateDeliveryAddress : undefined,
        createExamApplication: async (body?: ISubmitExamApplication) => {
            let res = null;
            if (exam && exam.examApplicationId) {
                res = await submitExamApplication({
                    examApplicationId: exam.examApplicationId,
                    body: new SubmitExamApplication(body),
                });
            }

            return !!res;
        },
        sendCodeToSignApplication: async (captcha: string | undefined) => {
            let res = null;
            if (exam && exam.examApplicationId) {
                res = await sendCodeToSignApplication({
                    examApplicationId: exam.examApplicationId,
                    body: { captcha },
                });
                setWaitingSecond(res ? res.waitingSeconds : null);
            }

            return res;
        },
        waitingSeconds,
    };

    return (
        <RegistrationForExam
          examApplication={examApplication}
          apiError={apiError}
          processing={processing}
          goBack={goBack}
          documentsProps={documentsProps}
          countries={countries}
          sendingProps={sendingProps}
          setApplicantOtherIdentity={async (data, type) => !!await setApplicantOtherIdentity({data: data && data.data, type})}
          setApplicantPassport={async data => !!await setApplicantPassport(data)}
          updateApplicantDiploma={async data => !!await updateApplicantDiploma(data)}
          onUpdateProfile={async (data) => {
                const status = !!await updateProfile(data);
                status && await fetchExamDetails(examId);
                return status;
            }}
        />
    );
};

const mapState = (state: IRootState) => ({
    exam: state.exam.exam,
    countries: state.countries.list,
    examApplication: state.exam.examApplication,
    documentTemplateGroups: state.applicant.documentTemplateGroups,
    apiError: state.errors.error,
});

const mapDispatch = (dispatch: Dispatch) => ({
    submitExamApplication: dispatch.exam.submitExamApplication,
    fetchExamDetails: dispatch.exam.fetchExamDetails,
    getDocumentTemplateGroups: dispatch.applicant.getDocumentTemplateGroupsAsync,
    getCountries: dispatch.countries.getCountriesAsync,
    sendCodeToSignApplication: dispatch.applicant.sendCodeToSignApplicationAsync,
    deleteExamApplicationDocument: dispatch.exam.deleteExamApplicationDocument,
    uploadExamApplicationDocument: dispatch.exam.uploadExamApplicationDocument,
    deleteExamApplicationDocumentSource: dispatch.exam.deleteExamApplicationDocumentSourceAsync,
    setApplicantPassport: dispatch.applicant.setApplicantPassportAsync,
    updateApplicantDiploma: dispatch.applicant.updateApplicantDiplomaAsync,
    setApplicantOtherIdentity: dispatch.applicant.setApplicantOtherIdentityAsync,
    updateProfile: dispatch.auth.updateProfile,
    onPush: (key: string) => dispatch(push(key)),
});

export default connect(mapState, mapDispatch)(RegistrationForExamContainer);

interface IProps {
    examId: string;
    onGoBack: () => void;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
type Props = connectedProps & IProps;
